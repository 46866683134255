import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import {
    setLoadingOn,
    setLoadingOff,
} from "../../../state/dashboard/mainSlice";
import {
    clearCart,
    clearProductOrder,
    setProductOrder,
} from "../../../state/dashboard/webshopSlice";
import { setAuthToken, logout } from "../../../service/dashboard/AuthService";
import {
    createProductOrder,
    readCustomerOfProductOrder,
} from "../../../service/dashboard/webshop/ProductOrderService";
import {
    readCitiesByZipCode,
    readStreetsByZipCodeAndCity,
    // readZipCodes,
} from "../../../service/home/AddressService";
import { Panel } from "primereact/panel";
import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    formatPhoneNumber,
    replacePhoneNotUsedCharacters,
    setNumberStringThousandSeparator,
} from "../../../util/stringFormat";
import { StatusCodes } from "http-status-codes";
import MessageDialog from "../../../component/MessageDialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import GeneralTermsDialog from "../../../component/GeneralTermsDialog";
import { Divider } from "primereact/divider";
import { SelectButton } from "primereact/selectbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../css/Checkout.css";
import pageTeaserCheckoutImage from "../../../asset/page-teaser-checkout.png";
import { isOnlinePartnerInAMonth } from "../../../util/dashboard/OnlinePartnerProgramUtil";

const CheckoutSimplePayTest: React.FC<any> = () => {
    const dispatch = useDispatch();

    // const productList: [] = useSelector(
    //     (state: RootState) => state.webshop.productList
    // );

    let navigate = useNavigate();
    setAuthToken();

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );
    const cart: [] = useSelector((state: RootState) => state.webshop.cart);
    const cartSummary: any = useSelector(
        (state: RootState) => state.webshop.cartSummary
    );
    const productOrder: any = useSelector(
        (state: RootState) => state.webshop.productOrder
    );

    // console.log(userProfile);

    // console.log(productOrder);

    useEffect(() => {
        if (
            productOrder &&
            productOrder.vipNumber === null &&
            userProfile &&
            userProfile.email &&
            cart &&
            cart.length > 0
        ) {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Vásárlói adatok betöltése folyamatban.",
                })
            );
            readCustomerOfProductOrder(userProfile.email).then(
                (response: any) => {
                    const newProductOrder = { ...productOrder };
                    newProductOrder.clubMemberId = response.data.clubMemberId;
                    newProductOrder.vipNumber = response.data.vipNumber;
                    newProductOrder.representativeNumber =
                        userProfile.representativeNumber;
                    newProductOrder.sellToCuNumber =
                        response.data.sellToCuNumber;
                    newProductOrder.sellToName = response.data.sellToName;
                    newProductOrder.sellToZip = response.data.sellToZip;
                    newProductOrder.sellToCity = response.data.sellToCity;
                    newProductOrder.sellToStreet = response.data.sellToStreet;
                    newProductOrder.sellToEmail = response.data.sellToEmail;
                    newProductOrder.sellToPhone = response.data.sellToPhone;
                    newProductOrder.billToCuNumber =
                        response.data.billToCuNumber;
                    newProductOrder.billToName = response.data.billToName;
                    newProductOrder.billToZip = response.data.billToZip;
                    newProductOrder.billToCity = response.data.billToCity;
                    newProductOrder.billToStreet = response.data.billToStreet;
                    newProductOrder.billToEmail = response.data.billToEmail;
                    newProductOrder.billToPhone = response.data.billToPhone;
                    newProductOrder.billToVatNumber =
                        response.data.billToVatNumber;
                    newProductOrder.shipToCuNumber =
                        response.data.shipToCuNumber;
                    newProductOrder.shipToName = response.data.shipToName;
                    newProductOrder.shipToZip = response.data.shipToZip;
                    newProductOrder.shipToCity = response.data.shipToCity;
                    newProductOrder.shipToStreet = response.data.shipToStreet;
                    newProductOrder.shipToEmail = response.data.shipToEmail;
                    newProductOrder.shipToPhone = response.data.shipToPhone;
                    newProductOrder.paymentMethod = "PAYBYCARD";
                    dispatch(setProductOrder(newProductOrder));
                    dispatch(setLoadingOff());
                    setShowContactInformation(true);
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                    navigate("../productlist");
                    dispatch(setLoadingOff());
                }
            );
        }
    }, [
        navigate,
        dispatch,
        productOrder,
        userProfile.vipNumber,
        userProfile.representativeNumber,
        userProfile,
        cart,
    ]);

    // TODO 2023.03.31-én kikapcsolni
    const [createdCart, setCreatedCart] = useState<any>([]);
    const [bonusMinusPrice, setBonusMinusPrice] = useState<string>("0");
    useEffect(() => {
        let cartWithHalfPrice: any = [];
        if (
            userProfile.representativeRegistrationDate &&
            isOnlinePartnerInAMonth(userProfile.representativeRegistrationDate)
                .discount
        ) {
            cart.forEach((item: any) => {
                if (item.productHalfPrice === false) {
                    cartWithHalfPrice = [...cartWithHalfPrice, item];
                } else {
                    if (item.productQuantity === 1) {
                        const newHalfPriceItem = { ...item };
                        newHalfPriceItem.productPriceGroupCode = "employee";
                        newHalfPriceItem.productPlDiscountPercentage = 50;
                        newHalfPriceItem.productPlPrice = Number(
                            newHalfPriceItem.productRrpPrice / 2
                        ).toString();
                        newHalfPriceItem.productPlTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        newHalfPriceItem.productTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        setBonusMinusPrice(
                            (
                                Number(item.productPlPrice) -
                                Number(newHalfPriceItem.productPlPrice)
                            ).toString()
                        );
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            newHalfPriceItem,
                        ];
                    } else if (item.productQuantity > 0) {
                        // szét kell szedni kettőbe
                        // 1, eredeti termék módosítása
                        const originalItem = { ...item };
                        --originalItem.productQuantity;
                        originalItem.productRrpTotalPrice = (
                            Number(originalItem.productRrpTotalPrice) -
                            Number(originalItem.productRrpPrice)
                        ).toString();
                        originalItem.productPlTotalPrice = (
                            Number(originalItem.productPlTotalPrice) -
                            Number(originalItem.productPlPrice)
                        ).toString();
                        originalItem.productTotalPrice =
                            originalItem.productPlTotalPrice;
                        originalItem.productHalfPrice = false;
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            originalItem,
                        ];
                        // 2, új 50%-os bónusz létrehozása
                        const newHalfPriceItem = { ...item };
                        newHalfPriceItem.productPriceGroupCode = "employee";
                        newHalfPriceItem.productQuantity = 1;
                        newHalfPriceItem.productPlDiscountPercentage = 50;
                        newHalfPriceItem.productPlPrice = Number(
                            item.productRrpPrice / 2
                        ).toString();
                        newHalfPriceItem.productRrpTotalPrice =
                            newHalfPriceItem.productRrpPrice;
                        newHalfPriceItem.productPlTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        newHalfPriceItem.productTotalPrice =
                            newHalfPriceItem.productPlPrice;
                        setBonusMinusPrice(
                            (
                                Number(item.productPlPrice) -
                                Number(newHalfPriceItem.productPlPrice)
                            ).toString()
                        );
                        cartWithHalfPrice = [
                            ...cartWithHalfPrice,
                            newHalfPriceItem,
                        ];
                    }
                }
            });
            setCreatedCart(cartWithHalfPrice);
        } else {
            setCreatedCart(cart);
            setBonusMinusPrice("0");
        }
    }, [cart, userProfile.representativeRegistrationDate]);

    const [checkoutStepsActiveItem, setCheckoutStepsActiveItem] =
        useState<number>(0);
    const [showContactInformation, setShowContactInformation] = useState(true);
    const [shipToAddressCardOnMouse, setShipToAddressCardOnMouse] =
        useState(false);
    const [shipToAddressEdit, setShipToAddressEdit] = useState(false);
    const [shipToMobileDisplay, setShipToMobileDisplay] = useState<string>("");
    const [subscribe, setSubscribe] = useState(false);
    const [subscribeFrequency, setSubscribeFrequency] = useState<string>("1M");
    const [subscribeDeliveryDay, setSubscribeDeliveryDay] = useState<number>(1);
    const [acceptWebshopGtac, setAcceptWebshopGtac] = useState(false);
    const [acceptSimplePayGtac, setAcceptSimplePayGtac] = useState(false);
    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);
    // const [generalTermsDialogShow, setGeneralTermsDialogShow] =
    //     useState<Number>(0);

    const [cities, setCities] = useState<any>([]);
    const [streets, setStreets] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState<string>("PAYBYCARD");

    const onZipChange = (e: any) => {
        if (e.value && e.value !== "") {
            if (e.value.replace("_", "").length === 4) {
                formikContact.setFieldValue("shipToZip", e.value);
                readCitiesByZipCode(e.value).then((response) => {
                    setCities(response.data);
                    setStreets([]);
                    if (response.data.length === 1) {
                        modifyCity(response.data[0].label);
                        readStreetsByZipCodeAndCity(
                            e.value,
                            response.data[0].label
                        ).then((response) => {
                            setStreets(response.data);
                        });
                    } else if (response.data.length > 1) {
                    }
                });
            } else {
                setCities([]);
                setStreets([]);
            }
        } else {
            setCities([]);
            setStreets([]);
        }
    };

    const onCityChange = (e: { value: any }) => {
        formikContact.setFieldValue("shipToCity", e.value);
        setStreets([]);
        readStreetsByZipCodeAndCity(
            formikContact.getFieldProps("shipToZip").value,
            e.value
        ).then((response) => setStreets(response.data));
    };

    const modifyCity = (s: string) => {
        formikContact.setFieldValue("shipToCity", s);
    };

    const formikContact = useFormik({
        initialValues: {
            shipToName: "",
            shipToZip: null,
            shipToCity: "",
            shipToStreet: "",
            shipToHouseNumber: "",
            shipToEmail: "",
            shipToMobile: "",
        },
        validationSchema: Yup.object({
            shipToName: Yup.string()
                .min(6, "Minimum 6 karakter kell, hogy legyen!")
                .required("Kötelező kitölteni!"),
            shipToZip: Yup.string()
                .nullable(true)
                .required("Kötelező kitölteni!"),
            shipToCity: Yup.string().required("Kötelező kitölteni!"),
            shipToStreet: Yup.string().required("Kötelező kitölteni!"),
            shipToHouseNumber: Yup.string().required("Kötelező kitölteni!"),
            shipToEmail: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
            shipToMobile: Yup.string()
                .required("Kötelező kitölteni!")
                .test(
                    "Mobil szám teszt",
                    "Érvénytelen mobil szám!",
                    function (val) {
                        if (
                            val &&
                            val.length > 0 &&
                            (val.includes("+3620") ||
                                val.includes("+3630") ||
                                val.includes("+3631") ||
                                val.includes("+3650") ||
                                val.includes("+3660") ||
                                val.includes("+3670"))
                            // (val.includes("+36(20)") ||
                            //     val.includes("+36(30)") ||
                            //     val.includes("+36(31)") ||
                            //     val.includes("+36(50)") ||
                            //     val.includes("+36(60)") ||
                            //     val.includes("+36(70)"))
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                ),
        }),
        onSubmit: (values) => {
            //TODO ellenőrizni, hogy nincs-e véletlenül null PL értékű termék
            replacePhoneNotUsedCharacters(values.shipToMobile);
            // console.log(values);
            const newProductOrder = { ...productOrder };
            newProductOrder.shipToName = values.shipToName.replace("#", "");
            newProductOrder.shipToZip = values.shipToZip;
            newProductOrder.shipToCity = values.shipToCity;
            newProductOrder.shipToStreet = (
                values.shipToStreet +
                " " +
                values.shipToHouseNumber
            ).replace("#", "");
            newProductOrder.shipToEmail = values.shipToEmail;
            newProductOrder.shipToPhone = replacePhoneNotUsedCharacters(
                values.shipToMobile
            );
            dispatch(setProductOrder(newProductOrder));
            setShipToAddressEdit(false);
        },
    });

    useEffect(() => {
        if (productOrder && productOrder.shipToName) {
            formikContact.setFieldValue("shipToName", productOrder.shipToName);
            formikContact.setFieldValue("shipToZip", productOrder.shipToZip);
            // formikContact.setFieldValue("shipToCity", productOrder.shipToCity);
            formikContact.setFieldValue(
                "shipToMobile",
                productOrder.shipToPhone
            );
            formikContact.setFieldValue(
                "shipToEmail",
                productOrder.shipToEmail
            );
            setShipToMobileDisplay(productOrder.shipToPhone);
            // console.log(productOrder.shipToPhone);
            readCitiesByZipCode(productOrder.shipToZip).then((response) => {
                setCities(response.data);
                setStreets([]);
                if (response.data.length === 1) {
                    modifyCity(response.data[0].label);
                    readStreetsByZipCodeAndCity(
                        productOrder.shipToZip,
                        response.data[0].label
                    ).then((response) => {
                        setStreets(response.data);
                    });
                } else if (response.data.length > 1) {
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productOrder]);

    const checkoutSteps = [
        { label: "Elérhetőségek" },
        { label: "Fizetési mód" },
        { label: "Kosár" },
        { label: "Megrendelés gyakorisága" },
        { label: "Összefoglaló" },
    ];

    const subscribeFrequencyOptions = [
        { name: "Havonta", value: "1M" },
        { name: "Két havonta", value: "2M" },
    ];

    const productRrpPriceBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.productRrpPrice);
    };

    const productPlPriceBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.productPlPrice);
    };

    const productPlTotalPriceBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.productPlTotalPrice);
    };

    const productDiscountAmountBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.productDiscountAmount);
    };

    const productTotalPriceBodyTemplate = (rowData: any) => {
        return formatCurrency(rowData.productTotalPrice);
    };

    const formatCurrency = (value: string) => {
        return (
            <span className="text-right">
                {setNumberStringThousandSeparator(value)}&nbsp;Ft
            </span>
        );
    };

    return (
        <>
            <MessageDialog
                messageDialogError={messageDialogError}
                messageDialogShow={messageDialogShow}
                messageDialogHeader={messageDialogHeader}
                messageDialogBody={messageDialogBody}
                messageDialogNavigateAction={messageDialogNavigateAction}
            />
            {/* <GeneralTermsDialog
                generalTermsDialogShow={generalTermsDialogShow}
                generalTermsDialogComponent="GeneralTermsClubMember230526"
            /> */}
            <div className="max-w-max">
                <div className="mb-2 flex justify-content-center">
                    <div className="w-auto md:w-7">
                        <img
                            src={pageTeaserCheckoutImage}
                            className="responsive fadeinleft animation-duration-500"
                            alt="zepterclub.hu vásárlás véglegesítése"
                        />
                    </div>
                </div>
                <Panel header={"Vásárlás véglegesítése"}>
                    <div className="checkout card justify-content-center">
                        <Steps
                            model={checkoutSteps}
                            activeIndex={checkoutStepsActiveItem}
                        />
                    </div>
                </Panel>
                {cart.length > 0 &&
                userProfile.email &&
                productOrder.vipNumber ? (
                    <Panel
                        className="mt-3"
                        header={checkoutSteps[checkoutStepsActiveItem].label}
                    >
                        <div className="card justify-content-center">
                            {checkoutStepsActiveItem === 0 ? (
                                <>
                                    <div className="grid flex-column lg:flex-row">
                                        {showContactInformation ? (
                                            <>
                                                <div className="col p-3">
                                                    <div className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3">
                                                        <div className="flex justify-content-between mb-3">
                                                            <span className="text-900 text-xl font-medium">
                                                                Vásárló
                                                            </span>
                                                        </div>
                                                        <span className="inline-block text-900 mb-3">
                                                            {
                                                                productOrder.sellToName
                                                            }
                                                        </span>
                                                        <span className="inline-block text-600 mb-3">
                                                            <i className="pi pi-home mr-2" />
                                                            {productOrder.sellToZip +
                                                                " " +
                                                                productOrder.sellToCity +
                                                                ", " +
                                                                productOrder.sellToStreet}
                                                        </span>
                                                        <span className="inline-block text-600 mb-3">
                                                            <i className="pi pi-mobile mr-2" />
                                                            {formatPhoneNumber(
                                                                productOrder.sellToPhone
                                                            )}
                                                        </span>
                                                        <span className="inline-block text-600">
                                                            <i className="pi pi-envelope mr-2" />
                                                            {
                                                                productOrder.sellToEmail
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col p-3">
                                                    <div className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3">
                                                        <div className="flex justify-content-between mb-3">
                                                            <span className="text-900 text-xl font-medium">
                                                                Számlázás
                                                            </span>
                                                        </div>
                                                        <div className="flex justify-content-between mb-3">
                                                            <span className="inline-block text-900">
                                                                {
                                                                    productOrder.billToName
                                                                }
                                                            </span>
                                                            {productOrder.billToVatNumber ? (
                                                                <span className="text-600 font-medium">
                                                                    <i className="pi pi-info-circle mr-2" />
                                                                    {
                                                                        productOrder.billToVatNumber
                                                                    }
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                        <span className="inline-block text-600 mb-3">
                                                            {productOrder.billToVatNumber ? (
                                                                <i className="pi pi-building mr-2" />
                                                            ) : (
                                                                <i className="pi pi-home mr-2" />
                                                            )}
                                                            {productOrder.billToZip +
                                                                " " +
                                                                productOrder.billToCity +
                                                                ", " +
                                                                productOrder.billToStreet}
                                                        </span>
                                                        {productOrder.billToPhone ? (
                                                            <span className="inline-block text-600 mb-3">
                                                                <i className="pi pi-mobile mr-2" />
                                                                {formatPhoneNumber(
                                                                    productOrder.billToPhone
                                                                )}
                                                            </span>
                                                        ) : null}
                                                        {productOrder.billToEmail ? (
                                                            <span className="inline-block text-600">
                                                                <i className="pi pi-envelope mr-2" />
                                                                {
                                                                    productOrder.billToEmail
                                                                }
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        ) : null}
                                        <div className="col p-3">
                                            <div
                                                className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3 cursor-pointer hover:border-primary transition-duration-150"
                                                onMouseEnter={() =>
                                                    setShipToAddressCardOnMouse(
                                                        true
                                                    )
                                                }
                                                onMouseLeave={() =>
                                                    setShipToAddressCardOnMouse(
                                                        false
                                                    )
                                                }
                                                onClick={() =>
                                                    setShipToAddressEdit(true)
                                                }
                                            >
                                                <div className="flex justify-content-between mb-3">
                                                    <span className="text-900 text-xl font-medium">
                                                        Szállítás
                                                    </span>
                                                    {shipToAddressCardOnMouse ? (
                                                        <span className="text-600 font-medium">
                                                            <i className="pi pi-pencil mr-2" />
                                                            Szerkesztés
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <span className="inline-block text-900 mb-3">
                                                    {productOrder.shipToName}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    {productOrder.billToVatNumber &&
                                                    productOrder.billToZip ===
                                                        productOrder.shipToZip &&
                                                    productOrder.billToStreet ===
                                                        productOrder.shipToStreet ? (
                                                        <i className="pi pi-building mr-2" />
                                                    ) : (
                                                        <i className="pi pi-home mr-2" />
                                                    )}
                                                    {productOrder.shipToZip +
                                                        " " +
                                                        productOrder.shipToCity +
                                                        ", " +
                                                        productOrder.shipToStreet}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    <i className="pi pi-mobile mr-2" />
                                                    {productOrder.shipToPhone ? (
                                                        formatPhoneNumber(
                                                            productOrder.shipToPhone
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Kötelező megadni
                                                            mobil számot!
                                                        </span>
                                                    )}
                                                </span>
                                                {productOrder.shipToEmail ? (
                                                    <span className="inline-block text-600">
                                                        <i className="pi pi-envelope mr-2" />
                                                        {
                                                            productOrder.shipToEmail
                                                        }
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <Panel
                                        className={
                                            shipToAddressEdit
                                                ? "fadeinup block mt-0 mb-3 p-2"
                                                : "fadeoutup hidden"
                                        }
                                        header="Szállítási adatok szerkesztése"
                                    >
                                        <form
                                            onSubmit={
                                                formikContact.handleSubmit
                                            }
                                            className="p-fluid"
                                        >
                                            <div className="formgrid grid">
                                                <div className="field col-12 md:col-12">
                                                    <label
                                                        htmlFor="shipToName"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Név
                                                    </label>
                                                    <InputText
                                                        className="w-full mb-3"
                                                        autoFocus
                                                        id="shipToName"
                                                        // name="shipToName"
                                                        {...formikContact.getFieldProps(
                                                            "shipToName"
                                                        )}
                                                    />
                                                    {formikContact.touched
                                                        .shipToName &&
                                                    formikContact.errors
                                                        .shipToName ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToName.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="formgrid grid">
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToZip"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Irányítószám
                                                    </label>
                                                    <InputMask
                                                        className="w-full mb-3"
                                                        mask="9999"
                                                        id="shipToZip"
                                                        {...formikContact.getFieldProps(
                                                            "shipToZip"
                                                        )}
                                                        onChange={onZipChange}
                                                    />
                                                    {formikContact.touched
                                                        .shipToZip &&
                                                    formikContact.errors
                                                        .shipToZip ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToZip.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToCity"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Város
                                                    </label>
                                                    <Dropdown
                                                        id="shipToCity"
                                                        className="w-full mb-3"
                                                        {...formikContact.getFieldProps(
                                                            "shipToCity"
                                                        )}
                                                        options={cities}
                                                        onChange={onCityChange}
                                                        placeholder="Válasszon várost!"
                                                        filter
                                                    />
                                                    {formikContact.touched
                                                        .shipToCity &&
                                                    formikContact.errors
                                                        .shipToCity ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToCity.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="formgrid grid">
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToStreet"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Cím
                                                    </label>
                                                    <Dropdown
                                                        id="shipToStreet"
                                                        className="w-full mb-3"
                                                        {...formikContact.getFieldProps(
                                                            "shipToStreet"
                                                        )}
                                                        options={streets}
                                                        placeholder="Válasszon utcát!"
                                                        filter
                                                    />
                                                    {formikContact.touched
                                                        .shipToStreet &&
                                                    formikContact.errors
                                                        .shipToStreet ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToStreet.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToHouseNumber"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Házszám
                                                    </label>
                                                    <InputText
                                                        className="w-full mb-3"
                                                        id="shipToHouseNumber"
                                                        name="shipToHouseNumber"
                                                        value={
                                                            formikContact.values
                                                                .shipToHouseNumber
                                                        }
                                                        onChange={
                                                            formikContact.handleChange
                                                        }
                                                    />
                                                    {formikContact.touched
                                                        .shipToHouseNumber &&
                                                    formikContact.errors
                                                        .shipToHouseNumber ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToHouseNumber.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="formgrid grid">
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToMobile"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Mobil
                                                    </label>
                                                    <InputMask
                                                        className="w-full mb-3"
                                                        mask="+36999999999"
                                                        id="shipToMobile"
                                                        name="shipToMobile"
                                                        value={
                                                            shipToMobileDisplay
                                                        }
                                                        // value={"+36(70)453-2603"}
                                                        onChange={(
                                                            val: any
                                                        ) => {
                                                            setShipToMobileDisplay(
                                                                val.value
                                                            );
                                                            formikContact.setFieldValue(
                                                                "shipToMobile",
                                                                val.value
                                                            );
                                                        }}
                                                    />
                                                    {formikContact.touched
                                                        .shipToMobile &&
                                                    formikContact.errors
                                                        .shipToMobile ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToMobile.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="shipToEmail"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Email
                                                    </label>
                                                    <InputText
                                                        className="w-full mb-3"
                                                        id="shipToEmail"
                                                        {...formikContact.getFieldProps(
                                                            "shipToEmail"
                                                        )}
                                                        onBlur={(val: any) => {
                                                            const emailTrim =
                                                                formikContact.values.shipToEmail
                                                                    .trim()
                                                                    .toLocaleLowerCase();
                                                            formikContact.setFieldValue(
                                                                "shipToEmail",
                                                                emailTrim
                                                            );
                                                        }}
                                                    />
                                                    {formikContact.touched
                                                        .shipToEmail &&
                                                    formikContact.errors
                                                        .shipToEmail ? (
                                                        <small className="p-error">
                                                            {formikContact.errors.shipToEmail.toString()}
                                                        </small>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="formgrid grid">
                                                <div className="field col-12 md:col-2"></div>
                                                <div className="field col-12 text-center md:col-8">
                                                    <Button
                                                        icon="pi pi-check"
                                                        label="Szállítási adatok módosítása"
                                                        className="p-button-success w-full md:w-10"
                                                        type="submit"
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-2"></div>
                                            </div>
                                        </form>
                                    </Panel>
                                    <div className="formgrid grid">
                                        <div className="field col-12 md:col-6"></div>
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-right"
                                                className="p-button-primary w-full md:w-9"
                                                type="button"
                                                label="Tovább"
                                                onClick={() => {
                                                    if (shipToAddressEdit) {
                                                        setMessageDialogError(
                                                            true
                                                        );
                                                        setMessageDialogHeader(
                                                            "Értesítés"
                                                        );
                                                        setMessageDialogBody(
                                                            "Kérjük először mentse el a szállítási adatokat!"
                                                        );
                                                        setMessageDialogNavigateAction(
                                                            ""
                                                        );
                                                        setMessageDialogShow(
                                                            Date.now()
                                                        );
                                                    } else if (
                                                        !productOrder.shipToPhone
                                                    ) {
                                                        setMessageDialogError(
                                                            true
                                                        );
                                                        setMessageDialogHeader(
                                                            "Értesítés"
                                                        );
                                                        setMessageDialogBody(
                                                            "Kérjük a folytatáshoz adjon meg mobiltelefonszámot a szállítási adatok szerkesztésével!"
                                                        );
                                                        setMessageDialogNavigateAction(
                                                            ""
                                                        );
                                                        setMessageDialogShow(
                                                            Date.now()
                                                        );
                                                    } else if (
                                                        !productOrder.shipToZip ||
                                                        !productOrder.shipToCity ||
                                                        !productOrder.shipToStreet
                                                    ) {
                                                        setMessageDialogError(
                                                            true
                                                        );
                                                        setMessageDialogHeader(
                                                            "Értesítés"
                                                        );
                                                        setMessageDialogBody(
                                                            "Kérjük a folytatáshoz adjon meg a teljes szállítási címet a szállítási adatok szerkesztésével!"
                                                        );
                                                        setMessageDialogNavigateAction(
                                                            ""
                                                        );
                                                        setMessageDialogShow(
                                                            Date.now()
                                                        );
                                                    } else {
                                                        setCheckoutStepsActiveItem(
                                                            checkoutStepsActiveItem +
                                                                1
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {/* Fizetési mód */}
                            {checkoutStepsActiveItem === 1 ? (
                                <>
                                    <div
                                        className="card mb-3 ml-5"
                                        style={{
                                            width: "65vw",
                                            overflowX: "auto",
                                        }}
                                    >
                                        <span className="text-lg text-700">
                                            Kérem válassza ki a fizetési módot:
                                        </span>
                                        <div className="field-radiobutton mt-3">
                                            <RadioButton
                                                inputId="paybycard"
                                                name="payment-method"
                                                value="PAYBYCARD"
                                                onChange={(e) =>
                                                    setPaymentMethod(e.value)
                                                }
                                                checked={
                                                    paymentMethod ===
                                                    "PAYBYCARD"
                                                }
                                            />
                                            <label htmlFor="paybycard">
                                                Bankkártya (OTP SimplePay)
                                            </label>
                                        </div>
                                        <div className="field-radiobutton mt-3">
                                            <RadioButton
                                                inputId="payupondelivery"
                                                name="payment-method"
                                                value="PAYUPONDELIVERY"
                                                onChange={(e) =>
                                                    setPaymentMethod(e.value)
                                                }
                                                checked={
                                                    paymentMethod ===
                                                    "PAYUPONDELIVERY"
                                                }
                                            />
                                            <label htmlFor="payupondelivery">
                                                Utánvét
                                            </label>
                                        </div>
                                        <div className="field-radiobutton">
                                            <RadioButton
                                                inputId="paybyadvancetransfer"
                                                name="payment-method"
                                                value="PAYBYADVANCETRANSFER"
                                                onChange={(e) =>
                                                    setPaymentMethod(e.value)
                                                }
                                                checked={
                                                    paymentMethod ===
                                                    "PAYBYADVANCETRANSFER"
                                                }
                                            />
                                            <label htmlFor="paybyadvancetransfer">
                                                Előre utalás
                                            </label>
                                        </div>
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-left"
                                                className="p-button-secondary w-full md:w-9"
                                                type="button"
                                                label="Vissza"
                                                onClick={() => {
                                                    const newProductOrder = {
                                                        ...productOrder,
                                                    };
                                                    newProductOrder.paymentMethod =
                                                        paymentMethod;
                                                    dispatch(
                                                        setProductOrder(
                                                            newProductOrder
                                                        )
                                                    );
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem -
                                                            1
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-right"
                                                className="p-button-primary w-full md:w-9"
                                                type="button"
                                                label="Tovább"
                                                onClick={() => {
                                                    const newProductOrder = {
                                                        ...productOrder,
                                                    };
                                                    newProductOrder.paymentMethod =
                                                        paymentMethod;
                                                    dispatch(
                                                        setProductOrder(
                                                            newProductOrder
                                                        )
                                                    );
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem +
                                                            1
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {/* Kosár tartalma */}
                            {checkoutStepsActiveItem === 2 ? (
                                <>
                                    <div className="checkout card mb-3">
                                        <DataTable
                                            value={createdCart}
                                            style={{
                                                maxWidth: "67vw",
                                                overflowX: "auto",
                                            }}
                                            stripedRows
                                        >
                                            <Column
                                                field="productItemNumber"
                                                header="Cikkszám"
                                            />
                                            <Column
                                                field="productName"
                                                header="Terméknév"
                                            />
                                            <Column
                                                field="productRrpPrice"
                                                header="Listaár"
                                                body={
                                                    productRrpPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productPlPrice"
                                                header="Kedvezményes egységár"
                                                body={
                                                    productPlPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productQuantity"
                                                header="Mennyiség"
                                                align="center"
                                            />
                                            <Column
                                                field="productPlTotalPrice"
                                                header="Kedvezményes ár"
                                                body={
                                                    productPlTotalPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productDiscountAmount"
                                                header="További kedvezmények"
                                                body={
                                                    productDiscountAmountBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productTotalPrice"
                                                header="Eladási ár"
                                                body={
                                                    productTotalPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                        </DataTable>
                                    </div>
                                    <div className="field col-12 mb-2 text-xl">
                                        <strong>Fizetendő:</strong>{" "}
                                        {cartSummary.totalPrice > 0 ? (
                                            "bruttó " +
                                            setNumberStringThousandSeparator(
                                                (
                                                    Number(
                                                        cartSummary.totalPrice
                                                    ) - Number(bonusMinusPrice)
                                                ).toString()
                                            ) +
                                            " Ft"
                                        ) : (
                                            <span className="text-red-400">
                                                Üres a kosár, kérjük ellenőrizze
                                                a mennyiségeket!
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 mb-3 line-height-3">
                                        <strong>Átvételi mód:</strong> Házhoz
                                        szállítás futárszolgálattal
                                        <br />
                                        <strong>Fizetési mód: </strong>
                                        {paymentMethod === "PAYBYCARD" ? (
                                            <>Bankkártya (OTP SimplePay)</>
                                        ) : null}
                                        {paymentMethod === "PAYUPONDELIVERY" ? (
                                            <>Utánvét</>
                                        ) : null}
                                        {paymentMethod ===
                                        "PAYBYADVANCETRANSFER" ? (
                                            <>Előre utalás</>
                                        ) : null}
                                        <br />
                                        <strong>Szállítási költség: </strong>
                                        {cartSummary.shipmentFee > 0 ? (
                                            <span className="text-red-400">
                                                +
                                                {setNumberStringThousandSeparator(
                                                    cartSummary.shipmentFee
                                                )}{" "}
                                                Ft
                                            </span>
                                        ) : (
                                            <span className="text-green-500">
                                                ingyenes
                                            </span>
                                        )}
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-left"
                                                className="p-button-secondary w-full md:w-9"
                                                type="button"
                                                label="Vissza"
                                                onClick={() =>
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem -
                                                            1
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-right"
                                                className="p-button-primary w-full md:w-9"
                                                type="button"
                                                label="Tovább"
                                                onClick={() =>
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem +
                                                            1
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {/* Egyszeri vagy megújuló rendelés */}
                            {checkoutStepsActiveItem === 3 ? (
                                <div
                                    className="card"
                                    // style={{
                                    //     width: "65vw",
                                    //     overflowX: "auto",
                                    // }}
                                >
                                    <span className="text-lg text-700 pt-3">
                                        Egyszeri és megújuló rendelésekre
                                        vonatkozó beállítások
                                    </span>
                                    <div className="formgrid grid mb-3 mt-3">
                                        <div className="field col-2 pt-2">
                                            <InputSwitch
                                                id="subscribe"
                                                name="subscribe"
                                                checked={subscribe}
                                                onChange={(e) => {
                                                    if (e.value) {
                                                        setSubscribe(e.value);
                                                    } else {
                                                        setSubscribe(false);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {/* align-items-center justify-content-center */}
                                        <div className="field col-10 text-justify pt-2">
                                            <span className="text-lg text-700">
                                                {subscribe ? (
                                                    <>
                                                        Megújuló megrendelést
                                                        választottam, így
                                                        rendelésedet
                                                        kényelmesen, havonta
                                                        vagy kéthavonta, az
                                                        általad választott napon
                                                        tudod átvenni.
                                                        <br />A rendelésedet{" "}
                                                        <span className="text-primary">
                                                            elegendő most
                                                            egyszer leadnod
                                                        </span>
                                                        , és az minden hónapban{" "}
                                                        <span className="text-primary">
                                                            automatikusan
                                                            megújul
                                                        </span>{" "}
                                                        mindaddig, amíg írásban
                                                        azt le nem mondod.
                                                    </>
                                                ) : (
                                                    <>
                                                        Egyszeri vásárlást
                                                        választottam.
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    {subscribe ? (
                                        <>
                                            <Divider />
                                            <div className="formgrid grid mb-3 text-center align-content-center">
                                                <div className="checkout field col-12 md:col-6">
                                                    <label
                                                        htmlFor="deliveryday"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        {subscribeFrequency ===
                                                        "1M"
                                                            ? "Havonta"
                                                            : "Két havonta"}{" "}
                                                        melyik napon szállítsuk
                                                        a termékeit?
                                                    </label>
                                                    <InputNumber
                                                        // className="max-w-2rem"
                                                        id="deliveryday"
                                                        name="deliveryday"
                                                        inputId="horizontal"
                                                        value={
                                                            subscribeDeliveryDay
                                                        }
                                                        onValueChange={(e) => {
                                                            setSubscribeDeliveryDay(
                                                                e.value
                                                                    ? e.value
                                                                    : 1
                                                            );
                                                        }}
                                                        showButtons
                                                        buttonLayout="horizontal"
                                                        min={1}
                                                        max={28}
                                                        step={1}
                                                        decrementButtonClassName="p-button-secondary"
                                                        incrementButtonClassName="p-button-secondary"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        suffix="."
                                                        inputClassName="text-center text-indigo-500 font-bold"
                                                    />
                                                </div>
                                                <div className="field col-12 md:col-6">
                                                    <label
                                                        htmlFor="deliveryFrequancy"
                                                        className="block text-900 font-medium mb-2"
                                                    >
                                                        Milyen gyakorisággal
                                                        kéri a szállítást?
                                                    </label>
                                                    <SelectButton
                                                        id="deliveryFrequancy"
                                                        name="deliveryFrequancy"
                                                        optionLabel="name"
                                                        optionValue="value"
                                                        unselectable={false}
                                                        value={
                                                            subscribeFrequency
                                                        }
                                                        options={
                                                            subscribeFrequencyOptions
                                                        }
                                                        onChange={(e) => {
                                                            // console.log(e.value);
                                                            setSubscribeFrequency(
                                                                e.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div className="formgrid grid">
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-left"
                                                className="p-button-secondary w-full md:w-9"
                                                type="button"
                                                label="Vissza"
                                                onClick={() =>
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem -
                                                            1
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-right"
                                                className="p-button-primary w-full md:w-9"
                                                type="button"
                                                label="Tovább"
                                                onClick={() => {
                                                    const newProductOrder = {
                                                        ...productOrder,
                                                    };
                                                    newProductOrder.subscribe =
                                                        subscribe.toString();
                                                    if (subscribe) {
                                                        newProductOrder.subscribeFrequency =
                                                            subscribeFrequency;
                                                        newProductOrder.subscribeDeliveryDay =
                                                            subscribeDeliveryDay.toString();
                                                    } else {
                                                        newProductOrder.subscribeFrequency =
                                                            null;
                                                        newProductOrder.subscribeDeliveryDay =
                                                            null;
                                                    }
                                                    dispatch(
                                                        setProductOrder(
                                                            newProductOrder
                                                        )
                                                    );
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem +
                                                            1
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* Összefoglaló */}
                            {checkoutStepsActiveItem === 4 &&
                            showContactInformation ? (
                                <>
                                    <div className="grid flex-column lg:flex-row">
                                        <div className="col p-3">
                                            <div className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3">
                                                <div className="flex justify-content-between mb-3">
                                                    <span className="text-900 text-xl font-medium">
                                                        Vásárló
                                                    </span>
                                                </div>
                                                <span className="inline-block text-900 mb-3">
                                                    {productOrder.sellToName}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    <i className="pi pi-home mr-2" />
                                                    {productOrder.sellToZip +
                                                        " " +
                                                        productOrder.sellToCity +
                                                        ", " +
                                                        productOrder.sellToStreet}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    <i className="pi pi-mobile mr-2" />
                                                    {formatPhoneNumber(
                                                        productOrder.sellToPhone
                                                    )}
                                                </span>
                                                <span className="inline-block text-600">
                                                    <i className="pi pi-envelope mr-2" />
                                                    {productOrder.sellToEmail}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col p-3">
                                            <div className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3">
                                                <div className="flex justify-content-between mb-3">
                                                    <span className="text-900 text-xl font-medium">
                                                        Számlázás
                                                    </span>
                                                </div>
                                                <div className="flex justify-content-between mb-3">
                                                    <span className="inline-block text-900">
                                                        {
                                                            productOrder.billToName
                                                        }
                                                    </span>
                                                    {productOrder.billToVatNumber ? (
                                                        <span className="text-600 font-medium">
                                                            <i className="pi pi-info-circle mr-2" />
                                                            {
                                                                productOrder.billToVatNumber
                                                            }
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <span className="inline-block text-600 mb-3">
                                                    {productOrder.billToVatNumber ? (
                                                        <i className="pi pi-building mr-2" />
                                                    ) : (
                                                        <i className="pi pi-home mr-2" />
                                                    )}
                                                    {productOrder.billToZip +
                                                        " " +
                                                        productOrder.billToCity +
                                                        ", " +
                                                        productOrder.billToStreet}
                                                </span>
                                                {productOrder.billToPhone ? (
                                                    <span className="inline-block text-600 mb-3">
                                                        <i className="pi pi-mobile mr-2" />
                                                        {formatPhoneNumber(
                                                            productOrder.billToPhone
                                                        )}
                                                    </span>
                                                ) : null}
                                                {productOrder.billToEmail ? (
                                                    <span className="inline-block text-600">
                                                        <i className="pi pi-envelope mr-2" />
                                                        {
                                                            productOrder.billToEmail
                                                        }
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col p-3">
                                            <div className="h-full flex flex-column border-round border-1 surface-border p-4 mb-3">
                                                <div className="flex justify-content-between mb-3">
                                                    <span className="text-900 text-xl font-medium">
                                                        Szállítás
                                                    </span>
                                                    {shipToAddressCardOnMouse ? (
                                                        <span className="text-600 font-medium">
                                                            <i className="pi pi-pencil mr-2" />
                                                            Szerkesztés
                                                        </span>
                                                    ) : null}
                                                </div>
                                                <span className="inline-block text-900 mb-3">
                                                    {productOrder.shipToName}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    {productOrder.billToVatNumber &&
                                                    productOrder.billToZip ===
                                                        productOrder.shipToZip &&
                                                    productOrder.billToStreet ===
                                                        productOrder.shipToStreet ? (
                                                        <i className="pi pi-building mr-2" />
                                                    ) : (
                                                        <i className="pi pi-home mr-2" />
                                                    )}
                                                    {productOrder.shipToZip +
                                                        " " +
                                                        productOrder.shipToCity +
                                                        ", " +
                                                        productOrder.shipToStreet}
                                                </span>
                                                <span className="inline-block text-600 mb-3">
                                                    <i className="pi pi-mobile mr-2" />
                                                    {productOrder.shipToPhone ? (
                                                        formatPhoneNumber(
                                                            productOrder.shipToPhone
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            Kötelező megadni
                                                            mobil számot!
                                                        </span>
                                                    )}
                                                </span>
                                                {productOrder.shipToEmail ? (
                                                    <span className="inline-block text-600">
                                                        <i className="pi pi-envelope mr-2" />
                                                        {
                                                            productOrder.shipToEmail
                                                        }
                                                    </span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout card mb-3">
                                        <DataTable
                                            value={createdCart}
                                            style={{
                                                maxWidth: "67vw",
                                                overflowX: "auto",
                                            }}
                                            stripedRows
                                        >
                                            <Column
                                                field="productItemNumber"
                                                header="Cikkszám"
                                            />
                                            <Column
                                                field="productName"
                                                header="Terméknév"
                                            />
                                            <Column
                                                field="productRrpPrice"
                                                header="Listaár"
                                                body={
                                                    productRrpPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productPlPrice"
                                                header="Kedvezményes egységár"
                                                body={
                                                    productPlPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productQuantity"
                                                header="Mennyiség"
                                                align="center"
                                            />
                                            <Column
                                                field="productPlTotalPrice"
                                                header="Kedvezményes ár"
                                                body={
                                                    productPlTotalPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productDiscountAmount"
                                                header="További kedvezmények"
                                                body={
                                                    productDiscountAmountBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                            <Column
                                                field="productTotalPrice"
                                                header="Eladási ár"
                                                body={
                                                    productTotalPriceBodyTemplate
                                                }
                                                alignHeader="center"
                                                align="right"
                                            />
                                        </DataTable>
                                    </div>
                                    <div className="field col-12 mb-2 text-xl">
                                        <strong>Fizetendő:</strong>{" "}
                                        {cartSummary.totalPrice > 0 ? (
                                            "bruttó " +
                                            setNumberStringThousandSeparator(
                                                (
                                                    Number(
                                                        cartSummary.totalPrice
                                                    ) - Number(bonusMinusPrice)
                                                ).toString()
                                            ) +
                                            " Ft"
                                        ) : (
                                            <span className="text-red-400">
                                                Üres a kosár, kérjük ellenőrizze
                                                a mennyiségeket!
                                            </span>
                                        )}
                                    </div>
                                    <div className="field col-12 mb-2 line-height-3">
                                        <strong>Átvételi mód:</strong> Házhoz
                                        szállítás futárszolgálattal
                                        <br />
                                        <strong>Fizetési mód: </strong>
                                        {paymentMethod === "PAYBYCARD" ? (
                                            <>Bankkártya (OTP SimplePay)</>
                                        ) : null}
                                        {paymentMethod === "PAYUPONDELIVERY" ? (
                                            <>Utánvét</>
                                        ) : null}
                                        {paymentMethod ===
                                        "PAYBYADVANCETRANSFER" ? (
                                            <>Előre utalás</>
                                        ) : null}
                                        <br />
                                        <strong>Szállítási költség: </strong>
                                        {cartSummary.shipmentFee > 0 ? (
                                            <span className="text-red-400">
                                                +
                                                {setNumberStringThousandSeparator(
                                                    cartSummary.shipmentFee
                                                )}{" "}
                                                Ft
                                            </span>
                                        ) : (
                                            <span className="text-green-500">
                                                ingyenes
                                            </span>
                                        )}
                                    </div>
                                    {subscribe ? (
                                        <div className="field col-12 mb-2 mt-0">
                                            <h3 className="mb-1 mt-0">
                                                Megújuló rendelés:
                                            </h3>
                                            <strong>
                                                Szállítási gyakoriság:
                                            </strong>{" "}
                                            {subscribeFrequency === "1M"
                                                ? "Havonta"
                                                : "Két havonta"}
                                            <br />
                                            <strong>
                                                Szállítási nap:
                                            </strong>{" "}
                                            {subscribeDeliveryDay}.
                                        </div>
                                    ) : null}
                                    {/* SimplePay checkbox */}
                                    {paymentMethod === "PAYBYCARD" ? (
                                        <div className="field col-12 md:col-12">
                                            <div className="field-checkbox col mb-0">
                                                <Checkbox
                                                    id="accepted-simplepay"
                                                    name="accepted-simplepay"
                                                    checked={
                                                        acceptSimplePayGtac
                                                    }
                                                    onChange={(e) => {
                                                        // console.log(e);
                                                        if (e.checked) {
                                                            setAcceptSimplePayGtac(
                                                                e.checked
                                                            );
                                                        } else {
                                                            setAcceptSimplePayGtac(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                />
                                                <label
                                                    htmlFor="accepted-simplepay"
                                                    className="flex flex-wrap text-lg text-900 line-height-3 ml-3"
                                                >
                                                    Tudomásul veszem, hogy a
                                                    Zepter Ungarn Kft. (1138
                                                    Budapest, Váci út 191.)
                                                    adatkezelő által a
                                                    zepterclub.hu felhasználói
                                                    adatbázisában tárolt alábbi
                                                    személyes adataim átadásra
                                                    kerülnek az OTP Mobil Kft.,
                                                    mint adatfeldolgozó részére.
                                                    Az adatkezelő által
                                                    továbbított adatok köre az
                                                    alábbi: vásárló neve, e-mail
                                                    címe, rendelés száma,
                                                    fizetendő összeg
                                                    <br />
                                                    Az adatfeldolgozó által
                                                    végzett adatfeldolgozási
                                                    tevékenység jellege és célja
                                                    a SimplePay Adatkezelési
                                                    tájékoztatóban, az alábbi
                                                    linken tekinthető meg:
                                                    <br />
                                                    <a
                                                        href="http://simplepay.hu/vasarlo-aff"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        http://simplepay.hu/vasarlo-aff
                                                    </a>
                                                </label>
                                            </div>
                                            {!acceptSimplePayGtac ? (
                                                <small className="p-error ml-2 mt-0">
                                                    Bankkártyás fizetéshez el
                                                    kell fogadni az OTP
                                                    SimplePay adatkezelési
                                                    tájékoztatót.
                                                </small>
                                            ) : null}
                                        </div>
                                    ) : null}
                                    <div className="field col-12 md:col-12">
                                        <div className="field-checkbox col mb-0">
                                            <Checkbox
                                                id="accepted"
                                                name="accepted"
                                                checked={acceptWebshopGtac}
                                                onChange={(e) => {
                                                    // console.log(e);
                                                    if (e.checked) {
                                                        setAcceptWebshopGtac(
                                                            e.checked
                                                        );
                                                    } else {
                                                        setAcceptWebshopGtac(
                                                            false
                                                        );
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor="accepted"
                                                className="flex flex-wrap text-lg text-900 ml-3"
                                            >
                                                <a
                                                    href="https://www.zepter.hu/Rules/Regulation"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="underline text-blue-600 hover:text-cyan-700 cursor-pointer"
                                                >
                                                    webshop szabályzatot
                                                </a>
                                                &nbsp;megismertem és elfogadom.
                                            </label>
                                        </div>
                                        {!acceptWebshopGtac ? (
                                            <small className="p-error ml-2 mt-0">
                                                Megrendeléshez el kell fogadni a
                                                szabályzatot.
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="formgrid grid">
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-arrow-left"
                                                className="p-button-secondary w-full md:w-9"
                                                type="button"
                                                label="Vissza"
                                                onClick={() =>
                                                    setCheckoutStepsActiveItem(
                                                        checkoutStepsActiveItem -
                                                            1
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="field col-12 text-center md:col-6">
                                            <Button
                                                icon="pi pi-book"
                                                className="p-button-success w-full md:w-9 md:w-9"
                                                type="button"
                                                label="Vásárlás véglegesítése"
                                                disabled={
                                                    cartSummary.totalQuantity ===
                                                    0
                                                }
                                                onClick={() => {
                                                    if (
                                                        paymentMethod ===
                                                            "PAYBYCARD" &&
                                                        !acceptSimplePayGtac
                                                    ) {
                                                        setMessageDialogError(
                                                            true
                                                        );
                                                        setMessageDialogHeader(
                                                            "Értesítés"
                                                        );
                                                        setMessageDialogBody(
                                                            "Bankkártyás fizetéshez el kell fogadni az OTP SimplePay adatkezelési tájékoztatót."
                                                        );
                                                        setMessageDialogNavigateAction(
                                                            ""
                                                        );
                                                        setMessageDialogShow(
                                                            Date.now()
                                                        );
                                                    } else if (
                                                        !acceptWebshopGtac
                                                    ) {
                                                        setMessageDialogError(
                                                            true
                                                        );
                                                        setMessageDialogHeader(
                                                            "Értesítés"
                                                        );
                                                        setMessageDialogBody(
                                                            "Megrendeléshez el kell fogadni a zepterclub.hu Webshop szabályzatot."
                                                        );
                                                        setMessageDialogNavigateAction(
                                                            ""
                                                        );
                                                        setMessageDialogShow(
                                                            Date.now()
                                                        );
                                                    } else {
                                                        dispatch(
                                                            setLoadingOn({
                                                                blockPage: true,
                                                                message:
                                                                    "Megrendelés rögzítése folyamatban.",
                                                            })
                                                        );
                                                        const newProductOrder =
                                                            {
                                                                ...productOrder,
                                                            };
                                                        newProductOrder.productOrderLineList =
                                                            createdCart
                                                                .map(
                                                                    (
                                                                        productItem: any
                                                                    ) => {
                                                                        // console.log(productItem);
                                                                        const newOrderItem =
                                                                            {
                                                                                itemNo: productItem.productItemNumber.replace(
                                                                                    "#",
                                                                                    ""
                                                                                ),
                                                                                itemName:
                                                                                    productItem.productName.replace(
                                                                                        "#",
                                                                                        ""
                                                                                    ),
                                                                                priceGroupCode:
                                                                                    productItem.productPriceGroupCode,
                                                                                rrpPrice:
                                                                                    productItem.productRrpPrice,
                                                                                plPrice:
                                                                                    productItem.productPlPrice,
                                                                                quantity:
                                                                                    productItem.productQuantity.toString(),
                                                                                rrpTotalPrice:
                                                                                    productItem.productRrpTotalPrice,
                                                                                plTotalPrice:
                                                                                    productItem.productPlTotalPrice,
                                                                                discountAmount:
                                                                                    productItem.productDiscountAmount,
                                                                                totalPrice:
                                                                                    productItem.productTotalPrice,
                                                                            };
                                                                        return newOrderItem;
                                                                    }
                                                                )
                                                                .filter(
                                                                    (
                                                                        productItem: any
                                                                    ) => {
                                                                        return (
                                                                            productItem.productQuantity !==
                                                                                0 &&
                                                                            productItem.productQuantity !==
                                                                                "0"
                                                                        );
                                                                    }
                                                                );
                                                        //fentebb lehet valami hibaüzenet, ha null egy termék
                                                        newProductOrder.grossAmount =
                                                            (
                                                                cartSummary.totalPrice -
                                                                Number(
                                                                    bonusMinusPrice
                                                                )
                                                            ).toString();
                                                        newProductOrder.netAmount =
                                                            Math.round(
                                                                Number(
                                                                    newProductOrder.grossAmount
                                                                ) / 1.27
                                                            ).toString();
                                                        newProductOrder.vatAmount =
                                                            Math.round(
                                                                Number(
                                                                    newProductOrder.grossAmount
                                                                ) -
                                                                    Number(
                                                                        newProductOrder.grossAmount
                                                                    ) /
                                                                        1.27
                                                            ).toString();
                                                        // TODO adatbázisból lekérdezni a szállítási díjat, minimum összeget az ingyenes szállításhoz
                                                        newProductOrder.shipmentFee =
                                                            Number(
                                                                newProductOrder.grossAmount
                                                            ) > 29999
                                                                ? "0"
                                                                : "1500";
                                                        newProductOrder.acceptedWebshopGtac =
                                                            "https://zepterclub.hu/cl100generaltermsandconditions230215";
                                                        console.log(
                                                            newProductOrder
                                                        );
                                                        createProductOrder(
                                                            newProductOrder
                                                        ).then(
                                                            (response) => {
                                                                //TODO elkapni paybycard-ot
                                                                console.log(
                                                                    response.data
                                                                );
                                                                // hideDialog();
                                                                setShowContactInformation(
                                                                    false
                                                                );
                                                                dispatch(
                                                                    clearCart()
                                                                );
                                                                dispatch(
                                                                    clearProductOrder()
                                                                );
                                                                dispatch(
                                                                    setLoadingOff()
                                                                );
                                                                setSubscribe(
                                                                    false
                                                                );
                                                                setSubscribeDeliveryDay(
                                                                    1
                                                                );
                                                                setSubscribeFrequency(
                                                                    "1M"
                                                                );
                                                                setAcceptWebshopGtac(
                                                                    false
                                                                );
                                                                setMessageDialogError(
                                                                    false
                                                                );
                                                                setMessageDialogHeader(
                                                                    "Értesítés"
                                                                );
                                                                if (
                                                                    response.data !==
                                                                    ""
                                                                ) {
                                                                    //átirnyáítás
                                                                    setMessageDialogBody(
                                                                        "Megrendelését sikeresen rögzítettük, hamarosan e-mailben is küldjük a részleteket. Most átirnyítjuk az OTP SimplePay bankkártyás fizetési oldalra."
                                                                    );
                                                                    setMessageDialogNavigateAction(
                                                                        response.data
                                                                    );
                                                                    setMessageDialogShow(
                                                                        Date.now()
                                                                    );
                                                                } else {
                                                                    setMessageDialogBody(
                                                                        "Megrendelését sikeresen rögzítettük, hamarosan e-mailben is küldjük a részleteket."
                                                                    );
                                                                    setMessageDialogNavigateAction(
                                                                        "../productlist"
                                                                    );
                                                                    setMessageDialogShow(
                                                                        Date.now()
                                                                    );
                                                                }
                                                                // TODO ha bankártyás fizetés
                                                                // és response quary-t figyelni
                                                                // ha van a link és az üzenet annyiban változik, hogy "most átirányítjuk a bakkártyás oldalra/SimplePay"
                                                            },
                                                            (error) => {
                                                                // console.log(error);
                                                                dispatch(
                                                                    setLoadingOff()
                                                                );
                                                                setMessageDialogError(
                                                                    true
                                                                );
                                                                setMessageDialogHeader(
                                                                    "Értesítés"
                                                                );
                                                                setMessageDialogBody(
                                                                    error
                                                                );
                                                                setMessageDialogNavigateAction(
                                                                    ""
                                                                );
                                                                setMessageDialogShow(
                                                                    Date.now()
                                                                );
                                                            }
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </Panel>
                ) : null}
                {cart.length === 0 ? (
                    <span className="mt-3 text-center text-lg text-red-400">
                        Üres a kosár, kérjük ellenőrizze a mennyiségeket!
                    </span>
                ) : null}
            </div>
        </>
    );
};

export default CheckoutSimplePayTest;
