import axios from 'axios';
import { server } from '../../../../config/endpoints';

const API_URL = server;

export const readAllProductCategories = () => {
    return axios
        .get(API_URL + '/protected/admin/productcategory')
        .then((response) => {
            return response;
        });
};

export const readProductCategory = (id: string) => {
    return axios
        .get(API_URL + '/protected/admin/productcategory/' + id)
        .then((response) => {
            return response;
        });
};

export const saveProductCategory = (formData: any) => {
    return axios
        .post(API_URL + '/protected/admin/productcategory', formData)
        .then((response) => {
            return response;
        });
};

export const deleteProductCategory = (id: string) => {
    return axios
        .delete(API_URL + '/protected/admin/productcategory/' + id)
        .then((response) => {
            return response;
        });
};
