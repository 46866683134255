interface InitialState {
    clubMemberAdminListFilterValue: null;
    clubMemberAdminFilteredList: [];
    documentAdminList: [];
    productAdminList: [];
    productCategoryAdminList: [];
}
const UpdateAdminListAction: string = "UpdateAdminListAction";

export default InitialState;
export { UpdateAdminListAction };
