import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const readSalesContract = (salesContractNo: string) => {
    return axios
        .get(API_URL + '/protected/installmentpayment/' + salesContractNo)
        .then((response) => {
            return response;
        });
};

export const createInstallmentPayment = (installmentPaymentRequest: any) => {
    return axios
        .post(API_URL + '/protected/simplepay/start', installmentPaymentRequest)
        .then((response) => {
            return response;
        });
};
