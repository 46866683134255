import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const readRepresentativeStructure = (representativeNumber: string) => {
    return axios
        .get(
            API_URL +
                '/api/admin/representativestructure/' +
                representativeNumber
        )
        .then((response) => {
            return response;
        });
};
