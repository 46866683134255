import axios from 'axios';
import { server } from '../../../../config/endpoints';

const API_URL = server;

let createProductPriceGroupRequest = (rowData: any) => {
    const result = {
        id: rowData.id,
        productId: rowData.productId,
        rrp: rowData.rrp,
        pl1: rowData.pl1,
        pl2: rowData.pl2,
        pl3: rowData.pl3,
        pl4: rowData.pl4,
        pl5: rowData.pl5,
        pl6: rowData.pl6,
        pl7: rowData.pl7,
        pl8: rowData.pl8,
        pl9: rowData.pl9,
        pl10: rowData.pl10,
        pl11: rowData.pl11,
        pl12: rowData.pl12,
        pl13: rowData.pl13,
        pl14: rowData.pl14,
        employee: rowData.employee,
        fromDate: rowData.fromDate,
        toDate: rowData.toDate,
        modifiedBy: rowData.modifiedBy,
    };
    // console.log(rowData.authenticated);
    return result;
};

//az összes termék összes árcsoportja
export const readAllPriceGroups = () => {
    return axios
        .get(API_URL + '/protected/admin/productpricegroup/')
        .then((response) => {
            return response;
        });
};

//egy adott termék összes árcsoportját
export const readProductAllPriceGroups = (productId: string) => {
    return axios
        .get(
            API_URL +
                '/protected/admin/product/' +
                productId +
                '/productpricegroup/'
        )
        .then((response) => {
            return response;
        });
};

// 1 sor
export const saveProductPriceGroup = (rowData: any) => {
    let request = createProductPriceGroupRequest(rowData);
    return axios
        .post(API_URL + '/protected/admin/productpricegroup/', request)
        .then((response) => {
            return response;
        });
};

// 1 sor
export const deleteProductPriceGroup = (priceGroupId: string) => {
    return axios
        .delete(API_URL + '/protected/admin/productpricegroup/' + priceGroupId)
        .then((response) => {
            return response;
        });
};
