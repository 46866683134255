import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setProductCategoryAdminList } from "../../../../state/dashboard/adminListSlice";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../../service/dashboard/AuthService";
import {
    readProductCategory,
    saveProductCategory,
    deleteProductCategory,
    readAllProductCategories,
} from "../../../../service/dashboard/admin/webshop/ProductCategoryService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InputTextarea } from "primereact/inputtextarea";
import parse from "html-react-parser";
import { TabPanel, TabView } from "primereact/tabview";
import ProductCategoryLogoAdminDetail from "./ProductCategoryLogoAdminDetail";

const ProductCategoryAdminDetail: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();
    const [categoryDto, setProductCategoryDto] = useState<any>();
    const [
        categoryAdminDetailDialogShow,
        setProductCategoryAdminDetailDialogShow,
    ] = useState<boolean>(false);
    const [
        categoryAdminDetailDialogChange,
        setProductCategoryAdminDetailDialogChange,
    ] = useState<number>(0);
    const [activeIndex, setActiveIndex] = useState(0);

    const productCategoryId: any = props.id;

    const [descriptionHTML, setDescriptionHTML] = useState<string>("");

    const richTextEditorModules = {
        toolbar: [
            [
                { header: [1, 2, 3, 4, false] },
                { size: ["small", false, "large", "huge"] },
            ],
            [
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                { color: [] },
                { background: [] },
            ],
            [
                { align: [] },
                { indent: "-1" },
                { indent: "+1" },
                { list: "ordered" },
                { list: "bullet" },
            ],
            ["link", "image", "clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const richTextEditorFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "align",
        "size",
        "color",
        "background",
        "link",
        "image",
    ];

    const refreshProductCategoryAdminList = () => {
        readAllProductCategories().then(
            (response) => {
                dispatch(
                    setProductCategoryAdminList(
                        response.data.productCategoryList
                    )
                );
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    useEffect(() => {
        if (
            props.show === true &&
            props.change !== 0 &&
            props.change !== categoryAdminDetailDialogChange
        ) {
            setProductCategoryDto(null);
            setDescriptionHTML("");
            readProductCategory(productCategoryId).then(
                (response) => {
                    console.log(response.data);
                    setProductCategoryDto(response.data);
                    setDescriptionHTML(response.data.description);
                },
                (error) => {
                    // console.log(error.response.status);
                    if (
                        error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                    ) {
                        // TODO kiírni
                        console.log("A név már szerepel a rendszerben");
                    }
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
            setProductCategoryAdminDetailDialogChange(props.change);
            setProductCategoryAdminDetailDialogShow(true);
        }
    }, [
        categoryAdminDetailDialogChange,
        navigate,
        productCategoryId,
        props.change,
        props.show,
    ]);

    useEffect(() => {
        readProductCategory(productCategoryId).then(
            (response) => {
                setProductCategoryDto(response.data);
            },
            (error) => {
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }, [activeIndex, navigate, productCategoryId]);

    const hideDialog = () => {
        setProductCategoryAdminDetailDialogShow(false);
        setProductCategoryDto(null);
        setDescriptionHTML("");
        formik.resetForm();
        refreshProductCategoryAdminList();
    };

    const formik = useFormik({
        initialValues: {
            id: categoryDto && categoryDto.id ? categoryDto.id : "",
            name: categoryDto && categoryDto.name ? categoryDto.name : "",
            description:
                categoryDto && categoryDto.description
                    ? categoryDto.description
                    : "",
            modifiedBy: user.email,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            description: Yup.string()
                .min(2, "A leírásnak egalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (
                categoryAdminDetailDialogShow &&
                window.confirm("Menti a módosításokat?")
            ) {
                //alert(JSON.stringify(values, null, 2));
                saveProductCategory(values).then(
                    (response) => {
                        // console.log(response.data);
                        setProductCategoryDto(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );
            }
        },
    });

    return (
        <Dialog
            modal
            style={{ width: "90vw" }}
            visible={categoryAdminDetailDialogShow}
            closable
            maximizable
            position="top"
            header="Kategória adatok"
            onHide={() => {
                hideDialog();
            }}
        >
            <TabView
                className="tabview-header-icon"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            >
                <TabPanel header="Adatok" leftIcon="pi pi-file">
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-12">
                                <label
                                    htmlFor="name"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Név
                                </label>
                                <InputText
                                    className={
                                        formik.errors.name
                                            ? "w-full p-invalid"
                                            : "w-full"
                                    }
                                    id="name"
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.errors.name ? (
                                    <small className="p-error">
                                        {formik.errors.name.toString()}
                                    </small>
                                ) : null}
                            </div>
                            <div className="field col-12 md:col-12">
                                <label
                                    htmlFor="description"
                                    className="block text-900 font-medium mb-2"
                                >
                                    Leírás
                                </label>
                                <Splitter
                                    className={
                                        formik.errors.description
                                            ? "p-invalid"
                                            : ""
                                    }
                                    style={{ height: "320px" }}
                                >
                                    <SplitterPanel className="h-full flex align-items-left justify-content-top">
                                        <div className="editor h-full w-full">
                                            <ReactQuill
                                                theme="snow"
                                                modules={richTextEditorModules}
                                                formats={richTextEditorFormats}
                                                id="description"
                                                value={
                                                    formik.values.description
                                                }
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        "description",
                                                        e
                                                    );
                                                    setDescriptionHTML(e);
                                                }}
                                            />
                                        </div>
                                    </SplitterPanel>
                                    <SplitterPanel className="flex align-items-left justify-content-top">
                                        <InputTextarea
                                            className="h-full w-full"
                                            id="descriptionHTML"
                                            // className={
                                            //     formik.errors.description
                                            //         ? "w-full p-invalid"
                                            //         : "w-full"
                                            // }
                                            value={descriptionHTML}
                                            onChange={(e) => {
                                                setDescriptionHTML(
                                                    e.target.value
                                                );
                                            }}
                                            onBlur={(e) => {
                                                // console.log(e);
                                                // formik.setFieldValue(
                                                //     "description",
                                                //     e.target.innerText
                                                // );
                                                formik.setFieldValue(
                                                    "description",
                                                    descriptionHTML
                                                );
                                            }}
                                            // {...formik.getFieldProps("description")}
                                        />
                                    </SplitterPanel>
                                </Splitter>
                                {formik.errors.description ? (
                                    <small className="p-error">
                                        {formik.errors.description.toString()}
                                    </small>
                                ) : null}
                            </div>
                        </div>
                        <Divider />
                        <div className="ql-editor">
                            {descriptionHTML ? parse(descriptionHTML) : null}
                        </div>
                        <Divider />
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-4 lg:col-3">
                                <Button
                                    className="p-button-secondary"
                                    type="button"
                                    label="Bezárás"
                                    icon="pi pi-arrow-circle-left"
                                    onClick={() => {
                                        hideDialog();
                                    }}
                                />
                            </div>
                            {/* TODO jogosultság reduxe */}
                            <div className="field col-12 md:col-4 lg:col-3">
                                <Button
                                    className="p-button-success"
                                    type="submit"
                                    label="Mentés"
                                    icon="pi pi-check"
                                />
                            </div>
                            <div className="field col-12 md:col-4 lg:col-3">
                                <Button
                                    className="p-button-danger"
                                    type="button"
                                    label="Törlés"
                                    icon="pi pi-trash"
                                    onClick={() => {
                                        //TODO van id, ha nincs akkor csak bezárás, vagy mentés submit kérdés?
                                        if (
                                            window.confirm(
                                                "Biztos törli a kategóriát?"
                                            )
                                        ) {
                                            deleteProductCategory(
                                                categoryDto.id
                                            ).then(
                                                (response) => {
                                                    // console.log(response.data);
                                                    hideDialog();
                                                },
                                                (error) => {
                                                    console.log(error);
                                                    // TODO esetleg nem bezárni az ablakot és hibaüzenet
                                                    hideDialog();
                                                }
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                </TabPanel>
                <TabPanel header="Logó" leftIcon="pi pi-image">
                    {categoryDto && categoryDto.id ? (
                        <ProductCategoryLogoAdminDetail
                            id={categoryDto.id}
                            logo={categoryDto.logo}
                        />
                    ) : (
                        <div>
                            Logó feltöltéshez először a kategória
                            alapinformációkat el kell menteni!
                        </div>
                    )}
                </TabPanel>
            </TabView>
        </Dialog>
    );
};

export default ProductCategoryAdminDetail;
