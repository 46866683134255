import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../../service/dashboard/AuthService";
import {
    readProductAllPriceGroups,
    saveProductPriceGroup,
    deleteProductPriceGroup,
} from "../../../../service/dashboard/admin/webshop/ProductPriceGroupService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

const ProductPriceGroupAdminDetail: React.FC<any> = (props: any) => {
    let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();

    //TODO van vagy nincs
    const productId: any = props.id;
    // console.log(productId);

    const newEmptyProductPriceGroup = {
        id: "",
        productId: productId,
        fromDate: "",
        toDate: "",
        rrp: "",
        pl1: "",
        pl2: "",
        pl3: "",
        pl4: "",
        pl5: "",
        pl6: "",
        pl7: "",
        pl8: "",
        pl9: "",
        pl10: "",
        pl11: "",
        pl12: "",
        pl13: "",
        pl14: "",
        employee: "",
    };
    const [productPriceGroupDto, setProductPriceGroupDto] = useState<any>([]);

    const refreshProductAllPriceGroups = () => {
        setProductPriceGroupDto([]);
        if (productId !== "") {
            readProductAllPriceGroups(productId).then(
                (response) => {
                    // console.log(response.data);
                    setProductPriceGroupDto(
                        response.data.productPriceGroupList.map(
                            (productPriceGroup: any) => {
                                return {
                                    id: productPriceGroup.id
                                        ? productPriceGroup.id
                                        : "",
                                    productId: productId,
                                    fromDate: productPriceGroup.fromDate
                                        ? productPriceGroup.fromDate
                                        : "",
                                    toDate: productPriceGroup.toDate
                                        ? productPriceGroup.toDate
                                        : "",
                                    rrp: productPriceGroup.rrp
                                        ? productPriceGroup.rrp
                                        : "",
                                    pl1: productPriceGroup.pl1
                                        ? productPriceGroup.pl1
                                        : "",
                                    pl2: productPriceGroup.pl2
                                        ? productPriceGroup.pl2
                                        : "",
                                    pl3: productPriceGroup.pl3
                                        ? productPriceGroup.pl3
                                        : "",
                                    pl4: productPriceGroup.pl4
                                        ? productPriceGroup.pl4
                                        : "",
                                    pl5: productPriceGroup.pl5
                                        ? productPriceGroup.pl5
                                        : "",
                                    pl6: productPriceGroup.pl6
                                        ? productPriceGroup.pl6
                                        : "",
                                    pl7: productPriceGroup.pl7
                                        ? productPriceGroup.pl7
                                        : "",
                                    pl8: productPriceGroup.pl8
                                        ? productPriceGroup.pl8
                                        : "",
                                    pl9: productPriceGroup.pl9
                                        ? productPriceGroup.pl9
                                        : "",
                                    pl10: productPriceGroup.pl10
                                        ? productPriceGroup.pl10
                                        : "",
                                    pl11: productPriceGroup.pl11
                                        ? productPriceGroup.pl11
                                        : "",
                                    pl12: productPriceGroup.pl12
                                        ? productPriceGroup.pl12
                                        : "",
                                    pl13: productPriceGroup.pl13
                                        ? productPriceGroup.pl13
                                        : "",
                                    pl14: productPriceGroup.pl14
                                        ? productPriceGroup.pl14
                                        : "",
                                    employee: productPriceGroup.employee
                                        ? productPriceGroup.employee
                                        : "",
                                };
                            }
                        )
                    );
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
        }
    };

    useEffect(() => {
        setProductPriceGroupDto([]);
        if (productId !== "") {
            readProductAllPriceGroups(productId).then(
                (response) => {
                    // console.log(response.data.productPriceGroupList);
                    setProductPriceGroupDto(
                        response.data.productPriceGroupList.map(
                            (productPriceGroup: any) => {
                                return {
                                    id: productPriceGroup.id
                                        ? productPriceGroup.id
                                        : "",
                                    productId: productId,
                                    fromDate: productPriceGroup.fromDate
                                        ? productPriceGroup.fromDate
                                        : "",
                                    toDate: productPriceGroup.toDate
                                        ? productPriceGroup.toDate
                                        : "",
                                    rrp: productPriceGroup.rrp
                                        ? productPriceGroup.rrp
                                        : "",
                                    pl1: productPriceGroup.pl1
                                        ? productPriceGroup.pl1
                                        : "",
                                    pl2: productPriceGroup.pl2
                                        ? productPriceGroup.pl2
                                        : "",
                                    pl3: productPriceGroup.pl3
                                        ? productPriceGroup.pl3
                                        : "",
                                    pl4: productPriceGroup.pl4
                                        ? productPriceGroup.pl4
                                        : "",
                                    pl5: productPriceGroup.pl5
                                        ? productPriceGroup.pl5
                                        : "",
                                    pl6: productPriceGroup.pl6
                                        ? productPriceGroup.pl6
                                        : "",
                                    pl7: productPriceGroup.pl7
                                        ? productPriceGroup.pl7
                                        : "",
                                    pl8: productPriceGroup.pl8
                                        ? productPriceGroup.pl8
                                        : "",
                                    pl9: productPriceGroup.pl9
                                        ? productPriceGroup.pl9
                                        : "",
                                    pl10: productPriceGroup.pl10
                                        ? productPriceGroup.pl10
                                        : "",
                                    pl11: productPriceGroup.pl11
                                        ? productPriceGroup.pl11
                                        : "",
                                    pl12: productPriceGroup.pl12
                                        ? productPriceGroup.pl12
                                        : "",
                                    pl13: productPriceGroup.pl13
                                        ? productPriceGroup.pl13
                                        : "",
                                    pl14: productPriceGroup.pl14
                                        ? productPriceGroup.pl14
                                        : "",
                                    employee: productPriceGroup.employee
                                        ? productPriceGroup.employee
                                        : "",
                                };
                            }
                        )
                    );
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
        }
    }, [productId, navigate]);

    const handleNewPriceGroupClick = () => {
        //TODO, nem hozhatsz létre új sort ha valamelyik nincs lezárva
        //lezárás dátuma a legrégebbi +1 nap

        // console.log(productPriceGroupDto);
        setProductPriceGroupDto((original: any) => {
            return [newEmptyProductPriceGroup, ...original];
        });
    };

    const onRowEditComplete = (e: any) => {
        // console.log(e.newData);
        const productPriceGroup = e.newData;
        productPriceGroup.productId = productId;
        productPriceGroup.modifiedBy = user.email;
        //TODO bevitt adatok ellenőrzése
        if (window.confirm("Menti a módosításokat?")) {
            // alert(JSON.stringify(values, null, 2));
            // console.log(productPriceGroup);
            saveProductPriceGroup(productPriceGroup).then(
                (response) => {
                    console.log(response.data);
                    refreshProductAllPriceGroups();
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const onRowEditChange = (e: any) => {
        console.log(e);
    };

    // const priceEditor = (options: any) => {
    //     return (
    //         <InputNumber
    //             value={options.value}
    //             onValueChange={(e: any) => {
    //                 console.log(e);
    //                 options.editorCallback(e.value);
    //             }}
    //             mode="currency"
    //             currency="HUF"
    //             locale="hu-HU"
    //         />
    //     );
    // };

    const textEditor = (options: any) => {
        return (
            <InputText
                type="text"
                value={options.value.replace(" ", "")}
                onChange={(e) => {
                    // console.log(e);
                    options.editorCallback(e.target.value.replace(" ", ""));
                }}
            />
        );
    };

    const deleteRow = (rowData: any) => {
        return (
            <Button
                className="p-button-danger p-button-rounded p-button-text"
                type="button"
                onClick={() => {
                    // console.log(rowData);
                    if (
                        //TODO a lista tud-e frissülni? Redux?
                        window.confirm("Biztos törli a kategóriát?")
                    ) {
                        deleteProductPriceGroup(rowData.id).then(
                            (response) => {
                                // console.log(response.data);
                                refreshProductAllPriceGroups();
                            },
                            (error) => {
                                console.log(error);
                                refreshProductAllPriceGroups();
                            }
                        );
                    }
                }}
                icon="pi pi-trash"
                aria-label="Delete"
            />
        );
    };

    return (
        <>
            {productId !== "" ? (
                <>
                    <div className="admin-list price-group-list">
                        <div className="card p-fluid">
                            <DataTable
                                value={productPriceGroupDto}
                                editMode="row"
                                dataKey="id"
                                onValueChange={onRowEditChange}
                                onRowEditComplete={onRowEditComplete}
                                style={{
                                    maxWidth: "87vw",
                                    overflowX: "auto",
                                }}
                            >
                                <Column
                                    header="Törlés"
                                    // headerStyle={{
                                    //     width: "10%",
                                    //     minWidth: "8rem",
                                    // }}
                                    bodyStyle={{ textAlign: "center" }}
                                    body={deleteRow}
                                    // editor={deleteRow}
                                />
                                <Column
                                    rowEditor
                                    header="Szerkesztés"
                                    // headerStyle={{
                                    //     width: "10%",
                                    //     minWidth: "8rem",
                                    // }}
                                    bodyStyle={{ textAlign: "center" }}
                                />
                                <Column
                                    field="fromDate"
                                    header="Dátumtól"
                                    editor={(options) => textEditor(options)}
                                    // editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="toDate"
                                    header="Dátumig"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="rrp"
                                    header="RRP"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl1"
                                    header="PL1"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl2"
                                    header="PL2"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl3"
                                    header="PL3"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl4"
                                    header="PL4"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl5"
                                    header="PL5"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl6"
                                    header="PL6"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl7"
                                    header="PL7"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl8"
                                    header="PL8"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl9"
                                    header="PL9"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl10"
                                    header="PL10"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl11"
                                    header="PL11"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl12"
                                    header="PL12"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl13"
                                    header="PL13"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="pl14"
                                    header="PL14"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                                <Column
                                    field="employee"
                                    header="FŐÁLLASÚ"
                                    editor={(options) => textEditor(options)}
                                    // style={{ width: "20%" }}
                                />
                            </DataTable>
                        </div>
                    </div>
                    <Divider />
                    <Button
                        type="button"
                        className="align-self-end w-full"
                        icon="pi pi-plus"
                        label="Új árcsoport hozzáadása"
                        onClick={handleNewPriceGroupClick}
                    />
                </>
            ) : (
                <div>
                    Árak kezeléséhez először a termék alapinformációkat el kell
                    menteni!
                </div>
            )}
        </>
    );
};

export default ProductPriceGroupAdminDetail;
