import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import {
    checkRecommenderLink,
    readInvited,
} from "../../service/home/ClubMemberRegistrationService";

//?cm=700000000021&cc=30276632

const Home: React.FC = () => {
    // console.log('Home is loading');

    let [searchParams] = useSearchParams();
    let navigate = useNavigate();
    const [validRecommenderLink, setValidRecommenderLink] =
        useState<boolean>(false);
    const [validInvitationLink, setValidInvitationLink] =
        useState<boolean>(false);
    const invitationId: string | null = searchParams.get("invitation");
    const clubMemberId: string | null = searchParams.get("cm");
    const clubConsultantId: string | null = searchParams.get("cc");

    useEffect(() => {
        // console.log("Home useEffect is called");
        if (invitationId !== null) {
            readInvited(invitationId).then(
                (response) => {
                    // console.log(response);
                    setValidInvitationLink(true);
                    navigate("/registration");
                    // console.log(response);
                },
                (error) => {
                    // console.log(error);
                    setValidInvitationLink(false);
                    navigate("/login?status=badrecommenderlink");
                }
            );
        } else if (clubMemberId !== null) {
            checkRecommenderLink(clubMemberId, clubConsultantId).then(
                (response) => {
                    setValidRecommenderLink(true);
                    navigate("/registration");
                },
                (error) => {
                    // console.log(error.response.status);
                    setValidRecommenderLink(false);
                    navigate("/login?status=badrecommenderlink");
                }
            );
        } else {
            navigate("/login");
        }
    }, [invitationId, clubMemberId, clubConsultantId, navigate]);

    if (validRecommenderLink === true || validInvitationLink === true) {
        return (
            <>
                <Outlet />
            </>
        );
    } else {
        return (
            <>
                <Outlet />
            </>
        );
    }
};

export default Home;
