import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoadingOff,
    setLoadingOn,
} from "../../../state/dashboard/mainSlice";
import { setActiveMenuItem } from "../../../state/dashboard/navigationSlice";
import {
    setClubMemberAdminFilteredList,
    setClubMemberAdminListFilterValue,
} from "../../../state/dashboard/adminListSlice";
import { RootState } from "../../../state/store";
import { StatusCodes } from "http-status-codes";
import { setAuthToken, logout } from "../../../service/dashboard/AuthService";
import { Panel } from "primereact/panel";
import { SelectButton } from "primereact/selectbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { readAllClubMembers } from "../../../service/dashboard/admin/ClubMemberService";
import ClubMemberAdminDetail from "./ClubMemberAdminDetail";
import "../../../css/Admin.css";

const ClubMemberAdminList: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("clubmemberadminlist"));

    const clubMemberAdminListFilterValue: any = useSelector(
        (state: RootState) => state.adminList.clubMemberAdminListFilterValue
    );

    const clubMemberAdminFilteredList: [] = useSelector(
        (state: RootState) => state.adminList.clubMemberAdminFilteredList
    );

    let navigate = useNavigate();
    setAuthToken();

    // const [clubMemberAdminList, setClubMemberAdminList] = useState([]);

    const isWithinLast5Days = (dateString: string): boolean => {
        const [year, month, day, hours, minutes, seconds] =
            dateString.split(/[\s.:]+/);
        const date = new Date(
            Number(year),
            Number(month) - 1,
            Number(day),
            Number(hours),
            Number(minutes),
            Number(seconds)
        );
        const fiveDaysAgo = new Date(Date.now() - 5 * 24 * 60 * 60 * 1000); // 5 napja
        return date >= fiveDaysAgo && date <= new Date();
    };

    const [
        clubMemberAdminDetailDialogShow,
        setClubMemberAdminDetailDialogShow,
    ] = useState<boolean>(false);
    const [clubMemberValidationId, setClubMemberValidationId] =
        useState<string>("");
    const [
        clubMemberAdminDetailDialogChange,
        setClubMemberAdminDetailDialogChange,
    ] = useState<Number>(0);

    useEffect(() => {
        // console.log(clubMemberAdminFilteredList);
        if (clubMemberAdminFilteredList.length === 0) {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message:
                        "Regisztráltak listájának összeállítása folyamatban.",
                })
            );
            readAllClubMembers().then(
                (response) => {
                    // setClubMemberAdminList(response.data.clubMemberList);
                    refreshClubMemberAdminFilteredList(
                        clubMemberAdminListFilterValue,
                        response.data.clubMemberList
                    );
                    // setClubMemberFilteredList(response.data.clubMemberList);
                    // console.log(response.data.clubMemberList);
                    dispatch(setLoadingOff());
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                    dispatch(setLoadingOff());
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        clubMemberAdminFilteredList.length,
        clubMemberAdminListFilterValue,
        navigate,
    ]);

    const refreshClubMemberAdminFilteredList = (
        filterValue: string,
        clubMemberList: []
    ) => {
        if (filterValue === "NOTFINISHED") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return clubMember.registrationStatus === "VALIDATED";
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "NEWCLUBMEMBER") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    clubMember.cuNumber === null &&
                    clubMember.representativeNumber === null &&
                    clubMember.navisionClubMemberTask !== "CHECK" &&
                    clubMember.registrationStatus === "VALIDATED"
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "UPDATECLUBMEMBER") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    clubMember.representativeNumber === null &&
                    clubMember.navisionClubMemberTask === "UPDATE" &&
                    clubMember.registrationStatus === "VALIDATED"
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "NEWREPRESENTATIVE") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    clubMember.representativeNumber !== null &&
                    clubMember.representativeNumber.startsWith("770") &&
                    clubMember.navisionRepresentativeTask !== "CHECK" &&
                    clubMember.registrationStatus === "VALIDATED"
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "CONFLICT") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    (clubMember.navisionClubMemberTask === "CHECK" ||
                        clubMember.navisionRepresentativeTask === "CHECK") &&
                    clubMember.registrationStatus === "VALIDATED"
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "PENDING") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    clubMember.registrationStatus === "PENDING" &&
                    clubMember.created &&
                    isWithinLast5Days(clubMember.created)
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else if (filterValue === "EMPLOYEE") {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return clubMember.zepterRank === "EMPLOYEE";
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        } else {
            const filteredList = clubMemberList.filter((clubMember: any) => {
                return (
                    clubMember.registrationStatus !== "PENDING" ||
                    (clubMember.registrationStatus === "PENDING" &&
                        clubMember.created &&
                        isWithinLast5Days(clubMember.created))
                );
            });
            dispatch(setClubMemberAdminFilteredList(filteredList));
        }

        // let orderedSelectedColumns = columns.filter((col) =>
        //     selectedColumns.some((sCol) => sCol.field === col.field)
        // );
    };

    const editClubMemberTemplate = (rowData: any) => {
        return (
            <Button
                type="button"
                onClick={() => {
                    setClubMemberValidationId(rowData.validationId);
                    setClubMemberAdminDetailDialogShow(true);
                    setClubMemberAdminDetailDialogChange(Date.now());
                }}
                icon="pi pi-pencil"
                className="p-button-rounded p-button-text"
                aria-label="Edit"
            />
        );
    };

    const clubMemberFilteredListOptions = [
        { label: "Nincs kész", value: "NOTFINISHED" },
        { label: "CU frissíteni", value: "UPDATECLUBMEMBER" },
        { label: "Új ZepterClub tag", value: "NEWCLUBMEMBER" },
        { label: "Új Online Partner", value: "NEWREPRESENTATIVE" },
        { label: "Konfliktus", value: "CONFLICT" },
        { label: "Nincs visszaigazolva", value: "PENDING" },
        { label: "Főállású", value: "EMPLOYEE" },
    ];

    const onClubMemberAdminFilteredListSelect = (event: { value: any }) => {
        // console.log(event.value);
        dispatch(setClubMemberAdminListFilterValue(event.value));
        refreshClubMemberAdminFilteredList(event.value, []);
    };

    return (
        <>
            <Panel header="Szűrők">
                <SelectButton
                    value={clubMemberAdminListFilterValue}
                    options={clubMemberFilteredListOptions}
                    onChange={onClubMemberAdminFilteredListSelect}
                />
            </Panel>
            <Panel
                className="pt-3 md:pt-4 lg:pt-5"
                header={
                    "Klubtagok listája - " +
                    clubMemberAdminFilteredList.length +
                    " fő"
                }
            >
                <ClubMemberAdminDetail
                    id={clubMemberValidationId}
                    show={clubMemberAdminDetailDialogShow}
                    change={clubMemberAdminDetailDialogChange}
                />
                <DataTable
                    className="admin-list"
                    value={clubMemberAdminFilteredList}
                    size="small"
                    paginator
                    style={{
                        maxWidth: "87vw",
                        overflowX: "auto",
                    }}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                    rows={20}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                >
                    <Column
                        header="Szerkesztés"
                        body={editClubMemberTemplate}
                    />
                    <Column field="name" header="Név" sortable filter />
                    <Column field="email" header="Email" sortable filter />
                    <Column
                        field="registrationStatus"
                        header="Státusz"
                        alignHeader="center"
                        align="center"
                        sortable
                        filter
                    ></Column>
                    <Column
                        field="navisionClubMemberTask"
                        header="Navision Klubtag Feladat"
                        alignHeader="center"
                        align="center"
                        sortable
                        filter
                    />
                    <Column
                        field="navisionRepresentativeTask"
                        header="Navision Tanácsadó Feladat"
                        alignHeader="center"
                        align="center"
                        sortable
                        filter
                    />
                </DataTable>
            </Panel>
        </>
    );
};

export default ClubMemberAdminList;
