import React, { useEffect } from "react";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "../../css/ClockCountdown.css";

// https://www.npmjs.com/package/@leenguyen/react-flip-clock-countdown

const ClockCountdown: React.FunctionComponent<any> = (props: any) => {
    // const englishLabels = document.getElementsByClassName(
    //     "_2aOn7"
    // ) as HTMLCollectionOf<HTMLDivElement>;

    // FlipClockCountdown inde.js
    // var labels = ['days', 'hours', 'minutes', 'seconds'];
    useEffect(() => {
        // console.log("ClockCountdown useEffect is called");
        // TODO css first white label color after translate change it
        const handler = () => {
            const englishLabels = document.getElementsByClassName(
                "_2aOn7"
            ) as HTMLCollectionOf<HTMLDivElement>;
            if (englishLabels.length === 4) {
                englishLabels[0].innerHTML = "Nap";
                englishLabels[1].innerHTML = "Óra";
                englishLabels[2].innerHTML = "Perc";
                englishLabels[3].innerHTML = "Mp";
            }
        };

        if (document.readyState === "complete") {
            handler();
        } else {
            window.addEventListener("load", handler);
            return () => document.removeEventListener("load", handler);
        }
    });

    return (
        <div>
            <FlipClockCountdown
                className="flip-clock-mobil lg:flip-clock"
                to={props.endTime}
            />
        </div>
    );
};
export default ClockCountdown;
