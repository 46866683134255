export const capitalizeFirstLetters = (text: string) => {
    if (text && text !== '') {
        const wordsBySpace = replaceAccentCharacters(text.toLowerCase()).split(
            ' '
        );
        text = wordsBySpace
            .map((word) => {
                if (word.includes('.ker.')) return '.ker.';
                return (
                    (word[0] ? word[0].toUpperCase() : '') + word.substring(1)
                );
            })
            .join(' ');
        const wordsByHyphen = text.split('-');
        text = wordsByHyphen
            .map((word) => {
                return (
                    (word[0] ? word[0].toUpperCase() : '') + word.substring(1)
                );
            })
            .join('-');
        text = text.replaceAll(' .ker.', ',');
        text = text.replaceAll(' Sor ', ' sor ');
        text = text.replaceAll(' Utca ', ' utca ');
        text = text.replaceAll(' Utca', ' utca');
        text = text.replaceAll(' U ', ' u ');
        text = text.replaceAll(' U. ', ' u. ');
        text = text.replaceAll(' U.', ' u. ');
        text = text.replaceAll(' És ', ' és ');
        text = text.replaceAll(' Út ', ' út ');
        text = text.replaceAll(' Út', ' út');
        text = text.replaceAll(' Köz ', ' köz ');
        text = text.replaceAll(' köz', ' köz');
    }
    return text;
};

export const replaceAccentCharacters = (text: string) => {
    if (text && text !== '') {
        const find = [
            'à',
            'â',
            'ä',
            'ã',
            'å',
            'ā',
            'À',
            'Â',
            'Ä',
            'Ã',
            'Å',
            'Ā',
            'è',
            'ê',
            'ë',
            'ė',
            'ē',
            'È',
            'Ê',
            'Ë',
            'Ė',
            'Ē',
            'î',
            'ï',
            'ì',
            'ī',
            'Î',
            'Ï',
            'Ì',
            'Ī',
            'û',
            'ù',
            'ū',
            'Û',
            'Ù',
            'Ū',
            'ô',
            'ò',
            'õ',
            'ō',
            'Ô',
            'Ò',
            'Õ',
            'Ō',
        ];
        const replace = [
            'á',
            'á',
            'á',
            'á',
            'á',
            'á',
            'Á',
            'Á',
            'Á',
            'Á',
            'Á',
            'Á',
            'é',
            'é',
            'é',
            'é',
            'é',
            'É',
            'É',
            'É',
            'É',
            'É',
            'í',
            'í',
            'í',
            'í',
            'Í',
            'Í',
            'Í',
            'Í',
            'ü',
            'ú',
            'ü',
            'Ü',
            'Ú',
            'Ü',
            'ö',
            'ó',
            'ö',
            'ö',
            'Ö',
            'Ó',
            'Ö',
            'Ö',
        ];
        for (let i = 0; i < find.length; i++) {
            text = text.replace(find[i], replace[i]);
        }
        text = text.trim();
    }
    return text;
};

export const replaceVipNumberNotUsedCharacters = (text: string) => {
    if (text && text !== '') {
        text = text.replaceAll('_', '');
        return text;
    }
    return null;
};

export const replaceVatNumberNotUsedCharacters = (text: string) => {
    if (text && text !== '') {
        text = text.replaceAll('-', '');
        text = text.replaceAll('_', '');
        return text;
    }
    return null;
};

export const replacePhoneNotUsedCharacters = (text: string) => {
    if (text && text !== '') {
        text = text.replace('(', '');
        text = text.replace(')', '');
        text = text.replaceAll('-', '');
        text = text.replaceAll('_', '');
        return text;
    }
    return null;
};

export const formatPhoneNumber = (text: string) => {
    if (text && text !== '') {
        return (
            '+' +
            text.replace(
                /\D*(\d{2})\D*(\d{2})\D*(\d{3})\D*(\d{4})\D*/,
                '$1 $2 $3 $4'
            )
        );
    }
    return null;
};

export const formatPhoneNumberWithoutSpace = (text: string): string => {
    if (text && text !== '') {
        const newPhoneNumber = '+' + text.replaceAll(' ', '');
        return newPhoneNumber.replaceAll('++', '+');
    }
    return '';
};

export const formatPhoneNumberToFormik = (text: string) => {
    if (text && text !== '') {
        return (
            '+' +
            text.replace(
                /\D*(\d{2})\D*(\d{2})\D*(\d{3})\D*(\d{4})\D*/,
                '$1($2)$3-$4'
            )
        );
    }
    return '';
};

export const setNumberStringThousandSeparator = (text: string) => {
    if (text && text !== '' && Number(text)) {
        return Number(text).toLocaleString('hu-HU');
    }
    return text;
};

export const replaceArabicZeterMonthToRoman = (text: string) => {
    if (text && text !== '') {
        const arabicRomanPairs = {
            '001': 'I.ZH.',
            '002': 'II.ZH.',
            '003': 'III.ZH.',
            '004': 'IV.ZH.',
            '005': 'V.ZH.',
            '006': 'VI.ZH.',
            '007': 'VII.ZH.',
            '008': 'VIII.ZH.',
            '009': 'IX.ZH.',
            '010': 'X.ZH.',
            '011': 'XI.ZH.',
            '012': 'XII.ZH.',
            '013': 'XIII.ZH.',
        };
        const yearMonth = text.split('-');
        if (yearMonth.length === 2) {
            yearMonth[0] = yearMonth[0] + '.';
            // yearMonth[1] = arabicRomanPairs[yearMonth[1].toString()];
            // const month = yearMonth[1].toString();
            yearMonth[1] =
                arabicRomanPairs[yearMonth[1] as keyof typeof arabicRomanPairs];
            return yearMonth.join('');
        } else {
            return text;
        }
    }
    return text;
};

export const replaceShortRanks = (text: string) => {
    if (!text) return 'NINCS RANG';
    if (text === 'JUNIOR') return 'JP';
    if (text === 'TEAM') return 'TP';
    if (text === 'SENIOR') return 'SP';
    if (text === 'DIVISION') return 'DP';
    if (text === 'AREA') return 'AP';
    if (text === 'REGIONAL') return 'RP';
    return text;
};

export const formatDate = (date: Date | undefined | null) => {
    console.log(date);
    if (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
    }
    return null;
};

export const formatShortDate = (date: Date | undefined | null) => {
    if (date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return month + '.' + day;
    }
    return null;
};

export const formatDateOfBirthFromNumber = (dateOfBirth: number) => {
    if (dateOfBirth && dateOfBirth !== null) {
        const dob = new Date(dateOfBirth);
        const year = dob.getFullYear();
        if (year.toString() === '1753') return 'nincs adat';
        const month = ('0' + (dob.getMonth() + 1)).slice(-2);
        const day = ('0' + dob.getDate()).slice(-2);
        return year + '.' + month + '.' + day + '.';
    }
    return '';
};

export const formatDateOfBirthFromDate = (dateOfBirth: Date | string) => {
    if (dateOfBirth && dateOfBirth !== null) {
        if (typeof dateOfBirth === 'object') {
            const year = dateOfBirth.getFullYear();
            const month = ('0' + (dateOfBirth.getMonth() + 1)).slice(-2);
            const day = ('0' + dateOfBirth.getDate()).slice(-2);
            return year + '.' + month + '.' + day;
        } else if (typeof dateOfBirth === 'string') {
            return dateOfBirth;
        }
    }
    return '';
};

export const dateOfBirthToFormik = (dateOfBirth: string | null) => {
    if (
        dateOfBirth &&
        dateOfBirth !== null &&
        dateOfBirth?.replaceAll('_', '').replaceAll('.', '').replaceAll('-', '')
            .length === 8
    ) {
        dateOfBirth = dateOfBirth
            .replaceAll('_', '')
            .replaceAll('.', '')
            .replaceAll('-', '');
        const year = parseInt(dateOfBirth.substring(0, 4));
        const month = parseInt(dateOfBirth.substring(4, 6));
        const day = parseInt(dateOfBirth.substring(6, 8));
        const date = new Date(year, month - 1, day);
        return date;
    }
    return dateOfBirth;
};

export const formatStringToDate = (dateString: any) => {
    if (typeof dateString === 'string' && dateString !== '1753.01.01') {
        const [year, month, day] = dateString
            .split('.')
            .map((part) => parseInt(part));
        return new Date(year, month - 1, day);
    } else return null;
};

export const formatName = (
    lastName: string | null,
    firstName: string | null,
    companyName: string | null,
    companyType: string | null
) => {
    if (firstName && lastName) {
        return lastName + ' ' + firstName;
    } else if (lastName) {
        return lastName;
    } else if (firstName) {
        return firstName;
    } else if (companyName && companyType) {
        return companyName + ' ' + companyType;
    } else if (companyName) {
        return companyName;
    } else {
        return 'Nincs név adat';
    }
};
