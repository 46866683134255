import React, { FunctionComponent } from "react";
// import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../../../state/dashboard/navigationSlice";
// import {
//     setAuthToken,
//     logout,
// } from "../../../../service/dashboard/AuthService";

const PriceGroupAdminList: FunctionComponent<any> = (props: any) => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("pricegroupadminlist"));
    return <>All Products Price List</>;
};

export default PriceGroupAdminList;
