import InitialState, { UpdateUserAction } from '../../types/redux/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: InitialState = {
    userProfile: {},
    userRanks: [],
    numberOfBizzClubPartners: null,
};

export const userSlice = createSlice({
    name: UpdateUserAction,
    initialState: initialState,
    reducers: {
        setUserProfile: (state, action: PayloadAction<any>) => {
            state.userProfile = action.payload;
        },
        setUserRanks: (state, action: PayloadAction<any>) => {
            state.userRanks = action.payload;
        },
        setNumberOfBizzClubPartners: (state, action: PayloadAction<any>) => {
            state.numberOfBizzClubPartners = action.payload;
        },
        cleanUser: (state, action: PayloadAction<any>) => {
            state.userProfile = {};
            state.userRanks = [];
            state.numberOfBizzClubPartners = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setUserProfile } = userSlice.actions;
export const { setUserRanks } = userSlice.actions;
export const { setNumberOfBizzClubPartners } = userSlice.actions;
export const { cleanUser } = userSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default userSlice.reducer;
