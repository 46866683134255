import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoadingOn,
    setLoadingOff,
} from "../../../../state/dashboard/mainSlice";
import { setActiveMenuItem } from "../../../../state/dashboard/navigationSlice";
import { setProductAdminList } from "../../../../state/dashboard/adminListSlice";
import { RootState } from "../../../../state/store";
import {
    setAuthToken,
    logout,
} from "../../../../service/dashboard/AuthService";
import {
    deleteProduct,
    readAllProducts,
} from "../../../../service/dashboard/admin/webshop/ProductService";
import { StatusCodes } from "http-status-codes";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ProductAdminDetail from "./ProductAdminDetail";
import noImageAvailable from "../../../../asset/no_image_available.png";
import "../../../../css/Admin.css";

const ProductAdminList: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("productadminlist"));

    const productAdminList: [] = useSelector(
        (state: RootState) => state.adminList.productAdminList
    );

    let navigate = useNavigate();
    setAuthToken();

    const [productAdminDetailDialogShow, setProductAdminDetailDialogShow] =
        useState<boolean>(false);
    const [productAdminDetailDialogChange, setProductAdminDetailDialogChange] =
        useState<number>(0);

    const [productId, setProductId] = useState<string>("");

    const refreshProductAdminList = () => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Termékek betöltése folyamatban.",
            })
        );
        readAllProducts().then(
            (response) => {
                // console.log(response.data.productList);
                const productAdminListSort = response.data.productList.map(
                    (productItem: any) => {
                        productItem.productImageList =
                            productItem.productImageList.sort(
                                (a: any, b: any) => {
                                    return (
                                        Number(a.orderNo) - Number(b.orderNo)
                                    );
                                }
                            );
                        return productItem;
                    }
                );
                dispatch(setProductAdminList(productAdminListSort));
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    useEffect(() => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Termékek betöltése folyamatban.",
            })
        );
        readAllProducts().then(
            (response) => {
                console.log(response.data.productList);
                dispatch(setProductAdminList(response.data.productList));
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }, [dispatch, navigate]);

    const headerTemplate = (options: {
        className: any;
        titleClassName: any;
        togglerClassName: any;
    }) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName}`;
        return (
            <div className={className}>
                <span className={titleClassName}>Termékek</span>
                <Button
                    className="p-panel p-button p-button-sm p-button-info"
                    type="button"
                    label="Új termék"
                    icon="pi pi-plus"
                    onClick={() => {
                        setProductId("");
                        setProductAdminDetailDialogShow(true);
                        setProductAdminDetailDialogChange(Date.now());
                    }}
                    aria-label="Create"
                />
            </div>
        );
    };

    const thumbnailBodyTemplate = (rowData: any) => {
        return (
            // <div>Kép</div>
            <img
                width="100"
                height="100"
                src={
                    rowData && rowData.productImageList[0]
                        ? `data:image/png;base64,${rowData.productImageList[0].thumbnail}`
                        : noImageAvailable
                }
                // src={
                //     rowData.productImageList &&
                //     rowData.productImageList.length > 0
                //         ? `data:image/png;base64,${
                //               rowData.productImageList.find(
                //                   (productItem: any) => {
                //                       return productItem.orderNo
                //                           ? productItem.orderNo === "1"
                //                           : null;
                //                   }
                //               ).thumbnail
                //           }`
                //         : noImageAvailable
                // }
                alt={
                    rowData.productImageList[0]
                        ? rowData.productImageList[0].id
                        : "No Image Available"
                }
                className="product-image"
            />
        );
    };

    const editProductTemplate = (rowData: any) => {
        return (
            <>
                <Button
                    className="p-button-rounded p-button-text"
                    type="button"
                    onClick={() => {
                        setProductId(rowData.id);
                        setProductAdminDetailDialogShow(true);
                        setProductAdminDetailDialogChange(Date.now());
                    }}
                    icon="pi pi-pencil"
                    aria-label="Edit"
                />
                <Button
                    className="p-button-danger p-button-rounded p-button-text"
                    type="button"
                    onClick={() => {
                        if (window.confirm("Biztos törli a terméket?")) {
                            // TODO hibaüzenet/hibakód, ha van kép vagy ár hozzákapcsolva
                            deleteProduct(rowData.id).then(
                                (response) => {
                                    // console.log(response.data);
                                    refreshProductAdminList();
                                },
                                (error) => {
                                    console.log(error);
                                    refreshProductAdminList();
                                }
                            );
                        }
                    }}
                    icon="pi pi-trash"
                    aria-label="Delete"
                />
            </>
        );
    };

    return (
        <>
            {" "}
            <Panel headerTemplate={headerTemplate}>
                <ProductAdminDetail
                    id={productId}
                    show={productAdminDetailDialogShow}
                    change={productAdminDetailDialogChange}
                />
                <div className="admin">
                    <DataTable
                        className="admin-list"
                        value={productAdminList}
                        size="small"
                        paginator
                        style={{
                            maxWidth: "87vw",
                            overflowX: "auto",
                        }}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                        rows={20}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                    >
                        <Column header="Akció" body={editProductTemplate} />
                        <Column
                            header="Thumbnail"
                            body={thumbnailBodyTemplate}
                            alignHeader="center"
                            align="center"
                        />
                        <Column
                            field="itemNumber"
                            header="Cikkszám"
                            alignHeader="center"
                            align="left"
                            sortable
                            filter
                        />
                        <Column
                            field="name"
                            header="Név"
                            alignHeader="center"
                            align="left"
                            sortable
                            filter
                        />
                        <Column
                            field="stockInWarehouse"
                            header="Raktár készlet"
                            alignHeader="center"
                            align="center"
                            sortable
                            filter
                        />
                        <Column
                            field="reservedStock"
                            header="Foglalt készlet"
                            alignHeader="center"
                            align="center"
                            sortable
                            filter
                        />
                        <Column
                            field="minStock"
                            header="Minimum készlet"
                            alignHeader="center"
                            align="center"
                            sortable
                            filter
                        />
                        <Column
                            field="productCategory.name"
                            header="Kategória"
                            alignHeader="center"
                            align="center"
                            sortable
                            filter
                        />
                        {/* TODO megjeleníteni hány terméket tartalmaz */}
                    </DataTable>
                </div>
            </Panel>
        </>
    );
};

export default ProductAdminList;
