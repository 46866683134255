import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

const MessageAlert: React.FC = () => {
    let [searchParams] = useSearchParams();

    const [messageAlertShow, setMessageAlertShow] = useState<boolean>(true);

    const handleOnClick = () => {
        setMessageAlertShow(false);
    };

    const status: string = searchParams.get("status") ?? "";
    const transactionId: string = searchParams.get("transactionid") ?? "";
    let header: string = "";
    let msg: string = "";

    if (status === "SUCCESS") {
        header = "SIKER";
        msg =
            "Gratulálunk, sikerült a bankkártyás fizetés, hamarosan e-mailben is értesítjük.";
    } else if (status === "FAIL") {
        header = "HIBA";
        msg =
            "A bankkártyás fizetés nem sikerült! Hamarosan e-mailben értesítjük a további teendőkről.";
    } else if (status === "TIMEOUT") {
        header = "HIBA: IDŐTÚLLÉPÉS";
        msg =
            "A bankkártyás fizetés nem sikerült időtúllépés miatt! Hamarosan e-mailben értesítjük a további teendőkről.";
    } else if (status === "CANCEL") {
        header = "VISSZAVONÁS";
        msg =
            "Ön megszakította a fizetést. Hamarosan e-mailben értesítjük a további teendőkről.";
    }
    if (transactionId !== "") {
        msg = msg + "\n\nSimplePay tranzakció azonosító: " + transactionId;
    }
    if (status === "FAIL") {
        msg =
            msg +
            "\n\n\nKérjük, ellenőrizze a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.";
    }

    const msgLines = msg !== "" ? msg.split("\n") : null;

    // success: successful authorisation
    // fail: authorisation failed
    // timeout: timeout when the payment has not been initiated until the set time limit
    // cancel: cancelled payment, browser closed, leaving the payment page
    return (
        <>
            {msg !== "" && messageAlertShow ? (
                <div
                    className="max-w-full"
                    key={`${Date.now()}-${Math.random()}`}
                >
                    {status === "SUCCESS" ? (
                        <div className="flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                            <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                            <div className="mr-3">
                                <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                    {header}
                                </div>
                                <div className="m-0 p-0 text-green-700 line-height-3">
                                    {msgLines !== null
                                        ? msgLines?.map(
                                              (part: string, index: number) => {
                                                  return (
                                                      <div
                                                          key={
                                                              "msgPart-" + index
                                                          }
                                                      >
                                                          {part}
                                                      </div>
                                                  );
                                              }
                                          )
                                        : msg}
                                </div>
                            </div>
                            <div className="ml-auto" onClick={handleOnClick}>
                                <div className="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-green-50 no-underline cursor-pointer transition-colors transition-duration-150">
                                    <i className="pi pi-times text-green-900"></i>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex align-items-start p-4 bg-pink-100 border-round border-1 border-pink-300">
                            <i className="pi pi-exclamation-triangle text-pink-900 text-2xl mr-3"></i>
                            <div className="mr-3">
                                <div className="text-pink-900 font-medium text-xl mb-2 line-height-1">
                                    {header}
                                </div>
                                <div className="m-0 p-0 text-pink-700 line-height-3">
                                    {msgLines !== null
                                        ? msgLines?.map(
                                              (part: string, index: number) => {
                                                  return (
                                                      <div
                                                          key={
                                                              "msgPart-" + index
                                                          }
                                                      >
                                                          {part}
                                                      </div>
                                                  );
                                              }
                                          )
                                        : msg}
                                </div>
                            </div>
                            <div className="ml-auto" onClick={handleOnClick}>
                                <div className="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-pink-50 no-underline cursor-pointer transition-colors transition-duration-150">
                                    <i className="pi pi-times text-pink-900"></i>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default MessageAlert;
