import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { Ripple } from "primereact/ripple";
import ReactPlayer from "react-player/lazy";
import GeneralTermsDialog from "../../component/GeneralTermsDialog";
import pageTeaserInformationImage from "../../asset/page-teaser-information.png";
import MYBFB from "../../component/dashboard/MYBFB";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { faS, faAlignRight } from "@fortawesome/free-solid-svg-icons";

// "fas" -> solid
// "far" -> regular
// "fal" -> light
// "fat" -> thin
// "fad" -> duotone
// "fab" -> brand
// library.add(faS, faAlignRight);

const Information = () => {
    // let navigate = useNavigate();

    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("information"));

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const [generalTermsDialogShow, setGeneralTermsDialogShow] =
        useState<Number>(0);
    const [generalTermsDialogComponent, setGeneralTermsDialogComponent] =
        useState<string>("");
    return (
        <>
            {/* <FontAwesomeIcon icon={["fas", "align-right"]} /> */}

            <GeneralTermsDialog
                generalTermsDialogShow={generalTermsDialogShow}
                generalTermsDialogComponent={generalTermsDialogComponent}
            />
            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto p-3 md:p-4 lg:p-5">
                <div className="surface-card border-round shadow-2 p-2 md:p-4 lg:p-5">
                    <div className=" mb-2 flex justify-content-center">
                        <div className="w-auto md:w-8">
                            <img
                                src={pageTeaserInformationImage}
                                className="responsive fadeinleft animation-duration-500"
                                alt="zepterclub.hu információk kép"
                            />
                        </div>
                    </div>
                    <div className="text-xl text-900 font-medium mb-3 ml-2">
                        Információk
                    </div>
                    {userProfile &&
                    (userProfile.zepterRank === "REPRESENTATIVE" ||
                        userProfile.zepterRank === "EMPLOYEE") &&
                    userProfile.representativeNumber !== null &&
                    userProfile.representativeNumber !== "" ? (
                        <MYBFB />
                    ) : null}
                    {userProfile &&
                    (userProfile.zepterRank === "REPRESENTATIVE" ||
                        userProfile.zepterRank === "EMPLOYEE") &&
                    userProfile.representativeNumber !== null &&
                    userProfile.representativeNumber !== "" &&
                    userProfile.contractReceiveDate !== null ? (
                        <>
                            <a
                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                style={{
                                    borderRadius: "12px",
                                    textDecoration: "none",
                                }}
                                href="https://zepterclub.hu/upload/pdf/Marketing_Terv_2024-09_HU_20240814.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="pi pi-euro mr-2 text-indigo-500"></i>
                                <span className="font-bold text-indigo-500">
                                    Marketing Terv 2024.08.14-től
                                </span>
                                <Ripple />
                            </a>
                            <a
                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                style={{
                                    borderRadius: "12px",
                                    textDecoration: "none",
                                }}
                                href="https://zepterclub.hu/upload/pdf/Marketing_Terv_2024-01_HU_20240131.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="pi pi-euro mr-2 text-indigo-500"></i>
                                <span className="font-medium">
                                    Marketing Terv 2024.01.31-től 2024.03.26-ig
                                </span>
                                <Ripple />
                            </a>
                            <a
                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                style={{
                                    borderRadius: "12px",
                                    textDecoration: "none",
                                }}
                                href="https://zepterclub.hu/upload/pdf/Marketing_Terv_2023-07_HU_20230621.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="pi pi-euro mr-2 text-indigo-500"></i>
                                <span className="font-medium">
                                    Marketing Terv 2023.06.21-től 2024.01.30-ig
                                </span>
                                <Ripple />
                            </a>
                            <a
                                className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                style={{
                                    borderRadius: "12px",
                                    textDecoration: "none",
                                }}
                                href="https://zepterclub.hu/upload/pdf/Marketing_Terv_magyarazat_2023-07.pdf"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="pi pi-info-circle mr-2 text-indigo-500"></i>
                                <span className="font-medium">
                                    2024. augusztus | KÉPZÉS Markting terv, új
                                    PL szintek, vevői szerződések
                                </span>
                                <Ripple />
                            </a>
                            <ReactPlayer
                                className="mt-15 mb-15"
                                width="100%"
                                controls={true}
                                url="https://youtu.be/u3SyMCKfP-0?si=FgDVR5cD4l1ts7Ri"
                            />
                            <hr />
                        </>
                    ) : null}
                    {/* TODO ide lehet PDL szintekről egy kép ha nem munkatárs*/}
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMNYJ2024Q4"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500 "></i>
                        <span className="font-bold text-indigo-500">
                            2024 Q4 Klubtag Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMNYJ2024Q3"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500 "></i>
                        <span className="font-medium">
                            2024 Q3 Klubtag Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMNYJ2024Q2"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500 "></i>
                        <span className="font-medium">
                            2024 Q2 Klubtag Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMNYJ2024Q1"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500 "></i>
                        <span className="font-medium">
                            2024 Q1 Klubtag Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMNYJ2023Q4"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            2023 Q4 Klubtag Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMOPPNYJ2023Q3"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            2023 Q3 Klubtag és Online Partner Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMOPPNYJ2023Q2"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            2023 Q2 Klubtag és Online Partner Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsCMOPPNYJ2023Q1"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            2023 Q1 Klubtag és Online Partner Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsMyBasicsNYJ2022"
                            );
                            setGeneralTermsDialogShow(Date.now());
                            // navigate("../2022_mybasics_nyeremenyjatek_szabalyzat");
                        }}
                    >
                        <i className="pi pi-star mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            2022 Dubai Nyereményjáték
                        </span>
                        <Ripple />
                    </nav>
                    <hr />
                    <a
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        style={{ borderRadius: "12px", textDecoration: "none" }}
                        href="https://zepterclub.hu/upload/pdf/OPP_kisokos_20230110.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="pi pi-file-pdf mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            Online Partner Kisokos 2023.01.10.
                        </span>
                        <Ripple />
                    </a>
                    <a
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        style={{ borderRadius: "12px", textDecoration: "none" }}
                        href="https://zepterclub.hu/upload/pdf/MyBasics_kisokos_221020.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="pi pi-file-pdf mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            My Basics Kisokos 2022.10.20.
                        </span>
                        <Ripple />
                    </a>
                    <a
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        style={{ borderRadius: "12px", textDecoration: "none" }}
                        href="https://zepterclub.hu/upload/pdf/MyBasics_magazin_2022.pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="pi pi-file-pdf mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            My Basics Magazin 2022
                        </span>
                        <Ripple />
                    </a>
                    <hr />
                    <a
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        style={{
                            borderRadius: "12px",
                            textDecoration: "none",
                        }}
                        href="https://www.zepter.hu/Rules/Regulation-BizzClub"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="pi pi-verified mr-2 text-indigo-500"></i>
                        <span className="font-medium">ZepterClub ÁSZF</span>
                        <Ripple />
                    </a>
                    <a
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        style={{
                            borderRadius: "12px",
                            textDecoration: "none",
                        }}
                        href="https://www.zepter.hu/Rules/Regulation"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="pi pi-verified mr-2 text-indigo-500"></i>
                        <span className="font-medium">Webshop ÁSZF</span>
                        <Ripple />
                    </a>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "GeneralTermsConsultant220615"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-verified mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            Tanácsadói tevékenység ÁSZF
                        </span>
                        <Ripple />
                    </nav>
                    <nav
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                        // text-indigo-500
                        style={{ borderRadius: "12px" }}
                        onClick={() => {
                            setGeneralTermsDialogComponent(
                                "DataHandling220615"
                            );
                            setGeneralTermsDialogShow(Date.now());
                        }}
                    >
                        <i className="pi pi-verified mr-2 text-indigo-500"></i>
                        <span className="font-medium">
                            Adatkezelési tájékoztató
                        </span>
                        <Ripple />
                    </nav>
                </div>
            </div>
        </>
    );
};
export default Information;
