// export const cartSumPriceCount = (cart: any) => {
//     let sumPrice = 0;
//     cart.forEach((item: any) => {
//         sumPrice += Number(item.productPlPrice) * item.productQuantity;
//     });
//     return sumPrice;
// };

// export const cartItemsNumberCount = (cart: any) => {
//     let sumItems = 0;
//     cart.forEach((item: any) => {
//         sumItems += item.productQuantity;
//     });
//     return sumItems;
// };

export const cartItemNumberCount = (cart: any, productId: any) => {
    let sumItems = 0;
    cart.forEach((item: any) => {
        if (
            item.productId === productId &&
            !(item.productQuantity > 0 && item.productTotalPrice === 0)
        ) {
            sumItems += item.productQuantity;
        }
    });
    return sumItems;
};
