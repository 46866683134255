import React from "react";
import "../../css/GeneralTerms.css";
import pageGeneralTermsImage from "../../asset/page-teaser-general-terms-cl100-aszf.png";

const GeneralTerms230301: React.FunctionComponent<any> = (props: any) => {
    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <div className="flex flex-wrap shadow-2">
                    <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                        <div className="mt-0 mb-2 flex justify-content-center">
                            <div className="w-auto md:w-8">
                                <img
                                    src={pageGeneralTermsImage}
                                    className="responsive fadeinleft animation-duration-500"
                                    alt="zepterclub.hu ászf kép"
                                />
                            </div>
                        </div>
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            Általános Szerződési Feltételek
                            <br />
                            2023. március 1-től
                            <br />
                            ZEPTER ClubLive100 program
                        </div>
                        <ol>
                            <li>
                                <span>A program leírása</span>
                                <p>
                                    A ZEPTER ClubLive100 hűségprogramot a ZEPTER
                                    International Ungarn Korlátolt Felelősségű
                                    Társaság (a továbbiakban: ZEPTER) indította,
                                    amely Magyarország törvényei szerint
                                    bejegyzett cég a 1138 Budapest, Váci út 191.
                                    alatt található székhellyel. A ClubLive100
                                    program előnyeivel a regisztrált tagok a
                                    világ bármely pontján élhetnek online
                                    vásárlás esetén, hivatalos ZEPTER boltokban
                                    és bemutatótermekben, és a ZEPTER
                                    kereskedőitől történő vásárlások során. A
                                    ClubLive100 tagok (a továbbiakban „Klubtag”,
                                    „Club Member”, „CM”) minden vásárláskor
                                    kedvezményben részesülnek – online
                                    vásárlásról, akár a hivatalos ZEPTER
                                    bemutatótermekben történő személyes
                                    vásárlásról van szó – ezen felül személyre
                                    szóló ajánlatokat és meghívásokat is
                                    kaphatnak. A ZEPTER ClubLive100
                                    hűségprogramban való részvétel ingyenes. A
                                    ZEPTER ClubLive100 hűségprogram általános
                                    szerződési feltételeit (a továbbiakban
                                    „Szabályzat”) jelen Szabályzat foglalja
                                    össze.
                                </p>
                            </li>
                            <li>
                                <span>A tagság feltételei</span>
                                <p>
                                    A ClubLive100 tagsággal járó előnyök
                                    igénybevételéhez az új Klubtagoknak
                                    regisztrálniuk kell a ClubLive100
                                    hűségprogramba. A regisztráció történhet
                                    online, a www.zepterclub.hu felületen is,
                                    vagy papíralapon. A regisztráció során és
                                    annak feltételeként meg kell adniuk az
                                    összes kért adatot és el kell fogadniuk a
                                    jelen Szabályzatban foglaltakat. Olyan
                                    személy válhat Klubtaggá, aki 18. életévét
                                    betöltötte, cselekvőképes és rendelkezik
                                    e-mail címmel, bejelentett levelezési
                                    címmel, avagy jogszerűen bejegyzett jogi
                                    személy. Egy személy csak egy klubtagsággal
                                    rendelkezhet.
                                    <br />A www.zepterclub.hu felületre történő
                                    regisztrációhoz e-mail cím és jelszó,
                                    valamint a klubtagsághoz szükséges egyéb
                                    személyes adatok megadása szükséges. Az
                                    elfelejtett jelszó helyett új jelszót
                                    igényelhet a Klubtag. A felhasználónév és
                                    jelszó olyan adat, melyet az Klubtag köteles
                                    megőrizni, és kizárni harmadik személyek
                                    jogosulatlan hozzáférését. ZEPTER fenntartja
                                    a jogot, hogy amennyiben az Klubtag
                                    regisztrációja alatt, avagy Klubtag
                                    adataival olyan megrendelések kerülnek
                                    rögzítésre, melyek jogellenesek, ZEPTER
                                    rendes üzletmenetét akadályozza vagy
                                    gátolja, úgy a regisztrációt törölje, avagy
                                    leadott a megrendelés teljesítését pedig
                                    visszautasítsa.
                                    <br />
                                    <strong>
                                        Felhívjuk Klubtagjaink szíves figyelmét,
                                        hogy a tagsághoz fűződő összes előny,
                                        árengedmény, kedvezmény, stb. a
                                        regisztráció során kapott online
                                        hozzáférési adatokkal vehető igénybe
                                        vagy érvényesíthető, így kérjük
                                        fokozottan ügyeljenek arra, hogy
                                        illetéktelen harmadik személyek ne
                                        férhessenek hozzá!
                                    </strong>
                                    <br />
                                    ZEPTER fenntartja a jogot arra, hogy
                                    ellenőrizze a leendő vagy már meglévő
                                    Klubtagok személyazonosságát, ügyleti
                                    képességét és e célból személyi okmányuk
                                    vagy más igazolás felmutatását kérje. A
                                    regisztráció során a Klubtagok kötelesek
                                    személyi adataikat és egyéb információikat
                                    teljes, pontos, és a ClubLive100 program
                                    számára felhasználható formában megadni. A
                                    Klubtagok kötelesek gondoskodni a
                                    regisztráció során megadott információk
                                    naprakészségéről. Az adataik változását
                                    e-mail-ben jelenthetik be (
                                    <a href="mailto:clublive100@zepter.hu">
                                        clublive100@zepter.hu
                                    </a>
                                    ).ZEPTER nem vonható felelősségre,
                                    amennyiben hiányosan megadott vagy elavult
                                    személyes adatok okán egy Klubtag
                                    hűségkártyáját nem veszi át, vagy ha a
                                    hiányos adatok miatt nem tud igénybe venni
                                    valamilyen Klubtagoknak járó kedvezményt,
                                    vagy ha a Klubtag gondatlansága okán
                                    illetéktelen felhasználás történik.A
                                    ClubLive100 programba minden Klubtag csak
                                    egyszer ajánlható és egyszer
                                    regisztrálható.A Klubtag a részére egyedileg
                                    biztosított klubtagsági szám alapján kerül
                                    azonosításra a hűségprogram és vásárlásai
                                    során.ZEPTER a klubtagsági számot az
                                    adásvételi szerződés beérkezését követően
                                    ellenőrzi, és kizárólag érvényes klubtagsági
                                    szám esetében biztosítja a hűségprogram
                                    előnyeit és kedvezményeit.
                                </p>
                            </li>
                            <li>
                                <span>
                                    A Klub kártyával kapcsolatos rendelkezések
                                </span>
                                <p>
                                    A ZEPTER a ClubLive100 hűségprogramba
                                    regisztráló Klubtagok részére ClubLive100
                                    kártyát biztosíthat a klubtagsági számhoz
                                    kapcsolódóan.
                                </p>
                            </li>
                            <li>
                                <span>A tagság előnyei</span>
                                <p>
                                    A jelen Szabályzatban megjelölt előnyök és
                                    kedvezmények (a továbbiakban „kedvezmény”)
                                    igénybevétele egyes esetekben felté¬telhez
                                    kötött. Az előnyök és kedvezmények
                                    igénybevételéhez szükséges további
                                    feltételek teljesítése a Klubtag
                                    felelőssége. Az egyes feltételeket az adott
                                    kedvezményre vonatkozó rendelkezések
                                    szabályozzák. A Klubtag egyes kedvezmények
                                    eseté¬ben köteles a regisztrációkor
                                    nyilatkozni, hogy miként és milyen formában
                                    kívánja a kedvezményt igénybe venni,
                                    választása a klubtagság során pedig nem
                                    módosítható.
                                </p>
                                <ol>
                                    <li>
                                        <span>Kedvezmények és előnyök:</span>
                                        <p>
                                            Az általános kedvezményeket és
                                            előnyöket valamennyi Klubtag
                                            érvényesítheti illetőleg jogosult
                                            rá.
                                        </p>
                                        <ol>
                                            <li>
                                                <span>
                                                    Vásárlási kedvezmény
                                                </span>
                                                <p>
                                                    A ClubLive100 tagsággal a
                                                    Klubtagok akár 2-40 %-os
                                                    kedvezményben részesülhetnek
                                                    bizonyos ZEPTER termékek
                                                    megvásárlása esetén az adott
                                                    vásárlás összértékének
                                                    függvényében. A kedvezmény a
                                                    fogyasztói árból
                                                    érvényesíthető közvetlenül a
                                                    vásárláskor. A kedvezményes
                                                    vételárat az egyes termé¬kek
                                                    tekintetében a mindenkori
                                                    árlista „Klubtag ár” rovata
                                                    tartalmazza (a továbbiakban
                                                    „Klubtag ár”). A kedvezmény
                                                    igénybe vehető a
                                                    https://zepter.hu
                                                    weboldalon, a hivatalos
                                                    ZEPTER boltokban és
                                                    bemutatótermekben, és ZEPTER
                                                    kereskedőitől történő
                                                    vásárlások esetén. A
                                                    ClubLive100 vásárlási
                                                    kedvezmény nem von¬ható
                                                    össze egyéb, nem Klubtag
                                                    részére is elérhető
                                                    vásárlási kedvezményekkel
                                                    vagy akciókkal. A vásárlási
                                                    kedvezmény nem vehető
                                                    igénybe olyan fogyó eszközök
                                                    esetében, mint a
                                                    víztisztítók szűrői,
                                                    alkatrészek, egyes
                                                    kiegészítő termékek. ZEPTER
                                                    termékeire vonatkozó teljes
                                                    fogyasztói árlista ZEPTER
                                                    budapesti és vidéki
                                                    kirendeltségein tekinthető
                                                    meg.
                                                </p>
                                                <span>
                                                    Kedvezmény szintek
                                                    (Privilege level,
                                                    továbbiakban „PL”){" "}
                                                </span>
                                                <ul>
                                                    <li>
                                                        PL1: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        5.000-24.999 Ft értékben
                                                        vásárol.
                                                    </li>
                                                    <li>
                                                        PL2: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        25.000-149.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL3: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        150.000-999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL4: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        1.000.000-1.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL5: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        2.000.000-2.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL6: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        3.000.000-4.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL7: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        5.000.000-9.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL8: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        10.000.000-14.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL9: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        15.000.000-24.999.999 Ft
                                                        értékben vásárol.
                                                    </li>
                                                    <li>
                                                        PL10: olyan klubtag
                                                        érvényesítheti, aki
                                                        fogyasztói áron számolva
                                                        legalább 25.000.000 Ft
                                                        értékben vásárol.
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <span>
                                                    Személyre szabott ajánlatok
                                                </span>
                                                <p>
                                                    A Klubtagok személyre
                                                    szabott promóciós
                                                    ajánlatokat, valamint
                                                    előadásokra, ünnepségekre,
                                                    termékbemutatókra, és új
                                                    ter¬mékek premierjeire szóló
                                                    meghívókat kapnak. A
                                                    Klubtagoknak ezen felül
                                                    friss információkat is
                                                    küldünk legújabb
                                                    termékeinkről, aktuális
                                                    egészségi-, és
                                                    szépségtippjeinkről,
                                                    orvostudományi kutatásokról,
                                                    nyereményjátékokról,
                                                    szponzorainkról, valamint
                                                    jótékonysági- és egyéb
                                                    eseményekről, amelyeket a
                                                    ZEPTER szervez az
                                                    ügyfeleinek.
                                                </p>
                                            </li>
                                            <li>
                                                <span>Voucher</span>
                                                <p>
                                                    Ha egy Klubtag ajánlására (a
                                                    továbbiakban ajánló tag) új
                                                    Klubtag (a továbbiakban
                                                    ajánlott tag) ZEPTER
                                                    terméket vásárol, úgy az
                                                    ajánló tag az ajánlott
                                                    Klubtag első vásárlása nettó
                                                    értékének akár 4%-val egyező
                                                    összegű „voucher”-re válik
                                                    jogo¬sulttá. A voucher
                                                    alapján a Klubtag
                                                    meghatározott összegű
                                                    kedvezményre válik
                                                    jogosulttá, amelyet
                                                    felhasználhat bármely Zepter
                                                    termék megvásárlása során, a
                                                    megvásárolni kívánt Zepter
                                                    termék kiskereskedelmi
                                                    árának 90%-ig terjedő
                                                    mértékben. Több voucher
                                                    értéke összevonható, de csak
                                                    abban az országban lehet
                                                    felhasználni, amelyben az új
                                                    Klubtagot ajánló ClubLive100
                                                    tag regisztrált.
                                                    <br />A voucher az ajánló
                                                    Klubtag nevére szól, és nem
                                                    átruházható.
                                                    <br />A voucher
                                                    érvényesítésére az átvételt
                                                    követő 1 (egy) évig van
                                                    lehetőség, ezt követően
                                                    voucher felhasználására
                                                    nincs mód.
                                                    <br />
                                                    Az árengedmény a
                                                    felhasználási időn belül a
                                                    tag által megvásárolni
                                                    kívánt Zepter termék
                                                    fogyasztói árlista Klubtag
                                                    ár legfeljebb 90%-ának
                                                    erejéig érvényesíthető.
                                                </p>
                                            </li>
                                            <li>
                                                <span>
                                                    Tanácsadói lehetőség a
                                                    ClubLive100-ban
                                                </span>
                                                <p>
                                                    A Klubtag Tanácsadóként is
                                                    regisztrálhat bármikor a
                                                    zepterclub.hu felületén
                                                    belül elérhető további
                                                    regisztrációs felületen.
                                                    Tanácsadóként a klubtag
                                                    tanácsadói megbízási
                                                    szerződést kell, hogy
                                                    kössön. A Klubtagokhoz
                                                    képest a Tanácsadók további
                                                    előnyöket és kedvezményeket
                                                    érhetnek el. A Klubtagok
                                                    számára elérhető akár 2-40
                                                    %-os személyes vásárlási
                                                    kedvezményen, valamint az
                                                    akár 4% értékű voucheren túl
                                                    a Tanácsadók ajánlói
                                                    prémiumra is jogosult lehet.
                                                    A Tanácsadóknak lehetősége
                                                    van akár 4% ajánlói
                                                    prémiumra is szert tenni a
                                                    közreműködésével
                                                    regisztrált, illetve
                                                    bizonyos feltételekkel az
                                                    ezekhez kapcsolódó
                                                    ClubLive100 tagok összes
                                                    további vásárlása után. A
                                                    Tanácsadó közreműködésével
                                                    történő regisztrációnak
                                                    kizárólag az tekinthető, ha
                                                    a Tanácsadó a zepterclub.hu
                                                    felületről elérhető egyedi,
                                                    elektronikus linket küld az
                                                    ajánlott személy részére, és
                                                    ezen keresztül az ajánlott
                                                    személy sikeres ClubLive100
                                                    online regisztrációt hajt
                                                    végre.
                                                </p>
                                            </li>
                                            <li>
                                                <span>
                                                    Kereskedői tevékenység
                                                </span>
                                                <p>
                                                    A Klubtagnak bármikor
                                                    lehetősége van ZEPTER
                                                    valamennyi termékének
                                                    értékesítésére az arra
                                                    vonatkozó kereskedői
                                                    megbízási szerződés, és a
                                                    vonatkozó egyéb szakmai
                                                    feltételek teljesítése
                                                    esetén. A Kereskedő ZEPTER
                                                    mindenkori Marketing Terve
                                                    alapján jogosult jutalékot
                                                    érvényesíteni. A jutalék
                                                    érvényesítésének további
                                                    feltétele a számla
                                                    kiállítása.
                                                </p>
                                            </li>
                                            <li>
                                                <span>Cl100 Webshop</span>
                                                <p>
                                                    A Klubtag a zepterclub.hu
                                                    felületen történő belépést
                                                    követően elérheti a CL100
                                                    Webshopot (a továbbiakban
                                                    „zepterclub.hu”). A
                                                    webshopot csak Klubtagok
                                                    vehetik igénybe. A
                                                    zepterclub.hu általános
                                                    szerződési feltételeit jelen
                                                    Szabályzat 2. sz. melléklete
                                                    tartalmazza.
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Kommunikáció</span>
                                <p>
                                    A ClubLive100 tagok rendszeres tájékoztatást
                                    kapnak az általuk elért előnyök és
                                    kedvezmények mértékéről és a legújabb
                                    hírekről postai levélben, e-mailben,
                                    közösségi médián keresztül, vagy SMS-ben.
                                    Amennyiben egy klubtag nem szeretne ilyen
                                    információkat kapni a továbbiakban, online
                                    felületünkön lehetősége van leiratkozni az
                                    üzenetekről.
                                    <br />
                                    ClubLive100 ügyfélszolgálat elérhetőségei,
                                    ahol ZEPTER fogadja a ClubLive100
                                    hűségprogrammal kapcsolatos észrevételeit,
                                    javaslatait, panaszait:{" "}
                                    <a href="mailto:clublive100@zepter.hu">
                                        clublive100@zepter.hu
                                    </a>
                                    .
                                </p>
                            </li>
                            <li>
                                <span>
                                    Felfüggesztés és visszavonás, megszüntetés
                                </span>
                                <p>
                                    A Klubtagok kötelesek betartani a jelen
                                    Szabályzatban leírtakat.A Klubtag köteles
                                    ZEPTER részére valós és teljes körű adatokat
                                    szolgáltatni.A ZEPTER fenntartja a jogot,
                                    hogy felfüggessze vagy megszüntesse azon
                                    Klubtagok tagságát (egyúttal az azzal járó
                                    kedvezményeket és előnyöket is), akik
                                    visszaélnek a klubtagsággal vagy bármely
                                    módon kárt okoznak a ZEPTER számára.Ilyen
                                    esetekben a Klubtag nem jogosult
                                    kártérítésre a tagság felfüggesztése, vagy
                                    megszüntetése okán.A Klubtag a klubtagságot
                                    írásban, ZEPTER-nek írt levéllel, vagy a{" "}
                                    <a href="mailto:clublive100@zepter.hu">
                                        clublive100@zepter.hu
                                    </a>
                                    -ra küldött e-mail üzenettel. A klubtagság
                                    megszüntetésével a klubtag lemond a
                                    tagsággal járó kedvezményekről és a fel nem
                                    használt bónuszairól és prémiumról. A
                                    klubtag ZEPTER-rel szemben – korábbi
                                    vásárlások kapcsán – felmerült fizetési
                                    kötelezettségei azok rendezéséig továbbra is
                                    fennállnak.
                                </p>
                            </li>
                            <li>
                                <span>Változtatások</span>
                                <p>
                                    ZEPTER bármikor visszavonhatja vagy
                                    megváltoztathatja a jelen Szabályzatot,
                                    jutalékot, prémiumot valamint a marketing
                                    tervet. Az aktuális Szabályzatot weblapunkon
                                    (
                                    <a
                                        href="https://www.zeptermagazin.hu/informaciok"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://www.zeptermagazin.hu/informaciok
                                    </a>
                                    ) találhatja. A változtatásokról, esetleges
                                    további előnyökről, hirdetmény útján vagy az
                                    elektronikus levélcímre küldött
                                    tájékoztatóval, illetve a zepterclub.hu
                                    felületen értesítjük a Klubtagokat.
                                </p>
                            </li>
                            <li>
                                <span>Irányadó jog</span>
                                <p>
                                    A ZEPTER ClubLive100 hűségprogrammal
                                    kapcsolatos kérdésekben a magyar jog az
                                    irányadó. A felmerülő viták és jogi kérdések
                                    rendezésében Zepter Ungarn Kft. székhelye
                                    szerinti illetékes bíróság a jogosult.
                                </p>
                            </li>
                            <li>
                                <span>Panaszkezelés</span>
                                <p>
                                    A ClubLive100 hűségprogrammal kapcsolatos
                                    panaszkezelési szabályokat elérheti Zepter
                                    honlapján, a{" "}
                                    <a
                                        href="https://www.zeptermagazin.hu/informaciok"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://www.zeptermagazin.hu/informaciok
                                    </a>{" "}
                                    címen. Zepter és a Klubtag az esetleges
                                    nézeteltéréseiket békés úton rendezik, ennek
                                    hiányában a Klubtag a bírósági út
                                    igénybevétele nélkül az illetékes békéltető
                                    testülethez fordulhat:
                                    <br />
                                    Budapesti Békéltető Testület (Székhelye:
                                    1016 Budapest, Krisztina krt.99.III.em.310.,
                                    Levelezési cím: 1253 Budapest, Pf.: 10.,
                                    Elektronikus levelezési cím:{" "}
                                    <a href="mailto:bekelteto.testulet@bkik.hu">
                                        bekelteto.testulet@bkik.hu
                                    </a>
                                    , Telefax: 06 (1) 488 21 86, Telefon: 06 (1)
                                    488 21 31)
                                    <br />
                                    Online vitarendezés lehetősége: ZEPTER a
                                    fogyasztói jogviták online rendezéséről,
                                    valamint a 2006/2004/EK rendelet és a
                                    2009/22/EK irányelv módosításáról szóló,
                                    2013. május 21-i 524/2013/EU európai
                                    parlamenti és tanácsi rendeletben, és a
                                    fogyasztói jogviták online rendezéséről
                                    szóló 524/2013/EU európai parlamenti és
                                    tanácsi rendeletben előírt online
                                    vitarendezési platform feladatai ellátásának
                                    módjáról és a panaszok bejelentésére
                                    szolgáló elektronikus űrlap kitöltésének
                                    módjáról, és az online vitarendezési
                                    kapcsolattartó pontok közötti együttműködés
                                    módjáról szóló, a Bizottság 2015/1051 számú
                                    végrehajtási rendelet előírásainak
                                    megfelelően közreműködik és eljár az online
                                    vitarendezési platformon keresztül
                                    beterjesztett panaszok elintézésben.
                                    <br />
                                    Az online vitarendezés tájékoztató anyaga:{" "}
                                    <a
                                        href="https://bekeltet.bkik.hu/hirek/online-vitarendezes"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://bekeltet.bkik.hu/hirek/online-vitarendezes
                                    </a>
                                    <br />
                                    Az online vitarendezése elérhetősége:{" "}
                                    <a
                                        href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HU"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=HU
                                    </a>
                                </p>
                            </li>
                        </ol>
                        <hr />
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            ADATKEZELÉSI TÁJÉKOZTATÓ
                        </div>
                        <span>
                            Adatkezelési tájékoztató a Club Live 100 vásárlói
                            klubhoz kapcsolódó tagsági jogviszonnyal összefüggő
                            adatkezelésekről:
                        </span>
                        <ol>
                            <li>
                                <span>Adatkezelő:</span>
                                <ol>
                                    <li>
                                        Zepter Ungarn Kft.(1138 Budapest, Váci
                                        út 191.,{" "}
                                        <a href="mailto:adat@zepter.hu">
                                            adat@zepter.hu
                                        </a>
                                        , a továbbiakban “Zepter”.).
                                    </li>
                                    <li>
                                        UBA UNITED BUSINESS ACTIVITIES HOLDING
                                        AG (CH-8832 Wollerau, Sihleggstrasse
                                        23., nyilvántartási szám:
                                        CH-170.3.016.516-9, a továbbiakban
                                        “UBA”)
                                    </li>
                                    <li>
                                        Zepter és UBA együttes említése esetén
                                        “Zepter International”.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>Adatvédelmi tisztviselő:</span> Név:
                                dr.Kovács Attila Krisztián E-mail:{" "}
                                <a href="mailto:adat@zepter.hu">
                                    adat@zepter.hu
                                </a>
                            </li>
                            <li>
                                Zepter által történő adatfelvétel esetén az
                                adatokat továbbítja az UBA részére. UBA olyan
                                harmadik országban található, melyre vonatkozóan
                                az Európai Bizottság megfelelőségi határozatot
                                bocsátott ki. Zepter a Magyarországon megadott
                                adatokat megosztja UBA-val, mint adatkezelővel.
                                Zepter, illetve a Zepter International
                                feldolgozás céljából átadja az adatokat
                                különböző címzetteknek. Ahol lehetséges, ott a
                                címzettek név szerint kerülnek megjelölésre,
                                ahol az nem lehetséges, ott a címzettek jól
                                meghatározott körét jelöljük meg.
                            </li>
                            <li>
                                Adatkezelés célja, jogalapja, kezelt adatok köre
                                (adatkategóriák), adatkezelés időtartama:
                                <ol>
                                    <li>
                                        <span>Club Live 100 tagság</span>
                                        <table>
                                            <tr>
                                                <th></th>
                                                <th>Adatkezelés célja</th>
                                                <th>Adatkezelés jogalapja</th>
                                                <th>Adatkategória</th>
                                                <th>Adatkezelés időtartama</th>
                                                <th>GDPR 13. Cikk (2) e.</th>
                                            </tr>
                                            <tr>
                                                <td>1.</td>
                                                <td>
                                                    <span>
                                                        Club Live 100 tagság:
                                                    </span>{" "}
                                                    Tagsági nyilvántartás, tagok
                                                    megkülönböztetése, új és /
                                                    vagy régi Zepter termékekkel
                                                    kapcsolatos vásárlási
                                                    információk, ajánlatok
                                                    küldése, termékbemutatóra
                                                    történő invitálás, vásárlási
                                                    szokások elemzése, klubtag
                                                    vásárlásainak
                                                    nyilvántartása, promóciós
                                                    cél, nyereményjátékról
                                                    tájékoztatás; kedvezmények
                                                    biztosítása, nyilvántartása,
                                                    kedvezmények elszámolása és
                                                    érvényesítése,
                                                    kedvezményekről, akciókról
                                                    tájékoztató küldése;
                                                    vásárlói szokások elemzése,
                                                    vásárlói élmény javítása,
                                                    adategyeztetés, hozzájárulás
                                                    kérése további
                                                    adatkezeléshez, hozzájárulás
                                                    kérése a hozzájárulási
                                                    időtartam
                                                    meghosszabbításához, direkt
                                                    marketing nyilvántartásban
                                                    szereplő érintettek
                                                    megkülönböztetése,
                                                    értékesítői tevékenységről
                                                    tájékoztatás küldése,
                                                    közvetlen személyes
                                                    megkeresés, értékesítői
                                                    tevékenységgel kapcsolatos
                                                    tájékoztatás közlése
                                                    céljából, közvetlen
                                                    személyes megkeresés
                                                    terméktájékoztató és/vagy
                                                    ajánlat közlése céljából, új
                                                    vagy kiterjesztett
                                                    adatkezelési hozzájárulás
                                                    kérése. A megkeresések
                                                    bármely ismert kommunikáció
                                                    csatornán történhetnek, így
                                                    telefon, e-mail vagy postai
                                                    út.
                                                </td>
                                                <td>
                                                    GDPR 6.§. (1) b. pont –
                                                    Szerződés teljesítése
                                                </td>
                                                <td>
                                                    teljes név, lakcím,
                                                    születési hely és idő, anyja
                                                    neve, e-mail cím, termék,
                                                    szerződésszám, vezetékes-,
                                                    és mobiltelefonszám,
                                                    klubtagsági szám;cég esetén
                                                    adószámot is tárolunk
                                                    születési dátum helyett
                                                </td>
                                                <td>
                                                    tagsági jogviszony
                                                    megszűnésétől számított 1 év
                                                    + 60 nap
                                                </td>
                                                <td>
                                                    szerződés teljesítésének
                                                    feltétele;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2.</td>
                                                <td>
                                                    <span>
                                                        Hírlevél küldés:
                                                    </span>{" "}
                                                    Új és / vagy régi Zepter
                                                    termékekkel kapcsolatos
                                                    vásárlási információk,
                                                    ajánlatok küldése,
                                                    termékbemutatóra történő
                                                    invitálás, promóciós cél,
                                                    nyereményjátékokról
                                                    tájékoztatás;
                                                    kedvezményekről, akciókról
                                                    tájékoztató küldése;
                                                    vásárlói szokások elemzése,
                                                    vásárlói élmény javítása,
                                                    adategyeztetés, hozzájárulás
                                                    kérése további
                                                    adatkezeléshez, hozzájárulás
                                                    kérése a hozzájárulási
                                                    időtartam
                                                    meghosszabbításához,
                                                    hírlevél nyilvántartásban
                                                    szereplő érintettek
                                                    megkülönböztetése,
                                                    értékesítői tevékenységről
                                                    tájékoztatás küldése;
                                                </td>
                                                <td>
                                                    GDPR 6.§. (1) a. pont –
                                                    önkéntes hozzájárulás; 2001.
                                                    évi CVIII. törvény 14.§.
                                                </td>
                                                <td>
                                                    e-mail cím, név, klubtagsági
                                                    szám;
                                                </td>
                                                <td>
                                                    hírlevélről leiratkozás,
                                                    hozzájárulás visszavonása;
                                                </td>
                                                <td>
                                                    hírlevél küldés
                                                    szolgáltatáshoz szükséges;
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>
                                                    <span>
                                                        Klubtagi tevékenység
                                                        ellátása:
                                                    </span>{" "}
                                                    Klubtag nyilvántartása,
                                                    megkülönböztetése más
                                                    Klubtagktól, Klubtag
                                                    ajánlásainak nyilvántartása,
                                                    kezelése, Klubtagk által
                                                    ajánlott személyek
                                                    vásárlásainak Klubtaghoz
                                                    hozzárendelése, Klubtag
                                                    további előnyeinek
                                                    nyilvántartása, elszámolása.
                                                    Jutalék nyilvántartása,
                                                    kifizetése, adózáshoz
                                                    szükséges adatok
                                                    nyilvántartása.
                                                </td>
                                                <td>
                                                    GDPR 6.§. (1) b. pont –
                                                    Szerződés teljesítése; GDPR
                                                    6.§. (1) c. - Jogi
                                                    kötelezettség teljesítése, a
                                                    személyi jövedelemadóról
                                                    szóló 1995. évi CXVII. tv.
                                                    16. § (1) bekezdése; Számv.
                                                    tv. 169. § (2);
                                                    társadalombiztosítási,-,
                                                    nyugdíj-, adóigazgatási
                                                    eljárás teljesítése
                                                </td>
                                                <td>
                                                    teljes név, lakcím,
                                                    születési idő, e-mail cím,
                                                    termék, szerződésszám,
                                                    klubtagsági szám,
                                                    számlaszám, Ajánlói prémium
                                                    kifizetés esetén az
                                                    adóbevallás elkészítéséhez
                                                    szükséges adatok: teljes
                                                    név, lakcím, születési idő,
                                                    termék, szerződésszám,
                                                    számlaszám, Prémium
                                                    kifizetés esetén az
                                                    adóigazolás elkészítéséhez
                                                    szükséges adatok: név,
                                                    születési név, születési
                                                    hely és idő, anyja neve,
                                                    adóazonosító jel, Taj szám,
                                                    személyi igazolvány száma,
                                                    állampolgárság, lakcím,
                                                    tartózkodási cím, e-mail
                                                    cím, telefonszám,
                                                    bankszámlaszám, bank neve
                                                </td>
                                                <td>
                                                    Klubtagi szerződés
                                                    megszűnésétől számított 5
                                                    éve + 60 nap;
                                                </td>
                                                <td>
                                                    Klubtagi szerződés
                                                    teljesítésének feltétele;
                                                </td>
                                            </tr>
                                        </table>
                                        <p>
                                            Tagsági nyilvántartás esetében az
                                            adatok megadása Ön részéről
                                            szerződéses kötelezettségen alapul,
                                            ugyanis szükséges a szerződés
                                            teljesítéséhez Zepter által kért
                                            adata. A szerződéskötésnek feltétele
                                            a szerződéses adatok megadása. A
                                            szerződés teljesítéséhez szükséges
                                            adat nélkül a szerződés Zepter
                                            részéről nem teljesíthető.
                                        </p>
                                        <p>
                                            Zepter profilalkotást nem végez,
                                            automatizált döntéshozatalra nem
                                            kerül sor.
                                        </p>
                                        <p>Címzettek:</p>
                                        <ul>
                                            <li>
                                                United Business Association
                                                (UBA) – adatkezelő tagság
                                                szervezetének fenntartása;
                                            </li>
                                            <li>
                                                Zepter Poland – adatfeldolgozó
                                                információ technológiai
                                                szolgáltatás és
                                                szerverüzemeltetés céljából;
                                            </li>
                                            <li>
                                                Zepter kereskedők – minden olyan
                                                jogi személy, jogi
                                                személyiséggel nem rendelkező
                                                szervezet illetve természetes
                                                személy, aki vagy amely
                                                Zepterrel érvényes kereskedői
                                                megbízást kötött, a megbízás
                                                teljesítése alatt.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <p>
                                    Érintett jogai: Ön jogosult jogorvoslatot
                                    kérni a ZEPTER-től a jelen tájékoztatóban
                                    meghatározott elérhetőségen postai úton vagy
                                    e-mail útján.
                                </p>
                                <p>
                                    <u>Tájékoztatáshoz való jog:</u> Személyes
                                    adatai kezeléséről kérhet tájékoztatást
                                    <br />
                                    <u>Helyesbítéshez való jog:</u> Az adatai
                                    pontosítását, javítását, helyesbítését
                                    kérheti.
                                    <br />
                                    <u>Törléshez való jog:</u> Bizonyos
                                    esetekben kérheti, hogy személyes adatai
                                    törlésre kerüljenek, így az eredeti
                                    adatkezelési cél érdekében már nincs szükség
                                    az adatai tárolására, vagy hozzájáruláson
                                    alapuló adatkezelés esetén visszavonja
                                    hozzájárulását, feltéve, hogy az adatok
                                    törlését jogszabály vagy egyéb jogi
                                    kötelezettség teljesítése nem zárja ki.
                                    <br />
                                    <u>
                                        Az adatkezelés korlátozásához való jog:
                                    </u>{" "}
                                    Bizonyos esetekben joga van kérni az adatok
                                    zárolását (fel nem használását) valamely
                                    intézkedés megtörténtének, vagy joga
                                    gyakorlásának elbírálásáig.
                                    <br />
                                    <u>Adathordozhatósághoz való jog:</u> Önnek
                                    joga van kérni elektronikus adatkezelés
                                    esetén, hogy adjuk át személyes adatait az
                                    Ön által kijelölt harmadik fél részére.
                                    <br />
                                    <u>Tiltakozáshoz való jog:</u> Ön
                                    tiltakozhat adatai kezelése ellen,
                                    amennyiben úgy látja, egyes adatkezelési cél
                                    érdekében már szükségtelen a további
                                    adatkezelés, kivéve, ha az adatkezelő
                                    igazolja, hogy az adatkezelés jogszerű és
                                    szükséges
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ön jogosult az adatkezeléssel szemben
                                    panasszal a Felügyeleti hatósághoz fordulni.
                                    A felügyeleti hatóság a Nemzeti Adatvédelmi
                                    és Információszabadság Hatóság.
                                </p>
                                <p>
                                    Nemzeti Adatvédelmi és Információszabadság
                                    Hatóság elérhetőségei: postacím: 1363
                                    Budapest, Pf.: 9.; cím: 1055 Budapest, Falk
                                    Miksa utca 9-11., Telefon: +36 (1) 391-1400;
                                    Fax: +36 (1) 391-1410, E-mail:{" "}
                                    <a href="mailto:ugyfelszolgalat@naih.hu">
                                        ugyfelszolgalat@naih.hu
                                    </a>
                                    ; URL:{" "}
                                    <a
                                        href="https://naih.hu"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://naih.hu
                                    </a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    A személyes adatok kezelésére a Zepter{" "}
                                    <a
                                        href="https://www.zeptermagazin.hu/adatvedelem"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        https://www.zeptermagazin.hu/adatvedelem
                                    </a>{" "}
                                    oldalon elérhető adatvédelmi szabályzata
                                    valamint a természetes személyeknek a
                                    személyes adatok kezelése tekintetében
                                    történő védelméről és az ilyen adatok szabad
                                    áramlásáról, valamint a 95/46/EK rendelet
                                    hatályon kívül helyezéséről szóló az EURÓPAI
                                    PARLAMENT ÉS A TANÁCS (EU) 2016/679
                                    RENDELETE (“GDPR” vagy “általános
                                    adatvédelmi rendelet”) az irányadó.
                                </p>
                                <p>
                                    Alulírott a tájékoztatást elolvastam,
                                    megértettem, és a hozzájárulásomat az
                                    alábbiak szerint adom meg (személyesen tett
                                    nyilatkozat esetén a nyilatkozat aláírásra
                                    kerül; online kitöltés esetén az
                                    elektronikus felületen kerül megjelölésre a
                                    nyilatkozattevő hozzájárulása a vonatkozó
                                    négyzet megjelölésével, mely döntés
                                    informatikailag kerül tárolásra):
                                </p>
                            </li>
                        </ol>
                        <hr />
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            2. sz. melléklet – zepterclub.hu webshop szabályzat
                        </div>
                        <span>zepterclub.hu Webshop Szabályzat</span>
                        <ol>
                            <li>
                                <span>ÁLTALÁNOS RENDELKEZÉSEK</span>
                                <ol>
                                    <li>
                                        A jelen ÁSZF hatálya kiterjed
                                        Magyarország területén nyújtott olyan
                                        elektronikus kereskedelmi
                                        szolgáltatásra, amely a
                                        https://zepterclub.hu-n található
                                        elektronikus áruházon (a továbbiakban:
                                        „zepterclub.hu", webáruház” vagy
                                        „webshop”) keresztül történik. Jelen
                                        ÁSZF hatálya kiterjed továbbá minden
                                        olyan kereskedelmi ügyletre Magyarország
                                        területén, amely jelen ÁSZF-ben
                                        meghatározott Felek között a webáruházon
                                        keresztül, annak használatával jön
                                        létre. A webáruházban történő vásárlást
                                        az elektronikus kereskedelmi
                                        szolgáltatások, az információs
                                        társadalommal összefüggő szolgáltatások
                                        egyes kérdéseiről szóló 2001. évi CVIII.
                                        törvény, a fogyasztó és a vállalkozás
                                        közötti szerződések részletes
                                        szabályairól szóló 45/2014. (II.26.)
                                        Kormányrendelet, a fogyasztó és
                                        vállalkozás közötti, az áruk
                                        adásvételére, valamint a digitális
                                        tartalom szolgáltatására és digitális
                                        szolgáltatások nyújtására irányuló
                                        szerződések részletes szabályairól szóló
                                        373/2021. (VI. 30.) Korm. rendelet,
                                        továbbá a Polgári Törvénykönyvről szóló
                                        2013. évi V. törvény szabályozza.
                                    </li>
                                    <li>
                                        A webáruházban történő vásárlás
                                        elektronikus úton leadott megrendeléssel
                                        lehetséges, a jelen ÁSZF-ben
                                        meghatározott módon.
                                    </li>
                                    <li>
                                        A webáruházat kizárólag Zepter
                                        ClubLive100 hűségprogramba regisztrált,
                                        érvényes tagsági jogvoszonnyal
                                        rendelkező személyek, azaz Klubtagok
                                        használhatják. Klubtagnak nem minősülő
                                        személlyel ZEPTER szerződést nem köt a
                                        zepterclub.hu felületén, a Klubtagnak
                                        nem minősülő személlyel történő
                                        szerződést ZEPTER érvénytelennek és
                                        hatálytalannak tekinti.
                                    </li>
                                    <li>
                                        A megrendeléssel és annak a ZEPTER
                                        általi visszaigazolásával a Felek között
                                        szerződés jön létre. A szerződés nem
                                        minősül írásba foglalt szerződésnek. A
                                        szerződés nem kerül iktatásra, és
                                        későbbiekben papír alapon nem
                                        előhívható, nem hozzáférhető. A
                                        szerződéskötés tényét ZEPTER
                                        rendszerében rögzített elektronikus
                                        adatok és a visszaigazoló e-mail
                                        igazolja.
                                    </li>
                                    <li>A szerződés nyelve magyar.</li>
                                    <li>
                                        ZEPTER magatartási kódex
                                        rendelkezéseinek nem veti alá magát.
                                    </li>
                                    <li>
                                        A webáruházban történő megrendeléssel,
                                        készletinformációval, regisztrációval,
                                        felhasználói fiókkal stb. kapcsolatos
                                        ügyintézés, tájékoztatás:
                                        <br />
                                        Telefonszám: + 36 1 323 24 29
                                        <br />
                                        E-mail:{" "}
                                        <a href="mailto:webshop@zepter.hu">
                                            webshop@zepter.hu
                                        </a>
                                        <br />
                                        Panaszügyintézés helye, módja
                                        <br />
                                        Cím: ZEPTER International Ungarn
                                        Korlátolt Felelősségü Társaság
                                        <br /> Postacím: 1138 Budapest, Váci út
                                        191.
                                        <br />
                                        E-mail:{" "}
                                        <a href="mailto:vevoszolgalat@zepter.hu">
                                            vevoszolgalat@zepter.hu
                                        </a>
                                        <br />
                                        Panaszügyintézés módja: e-mailben
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>
                                    MEGRENDELÉS – ÁLTALÁNOS RENDELKEZÉSEK
                                </span>
                                <ol>
                                    <li>
                                        A webáruházban történő böngészéshez
                                        külön regisztráció nem szükséges, a
                                        regisztráció a zepterclub.hu felületére
                                        történő belépés során történik, a
                                        ClubLive100 hűségprogramban történő
                                        regisztrációval. A megrendeléshez
                                        szükséges adatok megadása a
                                        zepterclub.hu felületre történő
                                        regisztrációval történik. A regisztráció
                                        felhasználói fiókot hoz létre (a
                                        továbbiakban “Fiók”). A webshopban a
                                        Fiókkal rendelkező Klubtag adhat le
                                        megrendeléseket.
                                    </li>
                                    <li>
                                        A zepterclub.hu-n történő
                                        regisztrációval a Klubtag elfogadja a
                                        jelen webshop Szabályzatot.
                                    </li>
                                    <li>
                                        A Klubtag által tévesen és/vagy
                                        pontatlanul megadott adatokra
                                        visszavezethető szállítási késedelemért,
                                        illetve egyéb problémáért, hibáért,
                                        kárért ZEPTER-t nem terheli felelősség.
                                    </li>
                                    <li>
                                        A termékek lényeges tulajdonságait,
                                        jellemzőit a termékről készített képre
                                        vagy termék megnevezésre kattintva az
                                        onnan elérhető adatlapokról lehet
                                        megismerni azzal, hogy a termék
                                        részletes, lényeges tulajdonságait a
                                        termékhez mellékelt használati utasítás
                                        tartalmazza. A termékek használati
                                        utasítását – ahol ezt a jogszabályok
                                        előírják – a ZEPTER a termékhez
                                        mellékeli.
                                    </li>
                                    <li>
                                        A webáruházban található termékadatok
                                        tájékoztató jellegűek. A fotón látható
                                        szín, illetve a termék tényleges fizikai
                                        színe között eltérés lehetséges. A
                                        Klubtag hozzájárul ahhoz, hogy abban az
                                        esetben, ha az adásvételi szerződés
                                        megkötése és teljesítése közötti
                                        időszakban a termék gyártója a termék
                                        lényeges tulajdonságát nem érintő (szín,
                                        formatervezés stb.) fejlesztést hajt
                                        végre, ZEPTER a terméktől lényegében el
                                        nem térő, funkciójában a mintával
                                        megegyező, korszerűbb termékkel
                                        teljesíthesse a szerződést. A terméktől
                                        műszaki jellemzőiben eltérő, vagy
                                        funkcióit tekintve továbbfejlesztett és
                                        ezen fejlesztések következtében
                                        jellemzően magasabb értéket képviselő
                                        termék elérhetősége esetén a Klubtag
                                        hozzájárulásával ZEPTER a fejlesztett
                                        termékkel is jogosult teljesíteni a
                                        szerződést.
                                    </li>
                                    <li>
                                        A termék fogyasztói vételára mindig a
                                        kiválasztott termék mellett
                                        feltüntetett, jól látható, forintban
                                        meghatározott összeg, amely az általános
                                        forgalmi adót már tartalmazza. A termék
                                        fogyasztói vételára egy darab termékre
                                        vonatkozik (egységár). A fogyasztói ár a
                                        Klubtag részére egy tájékoztató jellegű
                                        ár.
                                    </li>
                                    <li>
                                        A termék Klubtag által elérhető vételára
                                        ZEPTER által ún. ClubLive100 tagoknak
                                        biztosított hűségkedvezményes ár
                                        („Klubtag ár”). ZEPTER feltüntethet
                                        olyan árat is, amelynek elérésésének
                                        feltétele Tanácsadóvá vagy Kereskedővé
                                        válás.
                                    </li>
                                    <li>
                                        A termékek vételára – a jelen ÁSZF-ben
                                        foglalt kivételekkel – nem tartalmazza a
                                        kiszállítás költségét.
                                    </li>
                                    <li>
                                        A webáruházban megrendelhető termékek
                                        árai változtatásának jogát ZEPTER
                                        fenntartja azzal, hogy a módosítás a
                                        weboldalon való megjelenéssel
                                        egyidejűleg lép hatályba. Amennyiben a
                                        ZEPTER minden gondossága ellenére hibás
                                        ár kerül a weboldal felületére, különös
                                        tekintettel a nyilvánvalóan téves, pl. a
                                        termék közismert, általánosan elfogadott
                                        vagy becsült árától jelentősen eltérő,
                                        esetleg rendszerhiba miatt megjelenő "0"
                                        Ft-os vagy "1" Ft-os árra, akkor a
                                        ZEPTER nem köteles a terméket hibás áron
                                        szállítani, hanem felajánlhatja az
                                        aktuális webshop lista áron történő
                                        szállítást, amelynek ismeretében az
                                        Klubtag elállhat vásárlási szándékától.
                                    </li>
                                    <li>
                                        A Klubtag a Fiókon belül ún. Wallet-tel
                                        rendelkezik, amely egy virtuális
                                        pénztárca. A Wallet jeleníti meg a
                                        Klubtag által elért és a zepterclub.hu
                                        Szabályzat 4.1.3. pont szerinti
                                        kedvezményeket, egyúttal biztosítja azok
                                        rugalmas felhasználásának lehetőségét. A
                                        Klubtag a várárlása során döntésétől
                                        függően az egyes Termékek megvásárlása
                                        során a Termék, több Termék vásárlása
                                        során akár külön-ülön az egyes
                                        Termékekre eltérő összegű kedvezmény
                                        összeget vezethet át, és ezzel
                                        csökkentheti az általa kiválasztott
                                        Termékek vételárát.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>MEGRENDELÉS MENETE</span>
                                <ol>
                                    <li>
                                        A Klubtag a weboldal felső részén
                                        található termékkategóriák választásával
                                        eléri az érintett termékcsoportot. A
                                        termékcsoportba tartozó egyes termékekre
                                        kattintva a Klubtag választhat a
                                        termékek közül. A kiválasztott termékről
                                        információs ablak nyílik, melynél
                                        megtalálja a Klubtag a termék leírását,
                                        termékadatokat, a listaárat (fogyasztói
                                        ár), klubtag árat és a Klubtagi árat,
                                        melynek ismeretében megvásárlásra
                                        választhatja ki a terméket, és a
                                        „Kosárba” teheti.
                                    </li>
                                    <li>
                                        Megrendelés feladására a virtuális kosár
                                        használatával van lehetőség. A kosár a
                                        weboldal jobb felső sarkában részén
                                        található, a „Kosár” linkre kattintva
                                        tekinthető meg, ahol a termék darabszáma
                                        módosítható és törölhető is. A Kosárban
                                        látható, hogy a Klubtag milyen
                                        termékeket választott ki, és láthatja az
                                        összes kiválasztott termék teljes
                                        Klubtag vételárát, a termékek
                                        darabszámát.
                                    </li>
                                    <li>
                                        A Kosár tartalmának véglegesítése a
                                        „folytatás / rendelés adatai” gombra
                                        kattintva végezhető el. A „folytatás /
                                        rendelés adatai” gombra kattintva
                                        adhatóak meg a szállítási adatok. A
                                        megrendelés feladásához a zepterclub.hu
                                        Fiókba történő belépést követően már
                                        csak a számlázási és szállítási címet
                                        kell megadni (ha eltér a korábban
                                        megadott adattól), továbbá kötelező
                                        fizetési- és szállítási módot
                                        választani. ZEPTER-t az Klubtag által
                                        tévesen és/vagy pontatlanul megadott
                                        adatokra visszavezethető szállítási
                                        késedelemért, illetve egyéb problémáért,
                                        hibáért és károkért a ZEPTER-t nem
                                        terheli felelősség.
                                    </li>
                                    <li>
                                        A megrendelés feladásához a Klubtag
                                        nyilatkozni köteles – a megrendelés
                                        felületén található négyzetre kattintva
                                        – arról, hogy a jelen ÁSZF-et, a
                                        választott termék tulajdonságait
                                        megismerte, és ennek tudatában vásárolja
                                        meg a terméket valamint az ÁSZF
                                        rendelkezéseit elfogadja. Ezt követően a
                                        „megrendelés és fizetés” gombra
                                        kattintva kerül feladásra a megrendelés.
                                    </li>
                                    <li>
                                        A Klubtag a megrendelés leadása során
                                        érvényesítheti a Szabályzat 4.1.3.
                                        pontja szerinti Klubtag kedvezményeit, a
                                        megrendelés leadása során közvetlenül
                                        lehívhatja ezeket a kedvezményeket, vagy
                                        annak egy részét a rendelkezésére álló
                                        Wallet-ből. A vételár megfizetése során
                                        a a Szabályzat 4.1.3. pont szerinti
                                        kedvezményt legfeljebb a Termék Klubtag
                                        ár 90%-ig jogosult igénybe venni.
                                    </li>
                                    <li>
                                        A „megrendelés és fizetés” gombra
                                        történő klikkeléssel a Klubtag tudomásul
                                        veszi, hogy a fizetési kötelezettsége
                                        keletkezik ZEPTER irányába. A fizetési
                                        kötelezettség keletkezésére vonatkozó
                                        egyértelmű és figyelemfelhívó szöveget
                                        ZEPTER a „megrendelés és fizetés”
                                        gombhoz közvetlenül kapcsolódva tüntette
                                        fel.
                                    </li>
                                    <li>
                                        A Klubtag tudomásul veszi, hogy a ZEPTER
                                        nem felel a megrendelés meghiúsulása, a
                                        megrendelés hibás adattartalma,
                                        késedelmes-, vagy elmaradt megrendelés
                                        esetén amennyiben a hiba vagy késedelem,
                                        illetve meghiúsulás a Klubtag avagy
                                        ZEPTER-től eltérő harmadik személy (pl.
                                        elektronikus hírközlési szolgáltató,
                                        szerverszolgáltató stb.) érdekkörében
                                        felmerült okra – technikai, szolgáltatás
                                        kiesési vagy bármely egyéb okra -
                                        vezethető vissza.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>AJÁNLATI KÖTÖTTSÉG, VISSZAIGAZOLÁS</span>
                                <ol>
                                    <li>
                                        A Klubtag által elküldött megrendelés
                                        beérkezéséről ZEPTER késedelem nélkül
                                        visszaigazoló e-mailt küld, mely
                                        tartalmazza a Klubtag megrendelés ZEPTER
                                        általi elfogadását, a Klubtag által a
                                        megrendelés során megadott adatokat (pl.
                                        fizetési, számlázási és szállítási
                                        információk, stb.), a rendelés
                                        azonosítóját, a rendelés dátumát, a
                                        megrendelt termékhez tartozó elemek
                                        felsorolását, mennyiségét, a termék
                                        árát, szállítási költséget és a
                                        fizetendő végösszeget. A visszaigazoló
                                        e-mail a ZEPTER részéről a Klubtag által
                                        tett megrendelés elfogadásának minősül,
                                        és e visszaigazolással érvényes
                                        adásvételi szerződés jön létre ZEPTER és
                                        a Klubtag között. ZEPTER értesíti a
                                        Klubtagot a teljesítés akadályairól.
                                    </li>
                                    <li>
                                        A Klubtag mentesül a megrendelési
                                        kötöttség alól, ha 48 órán belül nem
                                        kapja meg ZEPTER-től az elküldött
                                        rendelésére vonatkozó visszaigazoló
                                        e-mailt.
                                    </li>
                                    <li>
                                        A rendelés feladása után, de még
                                        kiszállítás előtt, felvilágosítás,
                                        speciális kérés, megrendelés-módosítás
                                        vagy valamilyen felmerült probléma
                                        bejelentése a jelen ÁSZF-ben megjelölt
                                        elérhetőségen lehetséges.
                                    </li>
                                    <li>
                                        ZEPTER ajánlati kötöttsége megszűnik és
                                        a rendszerből törli a rendelést,
                                        amennyiben a Klubtag a megrendelt és
                                        kiszállított, de ki nem fizetett
                                        terméket (azaz utánvétes rendelést) nem
                                        veszi át, és 14 napon belül ismételt
                                        kiszállítást sem kér. ZEPTER az ismételt
                                        kiszállítást a termék vételárának, vagy
                                        annak egy részének valamint a szállítási
                                        költségek megfizetéséhez kötheti.
                                        Amennyiben a Klubtag ZEPTER feltételeit
                                        nem fogadja el, a felek közötti
                                        szerződés megszűnik az érintett termékre
                                        vonatkozóan és a Klubtag a terméket
                                        kizárólag új megrendelés útján lesz
                                        lehetősége megvásárolni. Az ilyen új
                                        megrendelés fizetési és szállítási
                                        feltételeit ZEPTER jogosult egyoldalúan
                                        meghatározni.
                                    </li>
                                    <li>
                                        A Klubtagnak a rendelés bármely
                                        szakaszában és a megrendelés ZEPTER
                                        részére történő elküldéséig a
                                        webáruházban bármikor lehetősége van az
                                        adatbeviteli hibák javítására (törlés
                                        és/vagy módosítás) a weboldal
                                        megrendelési felületén.
                                    </li>
                                    <li>
                                        A rendelés folyamata során a weboldal
                                        megrendelési felületén a megrendelt
                                        termék(ek) és a fizetendő, pontos
                                        végösszeg mindvégig szerepel.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>SZÁLLÍTÁSI FELTÉTELEK</span>
                                <ol>
                                    <li>
                                        Áru átvétele futárszolgálat
                                        közreműködésével
                                    </li>
                                    <li>
                                        ZEPTER a beérkezett megrendeléseket,
                                        raktárkészleten lévő termékek esetén,
                                        legkésőbb 2 munkanapon belül átadja a
                                        futárszolgálatnak. A csomag feladása
                                        előtt a megrendelés feldolgozásáról
                                        e-mailben értesíti a Klubtagot.
                                    </li>
                                    <li>
                                        A futárszolgálat a megrendelt terméket
                                        ZEPTER általi visszaigazolását követő
                                        3-10 munkanapon belül kikézbesíti a
                                        Klubtagnak és erről a regisztráció során
                                        megadott email címen értesíti a
                                        Klubtagot. ZEPTER a futárszolgálat
                                        előzőek szerinti határidőben történő
                                        szállításáért felelősséget nem vállal.
                                    </li>
                                    <li>
                                        A futárszolgálat kizárólag hétköznap,
                                        munkaidőben szállítja ki a terméke(ke)t,
                                        ezért fontos, hogy szállítási címként a
                                        megrendelés során olyan cím kerüljön
                                        megadásra, ahol ez időben a terméke(ke)t
                                        át tudja venni a Klubtag. Amennyiben
                                        valamely oknál fogva a kézbesítő az áru
                                        átadását nem tudja teljesíteni, minden
                                        eseten értesítést hagy a csomagról. Ha a
                                        futár nem talál senkit a megadott címen,
                                        következő napon megkísérli újra a
                                        kézbesítést. Kétszeri sikertelen
                                        kézbesítést követően a szállítmány
                                        visszakerül ZEPTER raktárába. A Klubtag
                                        hibájából történt sikertelen kézbesítés
                                        megismétlése díjköteles.
                                    </li>
                                    <li>
                                        Szállítási díj: 30.000,- Ft értékű
                                        megrendelés alatt a szállítási díj
                                        1.500,- Ft. 30.000,- Ft vagy a feletti
                                        megrendelés felett a szállítás
                                        díjmentes. A szállítási díj tartalmazza
                                        a csomagolás költségét is. Ha egy
                                        Klubtagtól azonos napon több megrendelés
                                        is érkezik, ezek a házhoz szállítás díja
                                        szempontjából külön kerülnek
                                        figyelembevételre. ZEPTER a szállítási
                                        díj változtatásának jogát fenntartja
                                        azzal, hogy a módosítás a weboldalon
                                        való megjelenéssel egyidejűleg lép
                                        hatályba. A módosítás a már megrendelt
                                        termékek vételárát nem befolyásolja.
                                    </li>
                                    <li>
                                        Futárszolgálati partner elérhetőségei:
                                        <br />
                                        GLS General Logistics Systems Hungary
                                        Csomag-Logisztikai Kft.2351 Alsónémedi
                                        <br />
                                        GLS Európa u. 2.
                                        <br />
                                        Cégjegyzékszám: 13-09-111755
                                        <br />
                                        Adószám: 12369410-2-44
                                        <br />
                                        <br />
                                        GLS Ügyfélszolgálat 7.00-18.00 <br />
                                        Tel.: (+36 29) 88 66 70
                                        <br />
                                        Mobil: +36 20 890-0660
                                        <br />
                                        Fax: (+36 29) 88 66 10
                                        <br />
                                        Email:{" "}
                                        <a href="mailto:info@gls-hungary.com">
                                            info@gls-hungary.com
                                        </a>
                                    </li>
                                    <li>
                                        ZEPTER a termék vételára megfizetése
                                        esetén intézkedik a termék 10 napon
                                        belül történő kiszállítása iránt. ZEPTER
                                        a termék futárszolgálat részére történő
                                        átadásáról tájékoztató e-mailt küld a
                                        Klubtag részére. A futárszolgálat a
                                        termék ZEPTER-től történő átvételét
                                        követő 6 napon belül 2 alkalommal
                                        kísérli meg a kiszállítást. Amennyiben a
                                        kiszállítás sikertelen, úgy a
                                        futárszolgálat a Klubtaggal időpontot
                                        egyeztetet egy újabb kiszállításra,
                                        melyre a 9 napon belül kerül sor a
                                        termék ZEPTER-től történő átvételét
                                        követően.
                                    </li>
                                    <li>
                                        ZEPTER a csomag tartalmára vonatkozó
                                        reklamációt kizárólag abban az esetben
                                        tud elfogadni, ha a hiány vagy sérülés
                                        ténye a fuvarlevélen rögzítésre kerül.
                                        Abban az esetben, ha a csomagolás az
                                        átvételkor sérült, úgy a csomagot a
                                        Klubtag a csomagot ne vegye át, hanem
                                        tagadja meg a csomag átvételét, és
                                        vegyen fel jegyzőkönyvet a kézbesítővel.
                                        A futárszolgálat kézbesítője a
                                        sérülésről jegyzőkönyvet vesz fel.
                                        Jegyzőkönyv hiányában ZEPTER a sérült,
                                        hiányos termékre történő panaszt
                                        elutasítja.
                                    </li>
                                    <li>
                                        ZEPTER felhívja a Klubtag figyelmét,
                                        hogy a Termék átvételét követő 3 napon
                                        belül köteles ellenőrizni a csomag
                                        tartalmát, és összevetnie az általa
                                        megrendelt termékekkel, valamint köteles
                                        ellenőrizni, hogy a Termék hiánytalan és
                                        sérülésmentes. Amennyiben a megrendelt
                                        Termékhez képest a csomag hiányos, vagy
                                        sérült (törött, horpadt stb.), vagy nem
                                        a megrendeléssel azonos Termék került
                                        kiszállításra, úgy a Klubtag köteles a
                                        ZEPTER-t 3 napon belül tájékoztatni a
                                        sérülésről, hiányról vagy más eltérésről
                                        a ZEPTER jelen ÁSZF-ben megjelölt
                                        valamely e-mail címen vagy a Klubtagi
                                        felületen.
                                    </li>
                                    <li>
                                        Tájékoztató a hulladék gazdálkodásról:
                                        ZEPTER a hulladékkezelési tevékenységét
                                        a 1138 Budapest, Váci út 191. szám
                                        alatti szervizében (Tel: (+36 1)
                                        437-6633/2-es mellék,{" "}
                                        <a href="mailto:szerviz@zepter.hu">
                                            szerviz@zepter.hu
                                        </a>
                                        ) nyitvatartási időben (H-Cs: 9-16.30,
                                        P: 9-15.30 óráig) látja el a 197/2014
                                        kormányrendelet szerint.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>FIZETÉSI FELTÉTELEK</span>
                                <ol>
                                    <li>
                                        Választható fizetési mód az utánvételes
                                        fizetés. A jelen webshopban kizárólag
                                        utánvétes fizetés lehetséges (tud előre
                                        is utalni, illetve terveink szerint
                                        bankkártyéás fizetés is lesz). Az
                                        utánvétes fizetési módnál, a csomag
                                        kézhez vételekor a futárnak kell
                                        fizetni. Utánvétes fizetési módot csak
                                        belföldre történő kiszállítás esetén
                                        választható. Használható pénznem: HUF.
                                        Utánvétes fizetési módot legfeljebb
                                        499.999,- Ft összeghatárig teljesít
                                        ZEPTER. A Klubtagnak a megrendelés
                                        elküldésével fizetési kötelezettsége
                                        keletkezik. Át nem vett és
                                        futárszolgálat útján sem kézbesített,
                                        avagy visszaküldött termékek esetén, a
                                        Termék kiszállítást követő újraküldését
                                        kizárólag a termék ellenértékének előre
                                        történő kiegyenlítése esetén áll
                                        módunkban ismételten elindítani. A
                                        futárszolgálatnál készpénzzel vagy
                                        bankkártyával teljesítheti a Klubtag a
                                        vételár fizetési kötelezettségét. A
                                        futárszolgálat biztosítja az utánvétes
                                        csomagok esetében a bankkártyás fizetés
                                        lehetőségét a következő bankkártyák
                                        esetében: MasterCard, Maestro, Visa,
                                        American Express. A bankkártyával
                                        történő fizetés esetén az ügyfelet a
                                        saját bankjának esetleges költségein túl
                                        más banki költség nem terheli.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>ELÁLLÁSI JOG</span>
                                <ol>
                                    <li>
                                        Az e pontban foglaltak kizárólag a
                                        szakmája, foglalkozása vagy üzleti
                                        tevékenysége körén kívül eljáró olyan
                                        természetes személy Klubtagra
                                        vonatkoznak, aki árut vesz, rendel, kap,
                                        használ, igénybe vesz, valamint az
                                        áruval kapcsolatos kereskedelmi
                                        kommunikáció, ajánlat címzettje (a
                                        továbbiakban: Fogyasztó). Fogyasztónak
                                        nem minősülő Klubtagot jelen pontban
                                        rögzített elállási jog nem illeti meg.
                                    </li>
                                    <li>
                                        Fogyasztó jogosult a termék adásvételére
                                        irányuló szerződés esetén
                                        <ol>
                                            <li>a terméknek,</li>
                                            <li>
                                                több termék szolgáltatásakor az
                                                utoljára szolgáltatott
                                                terméknek,
                                            </li>
                                        </ol>
                                        a Fogyasztó vagy az általa megjelölt, a
                                        fuvarozótól eltérő harmadik személy
                                        általi átvételének napjától számított 14
                                        (tizennégy) naptári napon belül a
                                        szerződéstől indokolás nélkül elállni.
                                    </li>
                                    <li>
                                        A Fogyasztó a szerződés megkötésének a
                                        napja és a termék átvételének napja
                                        közötti időszakban is jogosult
                                        gyakorolni az elállási jogát.
                                    </li>
                                    <li>Elállási jog gyakorlásának a menete</li>
                                    <li>
                                        Ha a Fogyasztó elállási jogával élni
                                        kíván, elállási szándékát tartalmazó
                                        egyértelmű nyilatkozatát köteles
                                        eljuttatni a jelen ÁSZF 1.7. pontjában
                                        feltüntetett elérhetőségek
                                        igénybevételével ZEPTER részére,
                                        személyesen, postán, elektronikus
                                        levélben.
                                    </li>
                                    <li>
                                        Az elállási nyilatkozat minta innen
                                        letölthető{" "}
                                        <a
                                            href="https://www.zepter.hu/rules/dokumetumok"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            https://www.zepter.hu/rules/dokumetumok
                                        </a>
                                    </li>
                                    <li>
                                        Amennyiben a Fogyasztó az elállási jog
                                        gyakorlására nem ZEPTER által közzétett
                                        mintát használja fel, a nyilatkozat
                                        egyértelmű, ha az tartalmazza az
                                        elállási nyilatkozaton túlmenően
                                        legalább a Fogyasztó nevét, a szállítási
                                        címet és a rendelésszámot.
                                    </li>
                                    <li>
                                        A Fogyasztó határidőben gyakorolja
                                        elállási jogát, ha a fent megjelölt
                                        határidő lejárta előtt elküldi elállási
                                        nyilatkozatát ZEPTER részére.
                                    </li>
                                    <li>
                                        A Fogyasztót terheli annak bizonyítása,
                                        hogy elállási jogát a jelen pontban
                                        meghatározott rendelkezéseknek
                                        megfelelően gyakorolta.
                                    </li>
                                    <li>
                                        Az elállást határidőben érvényesítettnek
                                        kell tekinteni, ha a Fogyasztó az erre
                                        irányuló nyilatkozatát 14 (tizennégy)
                                        naptári napon belül (akár a 14. naptári
                                        napon) elküldi ZEPTER-nek. Postai úton
                                        történő megküldés esetén a postára adás
                                        dátumát, elektronikus levélben történő
                                        megküldés esetén az elektronikus levél
                                        megküldésének idejét veszi figyelembe a
                                        ZEPTER a határidő számítás
                                        szempontjából.
                                    </li>
                                    <li>
                                        A Fogyasztó elállása esetén köteles a
                                        megrendelt termék(ke)et ZEPTER 7.12.
                                        pontban feltüntetett címre indokolatlan
                                        késedelem nélkül, de legkésőbb elállási
                                        nyilatkozatának közlésétől számított 14
                                        (tizennégy) naptári napon belül postai
                                        úton ajánlott küldeményként,
                                        futárszolgálattal vagy személyesen
                                        eljuttatni. A határidő betartottnak
                                        minősül, ha Fogyasztó a 14 napos
                                        határidő letelte előtt elküldi (postára
                                        adja vagy az általa megrendelt futárnak
                                        átadja), vagy személyesen beviszi a
                                        terméket a fentiekben megjelölt címek
                                        valamelyikére.
                                    </li>
                                    <li>
                                        A termék(ek) ZEPTER címére történő
                                        visszaküldésének költsége a Fogyasztót
                                        terheli. ZEPTER-nek az utánvéttel
                                        visszaküldött csomagot nem áll módjában
                                        átvenni. A termék(ek) visszaküldésének
                                        költségén kívül az elállás kapcsán a
                                        Fogyasztót semmilyen más költség nem
                                        terheli. A termék visszaküldési címe:
                                        2120 Dunakeszi, Pallag u.17. Zepter
                                        Raktár.
                                    </li>
                                    <li>
                                        Ha a Fogyasztó eláll a szerződéstől,
                                        haladéktalanul, de legkésőbb a Fogyasztó
                                        elállási nyilatkozatának kézhezvételétől
                                        számított 14 (tizennégy) naptári napon
                                        belül a ZEPTER visszatéríti a Fogyasztó
                                        által teljesített valamennyi
                                        ellenszolgáltatást. A ZEPTER jogosult a
                                        visszatérítést mindaddig visszatartani,
                                        amíg vissza nem kapta a terméket, vagy
                                        Fogyasztó nem igazolta kétséget
                                        kizáróan, hogy azt visszaküldte: a kettő
                                        közül ZEPTER a korábbi időpontot veszi
                                        figyelembe.
                                    </li>
                                    <li>
                                        A visszatérítés során a ZEPTER a
                                        megrendelés során alkalmazott fizetési
                                        móddal egyező fizetési módot alkalmaz,
                                        kivéve, ha Fogyasztó más fizetési mód
                                        igénybevételéhez kifejezetten a
                                        hozzájárulását adja, e visszatérítési
                                        mód alkalmazásából kifolyólag Fogyasztót
                                        semmilyen többletköltség nem terheli.
                                        Amennyiben a Fogyazstó a Walletben
                                        elérhető kedvezményeit használta fel a
                                        vásárlása során, úgy a visszatérítés
                                        során a felhasznált kedvezmény összege
                                        kerül visszavezetésre a Walletbe.
                                    </li>
                                    <li>
                                        Fogyasztó kizárólag akkor vonható
                                        felelősségre a termékben bekövetkezett
                                        értékcsökkenésért, ha az a termék
                                        jellegének, tulajdonságainak
                                        megállapításához szükséges használatot
                                        meghaladó használat miatt következett
                                        be. ZEPTER minden olyan esetben a teljes
                                        vételárát jogosult megtartani,
                                        amennyiben a Termék romlandó vagy
                                        minőségét rövid ideig őrzi meg
                                        (élelmiszer, étrend kiegészítő).
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>JÓTÁLLÁS</span>
                                <ol>
                                    <li>
                                        Az egyes tartós fogyasztási cikkekre
                                        vonatkozó kötelező jótállásról szóló
                                        151/2003. (IX.22) Korm. rendelet 1. §
                                        (1) bekezdése alapján ZEPTER a
                                        kormányrendelet 1. mellékletben
                                        felsorolt új tartós fogyasztási cikkekre
                                        köteles jótállást vállalni, amennyiben
                                        azt a Ptk. szerinti fogyasztónak
                                        értékesíti. Fogyasztónak nem minősülő
                                        Klubtagot jelen pontban rögzített
                                        jótállás nem illeti meg.
                                    </li>
                                    <li>
                                        ZEPTER az általa forgalomba hozott
                                        termékekre vállalja a jótállást. A
                                        kötelező jótállás időtartama és a
                                        jótállási kötelezettség terjedelme az
                                        értékesítés időpontjában hatályos
                                        jogszabály szerint áll fenn.
                                    </li>
                                    <li>
                                        A JÓTÁLLÁS NEM TERJED KI:
                                        <br />
                                        Sérült, vagy hiányos termékre, ha a
                                        hiányt vagy sérülést az átvételt követő
                                        3 napon belül a Klubtag ZEPTER részére
                                        nem jelzi.
                                        <br />A termékek azon alkatrészeire vagy
                                        elemeire, amelyek a termék használata
                                        során természetes kopásnak vagy
                                        elhasználódásnak vannak kitéve, pl.
                                        szűrők, fordított ozmózis membránok,
                                        fűtőszálak, izzók, akkumulátorok,
                                        biztosítékok, gélek, stb.
                                        <br />A termékek helytelen, ill. a
                                        használati útmutatóban leírtaktól eltérő
                                        használatából adódó károkra (mechanikai
                                        károkra, hő okozta károkra, stb.).
                                        <br />
                                        Olyan termékekre, amelyek esetében
                                        megállapítható, hogy nem a
                                        garanciavállaló vagy a garanciavállaló
                                        által kijelölt szerviz javította.
                                        <br />
                                        Olyan víztisztító berendezésekre,
                                        amelyeket nem a garanciavállaló által
                                        kijelölt szerviz technikus épített be és
                                        helyezett üzembe.
                                        <br />
                                        Olyan víztisztító berendezésekre,
                                        amelyek szervizelését nem végezték el a
                                        garanciavállaló előírásainak, illetve a
                                        készülékhez mellékelt használati
                                        útmutatóban előírt
                                        szerviz-intervallumoknak megfelelően.
                                        <br />A Magic Harmony kollekció
                                        kristálypoharaira.
                                        <br />A porcelán, üveg, és kerámia
                                        termékekre.
                                        <br />A kozmetikumokra, az Oxy Spray-re,
                                        az ékszerekre, a textilekre, a
                                        kávétermékekre, és az
                                        étrend-kiegészítőkre, olíva olajokra,
                                        teákra, vagy egyéb, élelmiszernek
                                        minősülő termékekre.
                                        <br />
                                        Olyan termékekre, melyek esetében a
                                        jótállási nyilatkozatban megadott
                                        feltételi nem teljesültek.
                                    </li>
                                    <li>
                                        A jótállási idő a termék átvételétől
                                        kezdődik meg.
                                    </li>
                                    <li>
                                        ZEPTER a 8.3 ponttól eltérő termék
                                        esetében kizárólag a gyártó által
                                        biztosított jótállást vállalja.
                                    </li>
                                    <li>
                                        ZEPTER által forgalmazott egyes
                                        termékekre a jogszabályban meghatározott
                                        jótállási időt meghaladó szerződéses
                                        jótállást vállal.
                                    </li>
                                    <li>
                                        Az egyes termékekre vállalt jótállás
                                        idejét megtekintheti a webshopban az
                                        egyes termékek termékoldalán, a „műszaki
                                        adatok” között.
                                    </li>
                                    <li>
                                        <p>A ZEPTER javaslatai</p>
                                        <p>
                                            A termék használata előtt kérjük,
                                            olvassa el figyelmesen a használati
                                            útmutatót, különös tekintettel a
                                            biztonsági és karbantartási
                                            tudnivalókra.
                                        </p>
                                        <p>
                                            Mivel a Syncro-Clik fedők magas
                                            nyomáson történő működtetésre
                                            készültek, erre figyelemmel a
                                            biztonságos használat érdekében
                                            kiemelten javasolt a Syncro-Clik
                                            fedők és tartozékaik 24 havonta
                                            történő, a ZEPTER
                                            Szervizközpontjában történő
                                            átvizsgálása.
                                        </p>
                                        <p>
                                            A garanciavállaló azt javasolja a
                                            vásárlók számára, hogy tartsák meg a
                                            termék eredeti csomagolását, mert ha
                                            később esetleg vissza kell juttatni
                                            a terméket a szervizbe, akkor
                                            szükség lehet rá. Az eredeti
                                            csomagolás hiányában a vásárlónak
                                            kell gondoskodnia a visszaküldendő
                                            termék megfelelő csomagolásáról.
                                            Amennyiben a nem megfelelő
                                            csomagolásból adódóan a
                                            visszaküldendő termék szállítás
                                            közben megsemmisül vagy károsodik, a
                                            felelősség a vásárlóé.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>SZAVATOSSÁG</span>
                                <ol>
                                    <li>
                                        A szavatossággal kapcsolatos
                                        rendelkezéseket jelen ÁSZF 2. sz.
                                        melléklete tartalmazza.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>FELELŐSSÉG</span>
                                <ol>
                                    <li>
                                        A weboldalon található információk
                                        jóhiszeműen kerültek felhelyezésre,
                                        azonban azok kizárólag tájékoztató célt
                                        szolgálnak, az információk
                                        pontosságáért, teljességéért ZEPTER nem
                                        vállal felelősséget.
                                    </li>
                                    <li>
                                        A Klubtag a weboldalt kizárólag a saját
                                        kockázatára használhatja, és elfogadja,
                                        hogy ZEPTER nem vállal felelősséget a
                                        használat során felmerülő vagyoni és nem
                                        vagyoni károkért a szándékosan, súlyos
                                        gondatlansággal, vagy bűncselekménnyel
                                        okozott, továbbá az életet, testi
                                        épséget, egészséget megkárosító
                                        szerződésszegésért való felelősségen
                                        túlmenően.
                                    </li>
                                    <li>
                                        ZEPTER kizár minden felelősséget a
                                        weboldal használói által tanúsított
                                        magatartásért. A Klubtag teljes
                                        mértékben és kizárólagosan felelős saját
                                        magatartásáért, ZEPTER ilyen esetben
                                        teljes mértékben együttműködik az eljáró
                                        hatóságokkal a jogsértések felderítése
                                        végett.
                                    </li>
                                    <li>
                                        A szolgáltatás oldalai olyan
                                        kapcsolódási pontokat (linkeket)
                                        tartalmazhatnak, amely más szolgáltatók
                                        által felügyelt oldalra vezet. Ezen
                                        szolgáltatók adatvédelmi gyakorlatáért
                                        és más tevékenységéért ZEPTER nem vállal
                                        felelősséget.
                                    </li>
                                    <li>
                                        Az internet globális jellege miatt a
                                        Klubtag elfogadja, hogy a weboldal
                                        használata során a vonatkozó nemzeti
                                        jogszabályok rendelkezéseit is
                                        figyelembe véve jár el. Amennyiben a
                                        weboldal használatával összefüggő
                                        bármely tevékenység a Klubtag államának
                                        joga szerint nem megengedett, a
                                        használatért kizárólag a Klubtagt
                                        terheli a felelősség.
                                    </li>
                                    <li>
                                        Amennyiben a Klubtag a weboldalon
                                        kifogásolható tartalmat észlel, köteles
                                        azt haladéktalanul jelezni ZEPTER-nek.
                                        Amennyiben ZEPTER jóhiszemű eljárása
                                        során a jelzést megalapozottnak találja,
                                        jogosult az információ haladéktalan
                                        törlésére vagy annak módosítására.
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <span>SZERZŐI JOGOK ÉS VÉDJEGYEK</span>
                                <ol>
                                    <li>
                                        A weboldal szerzői jogvédelem alatt áll.
                                        ZEPTER a szerzői jogi jogosultja vagy a
                                        feljogosított felhasználója a
                                        weboldalon, valamint a weboldalon
                                        keresztül elérhető szolgáltatások
                                        nyújtása során megjelenített valamennyi
                                        tartalomnak: bármely szerzői műnek,
                                        illetve más szellemi alkotásnak
                                        (ideértve többek közt valamennyi
                                        grafikát és egyéb anyagokat, a weboldal
                                        felületének elrendezését, szerkesztését,
                                        a használt szoftveres és egyéb
                                        megoldásokat, ötletet, megvalósítást).
                                    </li>
                                    <li>
                                        A weboldal tartalmának valamint egyes
                                        részeinek fizikai vagy más adathordozóra
                                        mentése vagy kinyomtatása magáncélú
                                        felhasználás céljából vagy ZEPTER
                                        előzetes írásbeli hozzájárulása esetén
                                        engedélyezett. A magáncélú
                                        felhasználáson túli felhasználás –
                                        például adatbázisban történő tárolás,
                                        továbbadás, közzé- vagy letölthetővé
                                        tétel, kereskedelmi forgalomba hozatal –
                                        kizárólag ZEPTER előzetes írásbeli
                                        engedélyével lehetséges.
                                    </li>
                                    <li>
                                        A jelen ÁSZF-ben kifejezetten
                                        meghatározott jogokon túlmenően a
                                        weboldal használata, illetve az ÁSZF
                                        egyetlen rendelkezése sem biztosít jogot
                                        a Klubtagnak a weboldal felületén
                                        szereplő bármely kereskedelmi névnek
                                        vagy védjegynek bármely használatára,
                                        hasznosítására. A weboldal
                                        rendeltetésszerű használatával járó
                                        megjelenítésen, az ehhez szükséges
                                        ideiglenes többszörözésen és a magáncélú
                                        másolatkészítésen túl e szellemi
                                        alkotások ZEPTER előzetes írásbeli
                                        engedélye nélkül semmilyen egyéb
                                        formában nem használhatók fel vagy
                                        hasznosíthatók.
                                    </li>
                                    <li>
                                        A Klubtag nem jogosult a termékek
                                        logóit, fényképét, védjegyeit,
                                        megjelöléseit használni, függetlenül
                                        attól, hogy azok mellett vagy azokkal
                                        összefüggésben feltüntetésre került a
                                        jogvédelemre vonatkozó feltüntetés
                                        (registered ®, copyright ©, trademark
                                        ™). A Klubtag felelős a magáncélú
                                        felhasználás esetén is ZEPTER védett
                                        jogai jóhiszemű felhasználásáért,
                                        illetőleg az Ügyfél felelős bármely
                                        magáncélú felhasználás esetén ZEPTER és
                                        védjegyei vagy más védett jogai jó
                                        hírnevének megőrzéséért.
                                    </li>
                                    <li>
                                        A Klubtag a webáruház használatával
                                        kifejezetten elismeri, hogy tudomással
                                        bír a Zepter cégcsoport (UBA A.G.,
                                        BIOPTRON A.G., Fieldpont B.V.) által
                                        bejegyzett védjegyek, különösen de nem
                                        kizárólagosan a „Zepter”, „Bioptron”
                                        szó-, és ábrás védjegyeinek jó hírnevű
                                        védjegy minőségéről.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <hr />
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            2. sz. Melléklet – Szavatossági jogok
                        </div>
                        <span>SZAVATOSSÁG</span>
                        <p>
                            Fogyasztó a kizárólag a szakmája, foglalkozása vagy
                            üzleti tevékenysége körén kívül eljáró természetes
                            személy, aki árut vesz, rendel, kap, használ,
                            igénybe vesz, valamint az áruval kapcsolatos
                            kereskedelmi kommunikáció, ajánlat címzettje.
                        </p>
                        <p>
                            Fogyasztó szavatossági jogait akkor gyakorolhatja,
                            ha a megvásárolt termék hibás, avagy nem nyújtja azt
                            minőséget, melyet a Fogyasztó a jelen ÁSZF, vagy a
                            jogszabály alapján elvárhatna.
                        </p>
                        <span>Kellékszavatosság</span>
                        <p>
                            A Fogyasztó ZEPTER hibás teljesítése esetén
                            ZEPTER-rel szemben kellékszavatossági igényt
                            érvényesíthet. Fogyasztói szerződés esetén a
                            Fogyasztó a termék(ek) átvételének időpontjától
                            számított 1 éves elévülési határidő alatt
                            érvényesítheti szavatossági igényeit azokért a
                            termékhibákért, amelyek a termék átadása
                            időpontjában már léteztek. Egyéves elévülési
                            határidőn túl kellékszavatossági jogait a Fogyasztó
                            érvényesíteni már nem tudja.
                        </p>
                        <p>
                            A Fogyasztó – választása szerint – kérhet kijavítást
                            vagy kicserélést, kivéve, ha az ezek közül a
                            Fogyasztó által választott igény teljesítése
                            lehetetlen vagy ZEPTER számára más igénye
                            teljesítéséhez képest aránytalan többletköltséggel
                            járna. Ha a kijavítást vagy a kicserélést a
                            Fogyasztó nem kérte, illetve nem kérhette, úgy
                            igényelheti az ellenszolgáltatás arányos
                            leszállítását vagy a hibát ZEPTER költségére a
                            Fogyasztó is kijavíthatja, illetve mással
                            kijavíttathatja vagy – végső esetben – a
                            szerződéstől is elállhat. Jelentéktelen hiba miatt
                            elállásnak nincs helye.
                        </p>
                        <p>
                            A Fogyasztó a választott kellékszavatossági jogáról
                            egy másikra is áttérhet, az áttérés költségét
                            azonban köteles viselni, kivéve, ha az indokolt
                            volt, vagy arra ZEPTER adott okot.
                        </p>
                        <p>
                            A Fogyasztó köteles a hibát annak felfedezése után
                            haladéktalanul, de nem később, mint a hiba
                            felfedezésétől számított kettő hónapon belül közölni
                            a ZEPTER-rel.
                        </p>
                        <p>
                            A Fogyasztó közvetlenül a ZEPTER-rel szemben
                            érvényesítheti kellékszavatossági igényét.
                        </p>
                        <p>
                            Az adásvételi szerződés teljesítésétől számított egy
                            éven belül a kellékszavatossági igénye
                            érvényesítésének a hiba közlésén túl nincs egyéb
                            feltétele, ha a Fogyasztó igazolja, hogy a terméket
                            a ZEPTER-től vásárolta (számla vagy a számla
                            másolatának bemutatásával). Ilyen esetben ZEPTER
                            csak akkor mentesül a szavatosság alól, ha ezt a
                            vélelmet megdönti, vagyis bizonyítja, hogy a termék
                            hibája a Fogyasztó részére történő átadást követően
                            keletkezett. Amennyiben ZEPTER bizonyítani tudja,
                            hogy a hiba oka a Fogyasztónak felróható okból
                            keletkezett, nem köteles a Fogyasztó által
                            támasztott szavatossági igénynek helyt adni. A
                            teljesítéstől számított egy év eltelte után azonban
                            már a Fogyasztó köteles bizonyítani, hogy az általa
                            felismert hiba már a teljesítés időpontjában is
                            megvolt.
                        </p>
                        <p>
                            Értelemszerűen bármely kellékszavatossági igény
                            érvényesítéséhez a termék(ek)et vissza kell juttatni
                            a 7.12. pontban megjelölt címre.
                        </p>
                        <p>
                            Ha a Fogyasztó a szavatossági igényét a terméktől –
                            a megjelölt hiba szempontjából – elkülöníthető része
                            tekintetében érvényesíti, a szavatossági igény a
                            termék egyéb részeire nem minősül érvényesítettnek.
                        </p>
                        <p>
                            Kicserélés vagy elállás esetén a Fogyasztó nem
                            köteles a termék(ek)nek azt az értékcsökkenését
                            megtéríteni, amely a rendeltetésszerű használat
                            következménye. A termék(ek) azon értékcsökkenését,
                            amely a nem rendeltetésszerű használat
                            következménye, a Fogyasztó köteles megfizetni ZEPTER
                            részére.
                        </p>
                        <p>
                            Hibás teljesítés esetén a szavatossági kötelezettség
                            teljesítésével kapcsolatos költségek ZEPTER-t
                            terhelik. ZEPTER a szavatossági kötelezettség
                            teljesítésével kapcsolatosan a Fogyasztónál
                            felmerült költségeket kizárólag a költségek hitelt
                            érdemlő igazolását (számla, postai ajánló szelvény,
                            stb. bemutatásával) követően fizet meg a Fogyasztó
                            részére. Ha a termék meghibásodásában a Fogyasztót
                            terhelő karbantartási kötelezettség elmulasztása is
                            közrehatott, a szavatossági kötelezettség
                            teljesítésével felmerült költségeket közrehatása
                            arányában a Fogyasztó köteles viselni, ha a termék
                            karbantartására vonatkozó ismeretekkel rendelkezett,
                            vagy ha ZEPTER e tekintetben tájékoztatási
                            kötelezettségének eleget tett. Amennyiben
                            bizonyítást nyer, hogy a termék hibája a teljesítést
                            követően keletkezett (azaz ZEPTER nem hibásan
                            teljesített), akkor a szavatossági jog
                            érvényesítésével felmerült költségek (beleértve a
                            termék Fogyasztó részére történő visszaküldésének
                            költségét is) a Fogyasztót terhelik.
                        </p>
                        <p>
                            Kellékszavatossági igény érvényesítéséhez
                            nyilatkozat minta itt elérhető:{" "}
                            <a
                                href="https://shop.zepter.hu/footer/szabalyzatok/dokumentumok"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://shop.zepter.hu/footer/szabalyzatok/dokumentumok
                            </a>
                        </p>
                        <p>
                            Amennyiben a Fogyasztó kellékszavatossági igény
                            érvényesítésére nem ZEPTER által közzétett mintát
                            használja fel, a nyilatkozatnak tartalmaznia kell
                            legalább a termék hibájának leírását, a választott
                            szavatossági igényt, a Fogyasztó nevét, a szállítási
                            címet és a rendelésszámot. A Fogyasztó
                            kellékszavatossági igényét a Webshop ÁSZF 1.7.
                            pontban megjelölt elérhetőségeken terjesztheti elő.
                        </p>
                        <span>Termékszavatosság</span>
                        <p>
                            A Fogyasztót nem illeti meg az a jog, hogy ugyanazon
                            hiba miatt kellékszavatossági és termékszavatossági
                            igényt egyszerre, egymással párhuzamosan
                            érvényesítsen. Termékszavatossági igény eredményes
                            érvényesítése esetén azonban a kicserélt termékre,
                            illetve kijavított részre vonatkozó
                            kellékszavatossági igényét a Fogyasztó a gyártóval
                            szemben érvényesítheti.
                        </p>
                        <p>
                            Termékszavatossági igényként a Fogyasztó kizárólag a
                            hibás termék kijavítását vagy kicserélését kérheti.
                            A termék hibáját termékszavatossági igény
                            érvényesítése esetén a Fogyasztónak kell
                            bizonyítania.
                        </p>
                        <p>
                            A termék akkor minősül hibásnak, ha az nem felel meg
                            a forgalomba hozatalakor hatályos minőségi
                            követelményeknek vagy, ha nem rendelkezik a gyártó
                            által adott leírásban szereplő tulajdonságokkal.
                        </p>
                        <p>
                            Termékszavatossági igényét a Fogyasztó a termék
                            gyártó általi forgalomba hozatalától számított két
                            éven belül érvényesítheti. E határidő elteltével e
                            jogosultságát elveszti. A Fogyasztó a hiba
                            felfedezése után késedelem nélkül köteles a hibát a
                            gyártóval közölni. A hiba felfedezésétől számított
                            két hónapon belül közölt hibát késedelem nélkül
                            közöltnek kell tekinteni. A közlés késedelméből
                            eredő kárért a Fogyasztó felelős.
                        </p>
                        <p>
                            A Fogyasztó termékszavatossági igényét a termék
                            gyártójával vagy forgalmazójával (ZEPTER) szemben
                            gyakorolhatja.
                        </p>
                        <p>
                            Ptk. értelmében gyártónak minősül a termék
                            előállítója és forgalmazója (ZEPTER).
                        </p>
                        <p>
                            A gyártó, forgalmazó (ZEPTER) kizárólag akkor
                            mentesül termékszavatossági kötelezettsége alól, ha
                            bizonyítani tudja, hogy:
                            <br />a terméket nem üzleti tevékenysége körében
                            gyártotta, illetve hozta forgalomba, vagy
                            <br />a hiba a tudomány és a technika állása szerint
                            a forgalomba hozatal időpontjában nem volt
                            felismerhető vagy
                            <br />a termék hibája jogszabály vagy kötelező
                            hatósági előírás alkalmazásából ered.
                            <br />
                            gyártónak, forgalmazónak (ZEPTER) a mentesüléshez
                            elegendő egy okot bizonyítania.
                            <br />
                            Termékszavatossági igény érvényesítéséhez
                            nyilatkozat minta itt elérhető:{" "}
                            <a
                                href="https://www.zepter.hu/rules/dokumetumok"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://www.zepter.hu/rules/dokumetumok
                            </a>
                        </p>
                        <p>
                            Amennyiben a Fogyasztó termékszavatossági igény
                            érvényesítésére nem ZEPTER által közzétett mintát
                            használja fel, a nyilatkozatnak tartalmaznia kell
                            legalább a termék hibájának leírását, a választott
                            szavatossági igényt, az Ügyfél nevét, a szállítási
                            címet és a rendelésszámot. A Fogyasztó
                            termékszavatossági igényét az 1.7. pontban megjelölt
                            elérhetőségeken terjesztheti elő.
                        </p>
                        <p>
                            Bármely termékszavatossági igény érvényesítéséhez a
                            termék(ek)et vissza kell juttatni 7.12. pontban
                            megjelölt címre.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralTerms230301;
