import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { logout, setAuthToken } from "../../../service/dashboard/AuthService";
import { StatusCodes } from "http-status-codes";
import { setNumberStringThousandSeparator } from "../../../util/stringFormat";
import { Dialog } from "primereact/dialog";
import { setProductDetailFromList } from "../../../state/dashboard/webshopSlice";
import { readProduct } from "../../../service/dashboard/admin/webshop/ProductService";
import { readDocument } from "../../../service/dashboard/admin/DocumentService";
import { Galleria } from "primereact/galleria";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import "../../../css/Webshop.css";
import noImageAvailable from "../../../asset/no_image_available.png";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import AddToCartButton from "../../../component/dashboard/webshop/AddToCartButton";
import {
    setLoadingOff,
    setLoadingOn,
} from "../../../state/dashboard/mainSlice";

const ProductDetail: React.FC<any> = (props: any) => {
    let navigate = useNavigate();
    setAuthToken();

    const dispatch = useDispatch();

    const topRef = useRef<any>(null);

    const productDetailFromList: any = useSelector(
        (state: RootState) => state.webshop.productDetailFromList
    );
    const [productDetail, setProductDetail] = useState<any>(null);
    const [productImages, setProductImages] = useState<any>(null);
    const [productDocumentURL, setProductDocumentURL] = useState<any>(null);

    useEffect(() => {
        if (
            productDetailFromList &&
            productDetailFromList.product &&
            productDetailFromList.product.id &&
            !productImages &&
            !productDetail
        ) {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Termék betöltése folyamatban.",
                })
            );
            readProduct(productDetailFromList.product.id).then(
                (response) => {
                    // console.log(response.data);
                    setProductDetail(response.data);
                    setProductImages(response.data.productImageList);

                    if (productDetailFromList.product.documentId) {
                        readDocument(
                            productDetailFromList.product.documentId
                        ).then(
                            (response) => {
                                // console.log(response.data.fileName);
                                if (response.data.fileName) {
                                    setProductDocumentURL(
                                        "https://zepterclub.hu/cl100-srv/api/document/" +
                                            response.data.fileName
                                    );
                                }
                            },
                            (error) => {
                                console.log(error.response);
                            }
                        );
                    }
                    dispatch(setLoadingOff());
                },
                (error) => {
                    console.log(error.response);
                    if (error.response && error.respone.status) {
                        console.log(error.response.status);
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                            dispatch(setLoadingOff());
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, productDetailFromList, productDetail, productImages]);

    const imageTemplate = (item: any) => {
        return (
            <img
                className="gallery-image"
                src={`data:image/png;base64,${item.image}`}
                alt={
                    productDetail && productDetail.product
                        ? productDetail.name + " kép"
                        : "A termékhez nem érhető el kép"
                }
            />
        );
    };

    const thumbnailTemplate = (item: any) => {
        return (
            <img
                className="gallery-thumbnail border-purple-500"
                src={`data:image/png;base64,${item.thumbnail}`}
                alt={
                    productDetail && productDetail.product
                        ? productDetail.name + " kép"
                        : "A termékhez nem érhető el kép"
                }
            />
        );
    };

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5,
        },
        {
            breakpoint: "960px",
            numVisible: 4,
        },
        {
            breakpoint: "768px",
            numVisible: 3,
        },
        {
            breakpoint: "560px",
            numVisible: 2,
        },
    ];

    return (
        <div className="webshop">
            {productDetailFromList && productDetail && productDetail.id ? (
                <Dialog
                    id="product-details"
                    className="product-details"
                    visible={
                        productDetailFromList &&
                        productDetailFromList.showDialog
                            ? productDetailFromList.showDialog
                            : false
                    }
                    onHide={() => {
                        dispatch(setProductDetailFromList(null));
                        setProductDetail(null);
                        setProductImages(null);
                        setProductDocumentURL(null);
                    }}
                    onShow={() => {
                        if (
                            topRef &&
                            topRef.current &&
                            topRef.current.scrollIntoView()
                        ) {
                            topRef.current.scrollIntoView();
                        }
                    }}
                    appendTo="self"
                    modal
                    showHeader
                    closable
                    header={
                        productDetail.name
                            ? productDetail.name + " információk"
                            : null
                    }
                    resizable={false}
                >
                    <div className="mt-0 pt-0}" ref={topRef}></div>
                    <TabView>
                        <TabPanel>
                            <div className="grid relative mt-2">
                                <div className="col-12 md:col-6 lg:col-6">
                                    {!productImages ? (
                                        <img
                                            className="gallery-image w-full border-solid border-blue-500 border-2 border-round-2xl"
                                            src={
                                                productDetailFromList.product
                                                    .productImageList[0]
                                                    ? `data:image/png;base64,${productDetailFromList.product.productImageList[0].thumbnail}`
                                                    : noImageAvailable
                                            }
                                            alt={
                                                productDetailFromList.product
                                                    .productImageList[0]
                                                    ? productDetailFromList
                                                          .product.name + " kép"
                                                    : "A termékhez nem érhető el kép"
                                            }
                                        />
                                    ) : (
                                        <div className="gallery-container card w-full border-blue-500">
                                            <Galleria
                                                value={productImages}
                                                responsiveOptions={
                                                    responsiveOptions
                                                }
                                                circular
                                                numVisible={5}
                                                showItemNavigators
                                                showItemNavigatorsOnHover
                                                style={{ maxWidth: "900px" }}
                                                item={imageTemplate}
                                                thumbnail={thumbnailTemplate}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 py-2 md:col-6 lg:col-6 sm:pl-5 md:pl-1 lg:py-1 lg:pl-5">
                                    <div className="flex align-items-center justify-content-between lg:mb-2 md:mb-3 md:mb-3 ml-2 mr-2">
                                        <span className="text-xl base font-medium text-900 font-bold mb-2">
                                            {productDetail.title
                                                ? productDetail.title
                                                : null}
                                        </span>
                                    </div>
                                    <div className="ml-2 mb-1 text-600">
                                        <div className="mb-2">
                                            <span className="lg:text-base md:text-base sm:text-sm font-medium ">
                                                Cikkszám:{" "}
                                                <span className="font-bold">
                                                    {productDetail.itemNumber
                                                        ? productDetail.itemNumber
                                                        : null}
                                                </span>
                                            </span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="lg:text-sm md:text-base sm:text-sm font-medium  line-through">
                                                Listaár:{" "}
                                                {productDetail.productPriceGroup &&
                                                productDetail.productPriceGroup
                                                    .rrp
                                                    ? setNumberStringThousandSeparator(
                                                          productDetail
                                                              .productPriceGroup
                                                              .rrp
                                                      ) + " Ft"
                                                    : null}
                                            </span>
                                        </div>

                                        {productDetailFromList &&
                                        productDetailFromList.bonus ===
                                            false ? (
                                            <>
                                                <div className="mb-2">
                                                    <span className="lg:text-base md:text-base sm:text-sm font-medium ">
                                                        Partneri ár:{" "}
                                                        <span className="font-bold">
                                                            {productDetailFromList.productPlPrice
                                                                ? setNumberStringThousandSeparator(
                                                                      productDetailFromList.productPlPrice
                                                                  ) + " Ft"
                                                                : null}
                                                        </span>
                                                    </span>
                                                </div>
                                                <div className="mb-2">
                                                    <span className="lg:text-base md:text-base sm:text-sm font-medium ">
                                                        Kedvezmény mértéke:{" "}
                                                        <span className="font-bold">
                                                            {productDetailFromList.productPlDiscountPercentage
                                                                ? setNumberStringThousandSeparator(
                                                                      "-" +
                                                                          productDetailFromList.productPlDiscountPercentage
                                                                  ) + "%"
                                                                : null}
                                                        </span>
                                                    </span>
                                                </div>
                                            </>
                                        ) : null}
                                        <div className="mt-3 mb-3 text-align-center sm:ml-2 sm:mr-2 md:ml-2 md:mr-2 lg:ml-3 lg:mr-3 ">
                                            {/* {productDetail.itemNumber.includes(
                                                "SF-SOL"
                                            ) ? (
                                                <>
                                                    <i className="pi pi-thumbs-up-fill mr-2 text-yellow-400" />
                                                    <span className="text-yellow-400 mt-0 mb-2 text-900 font-bold line-height-2 sm:text-base md:text-base lg:text-lg">
                                                        Nincs készleten, de
                                                        bevezető áron
                                                        előrendelhető márciusi
                                                        szállítással
                                                    </span>
                                                </>
                                            ) : ( */}
                                            {/* TODO készletek kártya alapján számolni */}
                                            <>
                                                <i
                                                    className={
                                                        productDetailFromList.productInStock
                                                            ? "pi pi-check pi-circle-fill mr-2 text-green-400"
                                                            : "pi pi-times mr-2 text-yellow-400"
                                                    }
                                                />
                                                <span
                                                    className={
                                                        productDetailFromList.productInStock
                                                            ? "text-green-400 mt-0 mb-2 text-900 font-bold line-height-2 sm:text-base md:text-base lg:text-lg"
                                                            : "text-yellow-400 mt-0 mb-2 text-900 font-bold line-height-2 sm:text-base md:text-base lg:text-lg"
                                                    }
                                                >
                                                    {productDetailFromList.productInStock
                                                        ? "Készleten"
                                                        : "Nincs készleten, de előrendelhető"}
                                                </span>
                                            </>
                                        </div>
                                        <div className="mb-3 h-7rem text-700 line-height-3 text-justify sm:ml-2 sm:mr-2 md:ml-2 md:mr-2 lg:ml-3 lg:mr-3">
                                            {productDetail.shortDescription
                                                ? productDetail.shortDescription
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-2 mb-3 mt-2 text-900">
                                <span className="lg:text-xl md:text-xl sm:text-base font-semibold">
                                    <i className="pi pi-book mr-2" />
                                    Termékleírás{" "}
                                </span>
                                <div className="ql-editor">
                                    {productDetail.longDescription &&
                                    productDetail.longDescription
                                        ? parse(productDetail.longDescription)
                                        : null}
                                </div>
                            </div>
                            <div className="col-12 lg:col-12 py-0 lg:pl-9 mr-2 justify-content padding-left: 0px; text-justify">
                                <TabView className="tab tabview-header-icon">
                                    <TabPanel
                                        header="Termék részletek"
                                        leftIcon="pi pi-info-circle pr-2"
                                    >
                                        <div className="ql-editor">
                                            {productDetail.details &&
                                            productDetail.details
                                                ? parse(productDetail.details)
                                                : null}
                                        </div>
                                    </TabPanel>
                                    <TabPanel
                                        header={
                                            productDetail.productCategory &&
                                            productDetail.productCategory.name
                                                ? parse(
                                                      productDetail
                                                          .productCategory
                                                          .name + " család"
                                                  )
                                                : null
                                        }
                                        leftIcon="pi pi-heart-fill pr-2"
                                    >
                                        <div className="ql-editor">
                                            {productDetail.productCategory &&
                                            productDetail.productCategory
                                                .description
                                                ? parse(
                                                      productDetail
                                                          .productCategory
                                                          .description
                                                  )
                                                : null}
                                        </div>
                                    </TabPanel>
                                </TabView>
                            </div>
                            {productDocumentURL && productDetail.name ? (
                                <>
                                    <a
                                        href={productDocumentURL}
                                        style={{
                                            border: "none",
                                            borderRadius: "1rem",
                                            textDecoration: "none",
                                        }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button
                                            icon="pi pi-file-pdf"
                                            className="p-ripple p-component cursor-pointer p-3 border-round-3xl bg-pink-500 hover:bg-pink-700 border-none text-0 w-full transition-all transition-duration-300 text-center"
                                            label={
                                                productDetail && productDetail
                                                    ? productDetail.name +
                                                      " letölthető dokumentum"
                                                    : ""
                                            }
                                            style={{
                                                border: "none",
                                                borderRadius: "2rem",
                                                // top: "1rem",
                                                bottom: "0.5rem",
                                            }}
                                        />
                                    </a>
                                    <Ripple />
                                </>
                            ) : null}
                            <AddToCartButton
                                product={productDetail}
                                productDiscountPrice={
                                    productDetail.productDiscountPrice
                                }
                                productDiscountPercentage={
                                    productDetail.productDiscountPercentage
                                }
                            />
                        </TabPanel>
                    </TabView>
                </Dialog>
            ) : null}
        </div>
    );
};

export default ProductDetail;
