export const isOnlinePartnerInAMonth = (
    representativeRegistrationDate: string
) => {
    const parseRegistrationDate = representativeRegistrationDate.replaceAll(
        '.',
        '-'
    );
    const endDate = new Date(parseRegistrationDate);
    endDate.setDate(endDate.getDate() + 31);
    const todayDate = new Date();

    // Test
    // console.log(representativeRegistrationDate);
    // console.log(parseRegistrationDate);
    // const startDate = new Date(parseRegistrationDate);
    // console.log('start ' + startDate);
    // console.log('end ' + endDate);
    // console.log('today ' + todayDate);
    // console.log('decision ' + (todayDate <= endDate));

    return { endDate: endDate, discount: todayDate <= endDate };
};

export const isIn2023Q1NYJ = (date: string) => {
    const parseRegistrationDate = date.replaceAll('.', '-');
    const startDate = new Date('2023-01-01');
    const endDate = new Date('2023-03-31');
    const registrationDate = new Date(parseRegistrationDate);

    // Test
    // console.log('start ' + startDate);
    // console.log('end ' + endDate);
    // console.log('registration ' + registrationDate);
    // console.log(
    //     'decision ' +
    //         (startDate <= registrationDate && registrationDate <= endDate)
    // );

    return startDate <= registrationDate && registrationDate <= endDate;
};

export const isIn2023Q2NYJ = (date: string) => {
    const parseRegistrationDate = date.replaceAll('.', '-');
    const startDate = new Date('2023-04-01');
    const endDate = new Date('2023-06-30');
    const registrationDate = new Date(parseRegistrationDate);

    // Test
    // console.log('start ' + startDate);
    // console.log('end ' + endDate);
    // console.log('registration ' + registrationDate);
    // console.log(
    //     'decision ' +
    //         (startDate <= registrationDate && registrationDate <= endDate)
    // );

    return startDate <= registrationDate && registrationDate <= endDate;
};

export const isIn2023Q3NYJ = (date: string) => {
    const parseRegistrationDate = date.replaceAll('.', '-');
    const startDate = new Date('2023-07-01');
    const endDate = new Date('2023-09-30');
    const registrationDate = new Date(parseRegistrationDate);

    // Test
    // console.log('start ' + startDate);
    // console.log('end ' + endDate);
    // console.log('registration ' + registrationDate);
    // console.log(
    //     'decision ' +
    //         (startDate <= registrationDate && registrationDate <= endDate)
    // );

    return startDate <= registrationDate && registrationDate <= endDate;
};
