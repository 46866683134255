import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const getRecommenderLink = (email: string) => {
    return axios
        .get(API_URL + '/protected/createrecommenderlink/' + email)
        .then((response) => {
            // console.log(response);
            return response;
        });
};
