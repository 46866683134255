import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const readClubMemberStructure = (vipNumber: string) => {
    return axios
        .get(API_URL + '/api/admin/clubmemberstructure/' + vipNumber)
        .then((response) => {
            return response;
        });
};
