import axios from 'axios';
import { server } from '../../../config/endpoints';

const API_URL = server;

export const readCustomerOfProductOrder = (email: string) => {
    return axios
        .get(API_URL + '/protected/productorder/customer/' + email)
        .then((response) => {
            return response;
        });
};

export const createProductOrder = (productOrder: any) => {
    return axios
        .post(API_URL + '/protected/productorder', productOrder)
        .then((response) => {
            return response;
        });
};
