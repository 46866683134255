import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { setCart } from "../../../state/dashboard/webshopSlice";
import { cartItemNumberCount } from "../../../util/dashboard/webshop/cartUtil";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Ripple } from "primereact/ripple";

const AddToCartButton: React.FunctionComponent<any> = (props: any) => {
    const dispatch = useDispatch();
    const cart: [] = useSelector((state: RootState) => state.webshop.cart);

    // const [product, setProduct] = useState<any>(null);

    const product: any = props.product;
    const productPriceGroupCode: any = props.productPriceGroupCode;
    const productPlDiscountPercentage: any = props.productPlDiscountPercentage;
    const productPlPrice: any = props.productPlPrice;

    //TODO ez a rész csak a bónusz miatt kerül be:
    // const productList: [] = useSelector(
    //     (state: RootState) => state.webshop.productList
    // );

    const toast: any = useRef(null);

    const showSuccess = () => {
        if (toast && toast.current && toast.current) {
            toast.current.show({
                severity: "success",
                summary: "Webshop üzenet",
                detail: product.name + " sikeresen hozzáadva a kosarához",
                life: 3000,
            });
        }
    };

    //TODO ez a main-ben is van, tarsd szinkronban

    return (
        <>
            <Toast ref={toast} position="bottom-left" />
            <Button
                label="Kosárba teszem"
                icon="pi pi-shopping-cart"
                className="p-ripple p-component cursor-pointer p-3 border-round-3xl bg-indigo-500 hover:bg-indigo-700 border-none text-0 w-full transition-all transition-duration-300 text-center"
                style={{
                    border: "none",
                    borderRadius: "2rem",
                    top: "0rem",
                    bottom: "1rem",
                }}
                onClick={() => {
                    // console.log(product);
                    const foundItem = cart.some(
                        (item: any) =>
                            item.productId &&
                            item.productId === product.id &&
                            item.productPlDiscountPercentage &&
                            product.productPlDiscountPercentage !== 100
                    );
                    let newCart = null;
                    // let newCartWithBonus = null;
                    if (foundItem) {
                        // console.log(foundItem);
                        newCart = cart.map((item: any) => {
                            const newItem = { ...item };
                            if (
                                newItem.productId === product.id &&
                                newItem.productPlDiscountPercentage !== 100
                            ) {
                                ++newItem.productQuantity;
                                newItem.productRrpTotalPrice = (
                                    Number(newItem.productRrpPrice) *
                                    newItem.productQuantity
                                ).toString();
                                newItem.productPlTotalPrice = (
                                    Number(newItem.productPlPrice) *
                                    newItem.productQuantity
                                ).toString();
                                newItem.productTotalPrice = (
                                    Number(newItem.productPlTotalPrice) -
                                    Number(newItem.productDiscountAmount)
                                ).toString();
                            }
                            return newItem;
                        });
                    } else {
                        const newItem = {
                            productId: product.id,
                            productItemNumber: product.itemNumber,
                            productName: product.name,
                            productThumbnail: product.productImageList[0].image,
                            productPriceGroupCode: productPriceGroupCode,
                            productRrpPrice: product.productPriceGroup.rrp,
                            productPlPrice: productPlPrice,
                            productPlDiscountPercentage:
                                productPlDiscountPercentage,
                            productQuantity: 1,
                            productRrpTotalPrice: product.productPriceGroup.rrp,
                            productPlTotalPrice: productPlPrice,
                            productDiscountAmount: "0",
                            productTotalPrice: productPlPrice,
                            productHalfPrice: false,
                        };
                        newCart = [...cart, newItem];

                        //Bónusz pozitura - logika itt még nem reduxban
                        // if (
                        //     newItem.productId === "36" &&
                        //     newItem.productPriceGroupCode === "pl4"
                        // ) {
                        //     const bonusProduct: any = productList.find(
                        //         (product: any) => {
                        //             return product.id === "42";
                        //         }
                        //     );
                        //     let bonusItem = null;
                        //     if (bonusProduct) {
                        //         bonusItem = {
                        //             productId: bonusProduct.id,
                        //             productItemNumber: bonusProduct.itemNumber,
                        //             productName: bonusProduct.name,
                        //             productThumbnail:
                        //                 bonusProduct.productImageList[0]
                        //                     .thumbnail,
                        //             productPriceGroupCode: "rrp",
                        //             productRrpPrice:
                        //                 bonusProduct.productPriceGroup.rrp,
                        //             productPlPrice:
                        //                 bonusProduct.productPriceGroup.rrp,
                        //             productPlDiscountPercentage: 100,
                        //             productQuantity: 1,
                        //             productRrpTotalPrice:
                        //                 bonusProduct.productPriceGroup.rrp,
                        //             productPlTotalPrice: 0,
                        //             productDiscountAmount:
                        //                 bonusProduct.productPriceGroup.rrp,
                        //             productTotalPrice: 0,
                        //         };
                        //         newCartWithBonus = [...newCart, bonusItem];
                        //     }
                        // }
                    }
                    // console.log(newCartWithBonus);
                    // dispatch(
                    //     setCart(newCartWithBonus ? newCartWithBonus : newCart)
                    // );
                    dispatch(setCart(newCart));
                    showSuccess();
                }}
            >
                {cartItemNumberCount(cart, product.id) !== 0 ? (
                    <Badge
                        className="mt-1"
                        value={cartItemNumberCount(cart, product.id)}
                    />
                ) : null}
            </Button>
            <Ripple />
        </>
    );
};
export default AddToCartButton;
