import React from "react";
import { Navigate, Outlet } from "react-router";

const ValidRecommenderRoute: React.FC = () => {
    let recommender = sessionStorage.getItem("cm");
    let invitation = sessionStorage.getItem("invitation");
    // console.log(recommender);

    if (!recommender && !invitation) {
        return <Navigate to="/login" replace />;
    }
    return <Outlet />;
};

export default ValidRecommenderRoute;
