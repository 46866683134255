import React, { FunctionComponent } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { useEffect, useState } from "react";
import DataHandling220615 from "../view/regulation/DataHandling220615";
import GeneralTermsClubMemberIvitation230719 from "../view/regulation/GeneralTermsClubMemberIvitation230719";
import GeneralTermsClubMember220615 from "../view/regulation/GeneralTermsClubMember220615";
import GeneralTermsClubMember221012 from "../view/regulation/GeneralTermsClubMember221012";
import GeneralTermsClubMember230215 from "../view/regulation/GeneralTermsClubMember230215";
import GeneralTermsClubMember230301 from "../view/regulation/GeneralTermsClubMember230301";
import GeneralTermsClubMember230329 from "../view/regulation/GeneralTermsClubMember230329";
import GeneralTermsClubMember230526 from "../view/regulation/GeneralTermsClubMember230526";
import GeneralTermsConsultant220615 from "../view/regulation/GeneralTermsConsultant220615";
import GeneralTermsMyBasicsNYJ2022 from "../view/regulation/GeneralTermsMyBasicsNYJ2022";
import GeneralTermsCMOPPNYJ2023Q1 from "../view/regulation/GeneralTermsCMOPPNYJ2023Q1";
import GeneralTermsCMOPPNYJ2023Q2 from "../view/regulation/GeneralTermsCMOPPNYJ2023Q2";
import GeneralTermsCMOPPNYJ2023Q3 from "../view/regulation/GeneralTermsCMOPPNYJ2023Q3";
import GeneralTermsCMNYJ2023Q4 from "../view/regulation/GeneralTermsCMNYJ2023Q4";
import GeneralTermsCMNYJ2024Q1 from "../view/regulation/GeneralTermsCMNYJ2024Q1";
import GeneralTermsCMNYJ2024Q2 from "../view/regulation/GeneralTermsCMNYJ2024Q2";
import GeneralTermsCMNYJ2024Q3 from "../view/regulation/GeneralTermsCMNYJ2024Q3";
import GeneralTermsCMNYJ2024Q4 from "../view/regulation/GeneralTermsCMNYJ2024Q4";

const GeneralTermsDialog: FunctionComponent<any> = (props: any) => {
    const [generalTermsDialogShow, setGeneralTermsDialogShow] =
        useState<boolean>(false);
    const [generalTermsDialogChange, setGeneralTermsDialogChange] =
        useState<number>(0);

    useEffect(() => {
        // console.log("MessageDialog useEffect is called");
        // console.log(props);
        if (
            props.generalTermsDialogShow !== 0 &&
            props.generalTermsDialogShow !== generalTermsDialogChange
        ) {
            setGeneralTermsDialogShow(true);
            setGeneralTermsDialogChange(props.generalTermsDialogShow);
        }
    }, [generalTermsDialogChange, props]);

    const generalTermsDialogFooter = (
        <div className="justify-content-center">
            <Divider />
            <Button
                label="Vissza"
                icon="pi pi-arrow-circle-left"
                onClick={() => setGeneralTermsDialogShow(false)}
            />
        </div>
    );

    return (
        <Dialog
            footer={generalTermsDialogFooter}
            visible={generalTermsDialogShow}
            position="top"
            showHeader={false}
            style={{ width: "100vw" }}
            modal
            onHide={() => setGeneralTermsDialogShow(false)}
        >
            <div>
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember220615" ? (
                    <GeneralTermsClubMember220615 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember221012" ? (
                    <GeneralTermsClubMember221012 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember230215" ? (
                    <GeneralTermsClubMember230215 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember230301" ? (
                    <GeneralTermsClubMember230301 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember230329" ? (
                    <GeneralTermsClubMember230329 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMember230526" ? (
                    <GeneralTermsClubMember230526 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsConsultant220615" ? (
                    <GeneralTermsConsultant220615 />
                ) : null}
                {props.generalTermsDialogComponent === "DataHandling220615" ? (
                    <DataHandling220615 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsClubMemberIvitation230719" ? (
                    <GeneralTermsClubMemberIvitation230719 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsMyBasicsNYJ2022" ? (
                    <GeneralTermsMyBasicsNYJ2022 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMOPPNYJ2023Q1" ? (
                    <GeneralTermsCMOPPNYJ2023Q1 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMOPPNYJ2023Q2" ? (
                    <GeneralTermsCMOPPNYJ2023Q2 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMOPPNYJ2023Q3" ? (
                    <GeneralTermsCMOPPNYJ2023Q3 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMNYJ2023Q4" ? (
                    <GeneralTermsCMNYJ2023Q4 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMNYJ2024Q1" ? (
                    <GeneralTermsCMNYJ2024Q1 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMNYJ2024Q2" ? (
                    <GeneralTermsCMNYJ2024Q2 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMNYJ2024Q3" ? (
                    <GeneralTermsCMNYJ2024Q3 />
                ) : null}
                {props.generalTermsDialogComponent ===
                "GeneralTermsCMNYJ2024Q4" ? (
                    <GeneralTermsCMNYJ2024Q4 />
                ) : null}
            </div>
        </Dialog>
    );
};

export default GeneralTermsDialog;
