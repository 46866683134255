import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
// import { useNavigate, useSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button } from "primereact/button";
import { server } from "../../config/endpoints";

const SimplePayTestSending: React.FC = () => {
    const [response, setResponse] = useState<string>("");
    // let navigate = useNavigate();

    const handlePayment = () => {
        const API_URL = server;
        const data = {
            salesContractNo: "8888888888",
            total: "20000",
        };

        axios
            //.get("http://localhost:8081/cl100-srv/api/simplepay/start/vincze26/10000")
            .post(API_URL + "/api/simplepay/start", data, {
                headers: {
                    "Content-Type": "application/json",
                    accept: "*",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                console.log(response.data);
                //setResponse(response.data);
                window.location.href = response.data;
            })
            .catch((error) => {
                const errorString: string = error ?? "";
                setResponse("HIBA: " + errorString);
                console.error(error);
            });
    };
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("simplepaytest"));

    let [searchParams] = useSearchParams();
    const status: string = searchParams.get("status") ?? "";

    return (
        <div className="surface-card border-round shadow-2 p-2 w-max-full md:p-4 lg:p-5">
            <h1 className="text-900 mb-5 ml-2">SimplePay Test</h1>
            <h2 className="text-700 mb-5 ml-2">
                Kapott "status" query: {status}
            </h2>
            <div className="flex justify-content-center">
                <Button
                    label="SimplePay Test START"
                    className="p-button-text"
                    autoFocus
                    onClick={handlePayment}
                />
            </div>
            <h2 className="text-700 mb-5 ml-2">Kapott "response":</h2>
            <pre className="text-700 mb-5 ml-2">
                {JSON.stringify(response, null, 2)}
            </pre>
        </div>
    );
};

export default SimplePayTestSending;
