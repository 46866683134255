import InitialState, {
    UpdateAdminListAction,
} from "../../types/redux/adminList";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
    clubMemberAdminListFilterValue: null,
    clubMemberAdminFilteredList: [],
    documentAdminList: [],
    productAdminList: [],
    productCategoryAdminList: [],
};

export const adminListSlice = createSlice({
    name: UpdateAdminListAction,
    initialState: initialState,
    reducers: {
        setClubMemberAdminListFilterValue: (
            state,
            action: PayloadAction<any>
        ) => {
            state.clubMemberAdminListFilterValue = action.payload;
        },
        setClubMemberAdminFilteredList: (state, action: PayloadAction<any>) => {
            state.clubMemberAdminFilteredList = action.payload;
        },
        setDocumentAdminList: (state, action: PayloadAction<any>) => {
            state.documentAdminList = action.payload;
        },
        setProductAdminList: (state, action: PayloadAction<any>) => {
            state.productAdminList = action.payload;
        },
        setProductCategoryAdminList: (state, action: PayloadAction<any>) => {
            state.productCategoryAdminList = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setClubMemberAdminListFilterValue } = adminListSlice.actions;
export const { setClubMemberAdminFilteredList } = adminListSlice.actions;
export const { setDocumentAdminList } = adminListSlice.actions;
export const { setProductAdminList } = adminListSlice.actions;
export const { setProductCategoryAdminList } = adminListSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default adminListSlice.reducer;
