import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const readZipCodes = () => {
    return axios
        .get(API_URL + '/api/zipcodes', {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((response) => {
            return response;
        });
};

export const readCitiesByZipCode = async (zipCode: string) => {
    return await axios
        .get(API_URL + '/api/zipcode/' + zipCode, {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((response) => {
            return response;
        });
};

export const readStreetsByZipCode = async (zipCode: string) => {
    return await axios
        .get(API_URL + '/api/street/' + zipCode, {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((response) => {
            return response;
        });
};

export const readStreetsByZipCodeAndCity = async (
    zipCode: string,
    city: string
) => {
    return await axios
        .get(API_URL + '/api/street/' + zipCode + '/' + city, {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((response) => {
            return response;
        });
};
