import React from "react";
import { useSearchParams } from "react-router-dom";

const SimplePayTestResponse: React.FC = () => {
    let [searchParams] = useSearchParams();
    const status: string = searchParams.get("status") ?? "";
    // success: successful authorisation
    // fail: authorisation failed
    // timeout: timeout when the payment has not been initiated until the set time limit
    // cancel: cancelled payment, browser closed, leaving the payment page
    return (
        <div className="surface-card border-round shadow-2 p-2 w-max-full md:p-4 lg:p-5">
            <h1 className="text-900 mb-5 ml-2">SimplePay Test</h1>
            <h2 className="text-700 mb-5 ml-2">
                Kapott "status" query: {status}
            </h2>
        </div>
    );
};

export default SimplePayTestResponse;
