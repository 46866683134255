import axios from 'axios';
import { server } from '../../config/endpoints';
import { capitalizeFirstLetters } from '../../util/stringFormat';

const API_URL = server;

export const readProfile = (email: string) => {
    return axios
        .get(API_URL + '/protected/profile/' + email)
        .then((response) => {
            response.data.userName = capitalizeFirstLetters(
                response.data.userName
            );
            response.data.companyName = capitalizeFirstLetters(
                response.data.companyName
            );
            response.data.companyType = capitalizeFirstLetters(
                response.data.companyType
            );
            response.data.firstName = capitalizeFirstLetters(
                response.data.firstName
            );
            response.data.lastName = capitalizeFirstLetters(
                response.data.lastName
            );
            response.data.address = capitalizeFirstLetters(
                response.data.address
            );
            if (
                response.data.vipRegistrationDate &&
                response.data.vipRegistrationDate.includes('1753')
            ) {
                response.data.vipRegistrationDate =
                    'Nincs belépési adat a Navision-ben';
            }
            if (
                response.data.recommenderName &&
                !response.data.recommenderName.includes('HIBA')
            ) {
                response.data.recommenderName = capitalizeFirstLetters(
                    response.data.recommenderName
                );
            }
            response.data.managerName = capitalizeFirstLetters(
                response.data.managerName
            );
            if (response.data.registrationStatus === 'VALIDATED') {
                response.data.registrationStatus = 'Email cím validálva';
            } else if (response.data.registrationStatus === 'APPROVED') {
                response.data.registrationStatus =
                    'Adatok ellenőrizve és elfogadva';
            } else if (response.data.registrationStatus === 'SYNCED') {
                response.data.registrationStatus =
                    'Profil szinkronizálva az adatbázissal';
            }
            // console.log(response.data);
            return response;
        });
};

export const readUserRanks = (representativeNumber: string) => {
    return axios
        .get(API_URL + '/protected/consultant/ranks/' + representativeNumber)
        .then((response) => {
            return response;
        });
};
