import axios from 'axios';
import { server } from '../../../../config/endpoints';

const API_URL = server;

let createProductCategoryLogoRequest = (formData: any) => {
    const result = {
        id: formData.id,
        logo: formData.logo,
        modifiedBy: formData.modifiedBy,
    };
    // console.log(formData.authenticated);
    return result;
};

export const saveProductCategoryLogo = (formData: any) => {
    let request = createProductCategoryLogoRequest(formData);
    return axios
        .post(API_URL + '/protected/admin/productcategory/logo/', request, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            return response;
        });
};

export const deleteProductCategoryLogo = (productCategoryId: string) => {
    return axios
        .delete(
            API_URL +
                '/protected/admin/productcategory/logo/' +
                productCategoryId
        )
        .then((response) => {
            return response;
        });
};
