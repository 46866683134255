import React from "react";
import "../../css/GeneralTerms.css";
import pageGeneralTermsImage from "../../asset/page-teaser-general-terms.png";

const GeneralTermsClubMemberIvitation230719: React.FunctionComponent<any> = (
    props: any
) => {
    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <div className="flex flex-wrap shadow-2">
                    <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                        <div className="mt-0 mb-2 flex justify-content-center">
                            <div className="w-auto md:w-8">
                                <img
                                    src={pageGeneralTermsImage}
                                    className="responsive fadeinleft animation-duration-500"
                                    alt="zepterclub.hu adatkezelési tájékoztató kép"
                                />
                            </div>
                        </div>
                        <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                            ADATKEZELÉSI NYILATKOZAT
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GeneralTermsClubMemberIvitation230719;
