import axios from 'axios';
import { server } from '../../../config/endpoints';

const API_URL = server;

export const readAllClubMembers = () => {
    return axios
        .get(API_URL + '/protected/admin/clubmember')
        .then((response) => {
            return response;
        });
};

export const readClubMemberByValidationId = (validationId: string) => {
    return axios
        .get(API_URL + '/protected/admin/clubmember/' + validationId)
        .then((response) => {
            return response;
        });
};

export const updateClubMember = (formData: any) => {
    return axios
        .post(API_URL + '/protected/admin/clubmember', formData)
        .then((response) => {
            return response;
        });
};

export const newClubMemberNumber = () => {
    return axios
        .get(API_URL + '/protected/vipmember/nextvipnumber')
        .then((response) => {
            return response;
        });
};
