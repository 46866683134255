interface InitialState {
    productList: [];
    productDetailFromList: null;
    cart: [];
    cartSummary: {
        totalQuantity: number;
        rrpTotalPrice: number;
        plTotalPrice: number;
        totalDiscountAmount: number;
        totalPrice: number;
        shipmentFee: number;
    };
    productOrder: {
        clubMemberId: null;
        vipNumber: null;
        representativeNumber: null;
        recommenderRepresentativeNumber: null;
        sellToCuNumber: null;
        sellToName: null;
        sellToZip: null;
        sellToCity: null;
        sellToStreet: null;
        sellToEmail: null;
        sellToPhone: null;
        billToCuNumber: null;
        billToName: null;
        billToZip: null;
        billToCity: null;
        billToStreet: null;
        billToEmail: null;
        billToPhone: null;
        billToVatNumber: null;
        shipToCuNumber: null;
        shipToName: null;
        shipToZip: null;
        shipToCity: null;
        shipToStreet: null;
        shipToEmail: null;
        shipToPhone: null;
        couponCode: null;
        usedWalletVoucher: null;
        paymentMethod: null;
        paymentStatus: null;
        shipmentFee: null;
        subscribe: null;
        subscribeFrequency: null;
        subscribeDeliveryDay: null;
        netAmount: null;
        vatAmount: null;
        grossAmount: null;
        productOrderLineList: [];
        acceptedWebshopGtac: null;
    };
}
const UpdateWebshopAction: string = 'UpdateWebshopAction';

export default InitialState;
export { UpdateWebshopAction };
