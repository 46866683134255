import React, {
    // useEffect,
    useRef,
    useState,
} from "react";
// import { useNavigate } from "react-router";
// import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    // logout,
} from "../../../../service/dashboard/AuthService";
import {
    deleteProductCategoryLogo,
    saveProductCategoryLogo,
} from "../../../../service/dashboard/admin/webshop/ProductCategoryLogoService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";

const ProductCategoryLogoAdminDetail: React.FC<any> = (props: any) => {
    // let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();
    const [uploadFileName, setUploadFileName] = useState<string>("");
    const [categoryLogo, setCategoryLogo] = useState<any>(props.logo);
    const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInputRef.current) hiddenFileInputRef.current.click();
    };

    //TODO van vagy nincs
    const categoryId: any = props.id;
    // const categoryLogo: any = props.logo;
    // console.log(categoryId);

    // const hideDialog = () => {
    //     setProductCategoryLogoDto(null);
    //     setUploadFileName("");
    //     formik.resetForm();
    // };

    const formik = useFormik({
        initialValues: {
            id: categoryId,
            logo: "",
            modifiedBy: user.email,
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: Yup.object({
            //TODO uniqe fájlnév ellenőrzése
            logo: Yup.mixed()
                .required("Kötelező fájlt kiválasztani!")
                .test(
                    "FILE_SIZE",
                    "A feltöltött fájl túl nagy, maximum 10 MB lehet!",
                    (value) => {
                        // console .log(value);
                        return value && value.size <= 10485760; //10 MB
                    }
                )
                .test(
                    "FILE_FORMAT",
                    "A feltöltött fájl nem png formátumú!",
                    (value) => value && value.type.includes("image/png")
                ),
        }),
        onSubmit: (values) => {
            // TODO és values.id nincs kitöltve, hogy csak új képekre legyen érvényes
            if (
                //TODO a kinti lista tud-e frissülni? Redux?
                window.confirm("Menti a logót?")
            ) {
                saveProductCategoryLogo(values).then(
                    (response) => {
                        // console.log(response.data);
                        // setProductCategoryLogoDto(response.data);
                        setCategoryLogo(response.data.logo);
                        setUploadFileName("");
                        formik.resetForm();
                        // refreshProductCategoryLogo();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            }
        },
    });

    return (
        <>
            {categoryId !== "" ? (
                <>
                    <div className="text-center p-2 bg-green-100 border-round border-1 border-green-300 mb-3">
                        <i className="pi pi-info-circle mr-2" />A logó mérete
                        fix 300x60, transzparens png formátumú!
                    </div>
                    {categoryLogo && categoryLogo !== "" ? (
                        <div className="image-container mt-3 mb-3 text-center">
                            <img
                                width="400"
                                src={`data:image/png;base64,${categoryLogo}`}
                                alt={categoryId}
                            />
                        </div>
                    ) : (
                        <div className="mb-3">Még nincs logó feltöltve</div>
                    )}
                    <>
                        <form
                            onSubmit={formik.handleSubmit}
                            className="p-fluid"
                        >
                            <div className="formgrid grid">
                                {/* TODO csak 200x200 png */}
                                <div className="field col-12 md:col-4">
                                    <Button
                                        type="button"
                                        className="align-self-end w-full"
                                        icon={
                                            uploadFileName === ""
                                                ? "pi pi-search"
                                                : "pi pi-image"
                                        }
                                        label={
                                            uploadFileName === ""
                                                ? "Új kép kiválasztása"
                                                : uploadFileName
                                        }
                                        onClick={handleClick}
                                    />
                                    <input
                                        id="content"
                                        name="content"
                                        type="file"
                                        ref={hiddenFileInputRef}
                                        style={{ display: "none" }}
                                        accept=".png"
                                        onChange={(event) => {
                                            if (
                                                event.currentTarget.files &&
                                                event.currentTarget.files
                                                    .length > 0
                                            ) {
                                                formik.setFieldValue(
                                                    "logo",
                                                    event.currentTarget.files[0]
                                                );
                                                setUploadFileName(
                                                    event.currentTarget.files[0]
                                                        .name
                                                );
                                                // console.log(
                                                //     event.currentTarget.files[0]
                                                // );
                                            }
                                        }}
                                    />

                                    {formik.errors.logo ? (
                                        <small className="p-error">
                                            {formik.errors.logo.toString()}
                                        </small>
                                    ) : null}
                                </div>
                                {formik.values.logo !== "" ? (
                                    <>
                                        {/* TODO jogosultság reduxe */}
                                        <div className="field col-12 md:col-4">
                                            <Button
                                                className="p-button-success"
                                                label={
                                                    categoryLogo &&
                                                    categoryLogo !== ""
                                                        ? "Logó cseréjének mentése"
                                                        : "Új logó mentése"
                                                }
                                                icon="pi pi-check"
                                                type="submit"
                                            />
                                        </div>
                                    </>
                                ) : null}
                                {categoryLogo && categoryLogo !== "" ? (
                                    <div className="field col-12 md:col-4">
                                        <Button
                                            className="p-button-danger"
                                            type="button"
                                            label="Törlés"
                                            icon="pi pi-trash"
                                            onClick={() => {
                                                //TODO van id, ha nincs akkor csak bezárás, vagy mentés submit kérdés?
                                                if (
                                                    window.confirm(
                                                        "Biztos törli a kategória logóját?"
                                                    )
                                                ) {
                                                    deleteProductCategoryLogo(
                                                        categoryId
                                                    ).then(
                                                        (response) => {
                                                            // console.log(response.data);
                                                            setUploadFileName(
                                                                ""
                                                            );
                                                            formik.resetForm();
                                                            setCategoryLogo(
                                                                null
                                                            );
                                                        },
                                                        (error) => {
                                                            console.log(error);
                                                            // TODO esetleg nem bezárni az ablakot és hibaüzenet
                                                            setUploadFileName(
                                                                ""
                                                            );
                                                            setCategoryLogo(
                                                                null
                                                            );
                                                            formik.resetForm();
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </form>
                    </>
                </>
            ) : (
                <div>
                    Logó feltöltéshez először a kategória alapinformációkat el
                    kell menteni!
                </div>
            )}
        </>
    );
};

export default ProductCategoryLogoAdminDetail;
