import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setNumberOfBizzClubPartners } from "../../state/dashboard/userSlice";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressBar } from "primereact/progressbar";
import { Card } from "primereact/card";
import { Badge } from "primereact/badge";
import { Tooltip } from "primereact/tooltip";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import MYBFB from "../../component/dashboard/MYBFB";
// import SFFLU2024XI from "../../component/dashboard/SFFLU2024XI";
import PhilipZepterQuotes from "../../component/dashboard/PhilipZepterQuotes";
import ClockCountdown from "../../component/dashboard/ClockCountdown";
import CountUp from "react-countup";
import { readConsultantDashboardStatistics } from "../../service/dashboard/DashboardStatisticsService";
import { readNumberOfClubMembers } from "../../service/home/NumberOfClubMembersService";
import { getToCloseDays } from "../../util/dashboard/commissionCloseDateUtil";
import { setNumberStringThousandSeparator } from "../../util/stringFormat";
import oppBannerSquare from "../../asset/opp_partner_benefits_square.png";
import oppBannerWide from "../../asset/opp_partner_benefits_wide.png";
import cardBannerWeboffice from "../../asset/dashboard_card_banner_weboffice.png";
import cardBannerAllStatistics from "../../asset/dashboard_card_banner_all_statistics.png";
import cardBannerOwnStatistics from "../../asset/dashboard_card_banner_own_statistics.png";
import TestStructure1 from "../../asset/card-banner-test-structure-1.png";
import TestStructure2 from "../../asset/card-banner-test-structure-2.png";
import TestStructure3 from "../../asset/card-banner-test-structure-3.png";
import "../../css/Dashboard.css";

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("dashboard"));

    let navigate = useNavigate();

    const activeMenuItem: string = useSelector(
        (state: RootState) => state.navigation.activeMenuItem
    );

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );
    const userRanks: any = useSelector(
        (state: RootState) => state.user.userRanks
    );
    const numberOfBizzClubPartners: number | null = useSelector(
        (state: RootState) => state.user.numberOfBizzClubPartners
    );

    const [numberOfClubMembers, setNumberOfClubMembers] = useState<string>("0");

    const [
        numberOfAllClubMemberPendingFromCl100,
        setnumberOfAllClubMemberPendingFromCl100,
    ] = useState<string>("0");

    const [
        numberOfAllActiveMyBasicsRepresentatives,
        setNumberOfAllActiveMyBasicsRepresentatives,
    ] = useState<string>("0");

    const [
        numberOfAllPendingMyBasicsRepresentatives,
        setNumberOfAllPendingMyBasicsRepresentatives,
    ] = useState<string>("0");

    const [numberOfOwnAllClubMembers, setNumberOfOwnAllClubMembers] =
        useState<string>("0");

    const [
        numberOfOwnAllPendingClubMembers,
        setNumberOfOwnAllPendingClubMembers,
    ] = useState<string>("0");

    const [
        numberOfOwnActiveMyBasicsRepresentatives,
        setNumberOfOwnActiveMyBasicsRepresentatives,
    ] = useState<string>("0");

    const [
        numberOfOwnPendingMyBasicsRepresentatives,
        setNumberOfOwnPendingMyBasicsRepresentatives,
    ] = useState<string>("0");

    const testStructureImages = [
        TestStructure1,
        TestStructure2,
        TestStructure3,
    ];

    useEffect(() => {
        if (
            userProfile &&
            userProfile.representativeNumber &&
            numberOfBizzClubPartners === null
        ) {
            readNumberOfClubMembers().then((response) => {
                setNumberOfClubMembers(response.data);
            });
            const request = {
                customerNumber: userProfile.cuNumber,
                representativeNumber: userProfile.representativeNumber,
            };
            if (userProfile.representativeNumber) {
                readConsultantDashboardStatistics(request).then(
                    (response: any) => {
                        setnumberOfAllClubMemberPendingFromCl100(
                            response.data.numberOfAllClubMemberPendingFromCl100
                        );
                        setNumberOfAllActiveMyBasicsRepresentatives(
                            response.data
                                .numberOfAllActiveMyBasicsRepresentatives
                        );
                        setNumberOfAllPendingMyBasicsRepresentatives(
                            response.data
                                .numberOfAllPendingMyBasicsRepresentatives
                        );
                        setNumberOfOwnAllClubMembers(
                            response.data.numberOfOwnAllClubMembers
                        );
                        setNumberOfOwnAllPendingClubMembers(
                            response.data.numberOfOwnAllPendingClubMembers
                        );
                        if (
                            typeof parseInt(
                                response.data.numberOfOwnAllClubMembers
                            ) === "number" &&
                            typeof parseInt(
                                response.data.numberOfOwnAllPendingClubMembers
                            ) === "number"
                        ) {
                            dispatch(
                                setNumberOfBizzClubPartners(
                                    parseInt(
                                        response.data.numberOfOwnAllClubMembers
                                    ) +
                                        parseInt(
                                            response.data
                                                .numberOfOwnAllPendingClubMembers
                                        )
                                )
                            );
                        } else {
                            dispatch(setNumberOfBizzClubPartners(0));
                        }
                        setNumberOfOwnActiveMyBasicsRepresentatives(
                            response.data
                                .numberOfOwnActiveMyBasicsRepresentatives
                        );
                        setNumberOfOwnPendingMyBasicsRepresentatives(
                            response.data
                                .numberOfOwnPendingMyBasicsRepresentatives
                        );
                        // console.log(response.data);
                    }
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile, numberOfBizzClubPartners]);

    const getBadgeColor = (toDate: string) => {
        const toCloseDays = getToCloseDays(toDate);
        if (toCloseDays > -1) {
            if (toCloseDays < 2) {
                return "bg-red-500 text-white";
            } else if (toCloseDays < 7) {
                return "bg-yellow-500 text-white";
            } else {
                return "bg-teal-500 text-white";
            }
        } else {
            return "bg-bluegray-500 text-white";
        }
    };

    //csak tesztelésre
    // userProfile.zepterRank === "CLUBMEMBER" ? (
    //TODO következő frissítés system configból
    // /* {false ? ( */
    return (
        <>
            {userProfile.zepterRank ? (
                <>
                    {userProfile.zepterRank === "REPRESENTATIVE" ||
                    userProfile.zepterRank === "EMPLOYEE" ? (
                        <>
                            <MYBFB />
                            {/* TODO 2023.10.31-ig */}
                            {/* <SFFLU2024XI /> */}
                            <PhilipZepterQuotes />
                            <div className="grid mb-4">
                                <div className="col-12 md:col-4">
                                    <div className="text-center -mb-4 mt-0">
                                        <Badge
                                            className="bg-blue-500 text-white mt-0 md:mt-0"
                                            value="Központi statisztikák"
                                        />
                                    </div>
                                    <Card className="mt-3 ml-3 mr-3 pt-0 pb-0 text-center md:ml-0 md:mr-0 md:min-h-full">
                                        <img
                                            className="max-w-full mb-3 fadeinleft animation-duration-500"
                                            src={cardBannerAllStatistics}
                                            alt="Zepter statisztikák"
                                        />
                                        <div className="ml-3">
                                            <span className="font-medium text-black-alpha-90">
                                                ZepterClub tagok száma
                                            </span>
                                            <h2 className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                                <CountUp
                                                    className="count"
                                                    start={0}
                                                    end={
                                                        parseInt(
                                                            numberOfClubMembers
                                                        ) +
                                                        parseInt(
                                                            numberOfAllClubMemberPendingFromCl100
                                                        )
                                                    }
                                                    duration={4}
                                                    separator=" "
                                                />
                                                <i
                                                    className="numberOfClubMembers pi pi-info-circle cursor-pointer text-teal-500 ml-2"
                                                    style={{
                                                        fontSize: "0.80em",
                                                    }}
                                                    // placeholder="Bottom"
                                                />
                                                <Tooltip
                                                    position="bottom"
                                                    target=".numberOfClubMembers"
                                                    content={
                                                        "Zepter adatbázisban: " +
                                                        setNumberStringThousandSeparator(
                                                            numberOfClubMembers
                                                        ) +
                                                        "\nNincs még rögzítve: " +
                                                        setNumberStringThousandSeparator(
                                                            numberOfAllClubMemberPendingFromCl100
                                                        )
                                                    }
                                                />
                                            </h2>
                                        </div>
                                        <Divider className="mt-5 mb-5" />
                                        <div className="ml-3">
                                            <span className="font-medium text-black-alpha-90">
                                                Online Partnerek száma
                                            </span>
                                            <h2 className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                                <CountUp
                                                    start={0}
                                                    end={parseInt(
                                                        numberOfAllActiveMyBasicsRepresentatives
                                                    )}
                                                    duration={4}
                                                    separator=" "
                                                />
                                                <i
                                                    className="numberOfAllActiveMyBasicsRepresentatives pi pi-info-circle cursor-pointer text-teal-500 ml-2"
                                                    style={{
                                                        fontSize: "0.80em",
                                                    }}
                                                    // placeholder="Bottom"
                                                />
                                                <Tooltip
                                                    position="bottom"
                                                    target=".numberOfAllActiveMyBasicsRepresentatives"
                                                    content={
                                                        "Zepter adatbázisban: " +
                                                        setNumberStringThousandSeparator(
                                                            (
                                                                parseInt(
                                                                    numberOfAllActiveMyBasicsRepresentatives
                                                                ) -
                                                                parseInt(
                                                                    numberOfAllPendingMyBasicsRepresentatives
                                                                )
                                                            ).toString()
                                                        ) +
                                                        "\nNincs még rögzítve: " +
                                                        setNumberStringThousandSeparator(
                                                            numberOfAllPendingMyBasicsRepresentatives
                                                        )
                                                    }
                                                />
                                            </h2>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Card className="mt-3 ml-3 mr-3 pt-0 pb-0 text-center md:ml-0 md:mr-0 md:min-h-full">
                                        <img
                                            className="max-w-full mb-1 max-w-full mb-3 fadeinup animation-duration-500"
                                            src={cardBannerWeboffice}
                                            alt="zepterclub.hu Weboffice Banner"
                                        />
                                        <div className="text-xl text-900 font-medium mb-3">
                                            ZepterClub webiroda
                                        </div>
                                        <p className="mt-5 mb-5 p-0 line-height-3">
                                            Következő verzió frissítés:
                                            2025.01.13.
                                        </p>
                                        <ClockCountdown
                                            endTime={
                                                new Date("2025-01-13T23:59:59")
                                            }
                                        />
                                    </Card>
                                </div>
                                <div className="col-12 md:col-4">
                                    <div className="text-center -mb-4 mt-4 md:mt-0">
                                        <Badge
                                            className="bg-pink-500 text-white"
                                            value="Saját statisztikáim"
                                        />
                                    </div>
                                    <Card className="mt-3 ml-3 mr-3 pt-0 pb-0 text-center md:ml-0 md:mr-0 md:min-h-full">
                                        <img
                                            className="max-w-full mb-3 fadeinright animation-duration-500"
                                            src={cardBannerOwnStatistics}
                                            alt="Saját statisztikák"
                                        />
                                        <Divider
                                            className="mt-0"
                                            align="center"
                                            type="dashed"
                                        >
                                            <span className="p-tag">
                                                Összesen
                                            </span>
                                        </Divider>
                                        <ul
                                            key="d-ul-1"
                                            className="list-none p-0 m-0"
                                        >
                                            <li
                                                key="d-li-1"
                                                className="flex align-items-center justify-content-between"
                                            >
                                                <span className="text-left text-900 font-medium mr-2">
                                                    ZepterClub tagjaim száma
                                                </span>
                                                <span className="text-right text-900">
                                                    {setNumberStringThousandSeparator(
                                                        (
                                                            parseInt(
                                                                numberOfOwnAllClubMembers
                                                            ) +
                                                            parseInt(
                                                                numberOfOwnAllPendingClubMembers
                                                            )
                                                        ).toString()
                                                    )}{" "}
                                                    fő
                                                    <i
                                                        className="numberOfOwnAllClubMembers pi pi-info-circle cursor-pointer text-teal-500 ml-2"
                                                        style={{
                                                            fontSize: "1em",
                                                        }}
                                                        // placeholder="Bottom"
                                                    />
                                                    <Tooltip
                                                        position="bottom"
                                                        target=".numberOfOwnAllClubMembers"
                                                        content={
                                                            "Zepter adatbázisban: " +
                                                            setNumberStringThousandSeparator(
                                                                numberOfOwnAllClubMembers
                                                            ) +
                                                            "\nNincs még rögzítve: " +
                                                            setNumberStringThousandSeparator(
                                                                numberOfOwnAllPendingClubMembers
                                                            )
                                                        }
                                                    />
                                                </span>
                                            </li>
                                            <li
                                                key="d-li-2"
                                                className="flex align-items-center justify-content-between"
                                            >
                                                <span className="text-left text-900 font-medium mr-2">
                                                    Online Partnereim száma
                                                </span>
                                                <span className="text-right text-900">
                                                    {setNumberStringThousandSeparator(
                                                        parseInt(
                                                            numberOfOwnActiveMyBasicsRepresentatives
                                                        ).toString()
                                                    )}{" "}
                                                    fő
                                                    <i
                                                        className="numberOfOwnActiveMyBasicsRepresentatives pi pi-info-circle cursor-pointer text-teal-500 ml-2"
                                                        style={{
                                                            fontSize: "1em",
                                                        }}
                                                        // placeholder="Bottom"
                                                    />
                                                    <Tooltip
                                                        position="bottom"
                                                        target=".numberOfOwnActiveMyBasicsRepresentatives"
                                                        content={
                                                            "Zepter adatbázisban: " +
                                                            setNumberStringThousandSeparator(
                                                                (
                                                                    parseInt(
                                                                        numberOfOwnActiveMyBasicsRepresentatives
                                                                    ) -
                                                                    parseInt(
                                                                        numberOfOwnPendingMyBasicsRepresentatives
                                                                    )
                                                                ).toString()
                                                            ) +
                                                            "\nNincs még rögzítve: " +
                                                            setNumberStringThousandSeparator(
                                                                numberOfOwnPendingMyBasicsRepresentatives
                                                            )
                                                        }
                                                    />
                                                </span>
                                            </li>
                                        </ul>
                                    </Card>
                                </div>
                            </div>
                            <div className="grid mb-3 mt-2">
                                {userProfile.zepterRank &&
                                (userProfile.zepterRank === "REPRESENTATIVE" ||
                                    userProfile.zepterRank === "EMPLOYEE") &&
                                userRanks &&
                                userRanks.length > 0
                                    ? userRanks.map((rank: any, key: any) => {
                                          return (
                                              <div
                                                  key={"container-" + key}
                                                  className="col-12 mt-3 md:col-4"
                                              >
                                                  <div className="text-center -mb-4 mt-0">
                                                      <Badge
                                                          className={getBadgeColor(
                                                              rank.toDate
                                                          )}
                                                          value={
                                                              getToCloseDays(
                                                                  rank.toDate
                                                              ) > -1
                                                                  ? getToCloseDays(
                                                                        rank.toDate
                                                                    ) +
                                                                    " NAP ZÁRÁSIG"
                                                                  : "LEZÁRVA"
                                                          }
                                                      />
                                                  </div>
                                                  <Card
                                                      key={"card-" + key}
                                                      className={
                                                          getToCloseDays(
                                                              rank.toDate
                                                          ) > -1
                                                              ? "mt-3 ml-3 mr-3 text-center md:ml-0 md:mr-0"
                                                              : "bg-bluegray-100 mt-3 ml-3 mr-3 text-center md:ml-0 md:mr-0"
                                                      }
                                                      title={rank.zepterMonth}
                                                      subTitle={
                                                          rank.fromDate +
                                                          " - " +
                                                          rank.toDate
                                                      }
                                                  >
                                                      <img
                                                          className="max-w-full mb-3"
                                                          src={
                                                              testStructureImages[
                                                                  key
                                                              ]
                                                          }
                                                          alt="Zepter teszt struktúra"
                                                      />
                                                      <div>
                                                          <span className="text-lg text-blue-500 mt-0 mb-0">
                                                              {rank.rank}
                                                              {/* {key} */}
                                                          </span>
                                                      </div>
                                                      <Accordion className="-mb-5 mt-5 -mr-3 -ml-3">
                                                          <AccordionTab
                                                              header="Forgalmak"
                                                              tabIndex={key}
                                                          >
                                                              <span className="align-items-left text-left text-500 font-small">
                                                                  Frissítés:{" "}
                                                                  {
                                                                      rank.registrationTime
                                                                  }
                                                              </span>
                                                              <Divider
                                                                  className="mb-0"
                                                                  align="center"
                                                              >
                                                                  <span className="p-tag">
                                                                      30%
                                                                  </span>
                                                              </Divider>
                                                              <ul
                                                                  key="d-ul-4"
                                                                  className="list-none p-0 m-0"
                                                              >
                                                                  <li
                                                                      key="d-li-10"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          előző
                                                                          havi
                                                                          személyes:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.previousPersonalR30
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-11"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          személyes:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.personalR30
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-12"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          L1:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.l1R30
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-13"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          személyes
                                                                          + L1:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.personalR30 +
                                                                                  rank.l1R30
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  {/* 2023 IV. ZM-tól MT-ben nem feltétel */}
                                                                  {/* <li
                                                                      key="d-li-14"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-500 font-medium mr-2">
                                                                          SC L1:
                                                                      </span>
                                                                      <span className="text-900">
                                                                          {
                                                                              rank.activeScl1R30
                                                                          }{" "}
                                                                          fő
                                                                      </span>
                                                                  </li> */}
                                                              </ul>
                                                              <Divider
                                                                  className="mb-0"
                                                                  align="center"
                                                                  type="dashed"
                                                              >
                                                                  <span className="p-tag">
                                                                      0%
                                                                  </span>
                                                              </Divider>
                                                              <ul
                                                                  key="d-ul-5"
                                                                  className="list-none p-0 m-0"
                                                              >
                                                                  <li
                                                                      key="d-li-15"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          előző
                                                                          havi
                                                                          személyes:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.previousPersonalR0
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-16"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          személyes:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.personalR0
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-17"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          L1:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.l1R0
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  <li
                                                                      key="d-li-18"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-left text-500 font-medium mr-2">
                                                                          személyes
                                                                          + L1:
                                                                      </span>
                                                                      <span className="text-right text-900">
                                                                          {setNumberStringThousandSeparator(
                                                                              rank.personalR0 +
                                                                                  rank.l1R0
                                                                          )}{" "}
                                                                          Ft
                                                                      </span>
                                                                  </li>
                                                                  {/* 2023 IV. ZM-tól MT-ben nem feltétel */}
                                                                  {/* <li
                                                                      key="d-li-19"
                                                                      className="flex align-items-center justify-content-between"
                                                                  >
                                                                      <span className="text-500 font-medium mr-2">
                                                                          SC L1:
                                                                      </span>
                                                                      <span className="text-900">
                                                                          {
                                                                              rank.activeScl1R0
                                                                          }{" "}
                                                                          fő
                                                                      </span>
                                                                  </li> */}
                                                              </ul>
                                                          </AccordionTab>
                                                      </Accordion>
                                                  </Card>
                                              </div>
                                          );
                                      })
                                    : null}
                            </div>
                        </>
                    ) : (
                        <div className="grid mb-3 mt-2">
                            <div className="col-12 mt-3 ">
                                {/* TODO 2023.10.31-ig */}
                                <Card className="mt-3 ml-3 mr-3 text-center md:ml-0 md:mr-0">
                                    {/* <SFFLU2024XI /> */}
                                    <div className="flex align-items-center justify-content-center">
                                        <img
                                            className="max-w-full mb-3 border-round-xl cursor-pointer fadeinleft animation-duration-500 md:hidden"
                                            src={oppBannerSquare}
                                            alt="Zepter Online Partner Banner 1:1"
                                            onClick={() => {
                                                navigate(
                                                    "/main/consultantregistration"
                                                );
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "consultantregistration"
                                                    )
                                                );
                                            }}
                                        />
                                        <img
                                            className="hidden max-w-full border-round-xl mb-3 cursor-pointer fadeinleft animation-duration-500 md:block"
                                            src={oppBannerWide}
                                            alt="Zepter Online Partner Banner"
                                            onClick={() => {
                                                navigate(
                                                    "/main/consultantregistration"
                                                );
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "consultantregistration"
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <nav
                                            className="p-ripple max-w-full flex align-items-center justify-content-center text-xl cursor-pointer p-3 mb-0 text-700 border-2 border-transparent hover:border-300 transition-duration-150 transition-colors"
                                            style={{
                                                borderRadius: "12px",
                                            }}
                                            onClick={() => {
                                                navigate(
                                                    "../consultantregistration"
                                                );
                                                dispatch(
                                                    setActiveMenuItem(
                                                        "consultantregistration"
                                                    )
                                                );
                                            }}
                                        >
                                            <i className="text-xl pi pi-user-plus mr-2 text-teal-500"></i>
                                            <span
                                                className={
                                                    activeMenuItem ===
                                                    "consultantregistration"
                                                        ? "font-medium text-teal-500"
                                                        : "font-medium"
                                                }
                                            >
                                                Online Partner regisztráció
                                            </span>
                                            <Ripple />
                                        </nav>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <span className="mb-2">
                        Dashboard betöltése, kérem várjon
                    </span>
                    <ProgressBar
                        className=" m-0 p-0 pt-0 md:pt-3 mr-2"
                        mode="indeterminate"
                    />
                </>
            )}
        </>
    );
};

export default Dashboard;
