import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoadingOff, setLoadingOn } from "../../state/dashboard/mainSlice";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setAuthToken } from "../../service/dashboard/AuthService";
import { Panel } from "primereact/panel";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { Badge } from "primereact/badge";
import {
    clubMemberSearch,
    validateFields,
} from "../../service/dashboard/ClubMemberSearchService";
import pageTeaserClubMemberSearchImage from "../../asset/page-teaser-clubmember-search.png";
import MessageDialog from "../../component/MessageDialog";

const ClubMemberSearch: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("clubmembersearch"));
    setAuthToken();

    const [clubMemberList, setClubMemberList] = useState([]);

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const companyTypes = [
        { label: "Válasszon cégformát!", value: "" },
        { label: "Ec. - Egyéni cég", value: "EC." },
        { label: "Ev. - Egyéni vállalkozás", value: "EV." },
        { label: "Bt. - Betéti társaság", value: "BT." },
        { label: "Kft. - Korlátolt Felelősségű Társaság", value: "KFT." },
        { label: "Nyrt. - Nyílt részvénytársaság", value: "NYRT." },
        { label: "Zrt. - Zárt részvénytársaság", value: "ZRT." },
        { label: "Egyesület", value: "EGY." },
        { label: "Szövetkezet", value: "SZVT." },
        { label: "Nonprofit szervezet", value: "NPF." },
        { label: "Egyéb", value: "EGYB." },
    ];

    const formik = useFormik({
        initialValues: {
            lastName: "",
            firstName: "",
            zip: "",
            companyName: "",
            companyType: "",
            vatNumber: "",
            email: "",
            phone: "",
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: validateFields,
        onSubmit: (values) => {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "ZepterClub tag keresés folyamatban.",
                })
            );
            clubMemberSearch(values).then(
                (response) => {
                    console.log(response);
                    setClubMemberList(response.data.clubMemberList);

                    dispatch(setLoadingOff());
                    if (
                        response.data.clubMemberList &&
                        response.data.clubMemberList.length === 0
                    ) {
                        setMessageDialogHeader("Értesítés");
                        setMessageDialogHeader("Nincs találat!");
                        setMessageDialogBody(
                            "A megadott paraméterek alapján nincs egyértelmű keresési találat. Kérem változtasson a keresési feltételeken!"
                        );
                        setMessageDialogError(true);
                        setMessageDialogShow(Date.now());
                    }
                },
                (error) => {
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(responseMessage);
                    dispatch(setLoadingOff());
                }
            );
        },
    });

    return (
        <>
            <MessageDialog
                messageDialogError={messageDialogError}
                messageDialogShow={messageDialogShow}
                messageDialogHeader={messageDialogHeader}
                messageDialogBody={messageDialogBody}
            />
            <div className="mb-2 flex justify-content-center">
                <div className="w-auto md:w-8">
                    <img
                        src={pageTeaserClubMemberSearchImage}
                        className="responsive fadeinleft animation-duration-500"
                        alt="zepterclub.hu ZepterClub tagok struktúra kép"
                    />
                </div>
            </div>
            <Panel className="z-0" header="ZepterClub tag keresés">
                <form onSubmit={formik.handleSubmit} className="p-fluid">
                    <div
                        className="formgrid grid"
                        style={
                            formik.values.lastName !== "" ||
                            formik.values.firstName !== "" ||
                            formik.values.zip.split("_").join("") !== ""
                                ? { backgroundColor: "var(--green-100)" }
                                : undefined
                        }
                    >
                        <div className="field col-12 md:col-5">
                            <label
                                htmlFor="lastName"
                                className="block text-900 font-medium mb-2"
                            >
                                Vezetéknév
                            </label>
                            <InputText
                                className={
                                    formik.errors.lastName
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                autoFocus
                                id="lastName"
                                {...formik.getFieldProps("lastName")}
                            />
                            {formik.errors.lastName ? (
                                <small className="p-error">
                                    {formik.errors.lastName}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col-12 md:col-5">
                            <label
                                htmlFor="firstName"
                                className="block text-900 font-medium mb-2"
                            >
                                Keresztnév
                            </label>
                            <InputText
                                className={
                                    formik.errors.firstName
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                id="firstName"
                                {...formik.getFieldProps("firstName")}
                            />
                            {formik.errors.firstName ? (
                                <small className="p-error">
                                    {formik.errors.firstName}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col-12 md:col-2">
                            <label
                                htmlFor="zip"
                                className="block text-900 font-medium mb-2"
                            >
                                Irányítószám
                            </label>
                            <InputMask
                                className={
                                    formik.errors.zip
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                mask="9999"
                                id="zip"
                                autoClear={false}
                                {...formik.getFieldProps("zip")}
                            />
                            {formik.errors.zip ? (
                                <small className="p-error">
                                    {formik.errors.zip}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <Divider align="center">
                        <Badge value="és/vagy" severity="success" />
                    </Divider>
                    <div
                        className="formgrid grid"
                        style={
                            formik.values.companyName !== "" ||
                            formik.values.companyType !== "" ||
                            formik.values.vatNumber
                                .split("_")
                                .join("")
                                .split("-")
                                .join("")
                                ? { backgroundColor: "var(--green-100)" }
                                : undefined
                        }
                    >
                        <div className="field col-12 md:col-5">
                            <label
                                htmlFor="companyName"
                                className="block text-900 font-medium mb-2"
                            >
                                Cégnév
                            </label>
                            <InputText
                                className={
                                    formik.errors.companyName
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                id="companyName"
                                {...formik.getFieldProps("companyName")}
                            />
                            {formik.errors.companyName ? (
                                <small className="p-error">
                                    {formik.errors.companyName}
                                </small>
                            ) : null}
                        </div>

                        <div className="field col-12 md:col-4">
                            <label
                                htmlFor="companyType"
                                className="block text-900 font-medium mb-2"
                            >
                                Cégforma
                            </label>
                            <Dropdown
                                id="companyType"
                                className={
                                    formik.errors.companyType
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                {...formik.getFieldProps("companyType")}
                                options={companyTypes}
                                placeholder="Válasszon cégformát!"
                            />
                            {formik.errors.companyType ? (
                                <small className="p-error">
                                    {formik.errors.companyType}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col-12 md:col-3">
                            <label
                                htmlFor="vatNumber"
                                className="block text-900 font-medium mb-2"
                            >
                                Adószám
                            </label>
                            <InputMask
                                className={
                                    formik.errors.vatNumber
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                mask="99999999-9-99"
                                id="vatNumber"
                                autoClear={false}
                                {...formik.getFieldProps("vatNumber")}
                            />
                            {formik.errors.vatNumber ? (
                                <small className="p-error">
                                    {formik.errors.vatNumber}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <Divider align="center">
                        <Badge value="és/vagy" severity="success" />
                    </Divider>
                    <div className="formgrid grid bg">
                        <div
                            className="field col-12 md:col-5"
                            style={
                                formik.values.email !== ""
                                    ? { backgroundColor: "var(--green-100)" }
                                    : undefined
                            }
                        >
                            <label
                                htmlFor="email"
                                className="block text-900 font-medium mb-2"
                            >
                                Email
                            </label>
                            <InputText
                                className={
                                    formik.errors.email
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                id="email1"
                                {...formik.getFieldProps("email")}
                                onBlur={(val: any) => {
                                    const emailTrim = formik.values.email
                                        .trim()
                                        .toLocaleLowerCase();
                                    formik.setFieldValue("email", emailTrim);
                                }}
                            />
                            {formik.errors.email ? (
                                <small className="p-error">
                                    {formik.errors.email}
                                </small>
                            ) : null}
                        </div>
                        <div className="field col-2 hidden md:block">
                            <Divider layout="vertical" align="center">
                                <Badge value="és/vagy" severity="success" />
                            </Divider>
                        </div>
                        <Divider className="md:hidden" align="center">
                            <Badge value="és/vagy" severity="success" />
                        </Divider>

                        <div
                            className="field col-12 md:col-5"
                            style={
                                formik.values.phone
                                    .split("_")
                                    .join("")
                                    .slice(3) !== ""
                                    ? { backgroundColor: "var(--green-100)" }
                                    : undefined
                            }
                        >
                            <label
                                htmlFor="phone"
                                className="block text-900 font-medium mb-2"
                            >
                                Telefon
                            </label>
                            <InputMask
                                className={
                                    formik.errors.phone
                                        ? "w-full p-invalid"
                                        : "w-full"
                                }
                                mask="+36999999999"
                                autoClear={false}
                                id="phone"
                                {...formik.getFieldProps("phone")}
                            />

                            {formik.errors.phone ? (
                                <small className="p-error">
                                    {formik.errors.phone}
                                </small>
                            ) : null}
                        </div>
                    </div>
                    <div className="formgrid grid">
                        <div className="col-12 md:col-6">
                            <Button
                                icon="pi pi-search"
                                label="Keresés"
                                className="w-full mt-3"
                                type="submit"
                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <Button
                                icon="pi pi-search-minus"
                                label="Új Keresés"
                                className="w-full mt-3 p-button-help"
                                onClick={() => {
                                    formik.resetForm({
                                        values: {
                                            lastName: "",
                                            firstName: "",
                                            zip: "",
                                            companyName: "",
                                            companyType: "",
                                            vatNumber: "",
                                            email: "",
                                            phone: "",
                                        },
                                        errors: {},
                                    });
                                    setClubMemberList([]);
                                }}
                                type="reset"
                            />
                        </div>
                    </div>
                </form>
            </Panel>
            {/* TODO csak egyet jelenítsen meg, ha több találat van akkor notice */}
            {clubMemberList && clubMemberList.length > 0 ? (
                <Panel
                    className="pt-3 md:pt-4 lg:pt-5"
                    header="ZepterClub tag találat"
                >
                    <DataTable
                        value={clubMemberList}
                        size="small"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                        rows={20}
                        rowsPerPageOptions={[10, 20, 50]}
                        style={{
                            maxWidth: "87vw",
                            overflowX: "auto",
                        }}
                    >
                        <Column
                            field="vipNumber"
                            header="ZepterClub tagsági szám"
                        ></Column>
                        {/* TODO teljes név */}
                        <Column field="name" header="Név"></Column>
                        <Column field="zip" header="Irányítószám"></Column>
                        <Column field="city" header="Város"></Column>
                    </DataTable>
                </Panel>
            ) : null}
        </>
    );
};

export default ClubMemberSearch;
