import React from "react";
import { Navigate, Outlet } from "react-router";
import { getCurrentUser } from "../service/dashboard/AuthService";

const WelcomeLoginRoute: React.FC = () => {
    let user = getCurrentUser();
    //    if (user) {
    //      console.log('user ' + user.token);
    //    } else {
    //      console.log('user is null');
    //    }

    if (user) {
        return <Navigate to="/main/dashboard" replace />;
    }
    return <Outlet />;
};

export default WelcomeLoginRoute;
