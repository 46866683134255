import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useFormik } from "formik";
import * as Yup from "yup";
import StatusCodes from "http-status-codes";
import RightLayoutBenefits2 from "../../component/RightLayoutBenefits2";
import MessageDialog from "../../component/MessageDialog";
import { autoLogin, login } from "../../service/dashboard/AuthService";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Login.css";
import pageTeaserLoginImage from "../../asset/page-teaser-login.png";

const Login: React.FC = () => {
    //   console.log('Login is called');
    let [searchParams] = useSearchParams();
    const status: any = searchParams.get("status");
    const validationId: any = searchParams.get("validationid");

    let navigate = useNavigate();

    if (validationId && validationId !== "") {
        autoLogin(validationId).then(
            () => {
                navigate("/main/dashboard");
            },
            (error) => {
                //   console.log(error.response.status);
                const responseMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    setMessageDialogHeader("Hibás automatikus bejelentkezés!");
                    setMessageDialogBody("Hibás validációs link!");
                } else if (
                    error.response.status === StatusCodes.NOT_ACCEPTABLE
                ) {
                    setMessageDialogHeader("Kérjük validálja az e-mail címét!");
                    setMessageDialogBody(
                        "Bejelentkezés előtt kérjük fogadja el az e-mail-ben küldött validációs linket!"
                    );
                } else if (error.response.status === StatusCodes.FORBIDDEN) {
                    setMessageDialogHeader("További ellenőrzés szükséges!");
                    setMessageDialogBody(
                        "A Zepter adatbázisában több azonos adattal rendelkező találatunk volt, " +
                            "így további validálásra van szükségünk, amelyet kollégáink pár napon belül elvégeznek. " +
                            "Ez idő alatt még nem fog tudni belépni a zepterclub.hu felületre, hamarosan értesítjük!"
                    );
                } else if (error.response.status === StatusCodes.NOT_FOUND) {
                    setMessageDialogHeader("Hibás validálás!");
                    setMessageDialogBody(
                        "Ön már korábban validálta az e-mail címét, kérem jelentkezzen be az e-mail cím, jelszó párossal."
                    );
                } else {
                    setMessageDialogHeader("Hiba");
                    if (
                        responseMessage === "Network Error" ||
                        error.response.status === StatusCodes.METHOD_NOT_ALLOWED
                    ) {
                        setMessageDialogBody(
                            "Hálozati hiba, kérjük próbáljon meg pár perc múlva bejelentkezni."
                        );
                    } else {
                        setMessageDialogBody(responseMessage);
                    }
                }
                setMessageDialogError(true);
                setMessageDialogShow(Date.now());
            }
        );
    }

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, "Minimum 8 karakter kell, hogy legyen!")
                .required("Kötelező kitölteni!"),
            email: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás email cím!")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            setMessageDialogHeader("Értesítés");
            setMessageDialogBody("");
            //alert(JSON.stringify(values, null, 2));
            login(values.email, values.password).then(
                () => {
                    navigate("/main/dashboard");
                },
                (error) => {
                    //   console.log(error.response.status);
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (
                        error.response.status &&
                        error.response.status === StatusCodes.UNAUTHORIZED
                    ) {
                        setMessageDialogHeader("Hibás bejelentkezés!");
                        setMessageDialogBody(
                            "Hibás felhasználó név (e-mail cím) és/vagy jelszó!"
                        );
                    } else if (
                        error.response.status &&
                        error.response.status === StatusCodes.NOT_ACCEPTABLE
                    ) {
                        setMessageDialogHeader(
                            "Kérjük validálja az e-mail címét!"
                        );
                        setMessageDialogBody(
                            "Bejelentkezés előtt kérjük fogadja el az e-mail-ben küldött validációs linket!"
                        );
                    } else if (
                        error.response.status &&
                        error.response.status === StatusCodes.NOT_FOUND
                    ) {
                        setMessageDialogHeader("Hibás validálás!");
                        setMessageDialogBody(
                            "Hibás link vagy már korábban validálta az e-mail címét, kérem jelentkezzen be az e-mail cím, jelszó párossal vagy ellenőrizze a linket."
                        );
                    } else if (
                        error.response.status &&
                        error.response.status === StatusCodes.FORBIDDEN
                    ) {
                        setMessageDialogHeader("További ellenőrzés szükséges!");
                        setMessageDialogBody(
                            "A Zepter adatbázisában több azonos adattal rendelkező találatunk volt, " +
                                "így további validálásra van szükségünk, amelyet kollégáink pár napon belül elvégeznek. " +
                                "Ez idő alatt még nem fog tudni belépni a zepterclub.hu felületre, hamarosan értesítjük!"
                        );
                    } else {
                        setMessageDialogHeader("Hiba");
                        if (
                            responseMessage === "Network Error" ||
                            !error.response.status ||
                            error.response.status ===
                                StatusCodes.METHOD_NOT_ALLOWED
                        ) {
                            setMessageDialogBody(
                                "Hálozati hiba, kérjük próbáljon meg pár perc múlva bejelentkezni."
                            );
                        } else {
                            setMessageDialogBody(responseMessage);
                        }
                    }
                    setMessageDialogError(true);
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    return (
        <>
            <div className="px-4 md:px-6 lg:px-8">
                <MessageDialog
                    messageDialogError={messageDialogError}
                    messageDialogShow={messageDialogShow}
                    messageDialogHeader={messageDialogHeader}
                    messageDialogBody={messageDialogBody}
                />
                {status === "validated" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Gratulálunk!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen validálta az e-mail címét.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "passwordchanged" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Gratulálunk!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen beállította az új jelszavát.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "sentpasswordrequestemail" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Értesítés!
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                                Sikeresen elküldtük a jelszó beállításhoz
                                szükséges linket, kérjük ellenőrizze az e-mail
                                fiókját!
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "registrationrequired" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-red-100 border-round border-1 border-red-300">
                        <i className="pi pi-times-circle text-red-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-red-900 font-medium text-xl mb-2 line-height-1">
                                Regisztráció szükséges
                            </div>
                            <p className="m-0 p-0 text-red-700 line-height-3">
                                Sajnos a jelszó igénylésnél megadott e-mail cím
                                nem szerepel a ZepterClub hűségprogram
                                adatbázisában, így nem tudunk jelszót beállítani
                                a fiókjához, új regisztráció szükséges.
                                <br />
                                Regisztrációhoz kérjen meghívó linket bármely
                                klubtagunktól vagy tanácsadónktól!
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "connectionerror" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-red-100 border-round border-1 border-red-300">
                        <i className="pi pi-times-circle text-red-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-red-900 font-medium text-xl mb-2 line-height-1">
                                Kommunikációs hiba
                            </div>
                            <p className="m-0 p-0 text-red-700 line-height-3">
                                Sajnos hiba lépett fel a Zepter központi
                                rendszeréhez törénő csatlakozás során.
                                <br />
                                Kérjük próbáljon meg később belépni.
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "badrecommenderlink" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                        <i className="pi pi-times-circle text-yellow-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">
                                Hibás ajánlói link
                            </div>
                            <p className="m-0 p-0 text-yellow-700 line-height-3">
                                Kérjen új linket a regisztrációhoz!
                            </p>
                        </div>
                    </div>
                ) : null}
                {status === "unauthorized" ? (
                    <div className="mb-5 flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                        <i className="pi pi-times-circle text-yellow-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">
                                Lejárt a munkamenet
                            </div>
                            <p className="m-0 p-0 text-yellow-700 line-height-3">
                                Kérjük jelentkezzen be újra.
                            </p>
                        </div>
                    </div>
                ) : null}
                <div className="flex flex-wrap shadow-2">
                    <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                        <div className="col-12 md:col-6 lg:col-5">
                            <img
                                src={pageTeaserLoginImage}
                                className="responsive fadeinleft animation-duration-500"
                                alt="zepterclub.hu bejelentkezés kép"
                            />
                        </div>
                        <div className="col-12 md:col-6 lg:col-7">
                            <div className="text-xl text-black-alpha-90 font-500 mb-3 sm:mt-3">
                                ZepterClub Webiroda Bejelentkezés
                            </div>
                            <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-3">
                                Ha még nem tagunk, akkor kérjen ajánlói linket a
                                regisztrációhoz bármely klubtagunktól vagy
                                tanácsadónktól!
                            </p>
                        </div>
                    </div>
                    <div className="mt-0 w-full p-4 surface-card lg:w-6 lg:p-7">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="p-fluid"
                        >
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label
                                        htmlFor="email"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        E-mail
                                    </label>
                                    <InputText
                                        className="w-full mb-3"
                                        id="email"
                                        // value={formik.values.email}
                                        {...formik.getFieldProps("email")}
                                        onBlur={(val: any) => {
                                            const emailTrim =
                                                formik.values.email
                                                    .trim()
                                                    .toLocaleLowerCase();
                                            formik.setFieldValue(
                                                "email",
                                                emailTrim
                                            );
                                        }}
                                    />
                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                        <small className="p-error">
                                            {formik.errors.email}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <label
                                        htmlFor="password"
                                        className="block text-900 font-medium mb-2"
                                    >
                                        Jelszó
                                    </label>
                                    <Password
                                        id="password"
                                        className="w-full mb-3"
                                        feedback={false}
                                        {...formik.getFieldProps("password")}
                                    />
                                    {formik.touched.password &&
                                    formik.errors.password ? (
                                        <small className="p-error">
                                            {formik.errors.password}
                                        </small>
                                    ) : null}
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <Button
                                        className="w-full"
                                        label="Bejelentkezés"
                                        type="submit"
                                    />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-12 md:col-12">
                                    <Link to="/getpassword">
                                        Elfelejtette a jelszavát?
                                    </Link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <RightLayoutBenefits2 />
                </div>
            </div>
        </>
    );
};

export default Login;
