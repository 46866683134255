import axios from 'axios';
import { server } from '../../config/endpoints';

const API_URL = server;

export const readNumberOfClubMembers = () => {
    return axios
        .get(API_URL + '/api/cl100/count', {
            headers: {
                Accept: 'application/json',
            },
        })
        .then((response) => {
            // console.log(response);
            return response;
        });
};
