import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
    setLoadingOff,
    setLoadingOn,
} from "../../../../state/dashboard/mainSlice";
import { setProductAdminList } from "../../../../state/dashboard/adminListSlice";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../../service/dashboard/AuthService";
import {
    deleteProduct,
    readAllProducts,
    readProduct,
    saveProduct,
} from "../../../../service/dashboard/admin/webshop/ProductService";
import { readAllProductCategories } from "../../../../service/dashboard/admin/webshop/ProductCategoryService";
import { readAllDocuments } from "../../../../service/dashboard/admin/DocumentService";
import ProductImageAdminDetail from "./ProductImageAdminDetail";
import ProductPriceGroupAdminDetail from "./ProductPriceGroupAdminDetail";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Splitter, SplitterPanel } from "primereact/splitter";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { InputTextarea } from "primereact/inputtextarea";
// import parse from "html-react-parser";
import { Skeleton } from "primereact/skeleton";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";

const ProductAdminDetail: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();

    const toast = useRef(null);

    const [productCategoryList, setProductCategoryList] = useState<any>([]);
    const [documentList, setDocumentList] = useState<any>([]);
    const [productDto, setProductDto] = useState<any>();
    const [productAdminDetailDialogShow, setProductAdminDetailDialogShow] =
        useState<boolean>(false);
    const [productAdminDetailDialogChange, setProductAdminDetailDialogChange] =
        useState<number>(0);

    const productId: any = props.id;

    const [minStock, setMinStock] = useState<string>("");
    const [longDescriptionHTML, setLongDescriptionHTML] = useState<string>("");
    const [detailsHTML, setDetailsHTML] = useState<string>("");

    const richTextEditorModules = {
        toolbar: [
            [
                { header: [1, 2, 3, 4, false] },
                { size: ["small", false, "large", "huge"] },
            ],
            [
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                { color: [] },
                { background: [] },
            ],
            [
                { align: [] },
                { indent: "-1" },
                { indent: "+1" },
                { list: "ordered" },
                { list: "bullet" },
            ],
            ["link", "image", "clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const richTextEditorFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "align",
        "size",
        "color",
        "background",
        "link",
        "image",
    ];

    const refreshProductAdminList = () => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Termékek frissítése folyamatban.",
            })
        );
        readAllProducts().then(
            (response) => {
                console.log(response.data.productList);
                dispatch(setProductAdminList(response.data.productList));
                dispatch(setLoadingOff());
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
    };

    useEffect(() => {
        if (
            props.show === true &&
            props.change !== 0 &&
            props.change !== productAdminDetailDialogChange
        ) {
            setProductCategoryList([]);
            readAllProductCategories().then(
                (response) => {
                    const map = response.data.productCategoryList.map(
                        (productCategory: any) => {
                            return {
                                label: productCategory.name,
                                value: productCategory.id,
                            };
                        }
                    );
                    setProductCategoryList(map);
                },
                (error) => {
                    // console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
            setDocumentList([]);
            readAllDocuments().then(
                (response) => {
                    console.log(response);
                    const map = response.data.documentList.map(
                        (document: any) => {
                            return {
                                label: document.fileName,
                                value: document.id,
                            };
                        }
                    );
                    const emptyDocument = {
                        label: "Válasszon dokumentumot!",
                        value: "",
                    };
                    setDocumentList([emptyDocument, ...map]);
                },
                (error) => {
                    // console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
            setLongDescriptionHTML("");
            setDetailsHTML("");
            setProductDto(null);
            if (productId !== "") {
                readProduct(productId).then(
                    (response) => {
                        // response.data.minStock = parseInt(response.data.minStock);
                        // console.log(response.data);
                        // if (!response.data.minStock) {
                        //     response.data.minStock = "";
                        // }
                        response.data.productCategory =
                            response.data.productCategory.id;
                        console.log(response.data);
                        setProductDto(response.data);
                        setMinStock(response.data.minStock);
                        setLongDescriptionHTML(response.data.longDescription);
                        setDetailsHTML(response.data.details);
                        // console.log(formik.getFieldProps());
                    },
                    (error) => {
                        // console.log(error.response.status);
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setProductAdminDetailDialogChange(props.change);
            setProductAdminDetailDialogShow(true);
        }
    }, [
        productId,
        navigate,
        productAdminDetailDialogChange,
        props.change,
        props.show,
        dispatch,
    ]);

    const hideDialog = () => {
        setProductAdminDetailDialogShow(false);
        setProductDto(null);
        setLongDescriptionHTML("");
        setMinStock("");
        formik.resetForm();
        refreshProductAdminList();
    };

    const formik = useFormik({
        initialValues: {
            id: productDto && productDto.id ? productDto.id : "",
            name: productDto && productDto.name ? productDto.name : "",
            title: productDto && productDto.title ? productDto.title : "",
            itemNumber:
                productDto && productDto.itemNumber
                    ? productDto.itemNumber
                    : "",
            productCategory:
                productDto && productDto.productCategory
                    ? productDto.productCategory
                    : "",
            documentId:
                productDto && productDto.documentId
                    ? productDto.documentId
                    : "",
            minStock:
                productDto && productDto.minStock ? productDto.minStock : "",
            shortDescription:
                productDto && productDto.shortDescription
                    ? productDto.shortDescription
                    : "",
            longDescription:
                productDto && productDto.longDescription
                    ? productDto.longDescription
                    : "",
            details: productDto && productDto.details ? productDto.details : "",
            modifiedBy: user.email,
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object({
            name: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            title: Yup.string()
                .min(2, "A címnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            itemNumber: Yup.string()
                .min(4, "A cikkszámnak legalább 4 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            productCategory: Yup.string().required("Kötelező kitölteni!"), //TODO ID ki van-e töltve
            documentId: Yup.string().notRequired().nullable(true),
            minStock: Yup.string().notRequired().nullable(true),
            shortDescription: Yup.string()
                .min(
                    10,
                    "A rövid leírásnak legalább 10 karakternek kell lennie"
                )
                .required("Kötelező kitölteni!"),
            longDescription: Yup.string()
                .min(
                    10,
                    "A hosszú leírásnak legalább 10 karakternek kell lennie"
                )
                .required("Kötelező kitölteni!"),
            details: Yup.string()
                .min(10, "A részleteknek legalább 10 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
        }),
        onSubmit: (values) => {
            if (
                //TODO a kinti lista tud-e frissülni? Redux?
                productAdminDetailDialogShow &&
                window.confirm("Menti a módosításokat?")
            ) {
                console.log(values);
                if (values.minStock === "___" || values.minStock === "") {
                    values.minStock = "";
                } else {
                    values.minStock = parseInt(
                        values.minStock.split("_").join("")
                    ).toString();
                }
                values.productCategory = { id: values.productCategory };
                //alert(JSON.stringify(values, null, 2));
                console.log(values);
                saveProduct(values).then(
                    (response) => {
                        // console.log(response.data);
                        setProductDto(response.data);
                        //TODO message a terméket sikeresen elmentettük
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );
            } else {
            }
        },
    });

    return (
        <>
            <Toast ref={toast}></Toast>
            <Dialog
                modal
                style={{ width: "90vw" }}
                visible={productAdminDetailDialogShow}
                closable
                maximizable
                position="top"
                header="Termék adatok"
                onHide={() => hideDialog()}
            >
                {productId && productId !== "" && formik.values.id === "" ? (
                    <div className="loadingSpinner">
                        <ProgressSpinner />
                    </div>
                ) : (
                    <TabView className="tabview-header-icon">
                        <TabPanel header="Adatok" leftIcon="pi pi-file">
                            <form
                                onSubmit={formik.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-3">
                                        <label
                                            htmlFor="itemNumber"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cikkszám
                                        </label>
                                        <InputText
                                            className={
                                                formik.errors.itemNumber
                                                    ? "w-full p-invalid"
                                                    : "w-full"
                                            }
                                            id="itemNumber"
                                            {...formik.getFieldProps(
                                                "itemNumber"
                                            )}
                                        />
                                        {formik.errors.itemNumber ? (
                                            <small className="p-error">
                                                {formik.errors.itemNumber.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-4">
                                        <label
                                            htmlFor="name"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Név
                                        </label>
                                        <InputText
                                            className={
                                                formik.errors.name
                                                    ? "w-full p-invalid"
                                                    : "w-full"
                                            }
                                            id="name"
                                            {...formik.getFieldProps("name")}
                                        />
                                        {formik.errors.name ? (
                                            <small className="p-error">
                                                {formik.errors.name.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-5">
                                        <label
                                            htmlFor="title"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cím
                                        </label>
                                        <InputText
                                            className={
                                                formik.errors.title
                                                    ? "w-full p-invalid"
                                                    : "w-full"
                                            }
                                            id="title"
                                            {...formik.getFieldProps("title")}
                                        />
                                        {formik.errors.title ? (
                                            <small className="p-error">
                                                {formik.errors.title.toString()}
                                            </small>
                                        ) : null}
                                    </div>

                                    <div className="field col-12 md:col-3">
                                        <label
                                            htmlFor="productCategory"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Kategória
                                        </label>
                                        {productCategoryList &&
                                        productCategoryList.length !== 0 ? (
                                            <Dropdown
                                                className={
                                                    formik.errors
                                                        .productCategory
                                                        ? "w-full p-invalid"
                                                        : "w-full"
                                                }
                                                // {...formik.getFieldProps(
                                                //     "productCategory"
                                                // )}
                                                options={productCategoryList}
                                                // onChange={formik.handleChange}
                                                onChange={(event: {
                                                    value: any;
                                                }) => {
                                                    formik.setFieldValue(
                                                        "productCategory",
                                                        event.value
                                                    );
                                                }}
                                                value={
                                                    formik.values
                                                        .productCategory
                                                }
                                                placeholder="Válasszon kategóriát!"
                                            />
                                        ) : (
                                            <Skeleton height="45px" />
                                        )}
                                        {formik.errors.productCategory ? (
                                            <small className="p-error">
                                                {formik.errors.productCategory.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="doucumentId"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            pdf dokumentum
                                        </label>
                                        {documentList &&
                                        documentList.length !== 0 ? (
                                            <Dropdown
                                                className={
                                                    formik.errors.documentId
                                                        ? "w-full p-invalid"
                                                        : "w-full"
                                                }
                                                {...formik.getFieldProps(
                                                    "documentId"
                                                )}
                                                options={documentList}
                                                onChange={formik.handleChange}
                                                value={formik.values.documentId}
                                                placeholder="Válasszon dokumentumot!"
                                            />
                                        ) : (
                                            <Skeleton height="45px" />
                                        )}
                                        {formik.errors.documentId ? (
                                            <small className="p-error">
                                                {formik.errors.documentId.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-3">
                                        <label
                                            htmlFor="minStock"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Minimum készlet
                                        </label>
                                        <InputMask
                                            className={
                                                formik.errors.minStock
                                                    ? "w-full p-invalid"
                                                    : "w-full"
                                            }
                                            mask="999"
                                            id="minStock"
                                            value={minStock}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "minStock",
                                                    e.target.value
                                                );
                                                if (e.target.value) {
                                                    setMinStock(e.target.value);
                                                } else {
                                                    setMinStock("");
                                                }
                                            }}
                                            autoClear={false}
                                        />
                                        {formik.errors.minStock ? (
                                            <small className="p-error">
                                                {formik.errors.minStock.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="shortDescription"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Rövid leírás
                                        </label>
                                        <InputText
                                            className={
                                                formik.errors.shortDescription
                                                    ? "w-full p-invalid"
                                                    : "w-full"
                                            }
                                            id="shortDescription"
                                            {...formik.getFieldProps(
                                                "shortDescription"
                                            )}
                                        />
                                        {formik.errors.shortDescription ? (
                                            <small className="p-error">
                                                {formik.errors.shortDescription.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="longDescription"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Hosszú Leírás
                                        </label>
                                        <Splitter
                                            className={
                                                formik.errors.longDescription
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                            style={{ height: "320px" }}
                                        >
                                            <SplitterPanel className="flex align-items-left justify-content-top">
                                                <div className="editor h-full w-full">
                                                    <ReactQuill
                                                        id="longDescription"
                                                        theme="snow"
                                                        modules={
                                                            richTextEditorModules
                                                        }
                                                        formats={
                                                            richTextEditorFormats
                                                        }
                                                        value={
                                                            formik.values
                                                                .longDescription
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "longDescription",
                                                                e
                                                            );
                                                            setLongDescriptionHTML(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </SplitterPanel>
                                            <SplitterPanel className="flex align-items-left justify-content-top">
                                                <InputTextarea
                                                    id="longDescriptionHTML"
                                                    className="w-full h-full"
                                                    value={longDescriptionHTML}
                                                    onChange={(e) => {
                                                        setLongDescriptionHTML(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onBlur={(e) => {
                                                        // console.log(e);
                                                        // formik.setFieldValue(
                                                        //     "description",
                                                        //     e.target.innerText
                                                        // );
                                                        formik.setFieldValue(
                                                            "longDescription",
                                                            longDescriptionHTML
                                                        );
                                                    }}
                                                />
                                            </SplitterPanel>
                                        </Splitter>
                                        {formik.errors.longDescription ? (
                                            <small className="p-error">
                                                {formik.errors.longDescription.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="details"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Részletek
                                        </label>
                                        <Splitter
                                            className={
                                                formik.errors.details
                                                    ? "p-invalid"
                                                    : ""
                                            }
                                            style={{ height: "320px" }}
                                        >
                                            <SplitterPanel className="h-full flex align-items-left justify-content-top">
                                                <div className="editor h-full w-full">
                                                    <ReactQuill
                                                        theme="snow"
                                                        modules={
                                                            richTextEditorModules
                                                        }
                                                        formats={
                                                            richTextEditorFormats
                                                        }
                                                        id="detailsn"
                                                        value={
                                                            formik.values
                                                                .details
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "details",
                                                                e
                                                            );
                                                            setDetailsHTML(e);
                                                        }}
                                                    />
                                                </div>
                                            </SplitterPanel>
                                            <SplitterPanel className="flex align-items-left justify-content-top">
                                                <InputTextarea
                                                    className="h-full w-full"
                                                    id="detailsHTML"
                                                    value={detailsHTML}
                                                    onChange={(e) => {
                                                        setDetailsHTML(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onBlur={(e) => {
                                                        // console.log(e);
                                                        // formik.setFieldValue(
                                                        //     "description",
                                                        //     e.target.innerText
                                                        // );
                                                        formik.setFieldValue(
                                                            "details",
                                                            detailsHTML
                                                        );
                                                    }}
                                                />
                                            </SplitterPanel>
                                        </Splitter>
                                        {formik.errors.details ? (
                                            <small className="p-error">
                                                {formik.errors.details.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                {/* <Divider /> */}
                                {/* <div className="ql-editor">
{longDescriptionHTML ? parse(longDescriptionHTML) : null}
</div> */}
                                <Divider />
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-4 lg:col-3">
                                        <Button
                                            className="p-button-secondary"
                                            type="button"
                                            label="Bezárás"
                                            icon="pi pi-arrow-circle-left"
                                            onClick={() => {
                                                hideDialog();
                                            }}
                                        />
                                    </div>
                                    {/* TODO jogosultság reduxe */}
                                    <div className="field col-12 md:col-4 lg:col-3">
                                        <Button
                                            className="p-button-success"
                                            type="submit"
                                            label="Mentés"
                                            icon="pi pi-check"
                                        />
                                    </div>
                                    <div className="field col-12 md:col-4 lg:col-3">
                                        <Button
                                            className="p-button-danger"
                                            type="button"
                                            label="Törlés"
                                            icon="pi pi-trash"
                                            onClick={() => {
                                                if (
                                                    //TODO a lista tud-e frissülni? Redux?
                                                    window.confirm(
                                                        "Biztos törli a terméket?"
                                                    )
                                                ) {
                                                    // TODO hibaüzenet/hibakód, ha van kép vagy ár hozzákapcsolva
                                                    deleteProduct(
                                                        productDto.id
                                                    ).then(
                                                        (response) => {
                                                            console.log(
                                                                response.data
                                                            );
                                                        },
                                                        (error) => {
                                                            //TODO Conflict
                                                            console.log(error);
                                                            // TODO esetleg nem bezárni az ablakot és hibaüzenet
                                                        }
                                                    );
                                                    hideDialog();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        {/* TODO csak akkor hozzáférhetőek a tab-ok ha van id */}
                        <TabPanel header="Képek" leftIcon="pi pi-images">
                            <ProductImageAdminDetail id={productId} />
                        </TabPanel>
                        <TabPanel header="Árak" leftIcon="pi pi-money-bill">
                            <ProductPriceGroupAdminDetail id={productId} />
                        </TabPanel>
                    </TabView>
                )}
            </Dialog>
        </>
    );
};

export default ProductAdminDetail;
