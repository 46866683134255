import mybPartnerFacebookGroupBannerSmall from "../../asset/myb_partner_fb_group_banner_small.png";

const MYBFB: React.FC = () => {
    return (
        <div className="flex align-items-center justify-content-center ml-3 mr-3 mb-1 md:ml-0 md:mr-0">
            <a
                target="_blank"
                href="https://www.facebook.com/groups/mybasics"
                rel="noreferrer"
            >
                <img
                    className="max-w-full mb-3 border-round-xl cursor-pointer fadeinup animation-duration-500"
                    src={mybPartnerFacebookGroupBannerSmall}
                    alt="MYB zárt Facebook csoport"
                />
            </a>
        </div>
    );
};

export default MYBFB;
