import axios from 'axios';
import { server } from '../../../../config/endpoints';

const API_URL = server;

let createProductImageRequest = (formData: any) => {
    const result = {
        id: formData.id,
        productId: formData.productId,
        orderNo: formData.orderNo,
        image: formData.image,
        modifiedBy: formData.modifiedBy,
    };
    // console.log(formData.authenticated);
    return result;
};

//admin
//order list miatt
// export const readProductAllThumbnails = (productId: string) => {
//     return axios
//         .get(API_URL + '/protected/admin/product/' + productId + '/thumbnail/')
//         .then((response) => {
//             return response;
//         });
// };

//Ok RENDBEN
// (lehet, hogy ez is megy bele a részletek lekérdezésbe)
// ez az összes thumbanilt, imaget és ordert is visszadja az adott termékhez.
// export const readProductAllImages = (productId: string) => {
//     return axios
//         .get(API_URL + '/protected/product/' + productId + '/image/')
//         .then((response) => {
//             return response;
//         });
// };

//RENDBEN
// (kártyához kell)
// az adott teméket elkéri
// export const readProductMainImage = (productId: string) => {
//     return axios
//         .get(API_URL + '/protected/product/' + productId + '/mainimage/')
//         .then((response) => {
//             return response;
//         });
// };

//OK RENDBEN
export const saveProductImage = (formData: any) => {
    let request = createProductImageRequest(formData);
    return axios
        .post(API_URL + '/protected/admin/productimage/', request, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            return response;
        });
};

//sorrend módosítása
export const saveProductImageOrderList = (orderList: any) => {
    return axios
        .post(API_URL + '/protected/admin/productimage/orderlist', orderList)
        .then((response) => {
            return response;
        });
};

//OK RENDBEN
export const deleteProductImage = (imageId: string) => {
    //TODO szerver oldal --> van aki hivatkozik rá?
    return axios
        .delete(API_URL + '/protected/admin/productimage/' + imageId)
        .then((response) => {
            return response;
        });
};

// export const displayProductImage = (fileName: string) => {
//     //protected & nincs token --> token
//     return axios.get(API_URL + '/api/document/' + fileName).then((response) => {
//         return response;
//     });
// };
