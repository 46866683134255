import "../../css/GeneralTerms.css";

const GeneralTermsCMOPPNYJ2023Q2 = (props: any) => {
    return (
        <>
            <div className="flex flex-wrap shadow-2">
                <div className="w-full lg:w-12 px-3 py-3 lg:p-7 bg-blue-50">
                    <div className="text-xl text-black-alpha-90 font-500 mt-3 mb-3 text-center">
                        NYEREMÉNYJÁTÉK
                        <br />
                        <br />
                        Játékszabályzat Zepter Klubtag és Online Partner
                        ösztönző programhoz kapcsolódó nyereményjátékhoz
                        <br />
                        <br />
                        Játékszabályzat 2023.04.01. – 2023.06.30.
                    </div>
                    <ol>
                        <li>
                            <span>A Játék</span>
                            <p>
                                Zepter Ungarn Kft. (Zepter Ungarn Kft.,
                                székhely: 1138 Budapest, Váci út 191..; Cg.:
                                01-09-076039; adószám: 10537361-2-41) a
                                továbbiakban: „Szervező” vagy „Zepter”)
                                nyereményjátékot szervez (a továbbiakban:
                                Nyereményjáték) a BizzClub hűségprogramban
                                továbbá ennek keretében a Online Partner
                                programban részt vevő, Online Partner Tanácsadók
                                részére. A Nyereményjáték célja, hogy a Club
                                Live 100 hűségprogram és Online Partner program
                                eredményességének növelése. A Nyereményjátékban
                                történő részvétel feltétele a jelen
                                Nyereményjáték feltételek elfogadása. A
                                Nyereményjátékban minden Klubtag és Online
                                Partner Tanácsadó részt vesz, aki kifejezetten
                                nem nyilatkozott a részvétel elutasításáról, és
                                teljesítette a Nyereményjáték feltételeit.
                            </p>
                        </li>
                        <li>
                            <span>Nyereményjátékban résztvevő személyek</span>
                            <p>
                                A Nyereményjátékban részt vehet minden 18.
                                életévét betöltött devizabelföldi, magyar
                                állampolgár, természetes személy, továbbá jogi
                                személy vagy jogi személyiséggel nem rendelkező
                                társaság, amennyiben Klubtagnak vagy Online
                                Partner Tanácsadónak minősül és teljesíti a
                                nyereményjáték feltételeit, kivéve a Szervező
                                megbízásából a Nyereményjáték előkészítésében és
                                lebonyolításában közreműködő személyek és cégek
                                vezető tisztségviselői, munkavállalói,
                                megbízottjai, valamint ezen személyeknek a Ptk.
                                fentebb hivatkozott közeli hozzátartozói.
                            </p>
                        </li>
                        <li>
                            <span>Feltételek, időtartam</span>
                            <p>
                                Nyereményjáték 2023.04.01 00:00 órától
                                2023.06.30. 23:59 óráig tart. A Nyereményjáték
                                megkezdése előtt, és a Nyereményjáték befejezése
                                után teljesített feltételek érvénytelenek.
                            </p>
                            <span>
                                Nyereményjátékon történő részvétel feltétele:
                            </span>
                            <ol>
                                <li>
                                    Nyereményjáték tartama alatt a{" "}
                                    <a
                                        href="https://zepterclub.hu/login"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        www.zepterclub.hu
                                    </a>{" "}
                                    felületen a BizzClub hűségprogramba történő
                                    regisztráció és klubtaggá válás.
                                </li>
                                <li>
                                    BizzClub Klubtag Nyereményjáték tartama
                                    alatt Online Partner Tanácsadóvá válik.
                                </li>
                                <li>
                                    Online Partner Tanácsadó további Klubtagot
                                    léptet be és az új belépő Online Partner
                                    Tanácsadóvá válik.
                                </li>
                                <p>
                                    Bármelyik fenti feltétel teljesítés a
                                    Nyereményjátékon történő részvételre
                                    jogosít.
                                </p>
                            </ol>
                        </li>
                        <li>
                            <span>Sorsolás menete</span>
                            <p>
                                <strong>
                                    A Nyereményjáték sorsolás időpontja és
                                    helyszíne: 2023.07.21. 11:00 napja
                                    elektronikus sorsolással történik, Zepter
                                    budapesti székhelyén, a 1138 Budapest, Váci
                                    út 191. cím alatt. Szervező a nyeremény
                                    kategóriák sorrendjében 1 nyertest és 1
                                    tartalék nyertest húz.
                                </strong>
                            </p>
                        </li>
                        <li>
                            <span>Sorsolás nyereménye</span>
                            <p>
                                A Szervező a sorsolás alkalmával 1 nyertest és 1
                                tartaléknyertest sorsol Nyeremény
                                kategóriánként. Amennyiben a nyertes bármely
                                okból nem jogosult a nyereményre, vagy 30 napon
                                belül nem elérhető, úgy a Szervező értesíti és
                                felkéri a nyeremény átvételére az adott
                                kategóriához kisorsolt, soron következő
                                tartaléknyertest.
                            </p>
                            <p>
                                A Szervező a sorsolást követően e-mailben
                                értesíti Nyertest a sorsolást követő 10 napon
                                belül.
                            </p>
                            <p>
                                A Nyeremény átadása a sorsolást követő 30 napon
                                belül történik.
                            </p>
                            <p>
                                Az a BizzClub Klubtag, aki több új Online
                                Partner Tanácsadót léptet be az Online Partner
                                programba, az új Online Partner Tanácsadónként
                                további 1 nyereménykuponnal vesz részt a 3.
                                kategória sorsolásán.{" "}
                            </p>
                            <span>Nyeremény kategóriák:</span>
                            <ol>
                                <li>
                                    Kategória: BizzClub hűségprogramba történő
                                    regisztráció és klubtaggá válás esetén
                                    <ul>
                                        <li>
                                            1 db ION-03 MyIonZ Pro hordható
                                            légtisztító készüléket 139.900 Ft
                                            értékben;
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Kategória: BizzClub Klubtag Nyereményjáték
                                    tartama alatt Online Partner Tanácsadóvá
                                    válik.
                                    <ul>
                                        <li>
                                            1 db TAS-100 Therapy Air Smart
                                            légtisztító készüléket 459.900 Ft
                                            értékben;
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Kategória: új Online Partner Tanácsadó
                                    beléptetése
                                    <ul>
                                        <li>
                                            1 db BL-002 ArtMix Pro 299.900 Ft
                                            értékben;
                                        </li>
                                        <li>
                                            1 db Z4L Bioptron MedoLight 299.900
                                            Ft értékben;
                                        </li>
                                        <li>
                                            1 db DP-4070 QuanoMed Doppio 199.900
                                            Ft értékben;
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                A nyertesek a nyeremény átvételére az értesítést
                                követően, de legkésőbb a sorsolást követő 30
                                napon belül jogosultak. Amennyiben a nyeremény
                                átvételére jogosult Játékos a nyereményét a
                                rendelkezésére álló határidőn belül nem venné
                                át, úgy a Szervező a fentiek szerint értesíti a
                                tartaléknyertest. Tartaléknyertes kiesése esetén
                                a Szervező nem köteles további tartaléknyertest
                                sorsolni. A játékban résztvevők vállalják, hogy
                                nyertességük esetén jogosultságukat személyi
                                azonosító irattal igazolják, és együttműködnek a
                                Szervezővel annak érdekében, hogy a nyeremény
                                átadása legkésőbb az adott sorsolást követő 30
                                napon belül megtörténjen. Amennyiben a nyertes
                                cselekvőképességében korlátozott vagy
                                cselekvőképtelen, úgy a nyereményével
                                kapcsolatos érdemi ügyintézésre vagy a nyeremény
                                átvételére nevében kizárólag törvényes
                                képviselője járhat el.
                            </p>
                            <p>A nyeremény pénzre nem váltható át.</p>
                            <p>
                                A nyerteseket a nyereménnyel kapcsolatosan nem
                                terheli személyi jövedelemadó-fizetési
                                kötelezettség. A nyeremény átvételével és
                                felhasználásával összefüggésben esetlegesen
                                felmerülő egyéb költségek és kiadások (pl.
                                nyeremény átadásának helyszínére történő utazás
                                költsége) a nyertest terhelik.
                            </p>
                        </li>
                        <li>
                            <span>Egyéb rendelkezések</span>
                            <p>
                                Szervező a Játékkal kapcsolatos esetleges
                                módosításokat vagy annak visszavonását a{" "}
                                <a
                                    href="https://zepterclub.hu/login"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    www.zepterclub.hu
                                </a>{" "}
                                oldalon teszi közzé. Jelen nyereményjátékra a
                                szerencsejáték szervezéséről szóló 1991. évi
                                XXXIV. törvényben foglaltak irányadók.
                            </p>
                        </li>
                        <li>
                            <span>Adatkezeléshez hozzájárulás</span>
                            <p>
                                A Szervező minden személyes adatot, amelyhez a
                                Nyereményjáték során jutnak hozzá, bizalmasan és
                                a hatályos adatvédelmi szabályoknak, valamint
                                jelen Szabályzatban foglaltaknak megfelelően
                                kezelnek. Az adatkezelési tájékoztató a jelen
                                Szabályzat 1. sz. mellékletét képezi.
                            </p>
                        </li>
                    </ol>
                    <span>Budapest, 2023. április 01.</span>

                    <hr />
                    <div className="text-xl text-black-alpha-90 font-500 mb-3 text-center">
                        1. számú Melléklet
                        <br />
                        <strong>Adatkezelési tájékoztató</strong>
                    </div>
                    <p>
                        Adatkezelési tájékoztató Zepter 2023.04.01. – 2023.06.30
                        között szervezett Nyereményjátékhoz kapcsolódóan
                        <br />
                        <span>Adatkezelő:</span> Zepter Ungarn Kft. (székhely:
                        1138 Budapest, Váci út 191.; cjsz.: 01-09-076039;
                        Adószám: 10537361-2-41), a továbbiakban “Adatkezelő”.{" "}
                        <span>Adattovábbítás:</span> Adatkezelő által történő
                        adatfelvétel esetén az adatokat az adatkezelő kezeli. Az
                        adattovábbításra nem kerül sor (azaz nem kerül sor
                        harmadik országba történő adatátadásra).
                        <br />
                        <span>
                            Adatkezelés célja, jogalapja, kezelt adatok köre
                            (adatkategóriák), adatkezelés időtartama:
                            Adatkezelés célja:
                        </span>{" "}
                        <span>
                            Promóciós nyereményjáték lebonyolítása, játékosok
                            nyilvántartása, nyeremények átadása.
                        </span>{" "}
                        Adatkezelés jogalapja: GDPR 6.§. (1) b. és c. pont –
                        nyereményjátékra vonatkozó szerződés teljesítése,
                        résztvevők nyilvántartása, megkülönböztetése, sorsolás
                        szervezése, nyertes és tartaléknyertes nyilvántartása,
                        nyertes és/vagy tartaléknyertes nevének és
                        irányítószámának nyilvánosságra hozatala;
                        <br />
                        <span>Adatkategória:</span> teljes név, e-mail cím,
                        telefonszám, irányítószám, nyeremény esetén nyertes
                        adatai, termékadatok, nyereményjáték azonosítói; jogi
                        személyek vagy jogi személyiség nélküli szervezetek
                        esetében cégnév, adószám, székhely, kapcsolattartó neve,
                        e-mail cím és telefonszám. Adatkezelés időtartama: a
                        nyereményjáték lezárultától számított 1 év + 60 nap,
                        nyertes esetében a jótállási időt követő 5 év és 60 nap.
                        A sorsolást megelőzően a nyereményjátékból kilépés
                        esetén jogi kötelezettség teljesítése miatt 5 év + 60
                        nap.
                        <br />
                        <span>Adatfeldolgozó:</span> Adatkezelő munkatársai
                        és/vagy megbízottai végzik a jelen hozzájárulás alapján
                        átadott adatokkal kapcsolatos tevékenységeket. Bizonyos
                        esetekben, az erről történő megfelelő tájékoztatás
                        mellett Adatfeldolgozó a címzettek csoportjait jelöli
                        meg, mint lehetséges adatfeldolgozó. Címzettek egy
                        csoportja esetében a csoport tagjai az adatkezelési
                        időtartam alatt a gazdasági szükségszerűség miatt akár
                        több alkalommal is változhatnak, a csoport tagjainak
                        fluktuációja miatt pedig Adatkezelő valamennyi
                        adatgyűjtést megelőzően nem, vagy nem teljeskörűen,
                        avagy nem átlátható (jelentős mennyiségre figyelemmel)
                        módon tudná csak tájékoztatni az érintettet a konkrét
                        adatfeldolgozó személyéről. Amennyiben azonban az
                        érintett tájékoztatást kér arra vonatkozóan, hogy
                        Adatkezelő a tevékenysége során konkrétan mely
                        csoporttag részére adott át személyes adatot, úgy az
                        érintett kérésére tájékoztatást nyújt.
                        <br />
                        <span>Önkéntes hozzájárulás:</span> A jelen adatkezelés
                        önkéntes. A hozzájárulás bármikor, korlátozás nélkül
                        visszavonható, azzal, hogy a hozzájárulás visszavonása
                        előtt végrehajtott adatkezelés jogszerűségét a
                        visszavonás nem érinti. A Nyereményjátékhoz kapcsolódó
                        adatkezelési hozzájárulás visszavonása egyúttal a
                        Nyereményjátékban történő részvétel megszüntetését is
                        jelenti. Amennyiben a hozzájárulást visszavonja, úgy az
                        adatait a jogszerű adatkezelést igazoló nyilvántartásba
                        veszi fel az Adatkezelő.
                        <br />
                        <span>Profilalkotás:</span> Adatkezelő az adatkezelés
                        során profilalkotást nem végez.
                        <br />
                        <span>Érintett jogai:</span> A helyesbítéshez való jog:
                        Az adatai pontosítását, javítását, helyesbítését
                        kérheti.
                        <br />
                        <span>A törléshez való jog:</span> Bizonyos esetekben
                        kérheti, hogy személyes adatai törlésre kerüljenek, így
                        az eredeti adatkezelési cél érdekében már nincs szükség
                        az adatai tárolására, vagy hozzájáruláson alapuló
                        adatkezelés esetén visszavonja hozzájárulását, feltéve,
                        hogy az adatok törlését jogszabály vagy egyéb jogi
                        kötelezettség teljesítése nem zárja ki.
                        <br />
                        <span>Az adatkezelés korlátozásához való jog:</span>
                        Bizonyos esetekben joga van kérni az adatok zárolását
                        (fel nem használását) valamely intézkedés
                        megtörténtének, vagy joga gyakorlásának elbírálásáig.
                        <br />
                        <span>Adathordozhatósághoz való jog:</span> Önnek joga
                        van kérni elektronikus adatkezelés esetén, hogy adjuk át
                        személyes adatait az Ön által kijelölt harmadik fél
                        részére. Az adatokat „.xls” vagy „.pdf” fájlformátumban
                        adjuk ki.
                        <br />
                        <span>Tiltakozáshoz való jog:</span> Ön tiltakozhat
                        adatai kezelése ellen, amennyiben úgy látja, egyes
                        adatkezelési cél érdekében már szükségtelen a további
                        adatkezelés, kivéve ha az adatkezelő igazolja, hogy az
                        adatkezelés jogszerű és szükséges.
                        <br />
                        <span>Felügyeleti hatóság:</span> Ön jogosult az
                        adatkezeléssel szemben panasszal a Felügyeleti
                        hatósághoz fordulni. A felügyeleti hatóság a Nemzeti
                        Adatvédelmi és Információszabadság Hatóság.
                        <br />
                        Nemzeti Adatvédelmi és Információszabadság Hatóság
                        elérhetőségei:
                        <br />
                        cím: 1055 Budapest, Falk Miksa u. 9-11. | telefon:
                        +36-1-391-1400 | fax: +36 -1-391-1410 | e-mail:
                        <a href="mailto:ugyfelszolgalat@naih.hu">
                            ugyfelszolgalat@naih.hu
                        </a>{" "}
                        | internetcím:{" "}
                        <a
                            href="https://naih.hu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://naih.hu
                        </a>
                        <br />
                        <span>Irányadó jog és szabályzat:</span> A személyes
                        adatok kezelésére a természetes személyeknek a személyes
                        adatok kezelése tekintetében történő védelméről és az
                        ilyen adatok szabad áramlásáról, valamint a 95/46/EK
                        rendelet hatályon kívül helyezéséről szóló az EURÓPAI
                        PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (“GDPR”
                        vagy “általános adatvédelmi rendelet”) az irányadó. Az
                        adatvédelmi szabályzat az Adatkezelő{" "}
                        <a
                            href="http://www.zeptermagazin.hu/adatvedelem"
                            target="_blank"
                            rel="noreferrer"
                        >
                            http://www.zeptermagazin.hu/adatvedelem
                        </a>{" "}
                        honlapján, székhelyén valamint egyébként a kereskedő
                        munkatársaknál, megbízottaknál minden esetben elérhető.
                    </p>
                </div>
            </div>
        </>
    );
};

export default GeneralTermsCMOPPNYJ2023Q2;
