import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setDocumentAdminList } from "../../../state/dashboard/adminListSlice";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../service/dashboard/AuthService";
import {
    readDocument,
    saveDocument,
    deleteDocument,
    readAllDocuments,
} from "../../../service/dashboard/admin/DocumentService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";

const DocumentAdminDetail: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();
    const [documentDto, setDocumentDto] = useState<any>();
    const [documentPreviewLink, setDocumentPreviewLink] = useState<string>("");
    const [documentAdminDetailDialogShow, setDocumentAdminDetailDialogShow] =
        useState<boolean>(false);
    const [
        documentAdminDetailDialogChange,
        setDocumentAdminDetailDialogChange,
    ] = useState<number>(0);
    const [blockedPage, setBlockedPage] = useState<boolean>(false);
    const [authenticatedChecked, setAuthenticatedChecked] =
        useState<boolean>(true);
    const [uploadFileName, setUploadFileName] = useState<string>("");
    const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInputRef.current) hiddenFileInputRef.current.click();
    };

    const documentId: any = props.id;

    const hideDialog = () => {
        setDocumentAdminDetailDialogShow(false);
        setDocumentDto(null);
        setUploadFileName("");
        setAuthenticatedChecked(true);
        setBlockedPage(false);
        formik.resetForm();
        refreshDocumentAdminList();
    };

    const refreshDocumentAdminList = () => {
        readAllDocuments().then(
            (response) => {
                dispatch(
                    setDocumentAdminList(
                        response.data.documentList.map((document: any) => {
                            if (document.authenticated) {
                                document.authenticated = "🔒védett";
                            } else {
                                document.authenticated = "publikus";
                            }
                            return document;
                        })
                    )
                );
                //TODO használja megjeleníteni
                // console.log(response.data.documentList);
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    useEffect(() => {
        if (
            props.show === true &&
            props.change !== 0 &&
            props.change !== documentAdminDetailDialogChange
        ) {
            if (documentId === "") {
                setDocumentDto(null);
                setUploadFileName("");
                setDocumentPreviewLink("");
                setAuthenticatedChecked(true);
            } else {
                readDocument(documentId).then(
                    (response) => {
                        // console.log(response.data);
                        setDocumentDto(response.data);
                        setUploadFileName(response.data.fileName);
                        // setDocumentPreviewLink()
                        setAuthenticatedChecked(response.data.authenticated);
                        if (response.data.authenticated) {
                            setDocumentPreviewLink(
                                "https://zepterclub.hu/cl100-srv/protected/document/" +
                                    response.data.fileName
                            );
                        } else {
                            setDocumentPreviewLink(
                                "https://zepterclub.hu/cl100-srv/api/document/" +
                                    response.data.fileName
                            );
                        }
                    },
                    (error) => {
                        console.log(error.response.status);
                        if (
                            error.response.status === StatusCodes.UNAUTHORIZED
                        ) {
                            //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                            logout();
                            navigate("/login?status=unauthorized");
                        }
                    }
                );
            }
            setDocumentAdminDetailDialogChange(props.change);
            setDocumentAdminDetailDialogShow(true);
        }
    }, [
        navigate,
        props,
        props.change,
        props.show,
        documentId,
        documentAdminDetailDialogChange,
    ]);

    const formik = useFormik({
        initialValues: {
            id: documentDto && documentDto.id ? documentDto.id : "",
            fileName:
                documentDto && documentDto.fileName ? documentDto.fileName : "",
            content:
                documentDto && documentDto.content ? documentDto.content : null,
            authenticated:
                documentDto && documentDto.authenticated
                    ? documentDto.authenticated.toString()
                    : "true",
            modifiedBy: user.email,
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: Yup.object({
            //TODO uniqe fájlnév ellenőrzése
            fileName: Yup.string()
                .min(2, "A névnek legalább 2 karakternek kell lennie")
                .required("Kötelező kitölteni!"),
            content: Yup.mixed()
                .nullable()
                .test("REQUIRED", "Kötelező kitölteni!", (value) => {
                    // console.log(value);
                    if (documentId === "") {
                        return value && value.size;
                    } else {
                        return true;
                    }
                })
                .test(
                    "FILE_SIZE",
                    "A feltöltött fájl túl nagy, maximum 10 MB lehet!",
                    (value) => {
                        if (value) {
                            return value && value.size <= 10485760; //10 MB
                        } else if (documentId !== "") {
                            return true;
                        }
                    }
                )
                .test(
                    "FILE_FORMAT",
                    "A feltöltött fájl nem pdf formátumú!",
                    (value) => {
                        if (value) {
                            return (
                                value && value.type.includes("application/pdf")
                            );
                        } else if (documentId !== "") {
                            return true;
                        }
                    }
                ),
            authenticated: Yup.string().required(),
        }),
        onSubmit: (values) => {
            if (
                //TODO a kinti lista tud-e frissülni? Redux?
                documentAdminDetailDialogShow &&
                window.confirm("Menti a módosításokat?")
            ) {
                setBlockedPage(true);
                //TODO feltöltés, kis türelmet
                // console.log(values);
                //alert(JSON.stringify(values, null, 2));
                saveDocument(values).then(
                    (response) => {
                        // console.log(response.data);
                        setDocumentDto(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );
            }
        },
    });

    return (
        <Dialog
            modal
            style={{ width: "90vw" }}
            visible={documentAdminDetailDialogShow}
            closable
            maximizable
            position="top"
            header="Dokumentum adatok"
            onHide={() => {
                hideDialog();
            }}
        >
            <BlockUI
                blocked={blockedPage}
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        Kérem várjon a fájlfeltöltés befejezéséig!
                    </div>
                }
            />
            <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="formgrid grid">
                    <div className="field col-12 md:col-7">
                        <label
                            htmlFor="name"
                            className="block text-900 font-medium mb-2"
                        >
                            Név
                        </label>
                        <InputText
                            className={
                                formik.errors.fileName
                                    ? "w-full p-invalid"
                                    : "w-full"
                            }
                            id="fileName"
                            {...formik.getFieldProps("fileName")}
                        />
                        {formik.errors.fileName ? (
                            <small className="p-error">
                                {formik.errors.fileName.toString()}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex-wrap field col-12 md:col-4">
                        <label
                            htmlFor="content"
                            className="block text-900 font-medium mb-2"
                            style={{ visibility: "hidden" }}
                        >
                            Fájl kiválasztása
                        </label>
                        <Button
                            type="button"
                            className="align-self-end w-full"
                            icon={
                                uploadFileName === ""
                                    ? "pi pi-search"
                                    : "pi pi-file-pdf"
                            }
                            label={
                                uploadFileName === ""
                                    ? "Fájl kiválasztása"
                                    : uploadFileName
                            }
                            onClick={handleClick}
                        />
                        <input
                            id="content"
                            name="content"
                            type="file"
                            ref={hiddenFileInputRef}
                            style={{ display: "none" }}
                            accept=".pdf"
                            onChange={(event) => {
                                if (
                                    event.currentTarget.files &&
                                    event.currentTarget.files.length > 0
                                ) {
                                    formik.setFieldValue(
                                        "content",
                                        event.currentTarget.files[0]
                                    );
                                    formik.setFieldValue(
                                        "fileName",
                                        event.currentTarget.files[0].name
                                            .toLowerCase()
                                            .replace(/\s/g, "")
                                    );
                                    setUploadFileName(
                                        event.currentTarget.files[0].name
                                    );
                                    // console.log(event.currentTarget.files[0]);
                                    // console.log(
                                    //     formik.getFieldProps("content")
                                    // );
                                }
                            }}
                        />

                        {formik.errors.content ? (
                            <small className="p-error">
                                {formik.errors.content.toString()}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex-wrap field col-12 md:col-1">
                        <label
                            htmlFor="authenticated"
                            className="block text-900 font-medium mb-2"
                        >
                            Védett
                        </label>
                        <InputSwitch
                            id="authenticated"
                            name="authenticated"
                            checked={authenticatedChecked}
                            onChange={(e) => {
                                // console.log(e.value);
                                if (e.value) {
                                    setAuthenticatedChecked(e.value);
                                    formik.setFieldValue(
                                        "authenticated",
                                        "true"
                                    );
                                } else {
                                    setAuthenticatedChecked(false);
                                    formik.setFieldValue(
                                        "authenticated",
                                        "false"
                                    );
                                }
                            }}
                        />
                        {formik.errors.authenticated ? (
                            <small className="p-error">
                                {formik.errors.authenticated.toString()}
                            </small>
                        ) : null}
                    </div>
                </div>
                {documentPreviewLink && documentPreviewLink !== "" ? (
                    <>
                        <Divider />
                        <div className="flex flex-wrap align-items-start p-3 mr-2 bg-green-100 border-round border-1 border-green-300">
                            <p className=" m-0 p-0 text-green-700">
                                <a
                                    href={documentPreviewLink}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {documentPreviewLink}
                                </a>
                            </p>
                        </div>
                    </>
                ) : null}
                <Divider />
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4 lg:col-3">
                        <Button
                            className="p-button-secondary"
                            label="Bezárás"
                            icon="pi pi-arrow-circle-left"
                            onClick={() => {
                                hideDialog();
                            }}
                        />
                    </div>
                    {/* TODO jogosultság reduxe */}
                    <div className="field col-12 md:col-4 lg:col-3">
                        <Button
                            className="p-button-success"
                            label="Mentés"
                            icon="pi pi-check"
                            type="submit"
                        />
                    </div>
                    <div className="field col-12 md:col-4 lg:col-3">
                        <Button
                            className="p-button-danger"
                            type="button"
                            label="Törlés"
                            icon="pi pi-trash"
                            onClick={() => {
                                //TODO van id, ha nincs akkor csak bezárás, vagy mentés submit kérdés?
                                if (
                                    window.confirm(
                                        "Biztos törli a dokumentumot?"
                                    )
                                ) {
                                    deleteDocument(documentDto.id).then(
                                        (response) => {
                                            // console.log(response.data);
                                            hideDialog();
                                        },
                                        (error) => {
                                            console.log(error);
                                            // TODO esetleg nem bezárni az ablakot és hibaüzenet
                                            hideDialog();
                                        }
                                    );
                                }
                            }}
                        />
                    </div>
                </div>
            </form>
        </Dialog>
    );
};

export default DocumentAdminDetail;
