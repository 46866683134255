import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import {
    changePassword,
    checkChangePasswordId,
} from "../../service/home/PasswordService";
import { useFormik } from "formik";
import * as Yup from "yup";
import RightLayoutBenefits1 from "../../component/RightLayoutBenefits1";
import MessageDialog from "../../component/MessageDialog";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Login.css";

const ChangePassword: React.FC = () => {
    let [searchParams] = useSearchParams();
    const uuid: any = searchParams.get("uuid");

    const [validChangePasswordId, setValidChangePasswordId] =
        React.useState<boolean>(false);

    let navigate = useNavigate();

    useEffect(() => {
        checkChangePasswordId(uuid).then(
            (response) => {
                setValidChangePasswordId(true);
            },
            (error) => {
                setValidChangePasswordId(false);
                navigate("../getpassword?status=changepasswordlinkexpired");
                // navigate("/notfound");
            }
        );
    }, [validChangePasswordId, uuid, navigate]);

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordAgain: "",
            accepted: false,
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%/^§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű"),
            passwordAgain: Yup.string()
                .min(8, "Legalább 8 karakter hosszú")
                .required("Kötelező kitölteni!")
                .matches(/[0-9]/, "Legalább egy szám")
                .matches(/[a-zA-Z]/, "Legalább egy kisbetű")
                // .matches(
                //     /[!?@#$€%^/§&*+_-~.,;=(){}]/,
                //     "Legalább egy speciális karakter !?@#$€%^/§&*+_-~.,;=(){}"
                // )
                .matches(/[A-Z]/, "Legalább egy nagybetű")
                .when("password", {
                    is: (val: string) => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Különböző jelszó"
                    ),
                }),
        }),
        onSubmit: (values) => {
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            //alert(JSON.stringify(values, null, 2));
            changePassword(uuid, values.password).then(
                (response) => {
                    setMessageDialogError(false);
                    setMessageDialogHeader("Értesítés");
                    setMessageDialogBody(
                        "Sikeresen beállította az új jelszavát!"
                    );
                    setMessageDialogNavigateAction(
                        "../login?status=passwordchanged"
                    );
                    setMessageDialogShow(Date.now());
                },
                (error) => {
                    // console.log(error.response.status);
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    if (error.response.status === 404) {
                        //TODO generálni ezt a hibaüzenetet
                        setMessageDialogBody(
                            "Hibás jelszó beállítás! Kérem igényeljen új jelszó módosító linket!"
                        );
                        setMessageDialogNavigateAction(
                            "../getpassword?status=changepassworderror"
                        );
                    } else {
                        if (responseMessage === "Network Error") {
                            setMessageDialogBody(
                                "Hálozati hiba, kérem próbálja meg pár perc múlva megadni az új jelszavát."
                            );
                        } else {
                            setMessageDialogBody(responseMessage);
                        }
                    }
                    setMessageDialogError(true);
                    setMessageDialogHeader("Hiba");
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    const header = <h6>Adja meg a jelszavát!</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Javaslatok</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: "1.5" }}>
                <li>Legalább 8 karakter hosszú</li>
                <li>Legalább egy nagybetű</li>
                <li>Legalább egy kisbetű</li>
                <li>Legalább egy szám</li>
                {/* <li>Legalább egy speciális karakter @#$%^&*?_~.,;=</li> */}
            </ul>
        </React.Fragment>
    );

    return (
        <>
            {!validChangePasswordId ? (
                <Outlet />
            ) : (
                <div className="px-4 md:px-6 lg:px-8">
                    <div className="flex flex-wrap shadow-2">
                        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
                            <div className="text-xl text-black-alpha-90 font-500 mb-3">
                                ZepterClub tag jelszó változtatás
                            </div>
                            <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-6">
                                Adja meg az új jelszavát!
                            </p>
                            <MessageDialog
                                messageDialogError={messageDialogError}
                                messageDialogShow={messageDialogShow}
                                messageDialogHeader={messageDialogHeader}
                                messageDialogBody={messageDialogBody}
                                messageDialogNavigateAction={
                                    messageDialogNavigateAction
                                }
                            />

                            <form
                                onSubmit={formik.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="password"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Jelszó
                                        </label>
                                        <Password
                                            id="password"
                                            toggleMask
                                            className="w-full mb-3"
                                            {...formik.getFieldProps(
                                                "password"
                                            )}
                                            weakLabel="Gyenge"
                                            mediumLabel="Közepes"
                                            strongLabel="Erős"
                                            promptLabel="Adja meg jelszavát!"
                                            panelClassName="password-panel-right"
                                            header={header}
                                            footer={footer}
                                        />
                                        {formik.touched.password &&
                                        formik.errors.password ? (
                                            <small className="p-error">
                                                {formik.errors.password}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="passwordAgain"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Jelszó ismét
                                        </label>
                                        <Password
                                            id="passwordAgain"
                                            toggleMask
                                            className="w-full mb-3"
                                            {...formik.getFieldProps(
                                                "passwordAgain"
                                            )}
                                            weakLabel="Gyenge"
                                            mediumLabel="Közepes"
                                            strongLabel="Erős"
                                            promptLabel="Adja meg jelszavát!"
                                            panelClassName="password-panel-right"
                                            header={header}
                                            footer={footer}
                                        />
                                        {formik.touched.passwordAgain &&
                                        formik.errors.passwordAgain ? (
                                            <small className="p-error">
                                                {formik.errors.passwordAgain}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <Button
                                            label="Küldés"
                                            className="w-full"
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <RightLayoutBenefits1 />
                    </div>
                </div>
            )}
        </>
    );
};

export default ChangePassword;
