import React from "react";
import NumberOfClubMembers from "./NumberOfClubMembers";

const RightLayoutBenefits1 = () => {
    return (
        <>
            <div className="w-full lg:w-6 px-4 py-4 lg:p-7 bg-blue-50">
                <div className="text-xl text-black-alpha-90 font-500 mb-3">
                    Üdvözöljük a ZepterClub hűségprogramban!
                </div>
                <p className="text-xl text-black-alpha-50 line-height-3 mt-0 mb-6">
                    A ZepterClub hűségprogram tagság teljesen ingyenes és
                    kötelezettség mentes!
                </p>
                <ul className="list-none p-0 m-0">
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-indigo-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-users"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Ingyenes tagság
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                A Zepter fennállásának 30. évfordulója
                                alkalmából hűséges vásárlói számára hozta létre
                                a ZepterClub hűségprogramot, melyhez bárki
                                csatlakozhat. A program állandó kedvezményeket
                                biztosít a vásárlásokhoz, és még további
                                kedvezményekhez juthatnak azok, akik segítik
                                munkánkat azzal, hogy ajánlanak minket
                                rokonaiknak, barátaiknak és ismerőseiknek.
                            </p>
                        </div>
                    </li>
                    <li className="flex align-items-start mb-4">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-orange-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-id-card"></i>
                            </span>
                        </div>
                        <div className="ml-3">
                            <span className="font-medium text-black-alpha-90">
                                Nincs kötelezettség
                            </span>
                            <p className="mt-2 mb-0 text-black-alpha-50 line-height-3">
                                Különleges VIP klub tagságunkkal Ön is
                                részesülhet kedvezményeinkből. Nem kell mást
                                tennie, mint regisztrálni és igényelni ezt a
                                remek kedvezményt. Karácsonykor pedig rendkívüli
                                ajánlatainkból válogathat.
                            </p>
                        </div>
                    </li>
                    <li className="flex align-items-start">
                        <div>
                            <span
                                className="flex align-items-center justify-content-center bg-green-500"
                                style={{
                                    width: "38px",
                                    height: "38px",
                                    borderRadius: "10px",
                                }}
                            >
                                <i className="text-xl text-white pi pi-chart-line"></i>
                            </span>
                        </div>
                        <NumberOfClubMembers />
                    </li>
                </ul>
            </div>
        </>
    );
};

export default RightLayoutBenefits1;
