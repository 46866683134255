import InitialState, { UpdateWebshopAction } from '../../types/redux/webshop';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { RootState } from '../store';
// import { useSelector } from 'react-redux';

const initialState: InitialState = {
    productList: [],
    productDetailFromList: null,
    cart: [],
    cartSummary: {
        totalQuantity: 0,
        rrpTotalPrice: 0,
        plTotalPrice: 0,
        totalDiscountAmount: 0,
        totalPrice: 0,
        shipmentFee: 0,
    },
    productOrder: {
        clubMemberId: null,
        vipNumber: null,
        representativeNumber: null,
        recommenderRepresentativeNumber: null,
        sellToCuNumber: null,
        sellToName: null,
        sellToZip: null,
        sellToCity: null,
        sellToStreet: null,
        sellToEmail: null,
        sellToPhone: null,
        billToCuNumber: null,
        billToName: null,
        billToZip: null,
        billToCity: null,
        billToStreet: null,
        billToEmail: null,
        billToPhone: null,
        billToVatNumber: null,
        shipToCuNumber: null,
        shipToName: null,
        shipToZip: null,
        shipToCity: null,
        shipToStreet: null,
        shipToEmail: null,
        shipToPhone: null,
        couponCode: null,
        usedWalletVoucher: null,
        paymentMethod: null,
        paymentStatus: null,
        shipmentFee: null,
        subscribe: null,
        subscribeFrequency: null,
        subscribeDeliveryDay: null,
        acceptedWebshopGtac: null,
        netAmount: null,
        vatAmount: null,
        grossAmount: null,
        productOrderLineList: [],
    },
};

export const webshopSlice = createSlice({
    name: UpdateWebshopAction,
    initialState: initialState,
    reducers: {
        setProductList: (state, action: PayloadAction<any>) => {
            state.productList = action.payload;
        },
        setProductDetailFromList: (state, action: PayloadAction<any>) => {
            state.productDetailFromList = action.payload;
        },
        setCart: (state, action: PayloadAction<any>) => {
            // state.cart = action.payload;

            //AKCIÓ 2023.I-III - Új Online Partner az első 31 napban a ha 2 terméknél többet vásárol a legolcsóbbat fél áron kapja.

            // kitöröli aaz összes bónuszt a kosárból
            // ás leszedni a fél árat a termékekről
            // és megszámoljuk a mennyiséget
            let cartSumQuantity = 0;
            const newCart: any = action.payload
                .filter((item: any) => {
                    return item.productPlDiscountPercentage !== 100;
                })
                .map((item: any) => {
                    const newItem = { ...item };
                    newItem.productHalfPrice = false;
                    cartSumQuantity += newItem.productQuantity;
                    return newItem;
                });
            // console.log('elvileg nincs half price és bónusz');
            // console.log(newCart);
            // console.log('sum db: ' + cartSumQuantity);
            //AKCIÓ 2023.I-III - Új Online Partner az első 31 napban a ha 2 terméknél többet vásárol a legolcsóbbat fél áron kapja.

            // leszedni a fél árat a termékekről
            // és megszámoljuk a mennyiséget
            // let newCartWithoutHalfPrice: any = newCart.map((item: any) => {
            //     const newItem = { ...item };
            //     newItem.productHalfPrice = false;
            //     cartSumQuantity += newItem.productQuantity;
            //     return newItem;
            // });
            // let newCartWithHalfPrice: any = [...newCartWithoutHalfPrice];
            // console.log('elvileg nincs half price és bónusz');
            // console.log(newCartWith);
            // console.log('sum db: ' + cartSumQuantity);
            //TODO <=31 napos?

            //összes darabszám ellenőrzése
            // const cartSumQuantity = newCartWithoutHalfPrice.reduce(
            //     (accumulator: number, currentItem: any) =>
            //         accumulator + currentItem.productQuantity,
            //     0
            // );
            // console.log('sum db: ' + cartSumQuantity);
            // let newCartWithHalfPriceSumQuantity = 0;
            // if (newCartWithHalfPrice.length > 0) {
            //     for (let i = 0; i < newCartWithHalfPrice.length; i++) {
            //         if (newCartWithHalfPrice[i].productQuantity) {
            //             newCartWithHalfPriceSumQuantity +=
            //                 newCartWithHalfPrice[i].productQuantity;
            //         }
            //     }
            // }

            // legolcsóbb termék kiszűrése
            const cheapestItem = newCart.reduce(
                (accumulator: any, currentItem: any) => {
                    return currentItem.productQuantity > 0 &&
                        Number(currentItem.productRrpPrice) <
                            Number(accumulator.productRrpPrice)
                        ? currentItem
                        : accumulator;
                },
                newCart[0]
            );

            // console.log('legolcsóbb: ');
            // console.log(cheapestItem);

            // let cheapestItem: any = { ...newCartWithHalfPrice[0] };
            // for (let i = 1; i < newCartWithHalfPrice.length; i++) {
            //     const currentItem = { ...newCartWithHalfPrice[i] };
            //     if (
            //         currentItem &&
            //         currentItem.productRrpPrice &&
            //         cheapestItem &&
            //         cheapestItem.productRrpPrice &&
            //         Number(currentItem.productRrpPrice) <
            //             Number(cheapestItem.productRrpPrice)
            //     ) {
            //         cheapestItem = { ...currentItem };
            //     }
            // }

            // beállítja a legolcsóbbat fél árasra
            const newCartWithHalfPrice = newCart.map((item: any) => {
                if (
                    cartSumQuantity > 2 &&
                    item &&
                    item.productId &&
                    cheapestItem &&
                    cheapestItem.productId &&
                    item.productId === cheapestItem.productId
                ) {
                    item.productHalfPrice = true;
                }
                return item;
            });

            // megkeressük a legolcsóbb terméket
            // let isCheapestItemIndividual: boolean =
            //     newCartWithHalfPrice[0].productQuantity === 1;
            // let cheapestItem: any = { ...newCartWithHalfPrice[0] };
            // for (let i = 1; i < newCartWithHalfPrice.length; i++) {
            //     let currentItem = newCartWithHalfPrice[i];
            //     if (
            //         currentItem &&
            //         currentItem.productRrpPrice &&
            //         cheapestItem &&
            //         cheapestItem.productRrpPrice &&
            //         Number(currentItem.productRrpPrice) <
            //             Number(cheapestItem.productRrpPrice)
            //     ) {
            //         cheapestItem = { ...currentItem };
            //         // isCheapestItemIndividual =
            //         //     currentItem.productQuantity === 1;
            //     }
            // }
            // //fél árasítani a cheapestItem-et és 1-re állítani a darbszámot
            // cheapestItem.productPriceGroupCode = 'employee';
            // cheapestItem.productPlPrice = Number(
            //     cheapestItem.productRrpPrice / 2
            // ).toString();
            // cheapestItem.productPlDiscountPercentage = 50;
            // cheapestItem.productQuantity = 1;
            // cheapestItem.productRrpTotalPrice =
            //     cheapestItem.productRrpPrice;
            // cheapestItem.productPlTotalPrice = Number(
            //     cheapestItem.productRrpPrice / 2
            // ).toString();
            // cheapestItem.productTotalPrice = Number(
            //     cheapestItem.productRrpPrice / 2
            // ).toString();
            // cheapestItem.productHalfPrice = true;

            //ha több db volt a termékből akkor csökkenteni a darabszámot.
            //ha egy darab volt a termékből akkor kivesszük a listából.
            //     if (!isCheapestItemIndividual) {
            //         for (let i = 0; i < newCartWithHalfPrice.length; i++) {
            //             if (
            //                 !isCheapestItemIndividual &&
            //                 newCartWithHalfPrice[i].productQuantity &&
            //                 newCartWithHalfPrice[i].productId &&
            //                 cheapestItem.productId &&
            //                 newCartWithHalfPrice[i].productId ===
            //                     cheapestItem.productId
            //             ) {
            //                 --newCartWithHalfPrice[i].productQuantity;
            //             }
            //         }
            //     } else {
            //         newCartWithHalfPrice = newCartWithHalfPrice.filter(
            //             (item: any) => {
            //                 return item.productId !== cheapestItem.productId;
            //             }
            //         );
            //     }
            //     newCartWithHalfPrice = [...newCartWithHalfPrice, cheapestItem];
            //     console.log(isCheapestItemIndividual);
            //     console.log(cheapestItem);

            //-------------------------------
            // Bevezető ajánlat visszavonásig, azaz ha egy új tanácsadó magától vásárol, akkor a következőket kapja a megrendelés mellé EXTRA BÓNUSZKÉNT:
            // 1 csomag vásárlása esetén: 1 db (id:"24") SF-DWM Dermawell 4.400 Ft értékben
            // 2 csomag vásárlása esetén: 1 db (id:"24") SF-DMW Dermawell + 1 db (id:"22") SF-DGW DigestiWell 9.400 Ft értékben
            // 3 csomag vásárlása esetén: 1 db (id:"24") SF-DMW Dermawell + 1 db (id:"22") SF-DGW DigestiWell és 1 db SF-CD (id:"16") Calm Day 19.200 Ft értékben
            // 4 csomag vásárlása esetén: 1 db (id:"40") ION-03 MyIon Z Pro 129.900 Ft értékben

            // bónusz hozzáadása akció alapján
            // const newCartWithBonus = []
            let newCartWithBonus: any = [...newCartWithHalfPrice];
            newCart.forEach((cartItem: any) => {
                if (
                    cartItem &&
                    cartItem.productId &&
                    cartItem.productId === '36' &&
                    cartItem.productQuantity &&
                    cartItem.productQuantity > 0 &&
                    cartItem.productPlPrice &&
                    cartItem.productPlPrice === '99000'
                ) {
                    state.productList.forEach((productItem: any) => {
                        const bonusItem = {
                            productId: null,
                            productItemNumber: null,
                            productName: null,
                            productThumbnail: null,
                            productPriceGroupCode: 'rrp',
                            productRrpPrice: null,
                            productPlPrice: null,
                            productPlDiscountPercentage: 100,
                            productQuantity: 1,
                            productRrpTotalPrice: null,
                            productPlTotalPrice: 0,
                            productDiscountAmount: null,
                            productTotalPrice: 0,
                        };
                        if (
                            cartItem.productQuantity === 1 &&
                            productItem.id === '24'
                        ) {
                            bonusItem.productId = productItem.id;
                            bonusItem.productItemNumber =
                                productItem.itemNumber;
                            bonusItem.productName = productItem.name;
                            bonusItem.productThumbnail =
                                productItem.productImageList[0].image;
                            bonusItem.productRrpPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productPlPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productRrpTotalPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productDiscountAmount =
                                productItem.productPriceGroup.rrp;
                            newCartWithBonus = [...newCartWithBonus, bonusItem];
                        } else if (
                            cartItem.productQuantity === 2 &&
                            (productItem.id === '24' || productItem.id === '22')
                        ) {
                            bonusItem.productId = productItem.id;
                            bonusItem.productItemNumber =
                                productItem.itemNumber;
                            bonusItem.productName = productItem.name;
                            bonusItem.productThumbnail =
                                productItem.productImageList[0].image;
                            bonusItem.productRrpPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productPlPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productRrpTotalPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productDiscountAmount =
                                productItem.productPriceGroup.rrp;
                            newCartWithBonus = [...newCartWithBonus, bonusItem];
                        } else if (
                            cartItem.productQuantity === 3 &&
                            (productItem.id === '24' ||
                                productItem.id === '22' ||
                                productItem.id === '16')
                        ) {
                            bonusItem.productId = productItem.id;
                            bonusItem.productItemNumber =
                                productItem.itemNumber;
                            bonusItem.productName = productItem.name;
                            bonusItem.productThumbnail =
                                productItem.productImageList[0].image;
                            bonusItem.productRrpPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productPlPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productRrpTotalPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productDiscountAmount =
                                productItem.productPriceGroup.rrp;
                            newCartWithBonus = [...newCartWithBonus, bonusItem];
                        } else if (
                            cartItem.productQuantity > 3 &&
                            productItem.id === '40'
                        ) {
                            bonusItem.productId = productItem.id;
                            bonusItem.productItemNumber =
                                productItem.itemNumber;
                            bonusItem.productName = productItem.name;
                            bonusItem.productThumbnail =
                                productItem.productImageList[0].image;
                            bonusItem.productRrpPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productPlPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productRrpTotalPrice =
                                productItem.productPriceGroup.rrp;
                            bonusItem.productDiscountAmount =
                                productItem.productPriceGroup.rrp;
                            newCartWithBonus = [...newCartWithBonus, bonusItem];
                        }
                    });
                }
            });

            state.cart = newCartWithBonus;

            // 2022.09.15. Gála promóció minden pl4 Try It vásárláshoz bónusz pozitura
            // let tryItSetQuantity = 0;
            // state.cart = action.payload.map((item: any) => {
            //     // console.log(item);
            //     const newItem = { ...item };
            //     if (
            //         newItem &&
            //         newItem.productId &&
            //         newItem.productId === "36"
            //     ) {
            //         tryItSetQuantity = newItem.productQuantity;
            //     } else if (
            //         newItem &&
            //         newItem.productId &&
            //         newItem.productId === "42"
            //     ) {
            //         newItem.productQuantity = tryItSetQuantity;
            //     }
            //     return newItem;
            // });

            // console.log(bonusProduct.id);
            // console.log(action);

            //Bónusz hozzáadása
            // const bonusProduct = state.productList.find((product: any) => {
            //     console.log(product);
            //     return product.id === "42";
            // });

            // console.log(state.productList);

            const summary = {
                totalQuantity: 0,
                rrpTotalPrice: 0,
                plTotalPrice: 0,
                totalDiscountAmount: 0,
                totalPrice: 0,
                shipmentFee: 0,
            };
            state.cart.forEach((item: any) => {
                summary.totalQuantity += Number(item.productQuantity);
                summary.rrpTotalPrice += Number(item.productRrpTotalPrice);
                summary.plTotalPrice += Number(item.productPlTotalPrice);
                summary.totalDiscountAmount += Number(
                    item.productDiscountAmount
                );
                summary.totalPrice += Number(item.productTotalPrice);
                // TODO 2023.03.31-én kikapcsolni az alábbit
                // if (item.productHalfPrice) {
                //     summary.plTotalPrice +=
                //         Number(item.productRrpPrice) / 2 -
                //         Number(item.productPlPrice);
                //     summary.totalPrice +=
                //         Number(item.productRrpPrice) / 2 -
                //         Number(item.productPlPrice);
                // }
            });
            //TODO adatbázisból
            summary.shipmentFee = summary.totalPrice > 29999 ? 0 : 1500;
            state.cartSummary = summary;
        },
        setCartSummary: (state, action: PayloadAction<any>) => {
            state.cartSummary = action.payload;
        },
        setProductOrder: (state, action: PayloadAction<any>) => {
            state.productOrder = action.payload;
        },
        clearCart: (state) => {
            state.cart = [];
            state.cartSummary = {
                totalQuantity: 0,
                rrpTotalPrice: 0,
                plTotalPrice: 0,
                totalDiscountAmount: 0,
                totalPrice: 0,
                shipmentFee: 0,
            };
        },
        clearProductOrder: (state) => {
            state.productOrder = {
                clubMemberId: null,
                vipNumber: null,
                representativeNumber: null,
                recommenderRepresentativeNumber: null,
                sellToCuNumber: null,
                sellToName: null,
                sellToZip: null,
                sellToCity: null,
                sellToStreet: null,
                sellToEmail: null,
                sellToPhone: null,
                billToCuNumber: null,
                billToName: null,
                billToZip: null,
                billToCity: null,
                billToStreet: null,
                billToEmail: null,
                billToPhone: null,
                billToVatNumber: null,
                shipToCuNumber: null,
                shipToName: null,
                shipToZip: null,
                shipToCity: null,
                shipToStreet: null,
                shipToEmail: null,
                shipToPhone: null,
                couponCode: null,
                usedWalletVoucher: null,
                paymentMethod: null,
                paymentStatus: null,
                shipmentFee: null,
                subscribe: null,
                subscribeFrequency: null,
                subscribeDeliveryDay: null,
                acceptedWebshopGtac: null,
                netAmount: null,
                vatAmount: null,
                grossAmount: null,
                productOrderLineList: [],
            };
        },
        clearWebshop: (state) => {
            state.productList = [];
            state.productDetailFromList = null;
            state.cart = [];
            state.cartSummary = {
                totalQuantity: 0,
                rrpTotalPrice: 0,
                plTotalPrice: 0,
                totalDiscountAmount: 0,
                totalPrice: 0,
                shipmentFee: 0,
            };
            state.productOrder = {
                clubMemberId: null,
                vipNumber: null,
                representativeNumber: null,
                recommenderRepresentativeNumber: null,
                sellToCuNumber: null,
                sellToName: null,
                sellToZip: null,
                sellToCity: null,
                sellToStreet: null,
                sellToEmail: null,
                sellToPhone: null,
                billToCuNumber: null,
                billToName: null,
                billToZip: null,
                billToCity: null,
                billToStreet: null,
                billToEmail: null,
                billToPhone: null,
                billToVatNumber: null,
                shipToCuNumber: null,
                shipToName: null,
                shipToZip: null,
                shipToCity: null,
                shipToStreet: null,
                shipToEmail: null,
                shipToPhone: null,
                couponCode: null,
                usedWalletVoucher: null,
                paymentMethod: null,
                paymentStatus: null,
                shipmentFee: null,
                subscribe: null,
                subscribeFrequency: null,
                subscribeDeliveryDay: null,
                acceptedWebshopGtac: null,
                netAmount: null,
                vatAmount: null,
                grossAmount: null,
                productOrderLineList: [],
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setProductList } = webshopSlice.actions;
export const { setProductDetailFromList } = webshopSlice.actions;
export const { setCart } = webshopSlice.actions;
export const { setCartSummary } = webshopSlice.actions;
export const { setProductOrder } = webshopSlice.actions;
export const { clearCart } = webshopSlice.actions;
export const { clearProductOrder } = webshopSlice.actions;
export const { clearWebshop } = webshopSlice.actions;

// You must export the reducer as follows for it to be able to be read by the store.
export default webshopSlice.reducer;
