import axios from 'axios';
import { server } from '../../config/endpoints';
import {
    formatDate,
    replaceAccentCharacters,
    replacePhoneNotUsedCharacters,
} from '../../util/stringFormat';
import { acceptedIvitationGtac } from '../../config/gtac';

const API_URL = server;

export const checkVipNumber = (vipNumber: string) => {
    return axios
        .get(API_URL + '/protected/clubmember/check/vipnumber/' + vipNumber)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { status: 409 };
        });
};

export const checkEmail = (email: string) => {
    return axios
        .get(API_URL + '/protected/clubmember/check/email/' + email)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { status: 409 };
        });
};

export const checkTelephone = (telephone: string) => {
    return axios
        .get(API_URL + '/protected/clubmember/check/telephone/' + telephone)
        .then((response) => {
            return response;
        })
        .catch(() => {
            return { status: 409 };
        });
};

export const checkMobile = (mobile: string) => {
    return axios
        .get(API_URL + '/protected/clubmember/check/mobile/' + mobile)
        .then((response) => {
            return response;
        })
        .catch(() => {
            return { status: 409 };
        });
};

export const checkVatNumber = (vatNumber: string) => {
    return axios
        .get(API_URL + '/protected/clubmember/check/vatnumber/' + vatNumber)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { status: 409 };
        });
};

export const readAllClubMemberPendingInvitation = (
    recommenderVipNumber: string
) => {
    return axios
        .get(
            API_URL + '/protected/clubmemberinvitation/' + recommenderVipNumber
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return { status: 409 };
        });
};

export const invitation1 = (
    formData: any,
    recommenderName: string | null,
    recommenderVipNumber: string | null,
    recommenderRepresentativeNumber: string | null
) => {
    let request = createInvitation1Request(
        formData,
        recommenderName,
        recommenderVipNumber,
        recommenderRepresentativeNumber
    );
    console.log('invitation1request' + request);
    return axios
        .post(API_URL + '/protected/clubmemberinvitation', request)
        .then((response) => {
            // console.log(response.data);
            return response;
        });
};

export const invitation2 = (
    formData: any,
    recommenderName: string | null,
    recommenderVipNumber: string | null,
    recommenderRepresentativeNumber: string | null
) => {
    let request = createInvitation2Request(
        formData,
        recommenderName,
        recommenderVipNumber,
        recommenderRepresentativeNumber
    );
    console.log('invitation2request' + request);
    return axios
        .post(API_URL + '/protected/clubmemberinvitation', request)
        .then((response) => {
            // console.log(response.data);
            return response;
        });
};

let createInvitation1Request = (
    formData: any,
    recommenderName: string | null,
    recommenderVipNumber: string | null,
    recommenderRepresentativeNumber: string | null
) => {
    if (!recommenderName) {
        recommenderName = null;
    }
    if (!recommenderVipNumber) {
        recommenderVipNumber = null;
    }
    if (!recommenderRepresentativeNumber) {
        recommenderRepresentativeNumber = null;
    }
    const result = {
        vipNumber: formData.vipNumber1,
        firstName: replaceAccentCharacters(formData.firstName),
        lastName: replaceAccentCharacters(formData.lastName),
        dateOfBirth: formatDate(formData.dateOfBirth),
        zip: formData.zip1,
        city: formData.city1,
        street: formData.street1,
        houseNumber: formData.houseNumber1,
        address: formData.address1,
        email: formData.email1,
        telephone: replacePhoneNotUsedCharacters(formData.telephone1),
        mobile: replacePhoneNotUsedCharacters(formData.mobile1),
        accepted: formData.accepted1,
        acceptedInvitationGtac: acceptedIvitationGtac,
        receivedRecommenderName: recommenderName,
        receivedRecommenderVipNumber: recommenderVipNumber,
        receivedRecommenderRepresentativeNumber:
            recommenderRepresentativeNumber,
    };
    return result;
};

let createInvitation2Request = (
    formData: any,
    recommenderName: string | null,
    recommenderVipNumber: string | null,
    recommenderRepresentativeNumber: string | null
) => {
    if (!recommenderName) {
        recommenderName = null;
    }
    if (!recommenderVipNumber) {
        recommenderVipNumber = null;
    }
    if (!recommenderRepresentativeNumber) {
        recommenderRepresentativeNumber = null;
    }
    const result = {
        vipNumber: formData.vipNumber2,
        companyName: replaceAccentCharacters(formData.companyName),
        companyType: formData.companyType,
        vatNumber: formData.vatNumber,
        zip: formData.zip2,
        city: formData.city2,
        street: formData.street2,
        houseNumber: formData.houseNumber2,
        address: formData.address2,
        email: formData.email2,
        telephone: replacePhoneNotUsedCharacters(formData.telephone2),
        mobile: replacePhoneNotUsedCharacters(formData.mobile2),
        accepted: formData.accepted2,
        acceptedInvitationGtac: acceptedIvitationGtac,
        receivedRecommenderName: recommenderName,
        receivedRecommenderVipNumber: recommenderVipNumber,
        receivedRecommenderRepresentativeNumber:
            recommenderRepresentativeNumber,
    };
    return result;
};
