import InitialState, {
    UpdateNavigationAction
} from "../../types/redux/navigation";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
    activeMenuItem: "dashboard"
};

export const navigationSlice = createSlice({
    name: UpdateNavigationAction,
    initialState: initialState,
    reducers: {
        setActiveMenuItem: (state, action: PayloadAction<string>) => {
            state.activeMenuItem = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setActiveMenuItem } = navigationSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default navigationSlice.reducer;
