import axios from 'axios';
import { server } from '../../../config/endpoints';

const API_URL = server;

let createDocumentRequest = (formData: any) => {
    let fileNameCorrection = formData.fileName.toLowerCase().replace(/\s/g, '');
    if (!fileNameCorrection.endsWith('.pdf')) fileNameCorrection += '.pdf';
    const result = {
        id: formData.id,
        fileName: fileNameCorrection,
        content: formData.content,
        authenticated: formData.authenticated,
        modifiedBy: formData.modifiedBy,
    };
    console.log(formData);
    return result;
};

export const readAllDocuments = () => {
    return axios.get(API_URL + '/protected/admin/document').then((response) => {
        return response;
    });
};

export const readDocument = (id: string) => {
    return axios
        .get(API_URL + '/protected/admin/document/' + id)
        .then((response) => {
            return response;
        });
};

export const saveDocument = (formData: any) => {
    let request = createDocumentRequest(formData);
    return axios
        .post(API_URL + '/protected/admin/document', request, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            return response;
        });
};

export const deleteDocument = (id: string) => {
    //TODO szerver oldal --> van aki hivatkozik rá?
    return axios
        .delete(API_URL + '/protected/admin/document/' + id)
        .then((response) => {
            return response;
        });
};

export const displayDocument = (fileName: string) => {
    //protected & nincs token --> token
    return axios.get(API_URL + '/api/document/' + fileName).then((response) => {
        return response;
    });
};
