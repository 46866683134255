import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem } from "../../../state/dashboard/navigationSlice";
import { setDocumentAdminList } from "../../../state/dashboard/adminListSlice";
import { RootState } from "../../../state/store";
import { useNavigate } from "react-router";
import { setAuthToken, logout } from "../../../service/dashboard/AuthService";
import {
    deleteDocument,
    readAllDocuments,
} from "../../../service/dashboard/admin/DocumentService";
import { StatusCodes } from "http-status-codes";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DocumentAdminDetail from "./DocumentAdminDetail";
import "../../../css/Admin.css";

const DocumentAdminList: React.FC<any> = (props: any) => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("documentadminlist"));

    const documentAdminList: [] = useSelector(
        (state: RootState) => state.adminList.documentAdminList
    );

    let navigate = useNavigate();
    setAuthToken();

    const [documentAdminDetailDialogShow, setDocumentAdminDetailDialogShow] =
        useState<boolean>(false);
    const [
        documentAdminDetailDialogChange,
        setDocumentAdminDetailDialogChange,
    ] = useState<number>(0);

    const [documentId, setDocumentId] = useState<string>("");

    const refreshDocumentAdminList = () => {
        readAllDocuments().then(
            (response) => {
                dispatch(
                    setDocumentAdminList(
                        response.data.documentList.map((document: any) => {
                            if (document.authenticated) {
                                document.authenticated = "🔒védett";
                            } else {
                                document.authenticated = "publikus";
                            }
                            return document;
                        })
                    )
                );
                //TODO használja megjeleníteni
                // console.log(response.data.documentList);
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    };

    useEffect(() => {
        readAllDocuments().then(
            (response) => {
                dispatch(
                    setDocumentAdminList(
                        response.data.documentList.map((document: any) => {
                            if (document.authenticated) {
                                document.authenticated = "🔒védett";
                            } else {
                                document.authenticated = "publikus";
                            }
                            return document;
                        })
                    )
                );
                //TODO használja megjeleníteni
                // console.log(response.data.documentList);
            },
            (error) => {
                // console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
            }
        );
    }, [dispatch, navigate]);

    const headerTemplate = (options: {
        className: any;
        titleClassName: any;
        togglerClassName: any;
    }) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName}`;
        return (
            <div className={className}>
                <span className={titleClassName}>Dokumentumok</span>
                <Button
                    className="p-panel p-button p-button-sm p-button-info"
                    type="button"
                    label="Új dokumentum"
                    icon="pi pi-plus"
                    onClick={() => {
                        setDocumentId("");
                        setDocumentAdminDetailDialogShow(true);
                        setDocumentAdminDetailDialogChange(Date.now());
                    }}
                    aria-label="Create"
                />
            </div>
        );
    };

    const editDocumentTemplate = (rowData: any) => {
        return (
            <>
                <Button
                    type="button"
                    onClick={() => {
                        setDocumentId(rowData.id);
                        setDocumentAdminDetailDialogShow(true);
                        setDocumentAdminDetailDialogChange(Date.now());
                    }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text"
                    aria-label="Edit"
                />
                <Button
                    className="p-button-danger p-button-rounded p-button-text"
                    type="button"
                    onClick={() => {
                        if (
                            //TODO a lista tud-e frissülni? Redux?
                            window.confirm("Biztos törli a dokumentumot")
                        ) {
                            deleteDocument(rowData.id).then(
                                (response) => {
                                    // console.log(response.data);
                                    refreshDocumentAdminList();
                                },
                                (error) => {
                                    console.log(error);
                                    refreshDocumentAdminList();
                                }
                            );
                        }
                    }}
                    icon="pi pi-trash"
                    aria-label="Delete"
                />
            </>
        );
    };

    return (
        <>
            <Panel headerTemplate={headerTemplate}>
                <DocumentAdminDetail
                    id={documentId}
                    show={documentAdminDetailDialogShow}
                    change={documentAdminDetailDialogChange}
                />
                <DataTable
                    className="admin-list"
                    value={documentAdminList}
                    size="small"
                    paginator
                    style={{
                        maxWidth: "87vw",
                        overflowX: "auto",
                    }}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Lista: {first}-{last} | Összesen: {totalRecords}"
                    rows={20}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                >
                    <Column header="Akció" body={editDocumentTemplate} />
                    <Column
                        field="fileName"
                        header="File Név"
                        sortable
                        filter
                    />
                    <Column
                        field="authenticated"
                        header="Védett"
                        sortable
                        filter
                    />
                    <Column field="usedBy" header="Használja" sortable filter />
                    {/* TODO megjeleníteni hány terméket tartalmaz */}
                </DataTable>
            </Panel>
        </>
    );
};

export default DocumentAdminList;
