import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoadingOff,
    setLoadingOn,
} from "../../../state/dashboard/mainSlice";
import { setClubMemberAdminFilteredList } from "../../../state/dashboard/adminListSlice";
import { RootState } from "../../../state/store";
// import { useSearchParams } from "react-router-dom";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../service/dashboard/AuthService";
import {
    newClubMemberNumber,
    readAllClubMembers,
    readClubMemberByValidationId,
    updateClubMember,
} from "../../../service/dashboard/admin/ClubMemberService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { capitalizeFirstLetters } from "../../../util/stringFormat";
// import { useDatePickerDefaultizedProps } from "@mui/x-date-pickers/DatePicker/shared";

const ClubMemberAdminDetail: FunctionComponent<any> = (props: any) => {
    const dispatch = useDispatch();

    let navigate = useNavigate();
    // TODO csak ADMIN és VALIDÁTOR tudjon menteni
    setAuthToken();
    const user = getCurrentUser();

    const [
        clubMemberAdminDetailDialogShow,
        setClubMemberAdminDetailDialogShow,
    ] = useState<boolean>(false);
    const [
        clubMemberAdminDetailDialogChange,
        setClubMemberAdminDetailDialogChange,
    ] = useState<number>(0);

    // let [searchParams] = useSearchParams();
    const [clubMemberDto, setClubMemberDto] = useState<any>();

    const [formChanged, setFormChanged] = useState<boolean>(false);

    // const validationId: any = searchParams.get("id");
    const validationId: any = props.id;

    const isDisabled = () => {
        return (
            (user &&
                user.roles[0] !== "ROLE_ADMIN" &&
                user.roles[0] !== "ROLE_VALIDATOR") ||
            (clubMemberDto && clubMemberDto.registrationStatus === "PENDING")
        );
    };

    const clubMemberAdminListFilterValue: any = useSelector(
        (state: RootState) => state.adminList.clubMemberAdminListFilterValue
    );

    const getNewClubMemberNumber = () => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Új klubkártya szám generálása.",
            })
        );
        newClubMemberNumber().then(
            (response) => {
                formik.setFieldValue("vipNumber", response.data.vipNumber);
                formik.setFieldValue("navisionClubMemberTask", "CREATE");
                formik.setFieldValue(
                    "vipRegistrationDate",
                    clubMemberDto.created.slice(0, 10)
                );
                setFormChanged(true);
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
    };
    const refreshClubMemberAdminFilteredList = () => {
        dispatch(
            setLoadingOn({
                blockPage: true,
                message: "Regisztráltak listájának frissítése folyamatban.",
            })
        );
        readAllClubMembers().then(
            (response) => {
                const clubMemberList = response.data.clubMemberList;
                if (clubMemberAdminListFilterValue === "NOTFINISHED") {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return (
                                clubMember.registrationStatus === "VALIDATED"
                            );
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (clubMemberAdminListFilterValue === "NEWCLUBMEMBER") {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return (
                                clubMember.cuNumber === null &&
                                clubMember.representativeNumber === null &&
                                clubMember.navisionClubMemberTask !== "CHECK" &&
                                clubMember.registrationStatus === "VALIDATED"
                            );
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (
                    clubMemberAdminListFilterValue === "UPDATECLUBMEMBER"
                ) {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return (
                                clubMember.representativeNumber === null &&
                                clubMember.navisionClubMemberTask ===
                                    "UPDATE" &&
                                clubMember.registrationStatus === "VALIDATED"
                            );
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (
                    clubMemberAdminListFilterValue === "NEWREPRESENTATIVE"
                ) {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return (
                                clubMember.representativeNumber !== null &&
                                clubMember.representativeNumber.startsWith(
                                    "770"
                                ) &&
                                clubMember.navisionRepresentativeTask !==
                                    "CHECK" &&
                                clubMember.registrationStatus === "VALIDATED"
                            );
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (clubMemberAdminListFilterValue === "CONFLICT") {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return (
                                (clubMember.navisionClubMemberTask ===
                                    "CHECK" ||
                                    clubMember.navisionRepresentativeTask ===
                                        "CHECK") &&
                                clubMember.registrationStatus === "VALIDATED"
                            );
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (clubMemberAdminListFilterValue === "PENDING") {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return clubMember.registrationStatus === "PENDING";
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else if (clubMemberAdminListFilterValue === "EMPLOYEE") {
                    const filteredList = clubMemberList.filter(
                        (clubMember: any) => {
                            return clubMember.zepterRank === "EMPLOYEE";
                        }
                    );
                    dispatch(setClubMemberAdminFilteredList(filteredList));
                } else {
                    dispatch(setClubMemberAdminFilteredList(clubMemberList));
                }
                // let orderedSelectedColumns = columns.filter((col) =>
                //     selectedColumns.some((sCol) => sCol.field === col.field)
                // );
                // setClubMemberFilteredList(response.data.clubMemberList);
                // console.log(response.data.clubMemberList);
                dispatch(setLoadingOff());
            },
            (error) => {
                console.log(error.response.status);
                if (error.response.status === StatusCodes.UNAUTHORIZED) {
                    //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                    logout();
                    navigate("/login?status=unauthorized");
                }
                dispatch(setLoadingOff());
            }
        );
    };

    useEffect(() => {
        // console.log(props);
        if (
            props.show === true &&
            props.change !== 0 &&
            props.change !== clubMemberAdminDetailDialogChange
        ) {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Partner adatok betöltése.",
                })
            );
            setClubMemberDto(null);
            readClubMemberByValidationId(validationId).then(
                (response) => {
                    console.log(response.data);
                    setClubMemberDto(response.data);
                },
                (error) => {
                    // console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        dispatch(setLoadingOff());
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
            setClubMemberAdminDetailDialogChange(props.change);
            setClubMemberAdminDetailDialogShow(true);
            dispatch(setLoadingOff());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        navigate,
        props,
        props.change,
        props.show,
        validationId,
        clubMemberAdminDetailDialogChange,
    ]);

    // const maxDate = new Date(
    //     new Date().setFullYear(new Date().getFullYear() - 18)
    // );
    // const minDate = new Date(
    //     new Date().setFullYear(new Date().getFullYear() - 122)
    // );

    const companyTypes = [
        { label: "Válasszon cégformát!", value: "" },
        { label: "Ec. - Egyéni cég", value: "EC." },
        { label: "Ev. - Egyéni vállalkozás", value: "EV." },
        { label: "Bt. - Betéti társaság", value: "BT." },
        { label: "Kft. - Korlátolt Felelősségű Társaság", value: "KFT." },
        { label: "Nyrt. - Nyílt részvénytársaság", value: "NYRT." },
        { label: "Zrt. - Zárt részvénytársaság", value: "ZRT." },
        { label: "Egyesület", value: "EGY." },
        { label: "Szövetkezet", value: "SZVT." },
        { label: "Nonprofit szervezet", value: "NPF." },
        { label: "Egyéb", value: "EGYB." },
    ];

    const registrationStatuses = [
        { label: "PENDING", value: "PENDING", disabled: "true" },
        { label: "VALIDATED", value: "VALIDATED" },
        { label: "APPROVED", value: "APPROVED" },
        { label: "SYNCED", value: "SYNCED", disabled: "true" },
    ];

    const formik = useFormik({
        initialValues: {
            id: clubMemberDto && clubMemberDto.id ? clubMemberDto.id : "",
            registrationStatus:
                clubMemberDto && clubMemberDto.registrationStatus
                    ? clubMemberDto.registrationStatus
                    : "",
            navisionClubMemberTask:
                clubMemberDto && clubMemberDto.navisionClubMemberTask
                    ? clubMemberDto.navisionClubMemberTask
                    : "",
            navisionRepresentativeTask:
                clubMemberDto && clubMemberDto.navisionRepresentativeTask
                    ? clubMemberDto.navisionRepresentativeTask
                    : "",
            cuNumber:
                clubMemberDto && clubMemberDto.cuNumber
                    ? clubMemberDto.cuNumber
                    : "",
            lastName:
                clubMemberDto && clubMemberDto.lastName
                    ? clubMemberDto.lastName
                    : "",
            firstName:
                clubMemberDto && clubMemberDto.firstName
                    ? clubMemberDto.firstName
                    : "",
            dateOfBirth:
                clubMemberDto && clubMemberDto.dateOfBirth
                    ? clubMemberDto.dateOfBirth
                    : "",
            companyName:
                clubMemberDto && clubMemberDto.companyName
                    ? clubMemberDto.companyName
                    : "",
            companyType:
                clubMemberDto && clubMemberDto.companyType
                    ? clubMemberDto.companyType
                    : "",
            vatNumber:
                clubMemberDto && clubMemberDto.vatNumber
                    ? clubMemberDto.vatNumber
                    : "",
            zip: clubMemberDto && clubMemberDto.zip ? clubMemberDto.zip : "",
            city: clubMemberDto && clubMemberDto.city ? clubMemberDto.city : "",
            street:
                clubMemberDto && clubMemberDto.street
                    ? clubMemberDto.street
                    : "",
            email:
                clubMemberDto && clubMemberDto.email ? clubMemberDto.email : "",
            mobile:
                clubMemberDto && clubMemberDto.mobile
                    ? clubMemberDto.mobile
                    : "",
            telephone:
                clubMemberDto && clubMemberDto.telephone
                    ? clubMemberDto.telephone
                    : "",
            vipNumber:
                clubMemberDto && clubMemberDto.vipNumber
                    ? clubMemberDto.vipNumber
                    : "",
            vipRegistrationDate:
                clubMemberDto && clubMemberDto.vipRegistrationDate
                    ? clubMemberDto.vipRegistrationDate
                    : "",
            recommenderVipNumber:
                clubMemberDto && clubMemberDto.recommenderVipNumber
                    ? clubMemberDto.recommenderVipNumber
                    : "",
            recommenderRepresentativeNumber:
                clubMemberDto && clubMemberDto.recommenderRepresentativeNumber
                    ? clubMemberDto.recommenderRepresentativeNumber
                    : "",
            receivedRecommenderVipNumber:
                clubMemberDto && clubMemberDto.receivedRecommenderVipNumber
                    ? clubMemberDto.receivedRecommenderVipNumber
                    : "",
            receivedRecommenderRepresentativeNumber:
                clubMemberDto &&
                clubMemberDto.receivedRecommenderRepresentativeNumber
                    ? clubMemberDto.receivedRecommenderRepresentativeNumber
                    : "",
            representativeNumber:
                clubMemberDto && clubMemberDto.representativeNumber
                    ? clubMemberDto.representativeNumber
                    : "",
            representativeRegistrationDate:
                clubMemberDto && clubMemberDto.representativeRegistrationDate
                    ? clubMemberDto.representativeRegistrationDate
                    : "",
            managerRepresentativeNumber:
                clubMemberDto && clubMemberDto.managerRepresentativeNumber
                    ? clubMemberDto.managerRepresentativeNumber
                    : "",
            taxNumber:
                clubMemberDto && clubMemberDto.taxNumber
                    ? clubMemberDto.taxNumber
                    : "",
            ssnNumber:
                clubMemberDto && clubMemberDto.ssnNumber
                    ? clubMemberDto.ssnNumber
                    : "",
            personalAccountNumber:
                clubMemberDto && clubMemberDto.personalAccountNumber
                    ? clubMemberDto.personalAccountNumber
                    : "",
            acceptedConsultantGtac:
                clubMemberDto && clubMemberDto.acceptedConsultantGtac
                    ? clubMemberDto.acceptedConsultantGtac
                    : "",
            acceptedClubMemberGtac:
                clubMemberDto && clubMemberDto.acceptedClubMemberGtac
                    ? clubMemberDto.acceptedClubMemberGtac
                    : "",
            modifiedBy: user.email,
            zepterRank:
                clubMemberDto && clubMemberDto.zepterRank
                    ? clubMemberDto.zepterRank
                    : "",
        },
        initialTouched: {
            lastName: true,
            firstName: true,
            // dateOfBirth: true,
            // mobile: true,
        },
        enableReinitialize: true,
        validationSchema: Yup.object(
            {
                // lastName: Yup.string().min(2).required("Kötelező kitölteni!"),
                // lastName: Yup.string()
                //     .nullable()
                //     .notRequired()
                //     .when("lastName", {
                //         is: (value: string | any[]) => value?.length,
                //         then: (rule) => rule.min(2),
                //     }),
                // // firstName: Yup.string().min(3).required("Kötelező kitölteni!"),
                // firstName: Yup.string()
                //     .nullable()
                //     .notRequired()
                //     .when("firstName", {
                //         is: (value: string | any[]) => value?.length,
                //         then: (rule) => rule.min(2),
                //     }),
                // dateOfBirth: Yup.date()
                //     .min(minDate)
                //     .max(maxDate)
                //     mobile: Yup.string()
                //         .required("Kötelező kitölteni!")
                //         .test(
                //             "Mobil szám teszt",
                //             "Érvénytelen mobil szám",
                //             function (val) {
                //                 console.log(val);
                //                 if (
                //                     val &&
                //                     val.length > 0 &&
                //                     (val.includes("+3620") ||
                //                         val.includes("+3630") ||
                //                         val.includes("+3631") ||
                //                         val.includes("+3650") ||
                //                         val.includes("+3660") ||
                //                         val.includes("+3670"))
                //                 ) {
                //                     return true;
                //                 } else {
                //                     return false;
                //                 }
                //             }
                //         ),
                //     telephone: Yup.string().nullable(true),
                //     taxNumber: Yup.string()
                //         .nullable(true)
                //         .min(10, "10 karakternek kell lenni")
                //         .max(10, "10 karakternek kell lenni")
                //         // .required("Kötelező kitölteni!")
                //         .test(
                //             "Adóazonosító jel teszt",
                //             "Érvénytelen adóazonosító jel",
                //             function (value) {
                //                 if (value && value !== "") {
                //                     if (value.length === 10 && value[0] === "8") {
                //                         let sum = 0;
                //                         for (let i = 0; i < 9; i++) {
                //                             sum += Number(value[i]) * (i + 1);
                //                         }
                //                         return (sum % 11) % 10 === Number(value[9]);
                //                     } else {
                //                         return false;
                //                     }
                //                 } else {
                //                     return true;
                //                 }
                //             }
                //         ),
                //     ssnNumber: Yup.string()
                //         .nullable(true)
                //         .min(11)
                //         .max(11)
                //         // .required("Kötelező kitölteni!")
                //         .test(
                //             "TAJ szám teszt",
                //             "Érvénytelen TAJ szám",
                //             function (value) {
                //                 if (value && value !== "") {
                //                     let result = value
                //                         .split("_")
                //                         .join("")
                //                         .split(" ")
                //                         .join("");
                //                     if (result.length === 9) {
                //                         //CDV test
                //                         const ssnOdd =
                //                             (Number(result[0]) +
                //                                 Number(result[2]) +
                //                                 Number(result[4]) +
                //                                 Number(result[6])) *
                //                             3;
                //                         const ssnEven =
                //                             (Number(result[1]) +
                //                                 Number(result[3]) +
                //                                 Number(result[5]) +
                //                                 Number(result[7])) *
                //                             7;
                //                         return (
                //                             (ssnOdd + ssnEven) % 10 ===
                //                             Number(result[8])
                //                         );
                //                     } else {
                //                         return false;
                //                     }
                //                 } else {
                //                     return false;
                //                 }
                //             }
                //         ),
                // //TODO backend banszámla további ellenőrzés
                // personalAccountNumber: Yup.string()
                //     .nullable(true)
                //     .min(17)
                //     .max(26)
                //     // .required("Kötelező kitölteni!")
                //     .test(
                //         "Bankszámlaszám hossz teszt",
                //         "Hibás a bankszámlaszám hossza",
                //         function (value) {
                //             if (value && value !== "") {
                //                 let result = value
                //                     .split("_")
                //                     .join("")
                //                     .split("-")
                //                     .join("");
                //                 if (result.length === 16 || result.length === 24) {
                //                     return true;
                //                 } else {
                //                     return false;
                //                 }
                //             } else {
                //                 return false;
                //             }
                //         }
                //     ),
            }
            // [
            //     // Add Cyclic deps here because when require itself
            //     ["lastName"],
            // ]
        ),
        onSubmit: (values) => {
            if (
                //TODO ELLLENŐRZÉS, hogy ha Valideted akkor szóljon neki, hogy biztosan ne legyen approved?
                //ha nincs CU szám APPROVED-nál, akkor ez biztosan jó? EZT JELÖLNI IS pirossal
                //ha van kerszám, de nincs manager szám
                clubMemberAdminDetailDialogShow &&
                window.confirm("Menti a módosításokat?")
            ) {
                //alert(JSON.stringify(values, null, 2));

                const newValues = { ...values };
                if (
                    newValues.lastName &&
                    newValues.firstName &&
                    newValues.lastName !== "" &&
                    newValues.firstName !== "" &&
                    (newValues.navisionClubMemberTask === "CHECK" ||
                        newValues.navisionRepresentativeTask === "CHECK" ||
                        newValues.zepterRank === "NOTSET")
                ) {
                    if (
                        newValues.vipNumber &&
                        newValues.vipRegistrationDate &&
                        newValues.recommenderVipNumber &&
                        newValues.navisionClubMemberTask === "CHECK" &&
                        newValues.vipNumber !== "" &&
                        newValues.vipRegistrationDate !== "" &&
                        newValues.recommenderVipNumber !== ""
                    ) {
                        newValues.navisionClubMemberTask = "EXIST";
                        newValues.zepterRank = "CLUBMEMBER";
                    }

                    if (
                        newValues.representativeNumber &&
                        newValues.representativeRegistrationDate &&
                        newValues.managerRepresentativeNumber &&
                        newValues.zepterRank === "CLUBMEMBER" &&
                        newValues.navisionRepresentativeTask === "CHECK" &&
                        newValues.representativeNumber !== "" &&
                        newValues.representativeRegistrationDate !== "" &&
                        newValues.managerRepresentativeNumber !== ""
                    ) {
                        newValues.navisionRepresentativeTask = "EXIST";
                        newValues.zepterRank = "REPRESENTATIVE";
                    } else if (
                        newValues.representativeNumber &&
                        newValues.representativeRegistrationDate &&
                        newValues.managerRepresentativeNumber &&
                        newValues.zepterRank === "CLUBMEMBER" &&
                        newValues.navisionRepresentativeTask === "CHECK" &&
                        newValues.representativeNumber === "" &&
                        newValues.representativeRegistrationDate === "" &&
                        newValues.managerRepresentativeNumber === ""
                    ) {
                        newValues.navisionRepresentativeTask = "NOTSET";
                    }
                }
                updateClubMember(newValues).then(
                    (response) => {
                        if (
                            values.zepterRank === "NOTSET" &&
                            values.zepterRank !== newValues.zepterRank
                            // &&window.confirm("Értesítő e-mail küldése?")
                        ) {
                            const mailLink =
                                "mailto:" +
                                newValues.email +
                                "?subject=%E2%9C%85%20zepterclub.hu%20ellen%C5%91rz%C3%A9s%20megt%C3%B6rt%C3%A9nt&body=Kedves%20" +
                                capitalizeFirstLetters(
                                    newValues.lastName +
                                        " " +
                                        newValues.firstName
                                ) +
                                "!%0D%0A%0D%0AMegt%C3%B6rt%C3%A9nt%20a%20zepterclub.hu%20adatb%C3%A1zis%20ellen%C5%91rz%C3%A9se%2C%20most%20m%C3%A1r%20be%20tud%20l%C3%A9pni%20az%20oldalra.%0D%0A%0D%0AK%C3%B6sz%C3%B6nj%C3%BCk%20a%20t%C3%BCrelm%C3%A9t!%0D%0A%0D%0A%C3%9Cdv%C3%B6zlettel%2C%0D%0AZepter%20Club%20Live%20100%20csapata";
                            const win = window.open(mailLink);
                            if (win && !win.closed) {
                                win.close();
                            }
                        }
                        console.log(response.data);
                        hideDialog();
                    },
                    (error) => {
                        console.log(error);
                        hideDialog();
                    }
                );
            } else {
            }
        },
    });

    const checkCuNumberField = () => {
        return formik.values.registrationStatus === "APPROVED" &&
            (formik.values.cuNumber === null || formik.values.cuNumber === "")
            ? "w-full mb-3 error-input"
            : "w-full mb-3";
    };

    const checkManagerRepresentativeNumberField = () => {
        return formik.values.representativeNumber !== null &&
            formik.values.representativeNumber !== "" &&
            (formik.values.managerRepresentativeNumber === null ||
                formik.values.managerRepresentativeNumber === "")
            ? "w-full mb-3 error-input"
            : "w-full mb-3";
    };

    const hideDialog = () => {
        setClubMemberAdminDetailDialogShow(false);
        setClubMemberDto(null);
        formik.resetForm();
        if (formChanged) {
            refreshClubMemberAdminFilteredList();
        }
    };

    return (
        <Dialog
            modal
            style={{ width: "90vw" }}
            visible={clubMemberAdminDetailDialogShow}
            closable
            maximizable
            position="top"
            header="ZepterClub tag adatok"
            onHide={() => {
                hideDialog();
            }}
        >
            <form onSubmit={formik.handleSubmit} className="p-fluid">
                <div className="formgrid grid">
                    <div className="field col-12 md:col-2">
                        <label
                            htmlFor="id"
                            className="block text-900 font-medium mb-2"
                        >
                            Azonosító
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled
                        />
                    </div>
                    {/* TODO select */}
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="registrationStatus"
                            className="block text-900 font-medium mb-2"
                        >
                            Státusz
                        </label>
                        <Dropdown
                            id="registrationStatus"
                            className="w-full mb-3"
                            {...formik.getFieldProps("registrationStatus")}
                            options={registrationStatuses}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            value={formik.values.registrationStatus}
                            placeholder="Válasszon státuszt"
                            disabled={isDisabled()}
                        />
                        {/* <InputText
                            className="w-full mb-3"
                            id="registrationStatus"
                            name="registrationStatus"
                            value={formik.values.registrationStatus}
                             onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        /> */}
                    </div>
                    {/* TODO Alert szerűen */}
                    <div className="field col-12 md:col-3">
                        <label
                            htmlFor="navisionClubMemberTask"
                            className="block text-900 font-medium mb-2"
                        >
                            Navision ZepterClub tag feladat
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="navisionClubMemberTask"
                            name="navisionClubMemberTask"
                            value={formik.values.navisionClubMemberTask}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled
                        />
                    </div>
                    <div className="field col-12 md:col-3">
                        <label
                            htmlFor="navisionRepresentativeTask"
                            className="block text-900 font-medium mb-2"
                        >
                            Navision Tanácsadó Feladat
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="navisionRepresentativeTask"
                            name="navisionRepresentativeTask"
                            value={formik.values.navisionRepresentativeTask}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled
                        />
                    </div>
                </div>

                <div className="formgrid grid">
                    <div className="field col-12 md:col-2">
                        <label
                            htmlFor="cuNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            CU szám
                        </label>
                        <InputText
                            className={checkCuNumberField()}
                            id="cuNumber"
                            name="cuNumber"
                            value={formik.values.cuNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="lastName"
                            className="block text-900 font-medium mb-2"
                        >
                            Vezetéknév
                        </label>
                        <InputText
                            className="w-full mb-3"
                            autoFocus
                            id="lastName"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <small className="p-error">
                                {formik.errors.lastName.toString()}
                            </small>
                        ) : null}
                    </div>

                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="firstName"
                            className="block text-900 font-medium mb-2"
                        >
                            Keresztnév
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="firstName"
                            name="firstName"
                            value={formik.values.firstName}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <small className="p-error">
                                {formik.errors.firstName.toString()}
                            </small>
                        ) : null}
                    </div>

                    <div className="field col-12 md:col-2">
                        <label
                            htmlFor="dateOfBirth"
                            className="block text-900 font-medium mb-2"
                        >
                            Születési dátum
                        </label>
                        <InputMask
                            className="w-full mb-3"
                            mask="9999.99.99"
                            id="dateOfBirth"
                            // value={formik.values.dateOfBirth}
                            value={
                                clubMemberDto ? clubMemberDto.dateOfBirth : null
                            }
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            // onChange={(val: any) => {
                            //     // console.log(val);
                            //     if (val && val.value !== "") {
                            //         const year = val.value.substring(0, 4);
                            //         const month = val.value.substring(5, 7);
                            //         const day = val.value.substring(8, 9);
                            //         const date = new Date(year, month - 1, day);
                            //         formik.setFieldValue("dateOfBirth", date);
                            //     } else {
                            //         formik.setFieldValue("dateOfBirth", null);
                            //     }
                            // }}
                        />
                        {formik.touched.dateOfBirth &&
                        formik.errors.dateOfBirth ? (
                            <small className="p-error">
                                {formik.errors.dateOfBirth
                                    .toString()
                                    .includes("Invalid")
                                    ? "Hibás születési dátum"
                                    : null}
                                {formik.errors.dateOfBirth
                                    .toString()
                                    .includes("Kötelező")
                                    ? "Kötelező kitölteni!"
                                    : null}
                                {formik.errors.dateOfBirth
                                    .toString()
                                    .includes("later")
                                    ? "A születési dátum túl korai"
                                    : null}
                                {formik.errors.dateOfBirth
                                    .toString()
                                    .includes("earlier ")
                                    ? "Regisztáció 18 éven felülieknek lehetséges"
                                    : null}
                            </small>
                        ) : null}
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-5">
                        <label
                            htmlFor="companyName"
                            className="block text-900 font-medium mb-2"
                        >
                            Cégnév
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="companyName"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                    {/* TODO legördülő */}
                    <div className="field col-12 md:col-2">
                        <label
                            htmlFor="companyType"
                            className="block text-900 font-medium mb-2"
                        >
                            Cégforma
                        </label>
                        <Dropdown
                            id="companyType"
                            className="w-full mb-3"
                            {...formik.getFieldProps("companyType")}
                            options={companyTypes}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            value={formik.values.companyType}
                            placeholder="Válasszon cégformát!"
                            disabled={isDisabled()}
                        />
                    </div>
                    <div className="field col-12 md:col-5">
                        <label
                            htmlFor="vatNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Adószám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="vatNumber"
                            name="vatNumber"
                            value={formik.values.vatNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-2">
                        <label
                            htmlFor="zip"
                            className="block text-900 font-medium mb-2"
                        >
                            Irányítószám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="zip"
                            name="zip"
                            value={formik.values.zip}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>

                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="city"
                            className="block text-900 font-medium mb-2"
                        >
                            Város
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="street"
                            className="block text-900 font-medium mb-2"
                        >
                            Utca
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="street"
                            name="street"
                            value={formik.values.street}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="email"
                            className="block text-900 font-medium mb-2"
                        >
                            E-mail
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="mobile"
                            className="block text-900 font-medium mb-2"
                        >
                            Mobilszám
                        </label>
                        <InputMask
                            className="w-full mb-3"
                            mask="+36999999999"
                            id="mobile"
                            value={clubMemberDto ? clubMemberDto.mobile : null}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                            <small className="p-error">
                                {formik.errors.mobile.toString()}
                            </small>
                        ) : null}
                    </div>

                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="telephone"
                            className="block text-900 font-medium mb-2"
                        >
                            Telefonszám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="telephone"
                            name="telephone"
                            value={formik.values.telephone}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>

                <div className="formgrid grid mb-0 pb-0">
                    <div className="field col-12 mb-0 pb-0 md:col-6">
                        <div className="formgrid grid mb-0 pb-0">
                            <div className="flex align-items-center justify-content-center col-2">
                                <Button
                                    type="button"
                                    icon="pi pi-refresh"
                                    className="p-button-rounded p-button-info p-button-sm mb-3"
                                    style={{
                                        maxWidth: "35px",
                                        maxHeight: "37px",
                                    }}
                                    aria-label="New"
                                    onClick={() => {
                                        getNewClubMemberNumber();
                                    }}
                                />
                            </div>
                            <div className="field col-10">
                                <label
                                    htmlFor="vipNumber"
                                    className="block text-900 text-left font-medium mb-2"
                                >
                                    ZepterClub tagsági szám
                                </label>
                                <InputText
                                    className="mb-3"
                                    id="vipNumber"
                                    name="vipNumber"
                                    value={formik.values.vipNumber}
                                    onChange={(e) => {
                                        setFormChanged(true);
                                        formik.handleChange(e);
                                    }}
                                    disabled={isDisabled()}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="field col-12 mb-0 pb-0 md:col-6">
                        <label
                            htmlFor="vipRegistrationDate"
                            className="block text-900 font-medium mb-2"
                        >
                            ZepterClub tagság regisztáció dátuma
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="vipRegistrationDate"
                            name="vipRegistrationDate"
                            value={formik.values.vipRegistrationDate}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="recommenderVipNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Ajánlói ZepterClub tagsági szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="recommenderVipNumber"
                            name="recommenderVipNumber"
                            value={formik.values.recommenderVipNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            // readOnly
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="recommenderRepresentativeNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Ajánlói kereskedő szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="recommenderRepresentativeNumber"
                            name="recommenderRepresentativeNumber"
                            value={
                                formik.values.recommenderRepresentativeNumber
                            }
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="receivedRecommenderVipNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Kapott ajánlói ZepterClub tagsági szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="receivedRecommenderVipNumber"
                            name="receivedRecommenderVipNumber"
                            value={formik.values.receivedRecommenderVipNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly
                        />
                    </div>
                    {/* TODO ha eltérés van a két számban jelölni */}
                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="receivedRecommenderRepresentativeNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Kapott ajánlói kereskedő szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="receivedRecommenderRepresentativeNumber"
                            name="receivedRecommenderRepresentativeNumber"
                            value={
                                formik.values
                                    .receivedRecommenderRepresentativeNumber
                            }
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="representativeNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Kereskedő szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="representativeNumber"
                            name="representativeNumber"
                            value={formik.values.representativeNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly={
                                formik.values.navisionRepresentativeTask !==
                                    "CHECK" &&
                                formik.values.navisionRepresentativeTask !==
                                    "NOTSET"
                            }
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="representativeRegistrationDate"
                            className="block text-900 font-medium mb-2"
                        >
                            Tanácsadó regisztáció dátuma
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="representativeRegistrationDate"
                            name="representativeRegistrationDate"
                            value={formik.values.representativeRegistrationDate}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly={
                                formik.values.navisionRepresentativeTask !==
                                    "CHECK" &&
                                formik.values.navisionRepresentativeTask !==
                                    "NOTSET"
                            }
                        />
                    </div>
                    {/* TODO jelölni ha üres */}
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="managerRepresentativeNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Menedzser szám
                        </label>
                        <InputText
                            className={checkManagerRepresentativeNumberField()}
                            id="managerRepresentativeNumber"
                            name="managerRepresentativeNumber"
                            value={formik.values.managerRepresentativeNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="ssnNUmber"
                            className="block text-900 font-medium mb-2"
                        >
                            TAJ szám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="ssnNUmber"
                            name="ssnNUmber"
                            value={formik.values.ssnNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="taxNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Adóazonosító
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="taxNumber"
                            name="taxNumber"
                            value={formik.values.taxNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                        {formik.touched.taxNumber && formik.errors.taxNumber ? (
                            <small className="p-error">
                                {formik.errors.taxNumber.toString()}
                            </small>
                        ) : null}
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="personalAccountNumber"
                            className="block text-900 font-medium mb-2"
                        >
                            Bankszámlaszám
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="personalAccountNumber"
                            name="personalAccountNumber"
                            value={formik.values.personalAccountNumber}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            disabled={isDisabled()}
                        />
                    </div>
                </div>
                {/* TODO csak akkor jelenjen meg ez a szekció ha van lehetséges */}
                {clubMemberDto &&
                clubMemberDto.potientialCustomerMatchingList &&
                clubMemberDto.potientialRepresentativeMatchingList &&
                (clubMemberDto.potientialCustomerMatchingList.length !== 0 ||
                    clubMemberDto.potientialRepresentativeMatchingList
                        .length !== 0) ? (
                    <div className="formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="potientialCustomerMatchingList"
                                className="block text-900 font-medium mb-2"
                            >
                                Lehetséges vásárló
                            </label>
                            <DataTable
                                className="w-full mb-3"
                                value={
                                    clubMemberDto
                                        ? clubMemberDto.potientialCustomerMatchingList
                                        : null
                                }
                                emptyMessage=""
                            >
                                <Column
                                    field="customerNumber"
                                    header="Vevőszám"
                                ></Column>
                                <Column
                                    field="vipNumber"
                                    header="ZepterClub tagsági szám"
                                ></Column>
                            </DataTable>
                        </div>
                        <div className="field col-12 md:col-6">
                            <label
                                htmlFor="potientialRepresentativeMatchingList"
                                className="block text-900 font-medium mb-2"
                            >
                                Lehetséges kereskedő
                            </label>
                            <DataTable
                                className="w-full mb-3"
                                value={
                                    clubMemberDto
                                        ? clubMemberDto.potientialRepresentativeMatchingList
                                        : null
                                }
                                emptyMessage=""
                            >
                                <Column
                                    field="customerNumber"
                                    header="Vevőszám"
                                ></Column>
                                <Column
                                    field="representativeExternalNumber"
                                    header="Kereskedő szám"
                                ></Column>
                            </DataTable>
                        </div>
                    </div>
                ) : null}
                {/* TODO link jelenjen meg popuppal */}
                <div className="formgrid grid">
                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="acceptedClubMemberGtac"
                            className="block text-900 font-medium mb-2"
                        >
                            Klubtag ÁSZF
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="acceptedClubMemberGtac"
                            name="acceptedClubMemberGtac"
                            value={formik.values.acceptedClubMemberGtac}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly
                        />
                    </div>

                    <div className="field col-12 md:col-6">
                        <label
                            htmlFor="acceptedConsultantGtac"
                            className="block text-900 font-medium mb-2"
                        >
                            Tanácsadó ÁSZF
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="acceptedConsultantGtac"
                            name="acceptedConsultantGtac"
                            value={formik.values.acceptedConsultantGtac}
                            onChange={(e) => {
                                setFormChanged(true);
                                formik.handleChange(e);
                            }}
                            readOnly
                        />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="created"
                            className="block text-900 font-medium mb-2"
                        >
                            Létrehozva
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="created"
                            name="created"
                            value={clubMemberDto ? clubMemberDto.created : null}
                            disabled
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="modified"
                            className="block text-900 font-medium mb-2"
                        >
                            Módosítva
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="modified"
                            name="modified"
                            value={
                                clubMemberDto ? clubMemberDto.modified : null
                            }
                            disabled
                        />
                    </div>
                    <div className="field col-12 md:col-4">
                        <label
                            htmlFor="modifiedBy"
                            className="block text-900 font-medium mb-2"
                        >
                            Utolsó módosító
                        </label>
                        <InputText
                            className="w-full mb-3"
                            id="modifiedBy"
                            name="modifiedBy"
                            value={
                                clubMemberDto ? clubMemberDto.modifiedBy : null
                            }
                            disabled
                        />
                    </div>
                </div>
                <Divider />
                <div className="formgrid grid">
                    <div className="field col-12 md:col-4 lg:col-3">
                        <Button
                            className="p-button-secondary"
                            label="Bezárás"
                            icon="pi pi-arrow-circle-left"
                            onClick={() => {
                                hideDialog();
                            }}
                        />
                    </div>
                    {/* TODO jogosultság reduxe */}
                    {/* {isDisabled() ? null : (
                        <div className="field col-12 md:col-4 lg:col-3">
                            <Button
                                className="p-button-help"
                                label="Szinkronizálás"
                                icon="pi pi-sync"
                            />
                        </div>
                    )} */}
                    {isDisabled() ? null : (
                        <div className="field col-12 md:col-4 lg:col-3">
                            <Button
                                className="p-button-success"
                                label="Mentés"
                                icon="pi pi-check"
                                type="submit"
                                disabled={!formChanged}
                            />
                        </div>
                    )}
                </div>
            </form>
        </Dialog>
    );
};

export default ClubMemberAdminDetail;
