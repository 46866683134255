import axios from 'axios';
import { server } from '../../config/endpoints';
import { acceptedClubMemberGtac } from '../../config/gtac';

const API_URL = server;

export const getPassword = (email: string) => {
    return axios
        .post(API_URL + '/api/getpassword', {
            email,
            acceptedClubMemberGtac,
            receivedRecommenderRepresentativeNumber:
                sessionStorage.getItem('cc'),
            receivedRecommenderVipNumber: sessionStorage.getItem('cm'),
        })
        .then((response) => {
            return response.data;
        });
};

export const checkChangePasswordId = (changePasswordId: any) => {
    return axios
        .get(API_URL + '/api/checkchangepasswordid/' + changePasswordId)
        .then((response) => {
            return response;
        });
};

export const changePassword = (uuid: string, password: string) => {
    return axios
        .post(API_URL + '/api/changepassword', {
            uuid,
            password,
            acceptedClubMemberGtac,
        })
        .then((response) => {
            return response.data;
        });
};
