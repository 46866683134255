import { locale, addLocale } from 'primereact/api';

export const setLocalHU = () => {
    // https://www.primefaces.org/primereact/locale/
    addLocale('hu', {
        firstDayOfWeek: 1,
        dayNames: [
            'vasárnap',
            'hétfő',
            'kedd',
            'szerda',
            'csütörtök',
            'péntek',
            'szombat',
        ],
        dayNamesShort: [
            'vasárnap',
            'hétfő',
            'kedd',
            'szerda',
            'csütörtök',
            'péntek',
            'szombat',
        ],
        dayNamesMin: ['V', 'H', 'K', 'Sze', 'CS', 'P', 'Szo'],
        monthNames: [
            'január',
            'február',
            'március',
            'április',
            'május',
            'június',
            'július',
            'augusztus',
            'szeptember',
            'október',
            'november',
            'december',
        ],
        monthNamesShort: [
            'jan.',
            'febr.',
            'márc.',
            'ápr.',
            'máj.',
            'jún.',
            'júl.',
            'aug.',
            'szept.',
            'okt.',
            'nov.',
            'dec.',
        ],
        today: 'Ma',
        weak: 'gyenge',
        medium: 'közepes',
        strong: 'erős',
        clear: 'Törlés',
        apply: 'Elfogad',
        addRule: 'Szabály hozzáadása',
        removeRule: 'Szabály törlése',
        passwordPrompt: 'Adja meg jelszavát!',
        matchAll: 'Összes egyező',
        matchAny: 'Valamelyik egyezik',
        emptyMessage: 'Nincs eredmény',
        emptyFilterMessage: 'Nincs eredmény',
        startsWith: 'Ezzel kezdődik',
        contains: 'Tartalamzza',
        notContains: 'Nem tartalamzza',
        endsWith: 'Ezzel végződik',
        equals: 'Egyenlő',
        notEquals: 'Nem egyenlő',
    });

    locale('hu');
};
