import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { StatusCodes } from "http-status-codes";
import {
    setAuthToken,
    getCurrentUser,
    logout,
} from "../../../../service/dashboard/AuthService";
import { readProduct } from "../../../../service/dashboard/admin/webshop/ProductService";
import {
    saveProductImage,
    saveProductImageOrderList,
    deleteProductImage,
} from "../../../../service/dashboard/admin/webshop/ProductImageService";
import { OrderList } from "primereact/orderlist";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

const ProductImageAdminDetail: React.FC<any> = (props: any) => {
    let navigate = useNavigate();
    // TODO csak ADMIN és PRODUCTMANAGER tudjon menteni
    setAuthToken();

    const user = getCurrentUser();
    const [productImageDto, setProductImageDto] = useState<any>();
    const [productImages, setProductImages] = useState([]);
    const [uploadFileName, setUploadFileName] = useState<string>("");
    const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

    const handleClick = () => {
        if (hiddenFileInputRef.current) hiddenFileInputRef.current.click();
    };

    //TODO van vagy nincs
    const productId: any = props.id;
    // console.log(productId);

    // const hideDialog = () => {
    //     setProductImageDto(null);
    //     setUploadFileName("");
    //     formik.resetForm();
    // };

    const formik = useFormik({
        initialValues: {
            id: productImageDto && productImageDto.id ? productImageDto.id : "",
            productId: productId,
            orderNo:
                productImageDto && productImageDto.orderNo
                    ? productImageDto.orderNo
                    : "",
            image:
                productImageDto && productImageDto.image
                    ? productImageDto.image
                    : "",
            modifiedBy: user.email,
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: Yup.object({
            //TODO uniqe fájlnév ellenőrzése
            // orderNo: Yup.string().required("Kötelező kitölteni!"),
            image: Yup.mixed()
                .required("Kötelező fájlt kiválasztani!")
                .test(
                    "FILE_SIZE",
                    "A feltöltött fájl túl nagy, maximum 10 MB lehet!",
                    (value) => {
                        // console .log(value);
                        return value && value.size <= 10485760; //10 MB
                    }
                )
                .test(
                    "FILE_FORMAT",
                    "A feltöltött fájl nem png formátumú!",
                    (value) => value && value.type.includes("image/png")
                ),
        }),
        onSubmit: (values) => {
            // TODO és values.id nincs kitöltve, hogy csak új képekre legyen érvényes
            if (
                productImages &&
                productImages.length > 4 &&
                values.orderNo === ""
            ) {
                window.alert("Maximum 5 kép tölthető fel egy termékhez!");
            } else {
                if (
                    //TODO a kinti lista tud-e frissülni? Redux?
                    window.confirm("Menti a képet?")
                ) {
                    // console.log(values);
                    //alert(JSON.stringify(values, null, 2));
                    if (values.orderNo === "") {
                        values.orderNo = productImages.length + 1;
                    }
                    // console.log(values.orderNo);
                    saveProductImage(values).then(
                        (response) => {
                            // console.log(response.data);
                            // setProductImageDto(response.data);
                            setProductImageDto(null);
                            setUploadFileName("");
                            formik.resetForm();
                            refreshProductImages();
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                }
            }
        },
    });

    const refreshProductImages = () => {
        if (productId !== "") {
            readProduct(productId).then(
                (response) => {
                    console.log(response.data);
                    setProductImages(response.data.productImageList);
                    // setProductImageDto(response.data);
                    //TODO
                    // setUploadFileName();
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
        }
    };

    useEffect(() => {
        setProductImageDto(null);
        //beolvassuk az összes képet, ha van termék Id
        if (productId !== "") {
            readProduct(productId).then(
                (response) => {
                    // console.log(response.data);
                    setProductImages(response.data.productImageList);
                    // setProductImageDto(response.data);
                    //TODO
                    // setUploadFileName();
                },
                (error) => {
                    console.log(error.response.status);
                    if (error.response.status === StatusCodes.UNAUTHORIZED) {
                        //TODO login oldalon üzenet --> Lejárt a munkamenet, jelentkezzen be
                        logout();
                        navigate("/login?status=unauthorized");
                    }
                }
            );
        }
    }, [productId, navigate]);

    const imageTemplate = (image: any) => {
        return (
            <div className="product-item">
                <div className="flex-0 pr-2 product-order-no">
                    <h3>#{image.orderNo}</h3>
                </div>
                <div className="flex-1 image-container">
                    <img
                        src={`data:image/png;base64,${image.thumbnail}`}
                        alt={image.id}
                    />
                </div>
                <div className="flex-1 product-action">
                    <Button
                        className="p-button-rounded p-button-text"
                        type="button"
                        onClick={() => {
                            formik.setFieldValue("id", image.id);
                            formik.setFieldValue("orderNo", image.orderNo);
                            handleClick();
                        }}
                        icon="pi pi-pencil"
                        aria-label="Edit"
                    />
                    <Button
                        className="p-button-danger p-button-rounded p-button-text"
                        type="button"
                        onClick={(e) => {
                            if (window.confirm("Biztos törli a képet?")) {
                                // TODO hibaüzenet/hibakód, ha van kép vagy ár hozzákapcsolva
                                console.log(e.target);

                                deleteProductImage(image.id).then(
                                    (response) => {
                                        // console.log(response.data);
                                        const cleanedOrderList =
                                            productImages.filter(
                                                (productImage: any) => {
                                                    return (
                                                        productImage.id !==
                                                        response.data.id
                                                    );
                                                }
                                            );
                                        const orderList = cleanedOrderList.map(
                                            (
                                                productImage: any,
                                                index: number
                                            ) => {
                                                ++index;
                                                productImage.orderNo = index;
                                                return {
                                                    id: productImage.id,
                                                    orderNo: index.toString(),
                                                };
                                            }
                                        );
                                        saveProductImageOrderList({
                                            productImageOrderList: orderList,
                                        }).then(
                                            (response) => {
                                                // console.log(response.data);
                                                refreshProductImages();
                                                // setProductImageDto(response.data);
                                                // setProductImageDto(null);
                                            },
                                            (error) => {
                                                console.log(error);
                                            }
                                        );
                                    },
                                    (error) => {
                                        console.log(error);
                                        refreshProductImages();
                                    }
                                );
                            }
                        }}
                        icon="pi pi-trash"
                        aria-label="Delete"
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {productId !== "" ? (
                <>
                    <div className="text-center p-2 bg-green-100 border-round border-1 border-green-300 mb-3">
                        <i className="pi pi-info-circle mr-2" />A képek mérete
                        fix 500x500, transzparens png formátumú!
                    </div>
                    <div className="image-order-list">
                        <OrderList
                            value={productImages}
                            header="Termék képek listája"
                            dragdrop
                            listStyle={{ height: "auto" }}
                            dataKey="orderNo"
                            itemTemplate={imageTemplate}
                            // onClick={(e) => {
                            //     console.log(e);
                            // }}
                            onChange={(event) => {
                                setProductImages(event.value);
                                // console.log(productImages);
                                // console.log(event.value);
                                const orderList = event.value.map(
                                    (productImage: any, index: number) => {
                                        ++index;
                                        productImage.orderNo = index;
                                        return {
                                            id: productImage.id,
                                            orderNo: index.toString(),
                                        };
                                    }
                                );
                                saveProductImageOrderList({
                                    productImageOrderList: orderList,
                                }).then(
                                    (response) => {
                                        // console.log(response.data);
                                        // setProductImageDto(response.data);
                                        // setProductImageDto(null);
                                        //TODO frissíteni a listát
                                    },
                                    (error) => {
                                        console.log(error);
                                    }
                                );
                            }}
                        />
                    </div>
                    {productImages &&
                    productImages.length > 4 &&
                    formik.values.orderNo === "" ? null : (
                        <>
                            <Divider />
                            <form
                                onSubmit={formik.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="formgrid grid">
                                    {/* TODO kép feltöltés,ha nincs meg az 5 */}
                                    {/* TODO csak 1000x1000 png */}
                                    <div className="field col-12 md:col-6">
                                        {/* <label
    htmlFor="content"
    className="block text-900 font-medium mb-2"
    style={{ visibility: "hidden" }}
>
    Új kép kiválasztása
</label> */}
                                        <Button
                                            type="button"
                                            className="align-self-end w-full"
                                            icon={
                                                uploadFileName === ""
                                                    ? "pi pi-search"
                                                    : "pi pi-image"
                                            }
                                            label={
                                                uploadFileName === ""
                                                    ? "Új kép kiválasztása"
                                                    : uploadFileName
                                            }
                                            onClick={handleClick}
                                        />
                                        <input
                                            id="content"
                                            name="content"
                                            type="file"
                                            ref={hiddenFileInputRef}
                                            style={{ display: "none" }}
                                            accept=".png"
                                            onChange={(event) => {
                                                if (
                                                    event.currentTarget.files &&
                                                    event.currentTarget.files
                                                        .length > 0
                                                ) {
                                                    formik.setFieldValue(
                                                        "image",
                                                        event.currentTarget
                                                            .files[0]
                                                    );
                                                    setUploadFileName(
                                                        event.currentTarget
                                                            .files[0].name
                                                    );
                                                    // console.log(
                                                    //     event.currentTarget.files[0]
                                                    // );
                                                }
                                            }}
                                        />

                                        {formik.errors.image ? (
                                            <small className="p-error">
                                                {formik.errors.image.toString()}
                                            </small>
                                        ) : null}
                                    </div>
                                    {formik.values.image !== "" ? (
                                        <>
                                            {/* TODO jogosultság reduxe */}
                                            <div className="field col-12 md:col-6">
                                                <Button
                                                    className="p-button-success"
                                                    label={
                                                        formik.values
                                                            .orderNo === ""
                                                            ? "Új kép mentése"
                                                            : formik.values
                                                                  .orderNo +
                                                              "# kép Cseréjének mentése"
                                                    }
                                                    icon="pi pi-check"
                                                    type="submit"
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </form>
                        </>
                    )}
                </>
            ) : (
                <div>
                    Képek kezeléséhez először a termék alapinformációkat el kell
                    menteni!
                </div>
            )}
        </>
    );
};

export default ProductImageAdminDetail;
