import axios from 'axios';
import { server } from '../../config/endpoints';
import {
    replaceAccentCharacters,
    replacePhoneNotUsedCharacters,
} from '../../util/stringFormat';

const API_URL = server;

const createClubMemberSearchRequest = (formData: any) => {
    const result = {
        firstName: replaceAccentCharacters(formData.firstName),
        lastName: replaceAccentCharacters(formData.lastName),
        companyName: replaceAccentCharacters(
            formData.companyName + ' ' + formData.companyType
        ),
        companyType: formData.companyType,
        vatNumber: formData.vatNumber,
        zip: formData.zip,
        email: formData.email,
        phone: replacePhoneNotUsedCharacters(formData.phone),
    };
    return result;
};

export const clubMemberSearch = (formData: any) => {
    const request = createClubMemberSearchRequest(formData);
    return axios
        .post(API_URL + '/protected/search/clubmember', request)
        .then((response) => {
            // console.log(response.data);
            return response;
        });
};

type clubMemberSearchObj = {
    [key: string]: string | undefined;
};

export const validateFields = (values: any) => {
    const errors: clubMemberSearchObj = {};
    // console.log(values);
    if (
        values.lastName === '' &&
        values.firstName === '' &&
        values.zip &&
        values.zip.split('_').join('') === '' &&
        values.companyName === '' &&
        values.companyType === '' &&
        values.vatNumber &&
        values.vatNumber.split('_').join('').split('-').join('') === '' &&
        values.email === '' &&
        values.phone &&
        values.phone.split('_').join('').slice(3) === ''
    ) {
        console.log('egyik sincs kitöltve, nem lehet submitálni, error dialog');
    } else {
        if (
            values.lastName !== '' ||
            values.firstName !== '' ||
            (typeof values.zip !== 'undefined' &&
                values.zip.split('_').join('') !== '')
        ) {
            if (values.lastName === '') {
                errors.lastName = 'A vezetéknevet ki kell tölteni';
            } else if (values.lastName && values.lastName.length < 2) {
                errors.lastName = 'A vezetéknév túl rövid';
            }

            if (values.firstName === '') {
                errors.firstName = 'A keresztnevet ki kell tölteni';
            } else if (values.firstName && values.firstName.length < 3) {
                errors.firstName = 'A keresztnév túl rövid';
            }

            // TODO szám-e?
            // TODO megfelelő tartomány?
            if (
                typeof values.zip !== 'undefined' &&
                values.zip.split('_').join('') === ''
            ) {
                errors.zip = 'Az irányítószámot ki kell tölteni';
            } else if (
                typeof values.zip !== 'undefined' &&
                values.zip.split('_').join('').length !== 4
            ) {
                errors.zip = 'Az irányítószám túl rövid';
            }
        }

        if (
            values.companyName !== '' ||
            values.companyType !== '' ||
            (typeof values.vatNumber !== 'undefined' &&
                values.vatNumber.split('_').join('').split('-').join('') !== '')
        ) {
            if (values.companyName === '') {
                errors.companyName = 'A cégnevet ki kell tölteni';
            } else if (values.companyName && values.companyName.length < 2) {
                errors.companyName = 'A cégnév túl rövid';
            }

            //TODO extra, hogy lista tartalma-e
            if (values.companyType === '') {
                errors.companyType = 'A cégformát ki kell választani';
            }

            // TODO számok-e
            // TODO megfelelő formátum?
            if (
                typeof values.zip !== 'undefined' &&
                values.vatNumber.split('_').join('').split('-').join('') === ''
            ) {
                errors.vatNumber = 'Az adószámot ki kell tölteni';
            } else if (
                typeof values.zip !== 'undefined' &&
                values.vatNumber.split('_').join('').split('-').join('')
                    .length < 8
            ) {
                errors.vatNumber = 'Az adószám legalább 8 szám hosszú';
            }
        }

        if (
            values.email !== '' &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = 'Az e-mail cím hibás';
        }

        if (
            typeof values.phone !== 'undefined' &&
            values.phone.split('_').join('').slice(3) !== ''
        ) {
            let phone = values.phone.split('_').join('');
            if (phone && phone.length < 11) {
                errors.phone = 'A telefonszám túl rövid';
            } else if (
                phone &&
                phone.length === 12 &&
                !phone.includes('+3620') &&
                !phone.includes('+3630') &&
                !phone.includes('+3631') &&
                !phone.includes('+3650') &&
                !phone.includes('+36360') &&
                !phone.includes('+3670')
            ) {
                errors.phone = 'A mobil telefonszám hibás';
            }
        }
    }

    // console.log('values: ', values);
    // console.log('errors: ', errors);
    return errors;
};
