import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setActiveMenuItem } from "../../state/dashboard/navigationSlice";
import { setAuthToken } from "../../service/dashboard/AuthService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { BlockUI } from "primereact/blockui";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import {
    readCitiesByZipCode,
    readStreetsByZipCodeAndCity,
} from "../../service/home/AddressService";
import {
    checkVipNumber,
    checkEmail,
    checkMobile,
    checkTelephone,
    checkVatNumber,
    invitation1,
    invitation2,
} from "../../service/dashboard/ClubMemberInvitationService";
import {
    replacePhoneNotUsedCharacters,
    replaceVatNumberNotUsedCharacters,
    replaceVipNumberNotUsedCharacters,
} from "../../util/stringFormat";
import MessageDialog from "../../component/MessageDialog";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../css/Registration.css";
import pageTeaserClubMemberInvitationImage from "../../asset/page-teaser-clubmember-invitation.png";

interface StateType {
    vipNumber: string;
    mobile: string;
    telephone: string;
    email: string;
    emailError: boolean;
    vatNumber: string;
}

const initialStates: StateType = {
    vipNumber: "",
    mobile: "",
    telephone: "",
    email: "",
    emailError: false,
    vatNumber: "",
};

const ClubMemberInvitation: React.FC = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("clubmemberinvitation"));
    setAuthToken();

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const [recommenderName, setRecommenderName] = useState<string | null>(null);
    const [recommenderVipNumber, setRecommenderVipNumber] = useState<
        string | null
    >(null);
    const [
        recommenderRepresentativeNumber,
        setRecommenderRepresentativeNumber,
    ] = useState<string | null>(null);

    useEffect(() => {
        if (recommenderName === null && userProfile && userProfile.userName) {
            setRecommenderName(userProfile.userName);
            // console.log("recommenderName: " + userProfile.userName);
        }
        if (
            recommenderVipNumber === null &&
            userProfile &&
            userProfile.vipNumber
        ) {
            setRecommenderVipNumber(userProfile.vipNumber);
            // console.log("recommenderVipNumber: " + userProfile.vipNumber);
        }
        if (
            recommenderRepresentativeNumber === null &&
            userProfile &&
            userProfile.representativeNumber
        ) {
            setRecommenderRepresentativeNumber(
                userProfile.representativeNumber
            );
            // console.log(
            //     "recommenderRepresentativeNumber: " +
            //         userProfile.representativeNumber
            // );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    const [blockedPage, setBlockedPage] = useState<boolean>(false);

    const showBlockedPage = () => {
        setBlockedPage(true);
    };

    const hideBlockedPage = () => {
        setBlockedPage(false);
    };

    // const [generalTermsDialogShow, setGeneralTermsDialogShow] =
    //     useState<Number>(0);
    // const [generalTermsDialogComponent, setGeneralTermsDialogComponent] =
    //     useState<string>("");

    // const [zipCodes, setZipCodes] = useState([]);
    const [states, setStates] = useState<StateType>(initialStates);

    const [dateOfBirth, setDateOfBirth] = useState<string | undefined>(
        undefined
    );
    const [cities, setCities] = useState<any>([]);
    const [streets, setStreets] = useState([]);

    const [messageDialogShow, setMessageDialogShow] = useState<Number>(0);
    const [messageDialogHeader, setMessageDialogHeader] = useState<string>("");
    const [messageDialogBody, setMessageDialogBody] = useState<string>("");
    const [messageDialogNavigateAction, setMessageDialogNavigateAction] =
        useState<string>("");
    const [messageDialogError, setMessageDialogError] =
        useState<boolean>(false);

    const maxDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 18)
    );
    const minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 122)
    );

    const companyTypes = [
        { label: "Válasszon cégformát!", value: "" },
        { label: "Ec. - Egyéni cég", value: "EC." },
        { label: "Ev. - Egyéni vállalkozás", value: "EV." },
        { label: "Bt. - Betéti társaság", value: "BT." },
        { label: "Kft. - Korlátolt Felelősségű Társaság", value: "KFT." },
        { label: "Nyrt. - Nyílt részvénytársaság", value: "NYRT." },
        { label: "Zrt. - Zárt részvénytársaság", value: "ZRT." },
        { label: "Egyesület", value: "EGY." },
        { label: "Szövetkezet", value: "SZVT." },
        { label: "Nonprofit szervezet", value: "NPF." },
        { label: "Egyéb", value: "EGYB." },
    ];

    const onZip1Change = (e: any) => {
        if (e.value && e.value !== "") {
            if (e.value.replace("_", "").length === 4) {
                formik1.setFieldValue("zip1", e.value);
                readCitiesByZipCode(e.value).then((response) => {
                    setCities(response.data);
                    setStreets([]);
                    if (response.data.length === 1) {
                        modifyCity1(response.data[0].label);
                        readStreetsByZipCodeAndCity(
                            e.value,
                            response.data[0].label
                        ).then((response) => {
                            setStreets(response.data);
                        });
                    } else if (response.data.length > 1) {
                    }
                });
            } else {
                setCities([]);
                setStreets([]);
            }
        } else {
            setCities([]);
            setStreets([]);
        }
    };

    const onZip2Change = (e: any) => {
        if (e.value && e.value !== "") {
            if (e.value.replace("_", "").length === 4) {
                formik2.setFieldValue("zip2", e.value);
                readCitiesByZipCode(e.value).then((response) => {
                    setCities(response.data);
                    setStreets([]);
                    if (response.data.length === 1) {
                        modifyCity2(response.data[0].label);
                        readStreetsByZipCodeAndCity(
                            e.value,
                            response.data[0].label
                        ).then((response) => {
                            setStreets(response.data);
                        });
                    } else if (response.data.length > 1) {
                    }
                });
            } else {
                setCities([]);
                setStreets([]);
            }
        } else {
            setCities([]);
            setStreets([]);
        }
    };

    const onCity1Change = (e: { value: any }) => {
        formik1.setFieldValue("city1", e.value);
        setStreets([]);
        readStreetsByZipCodeAndCity(
            formik1.getFieldProps("zip1").value,
            e.value
        ).then((response) => setStreets(response.data));
    };

    const onCity2Change = (e: { value: any }) => {
        formik2.setFieldValue("city2", e.value);
        setStreets([]);
        readStreetsByZipCodeAndCity(
            formik2.getFieldProps("zip2").value,
            e.value
        ).then((response) => setStreets(response.data));
    };

    const modifyCity1 = (s: string) => {
        formik1.setFieldValue("city1", s);
    };

    const modifyCity2 = (s: string) => {
        formik2.setFieldValue("city2", s);
    };

    const formik1 = useFormik({
        initialValues: {
            vipNumber1: "",
            lastName: "",
            firstName: "",
            // dateOfBirth: "",
            dateOfBirth: null,
            zip1: "",
            city1: "",
            street1: "",
            houseNumber1: "",
            email1: "",
            telephone1: null,
            mobile1: "",
            accepted1: false,
        },
        validationSchema: Yup.object({
            vipNumber1: Yup.string()
                .min(12, "Minimum 12 karakter kell, hogy legyen!")
                .max(12, "Maximum 12 karakter kell, hogy legyen!")
                .test(
                    "VIP szám teszt",
                    "Érvénytelen VIP szám vagy már klubtagé!",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.vipNumber) {
                            // it checks that the email is of the correct length
                            const vipNumber =
                                replaceVipNumberNotUsedCharacters(val);
                            if (vipNumber && vipNumber.length === 12) {
                                // it checks whether the email belongs to a vip member
                                const result = await checkVipNumber(val).then(
                                    (response) => {
                                        return (
                                            response &&
                                            response.status &&
                                            response.status === 200
                                        );
                                    }
                                );
                                const newState: StateType = { ...states };
                                newState.vipNumber = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            lastName: Yup.string().min(
                2,
                "Vezetéknévnek minimum 2 karakternek kell lenni!"
            ),
            firstName: Yup.string().min(
                3,
                "Keresztnévnek minimum 3 karakternek kell lenni!"
            ),
            dateOfBirth: Yup.date().nullable(true).min(minDate).max(maxDate),
            zip1: Yup.string(),
            city1: Yup.string(),
            street1: Yup.string(),
            houseNumber1: Yup.string(),
            email1: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás e-mail cím!")
                .required("Kötelező kitölteni!")
                .test(
                    "E-mail teszt",
                    "Az e-mail cím már klubtagé!",
                    async function (val) {
                        // && val !== states.email
                        //     states.emailError === true) ||
                        // states.emailError === false
                        if (val && val.length > 0) {
                            // it checks that the email is of the correct length
                            if (val && val.length > 4) {
                                // it checks whether the email belongs to a vip member
                                const result = await checkEmail(val).then(
                                    (response) => {
                                        const responseEmail =
                                            response &&
                                            response.status &&
                                            response.status === 200;
                                        // if (responseEmail === true) {
                                        //     const newState: StateType = {
                                        //         ...states,
                                        //     };
                                        //     newState.emailError = false;
                                        // } else {
                                        //     newState.emailError = true;
                                        // }
                                        // setStates(newState);
                                        return responseEmail;
                                    }
                                );
                                const newState: StateType = { ...states };
                                newState.email = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                ),
            telephone1: Yup.string()
                .nullable(true)
                .test(
                    "Telefonszám teszt",
                    "Érvénytelen telefonszám vagy már klubtagé!",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.telephone) {
                            // it checks that the telephone number is of the correct length
                            const telephone =
                                replacePhoneNotUsedCharacters(val);
                            if (telephone && telephone.length === 11) {
                                // it checks whether the telephone number belongs to a vip member
                                const result = await checkTelephone(
                                    telephone
                                ).then((response) => {
                                    return (
                                        response &&
                                        response.status &&
                                        response.status === 200
                                    );
                                });
                                const newState: StateType = { ...states };
                                newState.telephone = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            mobile1: Yup.string()
                .nullable(true)
                .test(
                    "Mobil szám teszt",
                    "Érvénytelen mobil szám vagy már klubtagé!",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.mobile) {
                            if (
                                val.includes("+36(20)") ||
                                val.includes("+36(30)") ||
                                val.includes("+36(31)") ||
                                val.includes("+36(50)") ||
                                val.includes("+36(60)") ||
                                val.includes("+36(70)")
                            ) {
                                // it checks that the mobile number is of the correct length
                                const mobile =
                                    replacePhoneNotUsedCharacters(val);
                                if (mobile && mobile.length === 12) {
                                    // it checks whether the mobile number belongs to a vip member
                                    const result = await checkMobile(
                                        mobile
                                    ).then((response) => {
                                        return (
                                            response &&
                                            response.status &&
                                            response.status === 200
                                        );
                                    });
                                    const newState: StateType = { ...states };
                                    newState.mobile = val;
                                    setStates(newState);
                                    return result === true;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            accepted1: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
        }),
        onSubmit: (values) => {
            // setFormData(values);
            //alert(JSON.stringify(values, null, 2));
            showBlockedPage();
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            // console.log(recommenderRepresentativeNumber);
            // console.log(recommenderVipNumber);
            invitation1(
                values,
                recommenderName,
                recommenderVipNumber,
                recommenderRepresentativeNumber
            ).then(
                (response) => {
                    // console.log(response);
                    formik1.resetForm();
                    setDateOfBirth(undefined);
                    formik2.resetForm();
                    setStates(initialStates);
                    hideBlockedPage();
                    setMessageDialogError(false);
                    setMessageDialogHeader("Sikeres Meghívás! Utolsó lépés!");
                    setMessageDialogBody(
                        "Kérlek szólj a meghívottnak, hogy ellenőrizze az email fiókját és fejezze be a regisztrációt a linkre kattintva."
                    );
                    //TODO ellenőrizni
                    // setMessageDialogNavigateAction("../login");
                    setMessageDialogShow(Date.now());
                },
                (error) => {
                    hideBlockedPage();
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessageDialogError(true);
                    setMessageDialogHeader("Meghívási hiba!");
                    if (responseMessage === "Network Error") {
                        setMessageDialogBody(
                            "Hálozati hiba, kérlek próbáld meg pár perc múlva a meghívást."
                        );
                    } else {
                        setMessageDialogBody(responseMessage);
                    }
                    setMessageDialogNavigateAction("");
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    const formik2 = useFormik({
        initialValues: {
            vipNumber2: "",
            companyName: "",
            companyType: "",
            vatNumber: "",
            zip2: "",
            city2: "",
            street2: "",
            houseNumber2: "",
            email2: "",
            telephone2: null,
            mobile2: null,
            accepted2: false,
        },
        validationSchema: Yup.object({
            vipNumber2: Yup.string()
                .min(12, "Minimum 12 karakter kell, hogy legyen!")
                .max(12, "Maximum 12 karakter kell, hogy legyen!")
                .test(
                    "VIP szám teszt",
                    "Érvénytelen VIP szám vagy már klubtagé!",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.vipNumber) {
                            // it checks that the email is of the correct length
                            const vipNumber =
                                replaceVipNumberNotUsedCharacters(val);
                            if (vipNumber && vipNumber.length === 12) {
                                // it checks whether the email belongs to a vip member
                                const result = await checkVipNumber(val).then(
                                    (response) => {
                                        return (
                                            response &&
                                            response.status &&
                                            response.status === 200
                                        );
                                    }
                                );
                                const newState: StateType = { ...states };
                                newState.vipNumber = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            companyName: Yup.string(),
            companyType: Yup.string(),
            vatNumber: Yup.string().test(
                "Adószám teszt",
                "Érvénytelen adószám vagy már klubtagé!",
                async function (val) {
                    if (val && val.length > 0 && val !== states.vatNumber) {
                        // it checks that the email is of the correct length
                        const vatNumberNaked =
                            replaceVatNumberNotUsedCharacters(val);
                        // console.log(vatNumberNaked?.length);
                        if (vatNumberNaked && vatNumberNaked.length === 11) {
                            // it checks whether the email belongs to a vip member
                            const result = await checkVatNumber(val).then(
                                (response) => {
                                    return (
                                        response &&
                                        response.status &&
                                        response.status === 200
                                    );
                                }
                            );
                            const newState: StateType = { ...states };
                            newState.vatNumber = val;
                            setStates(newState);
                            return result === true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                }
            ),
            zip2: Yup.string(),
            city2: Yup.string(),
            street2: Yup.string(),
            email2: Yup.string()
                .min(5, "Minimum 5 karakter kell, hogy legyen!")
                .email("Hibás e-mail cím!")
                .required("Kötelező kitölteni!")
                .test(
                    "E-mail teszt",
                    "Az e-mail cím már klubtagé!",
                    async function (val) {
                        //  && val !== states.email
                        if (val && val.length > 0) {
                            // it checks that the email is of the correct length
                            if (val && val.length > 4) {
                                // it checks whether the email belongs to a vip member
                                const result = await checkEmail(val).then(
                                    (response) => {
                                        return (
                                            response &&
                                            response.status &&
                                            response.status === 200
                                        );
                                    }
                                );
                                const newState: StateType = { ...states };
                                newState.email = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    }
                ),
            telephone2: Yup.string()
                .nullable(true)
                .test(
                    "Telefonszám teszt",
                    "Érvénytelen telefonszám vagy már klubtagé!",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.telephone) {
                            // it checks that the telephone number is of the correct length
                            const telephone =
                                replacePhoneNotUsedCharacters(val);
                            if (
                                telephone &&
                                telephone.length === 11 &&
                                val !== states.telephone
                            ) {
                                // it checks whether the telephone number belongs to a vip member
                                const result = await checkTelephone(
                                    telephone
                                ).then((response) => {
                                    return (
                                        response &&
                                        response.status &&
                                        response.status === 200
                                    );
                                });
                                const newState: StateType = { ...states };
                                newState.telephone = val;
                                setStates(newState);
                                return result === true;
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            mobile2: Yup.string()
                .nullable(true)
                .test(
                    "Mobil szám teszt",
                    "Érvénytelen mobil szám",
                    async function (val) {
                        if (val && val.length > 0 && val !== states.mobile) {
                            if (
                                val.includes("+36(20)") ||
                                val.includes("+36(30)") ||
                                val.includes("+36(31)") ||
                                val.includes("+36(50)") ||
                                val.includes("+36(60)") ||
                                val.includes("+36(70)")
                            ) {
                                // it checks that the mobile number is of the correct length
                                const mobile =
                                    replacePhoneNotUsedCharacters(val);
                                if (mobile && mobile.length === 12) {
                                    // it checks whether the mobile number belongs to a vip member
                                    const result = await checkMobile(
                                        mobile
                                    ).then((response) => {
                                        return (
                                            response &&
                                            response.status &&
                                            response.status === 200
                                        );
                                    });
                                    const newState: StateType = { ...states };
                                    newState.mobile = val;
                                    setStates(newState);
                                    return result === true;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        } else {
                            return true;
                        }
                    }
                ),
            accepted2: Yup.boolean()
                .oneOf([true], "Kötelező elfogadni!")
                .required("Kötelező elfogadni!"),
        }),
        onSubmit: (values) => {
            // setFormData(values);
            //alert(JSON.stringify(values, null, 2));
            showBlockedPage();
            setMessageDialogError(false);
            setMessageDialogHeader("");
            setMessageDialogBody("");
            setMessageDialogNavigateAction("");
            invitation2(
                values,
                recommenderName,
                recommenderVipNumber,
                recommenderRepresentativeNumber
            ).then(
                (response) => {
                    // console.log(response);
                    formik1.resetForm();
                    setDateOfBirth(undefined);
                    formik2.resetForm();
                    setStates(initialStates);
                    hideBlockedPage();
                    setMessageDialogError(false);
                    setMessageDialogHeader("Sikeres Meghívás! Utolsó lépés!");
                    setMessageDialogBody(
                        "Kérlek szólj a meghívottnak, hogy ellenőrizze az email fiókját és fejezze be a regisztrációt a linkre kattintva."
                    );
                    //TODO ellenőrizni
                    // setMessageDialogNavigateAction("../login");
                    setMessageDialogShow(Date.now());
                },
                (error) => {
                    hideBlockedPage();
                    const responseMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setMessageDialogError(true);
                    setMessageDialogHeader("Meghívási hiba!");
                    if (responseMessage === "Network Error") {
                        setMessageDialogBody(
                            "Hálozati hiba, kérlek próbáld meg pár perc múlva a meghívást."
                        );
                    } else {
                        setMessageDialogBody(responseMessage);
                    }
                    setMessageDialogNavigateAction("");
                    setMessageDialogShow(Date.now());
                }
            );
        },
    });

    return (
        <>
            {/* <GeneralTermsDialog
                generalTermsDialogShow={generalTermsDialogShow}
                generalTermsDialogComponent={generalTermsDialogComponent}
            /> */}
            <MessageDialog
                messageDialogError={messageDialogError}
                messageDialogShow={messageDialogShow}
                messageDialogHeader={messageDialogHeader}
                messageDialogBody={messageDialogBody}
                messageDialogNavigateAction={messageDialogNavigateAction}
            />
            <BlockUI
                blocked={blockedPage}
                className="max-w-screen h-full z-1"
                fullScreen
                template={
                    <div
                        className="text-center text-teal-50"
                        style={{ fontSize: "2rem" }}
                    >
                        <i
                            className="pi pi-spin pi-spinner"
                            style={{ fontSize: "3rem" }}
                        />
                        <br />
                        Kérem várjon a meghívás befejezéséig!
                    </div>
                }
            />
            <div className="flex flex-wrap shadow-2">
                <div className="grid p-4 pb-0 lg:p-7 lg:pb-0">
                    <div className="col-12 md:col-6">
                        <div className="flex align-items-center justify-content-center w-auto">
                            <img
                                className="responsive fadeinleft animation-duration-500"
                                src={pageTeaserClubMemberInvitationImage}
                                alt="ZepterClub tag meghívás"
                            />
                        </div>
                    </div>

                    <div className="text-center col-12 md:col-6">
                        <h1 className="text-black-alpha-90 font-500 mb-3 sm:mt-3">
                            Meghívó a ZepterClub hűségprogramba
                        </h1>
                        <h3 className="text-black-alpha-50 line-height-3 mt-0 mb-3">
                            Hívjon meg új ZepterClub tagot egyszerűen és
                            ingyenesen! Töltse ki azokat a mezőket, amiket
                            biztosan tud! <br />
                            E-mail cím megadása és adatkezelési nyilatkozat
                            kipipálása kötelező.
                        </h3>
                    </div>
                </div>
                <div className="mt-0 w-full p-4 surface-card">
                    <TabView>
                        <TabPanel header="MAGÁNSZEMÉLY">
                            <form
                                onSubmit={formik1.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="formgrid grid">
                                    <div className="field col-12">
                                        <label
                                            htmlFor="vipNumber1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Klubtagsági kártyaszám
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="999999999999"
                                            id="vipNumber1"
                                            {...formik1.getFieldProps(
                                                "vipNumber1"
                                            )}
                                        />
                                        {/* <InputText
                                            className="w-full mb-3"
                                            id="vipNumber1"
                                            {...formik1.getFieldProps(
                                                "vipNumber1"
                                            )}
                                            onBlur={() => {
                                                formik1.setFieldValue(
                                                    "vipNumber1",
                                                    formik1.values.vipNumber1
                                                );
                                            }}
                                        /> */}
                                        {formik1.touched.vipNumber1 &&
                                        formik1.errors.vipNumber1 ? (
                                            <small className="p-error">
                                                {formik1.errors.vipNumber1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-5">
                                        <label
                                            htmlFor="lastName"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Vezetéknév
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            autoFocus
                                            id="lastName"
                                            name="lastName"
                                            value={formik1.values.lastName}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.lastName &&
                                        formik1.errors.lastName ? (
                                            <small className="p-error">
                                                {formik1.errors.lastName}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-5">
                                        <label
                                            htmlFor="firstName"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Keresztnév
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            id="firstName"
                                            name="firstName"
                                            value={formik1.values.firstName}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.firstName &&
                                        formik1.errors.firstName ? (
                                            <small className="p-error">
                                                {formik1.errors.firstName}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-2">
                                        <label
                                            htmlFor="dateOfBirth"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Születési idő
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="9999.99.99"
                                            id="dateOfBirth"
                                            value={dateOfBirth}
                                            // value={
                                            //     formik1.values.dateOfBirth
                                            //         ? formik1.values.dateOfBirth
                                            //         : undefined
                                            // }
                                            onChange={(val: any) => {
                                                // console.log(val.value);
                                                if (val) {
                                                    setDateOfBirth(val.value);
                                                    const year =
                                                        val.value.substring(
                                                            0,
                                                            4
                                                        );
                                                    const month =
                                                        val.value.substring(
                                                            5,
                                                            7
                                                        );
                                                    const day =
                                                        val.value.substring(
                                                            8,
                                                            10
                                                        );
                                                    const date = new Date(
                                                        year,
                                                        month - 1,
                                                        day
                                                    );
                                                    formik1.setFieldValue(
                                                        "dateOfBirth",
                                                        date
                                                    );
                                                }
                                            }}
                                        />
                                        {formik1.touched.dateOfBirth &&
                                        formik1.errors.dateOfBirth ? (
                                            <small className="p-error">
                                                {formik1.errors.dateOfBirth.includes(
                                                    "Invalid"
                                                )
                                                    ? "Hibás születési dátum"
                                                    : null}
                                                {formik1.errors.dateOfBirth.includes(
                                                    "Kötelező"
                                                )
                                                    ? "Kötelező kitölteni!"
                                                    : null}
                                                {formik1.errors.dateOfBirth.includes(
                                                    "later"
                                                )
                                                    ? "A születési dátum túl korai"
                                                    : null}
                                                {formik1.errors.dateOfBirth.includes(
                                                    "earlier "
                                                )
                                                    ? "Meghívni csak 18 éven felülieket lehetséges"
                                                    : null}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="zip1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Irányítószám
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="9999"
                                            id="zip1"
                                            {...formik1.getFieldProps("zip1")}
                                            onChange={onZip1Change}
                                        />
                                        {formik1.touched.zip1 &&
                                        formik1.errors.zip1 ? (
                                            <small className="p-error">
                                                {formik1.errors.zip1}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="city1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Város
                                        </label>
                                        <Dropdown
                                            id="city1"
                                            className="w-full mb-3"
                                            {...formik1.getFieldProps("city1")}
                                            options={cities}
                                            onChange={onCity1Change}
                                            placeholder="Válasszon várost!"
                                            filter
                                        />
                                        {formik1.touched.city1 &&
                                        formik1.errors.city1 ? (
                                            <small className="p-error">
                                                {formik1.errors.city1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="street1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cím
                                        </label>
                                        <Dropdown
                                            id="street1"
                                            className="w-full mb-3"
                                            {...formik1.getFieldProps(
                                                "street1"
                                            )}
                                            options={streets}
                                            placeholder="Válasszon utcát!"
                                            filter
                                        />
                                        {formik1.touched.street1 &&
                                        formik1.errors.street1 ? (
                                            <small className="p-error">
                                                {formik1.errors.street1}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="houseNumber1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Házszám
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            id="houseNumber1"
                                            name="houseNumber1"
                                            value={formik1.values.houseNumber1}
                                            onChange={formik1.handleChange}
                                        />
                                        {formik1.touched.houseNumber1 &&
                                        formik1.errors.houseNumber1 ? (
                                            <small className="p-error">
                                                {formik1.errors.houseNumber1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="mobile1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Mobil
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="+36(99)999-9999"
                                            id="mobile1"
                                            {...formik1.getFieldProps(
                                                "mobile1"
                                            )}
                                        />
                                        {formik1.touched.mobile1 &&
                                        formik1.errors.mobile1 ? (
                                            <small className="p-error">
                                                {formik1.errors.mobile1}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="telephone1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Telefon
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="+3699999999"
                                            id="telephone1"
                                            {...formik1.getFieldProps(
                                                "telephone1"
                                            )}
                                        />
                                        {formik1.touched.telephone1 &&
                                        formik1.errors.telephone1 ? (
                                            <small className="p-error">
                                                {formik1.errors.telephone1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="email1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Email
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            id="email1"
                                            {...formik1.getFieldProps("email1")}
                                            onBlur={(val: any) => {
                                                const emailTrim =
                                                    formik1.values.email1
                                                        .trim()
                                                        .toLocaleLowerCase();
                                                formik1.setFieldValue(
                                                    "email1",
                                                    emailTrim
                                                );
                                            }}
                                        />
                                        {formik1.touched.email1 &&
                                        formik1.errors.email1 ? (
                                            <small className="p-error">
                                                {formik1.errors.email1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <div className="field-checkbox col">
                                            <Checkbox
                                                id="accepted1"
                                                name="accepted1"
                                                checked={
                                                    formik1.values.accepted1
                                                }
                                                onChange={formik1.handleChange}
                                            />
                                            <label htmlFor="accepted1">
                                                Nyilatkozom, hogy rendelkezem
                                                hozzájárulással a fenti adatok
                                                kezelésére.
                                            </label>
                                            {/* <label
                                                htmlFor="accepted1"
                                                className="cursor-pointer text-blue-700 hover:text-blue-500"
                                                onClick={() => {
                                                    setGeneralTermsDialogComponent(
                                                        "GeneralTermsClubMemberIvitation230719"
                                                    );
                                                    setGeneralTermsDialogShow(
                                                        Date.now()
                                                    );
                                                }}
                                            >
                                                <u>
                                                    Nyilatkozom, hogy
                                                    rendelkezem hozzájárulással
                                                    a fenti adatok kezelésére.
                                                </u>
                                            </label> */}
                                        </div>
                                        {formik1.touched.accepted1 &&
                                        formik1.errors.accepted1 ? (
                                            <small className="p-error">
                                                {formik1.errors.accepted1}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <Button
                                            label="Meghívás"
                                            className="w-full"
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </form>
                        </TabPanel>

                        <TabPanel header="VÁLLALKOZÁS">
                            <form
                                onSubmit={formik2.handleSubmit}
                                className="p-fluid"
                            >
                                <div className="formgrid grid">
                                    <div className="field col-12">
                                        <label
                                            htmlFor="vipNumber2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Klubtagsági kártyaszám
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="999999999999"
                                            id="vipNumber2"
                                            {...formik2.getFieldProps(
                                                "vipNumber2"
                                            )}
                                        />
                                        {formik2.touched.vipNumber2 &&
                                        formik2.errors.vipNumber2 ? (
                                            <small className="p-error">
                                                {formik2.errors.vipNumber2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="companyName"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cégnév
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            autoFocus
                                            id="companyName"
                                            name="companyName"
                                            value={formik2.values.companyName}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.companyName &&
                                        formik2.errors.companyName ? (
                                            <small className="p-error">
                                                {formik2.errors.companyName}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="companyType"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cégforma
                                        </label>
                                        <Dropdown
                                            id="companyType"
                                            className="w-full mb-3"
                                            {...formik2.getFieldProps(
                                                "companyType"
                                            )}
                                            options={companyTypes}
                                            onChange={formik2.handleChange}
                                            value={formik2.values.companyType}
                                            placeholder="Válasszon cégformát!"
                                        />
                                        {formik2.touched.companyType &&
                                        formik2.errors.companyType ? (
                                            <small className="p-error">
                                                {formik2.errors.companyType}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="vatNumber"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Adószám
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="99999999-9-99"
                                            id="vatNumber"
                                            value={formik2.values.vatNumber}
                                            onChange={formik2.handleChange}
                                        />
                                        {formik2.touched.vatNumber &&
                                        formik2.errors.vatNumber ? (
                                            <small className="p-error">
                                                {formik2.errors.vatNumber}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="zip2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Irányítószám
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="9999"
                                            id="zip2"
                                            {...formik2.getFieldProps("zip2")}
                                            onChange={onZip2Change}
                                        />
                                        {formik2.touched.zip2 &&
                                        formik2.errors.zip2 ? (
                                            <small className="p-error">
                                                {formik2.errors.zip2}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="city2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Város
                                        </label>
                                        <Dropdown
                                            id="city2"
                                            className="w-full mb-3"
                                            {...formik2.getFieldProps("city2")}
                                            options={cities}
                                            onChange={onCity2Change}
                                            placeholder="Válasszon várost!"
                                            filter
                                        />
                                        {formik2.touched.city2 &&
                                        formik2.errors.city2 ? (
                                            <small className="p-error">
                                                {formik2.errors.city2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="street2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Cím
                                        </label>
                                        <Dropdown
                                            id="street2"
                                            className="w-full mb-3"
                                            {...formik2.getFieldProps(
                                                "street2"
                                            )}
                                            options={streets}
                                            placeholder="Válasszon utcát!"
                                            filter
                                        />
                                        {formik2.touched.street2 &&
                                        formik2.errors.street2 ? (
                                            <small className="p-error">
                                                {formik2.errors.street2}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="houseNumber1"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Házszám
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            id="houseNumber2"
                                            name="houseNumber2"
                                            value={formik2.values.houseNumber2}
                                            onChange={formik2.handleChange}
                                        />

                                        {formik2.touched.houseNumber2 &&
                                        formik2.errors.houseNumber2 ? (
                                            <small className="p-error">
                                                {formik2.errors.houseNumber2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <label
                                            htmlFor="email2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Email
                                        </label>
                                        <InputText
                                            className="w-full mb-3"
                                            id="email2"
                                            {...formik2.getFieldProps("email2")}
                                            onBlur={(val: any) => {
                                                const emailTrim =
                                                    formik2.values.email2
                                                        .trim()
                                                        .toLocaleLowerCase();
                                                formik2.setFieldValue(
                                                    "email2",
                                                    emailTrim
                                                );
                                            }}
                                        />
                                        {formik2.touched.email2 &&
                                        formik2.errors.email2 ? (
                                            <small className="p-error">
                                                {formik2.errors.email2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="mobile2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Mobil
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="+36(99)999-9999"
                                            id="mobile2"
                                            {...formik2.getFieldProps(
                                                "mobile2"
                                            )}
                                        />
                                        {formik2.touched.mobile2 &&
                                        formik2.errors.mobile2 ? (
                                            <small className="p-error">
                                                {formik2.errors.mobile2}
                                            </small>
                                        ) : null}
                                    </div>
                                    <div className="field col-12 md:col-6">
                                        <label
                                            htmlFor="telephone2"
                                            className="block text-900 font-medium mb-2"
                                        >
                                            Telefon
                                        </label>
                                        <InputMask
                                            className="w-full mb-3"
                                            mask="+3699999999"
                                            id="telephone2"
                                            {...formik2.getFieldProps(
                                                "telephone2"
                                            )}
                                        />

                                        {formik2.touched.telephone2 &&
                                        formik2.errors.telephone2 ? (
                                            <small className="p-error">
                                                {formik2.errors.telephone2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-12 md:col-12">
                                        <div className="field-checkbox col">
                                            <Checkbox
                                                id="accepted2"
                                                name="accepted2"
                                                checked={
                                                    formik2.values.accepted2
                                                }
                                                onChange={formik2.handleChange}
                                            />
                                            <label htmlFor="accepted2">
                                                Nyilatkozom, hogy rendelkezem
                                                hozzájárulással a fenti adatok
                                                kezelésére.
                                            </label>
                                            {/* <label
                                                htmlFor="accepted2"
                                                className="cursor-pointer text-blue-700 hover:text-blue-500"
                                                onClick={() => {
                                                    setGeneralTermsDialogComponent(
                                                        "GeneralTermsClubMemberIvitation230719"
                                                    );
                                                    setGeneralTermsDialogShow(
                                                        Date.now()
                                                    );
                                                }}
                                            >
                                                <u>
                                                    Nyilatkozom, hogy
                                                    rendelkezem hozzájárulással
                                                    a fenti adatok kezelésére.
                                                </u>
                                            </label> */}
                                        </div>
                                        {formik2.touched.accepted2 &&
                                        formik2.errors.accepted2 ? (
                                            <small className="p-error">
                                                {formik2.errors.accepted2}
                                            </small>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <Button
                                            icon="pi pi-send"
                                            label="Meghívás"
                                            className="w-full"
                                            type="submit"
                                        />
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </>
    );
};

export default ClubMemberInvitation;
