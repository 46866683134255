import InitialState, { UpdateMainAction } from "../../types/redux/main";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: InitialState = {
    // loading: { blockPage: false, message: "" },
    loading: null,
};

export const mainSlice = createSlice({
    name: UpdateMainAction,
    initialState: initialState,
    reducers: {
        setLoadingOn: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        setLoadingOff: (state) => {
            state.loading = null;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setLoadingOn } = mainSlice.actions;
export const { setLoadingOff } = mainSlice.actions;
// You must export the reducer as follows for it to be able to be read by the store.
export default mainSlice.reducer;
