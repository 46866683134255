import axios from 'axios';
import { server } from '../../config/endpoints';
import { acceptedClubMemberGtac } from '../../config/gtac';
import {
    formatDate,
    replaceAccentCharacters,
    replacePhoneNotUsedCharacters,
} from '../../util/stringFormat';

const API_URL = server;

export const register1 = (
    formData: any,
    storedVipNumber: string | null,
    storedRepNumber: string | null,
    vipNumber: string | null,
    invitationId: string | null
) => {
    // console.log('Form data: ' + formData);
    let request = createRegistration1Request(
        formData,
        storedVipNumber,
        storedRepNumber,
        vipNumber,
        invitationId
    );
    return axios.post(API_URL + '/api/cl100', request).then((response) => {
        // console.log(response.data);
        return response;
    });
};

export const register2 = (
    formData: any,
    storedVipNumber: string | null,
    storedRepNumber: string | null,
    vipNumber: string | null,
    invitationId: string | null
) => {
    // console.log('Form data: ' + formData);
    let request = createRegistration2Request(
        formData,
        storedVipNumber,
        storedRepNumber,
        vipNumber,
        invitationId
    );
    return axios.post(API_URL + '/api/cl100', request).then((response) => {
        // console.log(response.data);
        return response;
    });
};

let createRegistration1Request = (
    formData: any,
    storedVipNumber: string | null,
    storedRepNumber: string | null,
    vipNumber: string | null,
    invitationId: string | null
) => {
    if (!storedVipNumber) {
        storedVipNumber = sessionStorage.getItem('cc');
    }
    if (!storedRepNumber) {
        storedRepNumber = sessionStorage.getItem('cm');
    }
    const result = {
        invitationId: invitationId,
        vipNumber: vipNumber,
        firstName: replaceAccentCharacters(formData.firstName),
        lastName: replaceAccentCharacters(formData.lastName),
        dateOfBirth: formatDate(formData.dateOfBirth),
        zip: formData.zip1,
        city: formData.city1,
        street: formData.street1,
        houseNumber: formData.houseNumber1,
        address: formData.address1,
        email: formData.email1,
        telephone: replacePhoneNotUsedCharacters(formData.telephone1),
        mobile: replacePhoneNotUsedCharacters(formData.mobile1),
        password: formData.password1,
        passwordAgain: formData.passwordAgain1,
        accepted: formData.accepted1,
        acceptedClubMemberGtac: acceptedClubMemberGtac,
        recommenderRepresentativeNumber: storedRepNumber,
        recommenderVipNumber: storedVipNumber,
    };
    // console.log(result);
    return result;
};

let createRegistration2Request = (
    formData: any,
    storedVipNumber: string | null,
    storedRepNumber: string | null,
    vipNumber: string | null,
    invitationId: string | null
) => {
    if (!storedVipNumber) {
        storedVipNumber = sessionStorage.getItem('cc');
    }
    if (!storedRepNumber) {
        storedRepNumber = sessionStorage.getItem('cm');
    }
    const result = {
        invitationId: invitationId,
        vipNumber: vipNumber,
        companyName: replaceAccentCharacters(formData.companyName),
        companyType: formData.companyType,
        vatNumber: formData.vatNumber,
        zip: formData.zip2,
        city: formData.city2,
        street: formData.street2,
        address: formData.address2,
        houseNumber: formData.houseNumber2,
        email: formData.email2,
        telephone: replacePhoneNotUsedCharacters(formData.telephone2),
        mobile: replacePhoneNotUsedCharacters(formData.mobile2),
        password: formData.password2,
        passwordAgain: formData.passwordAgain2,
        accepted: formData.accepted2,
        acceptedClubMemberGtac: acceptedClubMemberGtac,
        recommenderRepresentativeNumber: storedRepNumber,
        recommenderVipNumber: storedVipNumber,
    };
    return result;
};

export const readRecommender = (clubMemberId: any) => {
    return axios
        .get(API_URL + '/api/recommender?cm=' + clubMemberId)
        .then((response) => {
            // console.log(response);
            return response;
        });
};

export const checkRecommenderLink = (
    clubMemberId: any,
    clubConsultantId: any
) => {
    return axios
        .get(
            API_URL +
                '/api/checkrecommenderlink?cm=' +
                clubMemberId +
                '&cc=' +
                clubConsultantId
        )
        .then((response) => {
            // console.log(response);
            return response;
        });
};

// 200 - ok
// 404 - not exist
// 500 - error
export const readInvited = (invitationId: string) => {
    return axios
        .get(API_URL + '/api/clubmemberinvitation/read/' + invitationId)
        .then((response) => {
            // console.log(response);
            return response;
        });
};

export const checkValidationId = (validationId: any) => {
    return axios
        .get(API_URL + '/api/checkvalidationid/' + validationId)
        .then((response) => {
            // console.log(response);
            return response;
        });
};
