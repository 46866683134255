import axios from 'axios';
import { server } from '../../../../config/endpoints';

const API_URL = server;

export const readAllProducts = () => {
    return axios.get(API_URL + '/protected/admin/product').then((response) => {
        return response;
    });
};

export const readProduct = (id: string) => {
    return axios
        .get(API_URL + '/protected/admin/product/' + id)
        .then((response) => {
            return response;
        });
};

export const saveProduct = (formData: any) => {
    return axios
        .post(API_URL + '/protected/admin/product', formData)
        .then((response) => {
            return response;
        });
};

export const deleteProduct = (id: string) => {
    return axios
        .delete(API_URL + '/protected/admin/product/' + id)
        .then((response) => {
            return response;
        });
};
