import axios from 'axios';
import { server } from '../../config/endpoints';
import { acceptedConsultantGtac } from '../../config/gtac';

const API_URL = server;

export const register = (formData: any, email: string) => {
    let request = createRegistrationRequest(formData);
    //TODO védett végpont url frissíteni, alsó ago ellenőrizni
    return axios
        .put(API_URL + '/protected/consultant/create/' + email, request)
        .then((response) => {
            // console.log(response.data);
            return response;
        });
};

let createRegistrationRequest = (formData: any) => {
    const result = {
        accepted: formData.accepted,
        acceptedConsultantGtac: acceptedConsultantGtac,
    };
    return result;
};
